@import '../../assets/styles/variables';

.contact-item {
  gap: 57px;

  @include respondto(largeDesktopSm) {
    gap: 2.9688vw;
  }

  @include respondto(laptop13) {
    flex-direction: column;
    gap: 20px;
    padding: 15px 11px;
  }

  .line-border {
    @include respondto(laptop13) {
      margin: 0;
    }
  }

  .list-item-content {
    align-items: center;
    display: flex;
    flex-direction: row !important;
    gap: 0px;

    @include respondto(laptop13) {
      align-items: flex-start;
      flex-direction: column !important;
      gap: 17px;
    }
    .column-item {
      display: flex;
      flex-wrap: wrap;
      &:first-child {
        width: 54%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(2) {
        gap: 1rem;
        padding-left: 13%;
        padding-right: 69px;
        width: 34%;
        @include respondto(largeDesktopSm) {
          gap: 0.521vw;
          padding-left: 10.47vw;
          padding-right: 3.6vw;
        }

        @include respondto(laptop13) {
          justify-content: center;
          padding: 0;
          width: 100%;
        }
      }
    }

    .info-img {
      border-radius: 50%;
      height: 46px;
      margin-right: 20px;
      max-width: 46px;
      min-width: 46px;
      overflow: hidden;
      @include respondto(largeDesktopSm) {
        height: 2.3958vw;
        margin-right: 1.042vw;
        max-width: 2.3958vw;
        min-width: 2.3958vw;
      }
      @include respondto(laptop13) {
        margin: 0 auto;
      }

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .info-contact-details {
      align-items: center;
      column-gap: 4rem;
      display: flex;
      flex-direction: row;
      width: calc(100% - 66px);
      @include respondto(largeDesktopSm) {
        width: calc(100% - 3.4375vw);
      }
      @include respondto(laptop13) {
        align-items: center;
        flex-direction: column;
        justify-content: center;
        row-gap: 0.5rem;
        text-align: center;
        width: 100%;
      }

      .name {
        color: $black;
        font-size: $font-md;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        max-width: 30%;
        min-width: 30%;
      }
      .phone-numbers {
        max-width: 23%;
        min-width: 23%;
      }
      .phone-numbers a,
      .mail a {
        align-items: center;
        display: flex;
      }

      .phone-numbers,
      .mail {
        align-items: flex-start;
        display: flex;
        flex-direction: column;

        @include respondto(laptop13) {
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
        }

        svg {
          height: 15px;
          fill: $main-color;
          width: 15px;

          path {
            fill: $main-color;
          }
          @include respondto(phone) {
            height: 13px;
            width: 13px;
          }
        }

        a {
          color: $black;
          display: flex;
          font-size: $font-sm;
          font-style: normal;
          font-weight: 500;
          gap: 8px;
          line-height: normal;
          transition: 0.3s ease-in-out;

          @include respondto(laptop13) {
            border-right: none;
            padding-left: 0px;
          }
          @include respondto(phone) {
            font-size: 1.2rem;
          }
          &:first-of-type {
            padding-left: 0px;
          }

          &:last-of-type {
            border-right: none;
          }

          &:hover {
            color: $main-color-hover;
          }
        }
      }

      .mail {
        margin-bottom: 0px;
      }
    }

    .info-icons {
      display: flex;
      gap: 10px;
      margin-left: auto;
      max-width: max-content;

      @include respondto(laptop13) {
        justify-content: center;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
