@import '../../assets/styles/variables';

$spinner: $main-color;
$background: white;
$size: 50px;
.spiner-container {
  // position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
}
.btn-spinner {
  .spinner {
    animation: 1s linear 0s infinite normal none running spinner;
    border-color: $spinner $spinner $spinner transparent;
    border-image: initial;
    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
    height: 30px;
    opacity: 1;
    position: absolute;
    top: calc(50% - 15px);
    transform: translate(-50%, -50%);
    transition: opacity 0.2s ease-in-out 0s;
    width: 30px;
    z-index: 2;
  }
}
.btn-blue-spinner {
  height: 24px;
  margin-left: -30px;
  width: 30px;
  .spinner {
    animation: 1s linear 0s infinite normal none running spinner;
    border-color: $white $white $white transparent;
    border-image: initial;
    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
    height: 20px;
    opacity: 1;
    position: absolute;
    top: calc(50% - 10px);
    transform: translate(-50%, -50%);
    transition: opacity 0.2s ease-in-out 0s;
    width: 20px;
    z-index: 2;
    @include respondto(laptop13) {
      border-color: $black $black $black transparent;
    }
  }
  @include respondto(laptop13) {
    height: 30px;
    margin-left: 0;
  }
}
.btn-small {
  height: 24px;
  margin-left: calc(50% - 15px);
  width: 30px;
  .spinner {
    border-image: initial;
    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
    height: 20px;
    opacity: 1;
    position: absolute;
    top: calc(50% - 10px);
    transform: translate(-50%, -50%);
    transition: opacity 0.2s ease-in-out 0s;
    width: 20px;
    z-index: 2;
  }
  @include respondto(laptop13) {
    height: 30px;
    margin-left: 0;
  }
}
.spinner {
  animation: 1s linear 0s infinite normal none running spinner;
  border-color: $spinner $spinner $spinner transparent;
  border-image: initial;
  border-radius: 50%;
  border-style: solid;
  border-width: 5px;
  height: $size;
  height: 50px;
  opacity: 1;
  position: absolute;
  top: 50%;
  // left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s ease-in-out 0s;
  width: $size;
  width: 50px;
  z-index: 2;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
