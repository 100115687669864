@import '../../assets/styles/variables';

.agent-info-container {
  display: flex;
  flex-direction: column;
  gap: 35px;
  min-width: 768px;
  width: 100%;

  @include respondto(largeDesktopSm) {
    gap: 1.8229vw;
    min-width: 40vw;
  }

  @include respondto(laptop13) {
    min-width: 100%;
  }

  @include respondto(phone) {
    gap: 20px;
  }

  .form-container {
    background: $white;
    border-radius: 0 25px 25px 25px;
    box-shadow: $block-shadow;
    display: flex;
    gap: 48px;
    padding: 47px 35px 43px;

    @include respondto(largeDesktopSm) {
      border-radius: 0 1.302vw 1.302vw 1.302vw;
      gap: 2.5vw;
      padding: 2.4479vw 1.8229vw 2.2396vw;
    }

    @include respondto(laptop13) {
      border-radius: 25px;
    }

    @include respondto(phone) {
      flex-direction: column;
      gap: 20px;
      padding: 15px 11px;
    }

    .img-upload-block {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 20px;
      @include respondto(phone) {
        gap: 5px;
      }
      .image-container {
        position: relative;
        width: 100%;

        .custom-file-upload {
          align-items: center;
          background-color: $input-color;
          border: 1px solid $main-color;
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          height: 161px;
          justify-content: center;
          margin-left: auto;
          margin-right: auto;
          overflow: hidden;
          width: 161px;

          @include respondto(largeDesktopSm) {
            margin-left: auto;
            margin-right: auto;
          }

          @include respondto(laptopMd) {
            margin-top: 12px;
          }

          @include respondto(laptop13) {
            margin-top: 12px;
          }

          @include respondto(largeDesktopSm) {
            height: 8.385vw;
            width: 8.385vw;
          }

          svg {
            height: 6rem;
            fill: rgba($main-color, 0.3);
            stroke: rgba($main-color, 0.3);
            width: 6.9rem;
            @include respondto(largeDesktopSm) {
              height: 3.125vw;
              width: 3.594vw;
            }

            path {
              fill: rgba($main-color, 0.3);
              stroke: rgba($main-color, 0.3);
            }
          }

          img {
            height: 100%;
            object-fit: cover;
            width: 100%;
          }
        }
      }
    }

    .form-block {
      width: 100%;

      .title {
        color: $black;
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 36px;

        @include respondto(phone) {
          margin-bottom: 16px;
        }
      }

      form {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
      }
    }

    .user-name {
      color: $black;
      font-size: 1.8rem;
      font-weight: 600;
      margin-bottom: .5vw;

      @include respondto(phone) {
        font-size: 1.8rem;
        margin-bottom: 1rem;
        text-align: center;
      }
    }

    .user-info-item {
      align-items: center;
      color: $black;
      display: flex;
      font-size: $font-lg;
      font-weight: 500;
      gap: 16px;
      line-height: $font-lg;
      padding: 8px 0;
      @include respondto(phone) {
        font-size: 12px;
        justify-content: center;
        padding: 0 0 10px;
      }

      svg {
        height: 1.8rem;
        fill: $main-color;
        width: 1.8rem;

        @include respondto(phone) {
          height: 1.5rem;
          width: 1.5rem;
        }

        path {
          fill: $main-color;
        }
      }

      .number {
        padding-right: 8px;
      }
      a {
        color: $main-color;
        &:hover {
          color: $main-color-hover;
          text-decoration: underline;
        }
      }
    }
  }
}
