@import '../../assets/styles/variables';

.ads-form-container-block {
  .form-item {
    margin-bottom: 17px;
    @include respondto(largeDesktopSm) {
      margin-bottom: 0.885vw;
    }
  }

  .row {
    --bs-gutter-x: 3rem;

    .flex-range {
      align-items: center;
      display: flex;
      gap: 9px;
      width: 100%;
      @include respondto(largeDesktopSm) {
        gap: 0.469vw;
      }
    }
  }
}

.fieldset-block {
  &__hr {
    border: none;
    border-bottom: 1px solid $whisper;
    margin: 18px 0 31px;
    opacity: 1;
    @include respondto(largeDesktopSm) {
      margin: 0.938vw 0 1.615vw;
    }
  }

  // &:not(:last-of-type) {
  //     position: relative;

  //     &::after {
  //         content:'';
  //         position: absolute;
  //         width: calc(100% - 4rem);
  //         bottom: 0;
  //         left: 0;
  //         right: 0;
  //         background-color: $whisper;
  //         height: 1px;
  //         margin: auto;
  //         display: block;
  //     }
  // }
}

.fieldset {
  &-br {
    margin-bottom: 0.3rem;
    position: relative;
    @include respondto(largeDesktopSm) {
      margin-bottom: 0.156vw;
    }
    &::after {
      background-color: $whisper;
      bottom: 0;
      content: '';
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
    }
  }
}

.form-group-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  .form-item {
    &.form-item-md {
      width: calc(50% - 0.5rem);
      @include respondto(largeDesktopSm) {
        width: calc(50% - 0.26vw);
      }
      // @include respondto(desktopLg) {
      //   min-width: calc(50% - 0.5rem);
      //   width: auto;
      // }
    }
  }
}

.fieldset-check {
  margin-top: 27px;
  @include respondto(largeDesktopSm) {
    margin-top: 1.406vw;
  }
  .form-item-group-label {
    margin-bottom: 22px;
    @include respondto(largeDesktopSm) {
      margin-bottom: 1.1458vw;
    }
  }
}
.block-level {
  display: flex;
  flex-wrap: wrap;
  gap: 0 20px;
  #formGridCheckbox {
    width: 42%;
  }
  .form-item-group {
    width: 100% !important;
  }
}
.form-item {
  &__input-sm {
    .form-item-group {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
    }

    .form-item-group-label {
      margin: 0 18px 0 0;
      @include respondto(largeDesktopSm) {
        margin: 0 0.938vw 0 0;
      }
    }

    .form-item-group-block,
    .form-item-control {
      width: 59px;
      @include respondto(largeDesktopSm) {
        width: 3.073vw;
      }
    }

    .valid-feedback,
    .invalid-feedback {
      width: 100%;
    }
  }
}

.form-item-group-textarea {
  textarea {
    min-height: 219px;
    @include respondto(largeDesktopSm) {
      min-height: 11.406vw;
    }
  }
}

.form-item-switch-lang {
  background-color: $input-color;
  border-radius: 7px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 47px;
  width: fit-content;
  @include respondto(largeDesktopSm) {
    border-radius: 0.3646vw;
    margin-bottom: 2.4479vw;
  }
  .form-check {
    align-items: center;
    display: flex;
    margin: 0;
    padding: 0;
    position: relative;

    input[type='radio'] {
      cursor: pointer;
      filter: alpha(opacity=0);
      height: 100%;
      left: 0;
      opacity: 0;
      overflow: visible;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 100;
    }

    .form-check-label {
      background-color: $input-color;
      border-radius: 7px;
      color: $black;
      font-size: $font-md;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 0;
      min-width: 70px;
      padding: 10px;
      text-align: center;
      width: 50%;
    }

    .form-check-input {
      margin: 0;
      padding: 0;

      &:checked {
        + .form-check-label {
          background-color: $main-color !important;
          color: $white;
        }
      }
    }
  }
}

.generate-button {
  align-items: center;
  background-color: transparent;
  color: $black;
  display: flex;
  flex-direction: row;
  font-size: $font-md;
  font-weight: 500;
  gap: 8px;
  line-height: 20px;
  margin-bottom: 10px;
  @include respondto(largeDesktopSm) {
    gap: 0.4167vw;
    line-height: 1.042vw;
    margin-bottom: 0.521vw;
  }
  // @include respondto(largeDesktopMd) {
  //   font-size: 0.833vw;
  // }
  // @include respondto(laptopMd) {
  //   font-size: 1.0417vw;
  // }

  svg {
    height: 18px;
    width: 21px;
    @include respondto(largeDesktopSm) {
      height: 0.938vw;
      width: 1.094vw;
    }
  }
}
.filters-aligning {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px 0;
  justify-content: flex-start;
  @include respondto(largeDesktopSm) {
    gap: 0.521vw 0;
  }
}
