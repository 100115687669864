@import '../../assets/styles/variables';
.new-evaluation-wrapper {
  .header-container {
    .right-section .search-container {
      visibility: hidden;
    }
  }
}

.new-evaluation-container {
  padding: 3rem;
  @include respondto(laptop13) {
    padding: 3rem 1.1rem;
  }
  .buttons-bottom {
    gap: 3rem;
    @include respondto(phone) {
      gap: 1rem;
    }
    .button {
      min-width: 12rem;
      @include respondto(phone) {
        min-width: calc(50% - 0.5rem);
      }
    }
  }
}
