@import "../../assets/styles/variables";

.flex-container {
    gap: 39px;
}

.home-page {
    .swiper-dashboard-container {
        @include respondto(laptop13) {
            display: none;
        }
    }
}

.table-swiper {
    max-height: 1050px;
    height: 100%;
    gap: 20px;
    @include respondto(largeDesktopSm) {
        gap: 1vw .5rem;
        max-height: 54.688vw;
    }

    @include respondto(laptop13) {
        gap: 16px;
        flex-direction: column-reverse;
        max-height: 100%;
    }
}