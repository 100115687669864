@import '../../assets/styles/variables';

.property-list {
  form {
    .form-check-input {
      margin-right: 15px;

      @include respondto(largeDesktopSm) {
        margin-right: 0.781vw;
      }
    }

    .filter-item {
      align-items: center;
      display: flex;
      label {
        font-size: 1.3rem;
        margin-bottom: 0;
      }

      p {
        color: $black;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}

.list-items-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-height: 50vh;
  position: relative;
  .empty-text {
    font-size: 1.6rem;
    padding: 50px;
    text-align: center;
    width: 100%;
  }
  .label-item-rounded-info-sm {
    font-size: 1.2rem;
  }
}

.main-wrapper {
  @include respondto(laptop13) {
    .filter-container-category {
      display: none;
    }

    .property-list {
      display: none;
    }
  }

  .add-btn {
    display: none;
  }

  @include respondto(desktopXLg) {
    .add-btn {
      align-items: center;
      background-color: $main-color;
      border-radius: 1.6rem;
      bottom: 105px;
      cursor: pointer;
      display: flex;
      height: 5rem;
      justify-content: center;
      position: fixed;
      right: 16px;
      width: 5rem;
      z-index: 16;
    }
  }
}
