@import '../../assets/styles/variables';

.prices-modal {
  align-items: center;
  display: flex !important;
  justify-content: center !important;
  margin: 0 auto !important;
  .modal-dialog {
    bottom: auto !important;
    max-width: 60vw !important;
    width: 100%;
    @include respondto(laptop13) {
      max-width: 100% !important;
    }
  }
  .modal-content {
    border-radius: 20px !important;
  }
  .modal-body {
    padding: 2rem !important;
  }
  .prices-history-container .table-item {
    margin: 0;
  }
}
