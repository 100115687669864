@import '../../assets/styles/variables';

.form-item {
  .error-message {
    color: $red;
  }
  &-group {
    width: 100%;
    .rotate-arrow {
      .react-select__dropdown-indicator {
        transform: rotate(-90deg);
      }
    }

    &-label {
      color: $black;
      font-size: 1.5rem;
      font-weight: 300;
      line-height: 1;
      margin-bottom: 7px;

      @include respondto(largeDesktopSm) {
        font-size: 0.781vw;
        margin-bottom: 0.3646vw;
      }

      @include respondto(laptopMd) {
        font-size: 0.9722vw;
      }
    }

    .form-item-control-select {
      background-color: $input-color;
      border: 1px solid $input-color;
      border-radius: 10px;
      color: rgba($black, 0.62) !important;
      font-size: $font-sm;
      line-height: 17px;
      min-height: 34px;
      width: 100%;

      @include respondto(largeDesktopSm) {
        // font-size: 0.729vw;
        min-height: 2.2396vw;
      }

      // @include respondto(laptopMd) {
      //   font-size: 0.9028vw;
      // }

      @include respondto(phone) {
        font-size: 16px;
      }

      .react-select__indicator {
        padding: 2px;
        // @include respondto(largeDesktopSm) {
        //   padding: 0.4167vw;
        // }

    
      }

      &.react-select__control {
        &--is-focused {
          box-shadow: 0 0 0 1px $nav-color;
        }
      }

      .react-select {
        &__placeholder {
          color: $input-placeholder;
          font-size: $font-sm;

        }

        &__input {
          &-container {
            color: rgba($black, 0.62);
          }

          &__placeholder {
            color: $input-placeholder;
            font-size: $font-sm;

          }
        }

        &__menu {
          font-size: 16px !important;

          @include respondto(largeDesktopSm) {
            font-size: 0.833vw !important;
          }

          @include respondto(laptopMd) {
            font-size: 1.0417vw;
          }

          * {
            font-size: 20px !important;

            @include respondto(largeDesktopSm) {
              font-size: 1.042vw !important;
            }
          }
        }

        &__indicator {
          color: $gray;

          &-separator {
            display: none;
          }
        }
        &__clear-indicator {
          color: $main-color !important;
        }
        &__multi-value {
          &__remove,
          &__label {
            background-color: $ghost-white;
          }

          &__label {
            color: $black;
            font-size: $font-sm;

          }

          &__remove {
            color: $black;

            svg {
              height: 16px;
              fill: $main-color;
              width: 16px;
            }
          }
        }
      }

      &:hover {
        border: 1px solid $ghost-white;
      }

      &::placeholder {
        color: $input-placeholder;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba($blue-genie, 0.25);
      }

      &.is-valid {
        border: 1px solid rgb(25, 135, 84);
      }

      &.is-invalid {
        border: 1px solid rgb(220, 53, 69);
      }

      &:disabled {
        background-color: $delta-ligthness;
        border: 1px solid $delta-ligthness;
      }
    }
  }
}

.react-select {
  &__menu {
    font-size: 16px;

    @include respondto(largeDesktopSm) {
      font-size: 0.833vw;
    }

    @include respondto(laptopMd) {
      font-size: 0.9028vw;
    }
  }
}
.rotate {
  transform: rotateX(180deg);
  path {
    height: 6px;
    width: 10px;
  }
}
.invalid-feedback {
  &.display-block {
    display: block;
  }
}

.valid-feedback {
  &.display-block {
    display: block;
  }
}

.react-select__menu {
  min-width: max-content;
  z-index: 999999;
}
.react-select__option {
  min-width: max-content;
}
