@import '../../assets/styles/variables';

.no-access-container {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
  padding: 15px;
  border-radius: 10px;
  margin: 20px 0;
  font-size: 15px;

  .button {
    background-color: $main-color;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 10px;
    cursor: pointer;
    margin: 10px;
  }

  .highlight {
    font-weight: bold;
  }
}

