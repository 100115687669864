@import '../../assets/styles/variables';

.modal-body {
  .agents-list-filter {
    .search-container {
      margin-top: 15px;
    }

    form {
      gap: 0;
    }
  }
}
