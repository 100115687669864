@import "../../assets/styles/variables";

.reports-cards-container {
    justify-content: flex-start;
    gap: 39px;
    margin-bottom: 50px;
    flex-wrap: wrap;
    @include respondto(largeDesktopSm) {
        gap: 2.0313vw;
        margin-bottom: 2.604vw;
    }
    // @include respondto(laptop13) {
    //     display: grid !important;
    //     grid-template-columns: 1fr 1fr;
    //     margin-bottom: 3rem;
    // }
    @include respondto(phone) {
        gap: 15px;
    }
    .notification-item {
        max-width: 308px;
        width: 100%;
        border-radius: 40px;
        background: $white;
        box-shadow: $block-shadow;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        padding: 37px;
        @include respondto(largeDesktopSm) {
            border-radius: 2.0833vw;
            padding: 1.9271vw;
            max-width: 25%;
            width: calc(25% - 2.0313vw);
        }
        @include respondto(laptop13) {
            max-width: 50%;
            width: calc(50% - 20px);
        }
        @include respondto(phone) {
            width: calc(50% - 7.5px);
        }

        @include respondto(phone) {
            border-radius: 5.208vw;
            padding: 4.557vw;
        }

        img {
            margin-bottom: 25px;
            width: 4.6rem;
            @include respondto(largeDesktopSm) {
                margin-bottom: 1.302vw;
                width: 2.3958vw;

            }
            @include respondto(phone) {
                margin-bottom: 3.255vw;
                width: 5.99vw;
            }
        }

        p {
            max-width: 135px;
            color: $black;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            min-height: 135px;
            @include respondto(largeDesktopSm) {
                font-size: 1.25vw;
                min-height: 7.031vw;
                max-width: 7.031vw;
            }
            @include respondto(laptop13) {
                max-width: 100%;
                min-height: 90px;
            }

            @include respondto(phone) {
                font-size: 3.125vw;
                min-height: unset;
                margin-bottom: 1.953vw;
            }
        }

        .button-sm {
            box-shadow: $block-shadow;
            border-radius: 7px;
            min-width: 125px;
            @include respondto(largeDesktopSm) {
                border-radius: 0.3646vw;
                min-width: 6.51vw; 
            }
            @include respondto(phone) {
                font-size: 1.2rem;
                line-height: 2.5rem;
                min-width: 16.276vw;
                padding: 0 2.604vw;
            }
        }

    }
}