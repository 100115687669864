@import '../../assets/styles/variables';

.gallery-block {
  border-radius: 15px;
  margin-bottom: 16px;
  position: relative;
  width: 100%;
  @include respondto(largeDesktopMd) {
    border-radius: 1.302vw;
    margin-bottom: 0.833vw;
  }
  @include respondto(phone) {
    border-radius: 0;
  }

  .gallery__prev {
    left: 10px;

    img {
      transform: rotate(180deg);
    }
  }

  .gallery__next {
    right: 10px;
  }

  .gallery__nav {
    align-items: center;
    background-color: $white;
    border-radius: 50%;
    color: $black;
    display: flex;
    flex-direction: column;
    height: 26px;
    justify-content: center;
    position: absolute;
    top: calc(50% - 13px);
    transition: 0.3s ease-in-out;
    width: 26px;
    z-index: 2;

    img {
      height: 20px;
      width: 20px;
    }

    &.swiper-button-disabled {
      opacity: 0.4;
    }
    &.swiper-button-lock {
      opacity: 0;
      visibility: hidden;
    }

    svg {
      fill: $black !important;

      path {
        height: 8px;
        width: 5px;
        fill: $black !important;
      }
    }

    &:hover:not(.swiper-button-disabled) {
      background-color: $main-color-hover;

      svg {
        fill: $white !important;

        path {
          fill: $white !important;
        }
      }
    }
  }

  .gallery__count {
    align-items: center;
    background-color: $black;
    border-radius: 4px;
    color: $white;
    display: inline-flex;
    font-size: $font-sm;
    font-weight: 500;
    gap: 10px;
    justify-content: center;
    left: 10px;
    padding: 4px 10px;
    position: absolute;
    top: 10px;
    z-index: 2;
    @include respondto(largeDesktopSm) {
      border-radius: 0.2083vw;
      // padding: 0.2083vw 0.521vw;
    }
  }

  .swiper-slide {
    align-items: center;
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    padding-top: 64.65%;
    position: relative;
    width: 100%;
 
    img {
      border-radius: 15px;
      bottom: 0;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      // @include respondto(largeDesktopSm) {
      //   border-radius: 1.302vw;
      // }
      @include respondto(phone) {
        border-radius: 0;
      }
    }
  }
}
