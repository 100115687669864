@import '../../assets/styles/variables';

.form-item {
  &-group {
    width: 100%;

    &-label {
      color: $black;
      font-size: 1.5rem;
      font-weight: 300;
      line-height: 1;
      margin-bottom: 7px;

      @include respondto(largeDesktopSm) {
        font-size: 0.781vw;
      }

      @include respondto(laptopMd) {
        font-size: 0.9722vw;
      }
    }

    .form-item-control-select {
      background-color: $input-color;
      border: 1px solid $input-color;
      border-radius: 7px;
      color: rgba($black, 0.62) !important;
      font-size: $font-sm;
      line-height: 17px;
      min-height: 34px;
      width: 100%;

      @include respondto(largeDesktopSm) {
        border-radius: 0.521vw;
      }

      @include respondto(phone) {
        font-size: 16px;
      }

      &.react-select__control {
        &--is-focused {
          box-shadow: 0 0 0 1px $nav-color;
        }
      }

      .react-select {
        &__placeholder {
          color: $input-placeholder;
          font-size: $font-sm;
        }

        &__input {
          &-container {
            color: rgba($black, 0.62);
          }

          &__placeholder {
            color: $input-placeholder;
            font-size: $font-sm;
          }
        }

        &__indicator {
          color: $gray;

          &-separator {
            display: none;
          }
        }

        &__multi-value {
          &__remove,
          &__label {
            background-color: $ghost-white;
          }

          &__label {
            color: $black;
            font-size: $font-sm;
          }

          &__remove {
            color: $black;

            svg {
              height: 16px;
              fill: $main-color;
              width: 16px;

              @include respondto(largeDesktopSm) {
                height: 0.833vw;
                width: 0.833vw;
              }
            }
          }
        }
      }

      &:hover {
        border: 1px solid $ghost-white;
      }

      &::placeholder {
        color: $input-placeholder;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba($nav-color, 0.25);
      }

      &.is-valid {
        border: 1px solid rgb(25, 135, 84);
      }

      &.is-invalid {
        border: 1px solid rgb(220, 53, 69);
      }

      &:disabled {
        background-color: $delta-ligthness;
        border: 1px solid $delta-ligthness;
      }
    }
  }
}

.react-select {
  &__menu {
    font-size: $font-md !important;

    * {
      font-size: $font-md !important;
    }
  }
}

.invalid-feedback {
  &.display-block {
    display: block;
  }
}

.valid-feedback {
  &.display-block {
    display: block;
  }
}
