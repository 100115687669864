@import '../../assets/styles/variables';
.add-contracts-templates-wrapper {
  .header-container {
    .right-section .search-container {
      visibility: visible;
    }
  }
}

.add-contract-template-container {
  gap: 30px;
  min-width: 100%;
  @include respondto(laptop13) {
    flex-direction: column;
  }
  .add-contract-form-md {
    padding: 3rem;
    width: calc(50% - 15px);
    @include respondto(desktopSmall) {
      padding: 2rem;
    }
    @include respondto(laptop13) {
      width: 100%;
    }
    @include respondto(phone) {
      padding: 1.5rem 1rem;
    }
    &.bg-blue {
      @include respondto(laptop13) {
        background: $white;
      }
    }
  }
  .font-lg {
    font-size: $font-lg;
    margin-bottom: 2rem;
  }
}
