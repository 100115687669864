@mixin font-face(
    $font-family,
    $file-path,
    $font-weight: "normal",
    $font-style: "normal"
) {
    @font-face {
        font-family: $font-family;
        src: url("#{$file-path}.ttf");
        font-weight: $font-weight;
        font-style: $font-style;
        font-display: swap;
    }
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-ExtraLight.eot");
    src: url("../fonts/poppins/Poppins-ExtraLight.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/poppins/Poppins-ExtraLight.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-ExtraLight.woff") format("woff"),
        url("../fonts/poppins/Poppins-ExtraLight.ttf") format("truetype"),
        url("../fonts/poppins/Poppins-ExtraLight.svg#Poppins-ExtraLight")
            format("svg");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-ExtraLightItalic.eot");
    src: url("../fonts/poppins/Poppins-ExtraLightItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/poppins/Poppins-ExtraLightItalic.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-ExtraLightItalic.woff") format("woff"),
        url("../fonts/poppins/Poppins-ExtraLightItalic.ttf") format("truetype"),
        url("../fonts/poppins/Poppins-ExtraLightItalic.svg#Poppins-ExtraLightItalic")
            format("svg");
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-BlackItalic.eot");
    src: url("../fonts/poppins/Poppins-BlackItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/poppins/Poppins-BlackItalic.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-BlackItalic.woff") format("woff"),
        url("../fonts/poppins/Poppins-BlackItalic.ttf") format("truetype"),
        url("../fonts/poppins/Poppins-BlackItalic.svg#Poppins-BlackItalic")
            format("svg");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-BoldItalic.eot");
    src: url("../fonts/poppins/Poppins-BoldItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/poppins/Poppins-BoldItalic.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-BoldItalic.woff") format("woff"),
        url("../fonts/poppins/Poppins-BoldItalic.ttf") format("truetype"),
        url("../fonts/poppins/Poppins-BoldItalic.svg#Poppins-BoldItalic")
            format("svg");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-Bold.eot");
    src: url("../fonts/poppins/Poppins-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/poppins/Poppins-Bold.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-Bold.woff") format("woff"),
        url("../fonts/poppins/Poppins-Bold.ttf") format("truetype"),
        url("../fonts/poppins/Poppins-Bold.svg#Poppins-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-Medium.eot");
    src: url("../fonts/poppins/Poppins-Medium.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/poppins/Poppins-Medium.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-Medium.woff") format("woff"),
        url("../fonts/poppins/Poppins-Medium.ttf") format("truetype"),
        url("../fonts/poppins/Poppins-Medium.svg#Poppins-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-MediumItalic.eot");
    src: url("../fonts/poppins/Poppins-MediumItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/poppins/Poppins-MediumItalic.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-MediumItalic.woff") format("woff"),
        url("../fonts/poppins/Poppins-MediumItalic.ttf") format("truetype"),
        url("../fonts/poppins/Poppins-MediumItalic.svg#Poppins-MediumItalic")
            format("svg");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-ExtraBold.eot");
    src: url("../fonts/poppins/Poppins-ExtraBold.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/poppins/Poppins-ExtraBold.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-ExtraBold.woff") format("woff"),
        url("../fonts/poppins/Poppins-ExtraBold.ttf") format("truetype"),
        url("../fonts/poppins/Poppins-ExtraBold.svg#Poppins-ExtraBold")
            format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-SemiBold.eot");
    src: url("../fonts/poppins/Poppins-SemiBold.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/poppins/Poppins-SemiBold.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-SemiBold.woff") format("woff"),
        url("../fonts/poppins/Poppins-SemiBold.ttf") format("truetype"),
        url("../fonts/poppins/Poppins-SemiBold.svg#Poppins-SemiBold")
            format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-Black.eot");
    src: url("../fonts/poppins/Poppins-Black.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/poppins/Poppins-Black.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-Black.woff") format("woff"),
        url("../fonts/poppins/Poppins-Black.ttf") format("truetype"),
        url("../fonts/poppins/Poppins-Black.svg#Poppins-Black") format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-Italic.eot");
    src: url("../fonts/poppins/Poppins-Italic.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/poppins/Poppins-Italic.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-Italic.woff") format("woff"),
        url("../fonts/poppins/Poppins-Italic.ttf") format("truetype"),
        url("../fonts/poppins/Poppins-Italic.svg#Poppins-Italic") format("svg");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-Light.eot");
    src: url("../fonts/poppins/Poppins-Light.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/poppins/Poppins-Light.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-Light.woff") format("woff"),
        url("../fonts/poppins/Poppins-Light.ttf") format("truetype"),
        url("../fonts/poppins/Poppins-Light.svg#Poppins-Light") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-Regular.eot");
    src: url("../fonts/poppins/Poppins-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/poppins/Poppins-Regular.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-Regular.woff") format("woff"),
        url("../fonts/poppins/Poppins-Regular.ttf") format("truetype"),
        url("../fonts/poppins/Poppins-Regular.svg#Poppins-Regular")
            format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-LightItalic.eot");
    src: url("../fonts/poppins/Poppins-LightItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/poppins/Poppins-LightItalic.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-LightItalic.woff") format("woff"),
        url("../fonts/poppins/Poppins-LightItalic.ttf") format("truetype"),
        url("../fonts/poppins/Poppins-LightItalic.svg#Poppins-LightItalic")
            format("svg");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-ExtraBoldItalic.eot");
    src: url("../fonts/poppins/Poppins-ExtraBoldItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/poppins/Poppins-ExtraBoldItalic.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-ExtraBoldItalic.woff") format("woff"),
        url("../fonts/poppins/Poppins-ExtraBoldItalic.ttf") format("truetype"),
        url("../fonts/poppins/Poppins-ExtraBoldItalic.svg#Poppins-ExtraBoldItalic")
            format("svg");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-ThinItalic.eot");
    src: url("../fonts/poppins/Poppins-ThinItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/poppins/Poppins-ThinItalic.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-ThinItalic.woff") format("woff"),
        url("../fonts/poppins/Poppins-ThinItalic.ttf") format("truetype"),
        url("../fonts/poppins/Poppins-ThinItalic.svg#Poppins-ThinItalic")
            format("svg");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-SemiBoldItalic.eot");
    src: url("../fonts/poppins/Poppins-SemiBoldItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/poppins/Poppins-SemiBoldItalic.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-SemiBoldItalic.woff") format("woff"),
        url("../fonts/poppins/Poppins-SemiBoldItalic.ttf") format("truetype"),
        url("../fonts/poppins/Poppins-SemiBoldItalic.svg#Poppins-SemiBoldItalic")
            format("svg");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-Thin.eot");
    src: url("../fonts/poppins/Poppins-Thin.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/poppins/Poppins-Thin.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-Thin.woff") format("woff"),
        url("../fonts/poppins/Poppins-Thin.ttf") format("truetype"),
        url("../fonts/poppins/Poppins-Thin.svg#Poppins-Thin") format("svg");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
