@import '../../assets/styles/variables';

.request-associated {
  display: flex !important;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  .heading-block {
    margin: 0 !important;
  }
  &__emails {
    border-bottom: 1px solid $whisper;
    margin-bottom: 40px;
    padding-bottom: 40px;
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .button-blue {
      width: 100%;
    }
  }
  &__contact-items {
    display: flex;
    flex-direction: column;
    gap: 15px;
    @include respondto(desktopSmall) {
      margin-top: 2rem !important;
    }
  }
  &__contact-item {
    background-color: #f5f5fd;
    border-radius: 20px;
    padding: 25px;

    .img-block {
      border-radius: 50%;
      margin-right: 20px;
      min-height: 70px;
      min-width: 70px;
      overflow: hidden;
      width: 70px;
      @include respondto(largeDesktopSm) {
        height: 3.646vw;
        width: 3.646vw;
      }
      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .remove-btn {
      align-items: center;
      background-color: $main-color;
      border-radius: 50%;
      display: flex;
      height: 40px;
      justify-content: center;
      margin-left: 10px;
      min-width: 40px;
      transition: 0.2s ease-in-out;
      width: 40px;

      @include respondto(phoneSm) {
        position: absolute;
        right: 10px;
        top: 0;
      }

      svg {
        height: 12px;
        width: 12px;
      }

      &:hover {
        background-color: $main-color-hover;
      }
    }
    .edit-btn {
      align-items: center;
      background-color: $main-color;
      border-radius: 50%;
      display: flex;
      height: 40px;
      justify-content: center;
      min-width: 40px;
      transition: 0.2s ease-in-out;
      width: 40px;

      @include respondto(phoneSm) {
        position: absolute;
        right: 10px;
        top: 0;
      }

      svg {
        height: 20px;
        width: 16px;
        fill: white;
      }

      &:hover {
        background-color: $main-color-hover;
      }
    }

    &--top {
      align-items: center;
      border-bottom: 1px solid #e7e7e7;
      display: flex;
      flex-direction: row;
      margin-bottom: 25px;
      padding-bottom: 25px;
      width: 100%;
    }
    &--bottom {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: flex-start;
      width: 100%;
    }
    .info-contact {
      margin-right: auto;
      max-width: -webkit-fill-available;
      .name {
        color: $black;
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 2.7rem;
      }
      .number {
        align-items: center;
        color: $black;
        display: flex;
        font-size: 1.4rem;
        font-weight: 500;
        gap: 8px;
        line-height: 2.1rem;
      }
      .info-item {
        display: flex;
        gap: 10px;
        padding-top: 7px;
      }
    }
  }

  .form-item-group-search {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 9px;
    justify-content: flex-start;
    margin: 40px auto;
    .form-item-group {
      max-width: 50%;
    }
    .react-select {
      &__menu {
        z-index: 99999;
      }
    }
    .form-item-group-label {
      color: $black;
      font-size: 1.3rem;
      font-weight: 600;
      line-height: 1.3;
      min-width: max-content;
    }
  }
  .clear-icon {
    cursor: pointer;
    height: 10px;
    position: absolute;
    right: 4px;
    top: calc(50% - 11px);
    width: 20px;
  }
  .checkbox-container {
    max-width: 20px;
    min-width: 20px;
    width: 100%;

    @include respondto(largeDesktopSm) {
      max-width: 1.042vw;
      min-width: 1.042vw;
    }

    .form-check-input {
      height: 20px;
      width: 20px;

    }
  }
  .request-associated-properties {
    margin: 40px 0;
    width: 100%;
  }
}
