@import '../../assets/styles/variables';

.flex-container {
  gap: 39px;
}
.table-swiper {
  gap: 42px;
  height: 100%;
  max-height: 1050px;
}
.report-estate-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  @include respondto(laptop13) {
    width: 100%;
  }
  .display-flex {
    @include respondto(laptop13) {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
}
.report-estate-wrapper {
  gap: 30px;
  @include respondto(laptop13) {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
