@import 'react-toastify/dist/ReactToastify.css';
@import '../assets/styles/global.scss';
@import '../assets/styles/variables';

.Toastify__toast-container--top-right {
  right: 30px;
}
.Toastify__toast-theme--light {
  min-width: 335px;
}
.Toastify__toast-body > div:last-child {
  font-size: 16px;
}
