@import '../../assets/styles/variables';

.ads-form-container {
  .ads-form-tabs {
    width: fit-content;
  }
}

.bg-gray {
  background-color: $input-color;
}

.checkbox-group {
  &-fieldset {
    display: flex;
    flex-direction: column;
    height: 100%;

    .heading-block {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      position: relative;
      @include respondto(largeDesktopSm) {
        gap: 0.781vw;
      }
      .checkbox-group-plus {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
      }
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 9px;
    width: 100%;
    @include respondto(largeDesktopSm) {
      gap: 0.469vw;
    }
    &-md {
      gap: 13px;
      @include respondto(largeDesktopSm) {
        gap: 0.677vw;
      }
    }

    &__item {
      border-radius: 10px;
      min-height: 350px;
      padding: 20px 30px;

      @include respondto(largeDesktopSm) {
        border-radius: 0.521vw;
        min-height: 18.229vw;
        padding: 1.042vw 1.563vw;
      }

      @include respondto(laptop13) {
        min-height: auto;
      }

      &-md {
        width: calc(33.33% - 9px);

        @include respondto(largeDesktopSm) {
          width: calc(33.33% - 0.469vw);
        }

        @include respondto(laptop13) {
          width: 100%;
        }

        @include respondto(phone) {
          width: 100%;
        }
      }

      &[aria-disabled='true'] {
        position: relative;

        &::before {
          background-color: rgba($input-color, 0.7);
          border-radius: 10px;
          bottom: 0;
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 2;
          @include respondto(largeDesktopSm) {
            border-radius: 0.521vw;
          }
        }
      }
    }

    .checkbox-promo-list-bottom {
      margin-top: auto;
    }
  }

  &-fieldset {
    .heading-block {
      font-size: $font-lg;
      line-height: 2;
      margin-bottom: 2.5rem;
      @include respondto(largeDesktopSm) {
        margin-bottom: 1.302vw;
      }
    }

    .form-item {
      margin-bottom: 12px;
      @include respondto(largeDesktopSm) {
        margin-bottom: 0.625vw;
      }
    }
  }
}

.additional-info {
  font-size: $font-md;
  margin-bottom: 10px;
  margin-top: 8px;
  @include respondto(largeDesktopSm) {
    margin-bottom: 0.625vw;
    margin-top: 0.4167vw;
  }

  &__url {
    align-items: center;
    color: $black;
    display: flex;
    font-size: $font-md;
    gap: 15px;
    line-height: 120%;
    margin: 7px 0 33px;
    max-width: 100%;
    @include respondto(largeDesktopSm) {
      gap: 0.781vw;
      margin: 0.3646vw 0 1.7188vw;
    }
    span {
      transition: 0.3s ease-in-out;
    }

    &:hover {
      color: $main-color-hover;
    }
  }
}

.checkbox-group-plus {
  background-color: $main-color;
  border-radius: 50%;
  color: $white;
  font-size: 2rem;
  font-weight: 500;
  height: 36px;
  margin-left: auto;
  transition: 0.2s ease-in-out;
  width: 36px;
  @include respondto(largeDesktopSm) {
    font-size: 1.042vw;
    height: 1.875vw;
    width: 1.875vw;
  }
  &:hover {
    background-color: $main-color-hover;
  }
}
