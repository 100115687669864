@import '../../assets/styles/variables';
.container {
  &:has(.forgot-container) {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    padding: 2rem;
  }
}

.forgot-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: auto;
  position: relative;
  width: 100%;
  .loginbgimg {
    bottom: 0px;
    height: 100%;
    left: -35px;
    position: fixed;
    right: 0px;
  }
  p {
    font-size: 1.6rem;
    font-weight: 300;
    margin-bottom: 5rem;
    text-align: center;
    @include respondto(phone) {
      font-size: 1.4rem;
    }
  }

  .form-conatiner {
    background: $white;
    border-radius: 40px;
    box-shadow: 0px 27px 42.3px 0px rgba($black, 0.04);
    max-width: 707px;
    padding: 96px 108px 98px 109px;
    position: relative;
    width: 100%;
    z-index: 2;
    @include respondto(largeDesktopSm) {
      border-radius: 2.0833vw;
      padding: 5vw 5.625vw 5.104vw 5.677vw;
    }
    @include respondto(laptop13) {
      padding: 5vw 5.625vw 5.104vw 5.677vw;
    }
    @include respondto(phone) {
      max-width: 40rem;
      padding: 40px 30px;
    }

    @include respondto(phoneSm) {
      padding: 40px 15px;
    }
  }

  .form-title-block {
    align-items: center;
    display: flex;
    gap: 17px;
    justify-content: center;
    margin-bottom: 40px;

    @include respondto(phone) {
      margin-bottom: 30px;
    }

    .logo {
      svg {
        height: 51px;
        width: 51px;

        path {
          fill: $main-color;
        }
      }
    }

    .title {
      color: $black;
      font-size: 3.2rem;
      font-style: normal;
      font-weight: 200;
      line-height: normal;
      @include respondto(phone) {
        font-size: 2rem;
      }
    }
  }

  .inputs-field {
    margin-bottom: 43px;
    position: relative;

    @include respondto(phone) {
      margin-bottom: 30px;
    }

    &:last-of-type {
      margin-bottom: 33px;
    }

    input {
      border-bottom: 1px solid $light-shade-gray;
      color: $black;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 200;
      line-height: normal;
      padding: 0 0 1.7rem 3.7rem;
      width: 100%;

      &.has-error {
        border-bottom: 1px solid $red;
      }
    }

    .error-message {
      color: $red;
      text-align: left;
    }

    ::placeholder {
      color: $dim-gray;
    }

    .icon {
      left: 0;
      position: absolute;
      top: 0.3rem;
      width: 2rem;
    }
  }

  .submit-button {
    align-items: center;
    background: $main-color;
    border-radius: 28px;
    color: $white;
    display: flex;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 500;
    gap: 5px;
    height: 56px;
    justify-content: center;
    line-height: normal;
    margin: 0 auto;
    min-width: 150px;
    padding: 12px;
    transition: 0.3s ease-in-out;

    @include respondto(phone) {
      font-size: 1.6rem;
      height: 40px;
    }

    &:hover {
      background-color: $main-color-hover;
    }

    &:disabled {
      background: $dim-gray;
    }
  }
  .bottom-content {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
    .link {
      align-items: center;
      color: $main-color;
      display: flex;
      font-size: 1.6rem;
      justify-content: center;
      svg {
        fill: $main-color;
      }
      &:hover {
        color: $main-color-hover;
      }
    }
  }
}
