@import '../../assets/styles/variables';

.request-associated-imoAP {
  .label-item-rounded-info-sm {
    height: auto;
  }

  &-item {
    align-items: center;
    display: grid;
    gap: 0;
    grid-template-columns: 5% 16.4759% 31.586% 37.952% 9%;
    padding: 1.9rem 1.6rem;
    width: 100%;
    @include respondto(phone) {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      padding: 1.5rem 2rem;
    }
    .info-adress .display-flex {
      flex-direction: row;
      gap: 0.5rem;
    }
    .checkbox-container .form-check-input :not(:checked) {
      background-color: transparent;
    }
    &--column {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      font-size: $font-md;
      font-weight: 400;
      height: 100%;
      justify-content: center;
      line-height: 2.4rem;

      &:first-child {
        display: flex;
        justify-content: center;

        @include respondto(phone) {
          display: none;
        }
      }
      &:nth-child(3) {
        justify-content: space-between;
        padding-left: 2rem;
      }
      &:nth-child(4) {
        padding-left: 2rem;
      }

      @include respondto(phone) {
        width: 100%;
      }
      .image {
        margin: 0;
        max-width: 16rem;
        min-height: 16rem;
        min-width: 16rem;
        @include respondto(phone) {
          margin: 0;
        }
        img {
          border-radius: 15px;
          height: 100%;
          max-height: 16rem;
          max-width: 100%;
          min-height: 100%;
          min-width: 100%;
          object-fit: cover;
        }
      }

      .action-buttons-list {
        justify-content: center;
        margin: 0;
        width: 100%;
        @include respondto(phone) {
          width: 100%;
        }
      }

      .description-text {
        color: $black;
        display: -webkit-box;
        font-size: $font-md;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin-bottom: 23px;
        overflow: hidden;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        @include respondto(phone) {
          font-size: 1.2rem;
        }
      }
      .date-text {
        font-size: $font-md;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 9px;
      }

      .details-container {
        .title-text {
          color: $black;
          font-size:  $font-md;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 5px;
          @include respondto(phone) {
            font-size: 1.2rem;
          }
        }

        .rent-text {
          align-items: center;
          color: $main-color;
          display: flex;
          flex-wrap: wrap;
          font-size:  $font-md;
          font-style: normal;
          font-weight: 500;
          gap: 5px;
          line-height: 0px;
          @include respondto(phone) {
            font-size: 1.2rem;
          }
          @include respondto(phoneSm) {
            align-items: flex-start;
            flex-direction: column;
          }

          span {
            color: $black;
            font-size: $font-md;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            @include respondto(phone) {
              font-size: 1.2rem;
            }
          }
        }
      }

      .pills-details {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .label-item-tag {
          background: $white;
          border-radius: 5px;
          font-weight: 300;

          @include respondto(phone) {
            font-size: 12px !important;
          }
        }
      }
    }

    .action-buttons-list {
      align-items: flex-end;
      flex-direction: column;
    }
  }
}
