@import '../../assets/styles/variables';

.popup-container {
  position: fixed;
  background-color: #f8e38c;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-family: Poppins;

  @include respondto(onlyDesktop) {
    top: 90px;
    right: 20px;
    transform: none;
  }

  @include respondto(laptop13) {
    position: sticky;
    bottom: 90px;
    width: 100%;
  }
}

.popup-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  span {
    font-size: 16px;
  }
}

.popup-button {
  background-color: $black;
  color: $white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: $main-color;
  }
}

.reload-icon {
  margin-left: 8px;
}
