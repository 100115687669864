@import '../../assets/styles/variables';

.reports-agents-wrapper {
  position: relative;
  width: 100%;

  .reports-agents-table {
    .reports-agents-item-thead {
      border-radius: 15px;
      box-shadow: $block-shadow;
    }
    .desktop-titles {
      @include respondto(laptop13) {
        display: none !important;
      }

      .slot-column {
        width: 12%;

        @include respondto(laptop13) {
          width: 100%;
        }

        &:first-child {
          width: 30%;

          @include respondto(laptop13) {
            width: 100%;
          }
        }

        &:not(:first-child) {
          span {
            display: inline-block;
            min-width: 60px;
            text-align: center;
          }

          @include respondto(laptop13) {
            text-align: left;
          }
        }
      }
    }

    .reports-agents-item {
      align-items: center;
      background: $white;
      border-radius: 15px 15px;
      box-shadow: $block-shadow;
      display: flex;
      gap: 20px;
      justify-content: space-between;
      margin-bottom: 10px;
      padding: 10px 25px;

      @include respondto(laptop13) {
        flex-direction: column;
        padding: 15px 11px;
      }

      @include respondto(phone) {
        gap: 15px;
      }

      .column-item {
        width: 12%;

        @include respondto(laptop13) {
          width: 100%;
        }

        &:nth-child(1) {
          width: 30%;

          @include respondto(laptop13) {
            width: 100%;
          }

          .slot-column {
            width: 30%;
            @include respondto(laptop13) {
              width: auto;
            }
          }
        }

        // &:not(:nth-child(2)) {
        @include respondto(laptop13) {
          text-align: left;
        }
        // }
      }

      .slot-column {
        color: $black;
        font-size: $font-sm;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        .profile-details {
          @include respondto(laptop13) {
            width: 100%;
          }

          .name {
            font-size: $font-sm;
            @include respondto(laptop13) {
              font-size: 1.6rem;
            }
            &:hover {
              color: $main-color;
            }
          }

          @include respondto(laptop13) {
            text-align: center;
          }
        }

        .content-numbers {
          align-items: center;
          display: flex;
          flex-direction: row;
          font-size: $font-sm;
          font-style: normal;
          font-weight: 300;
          gap: 5px;
          .form-item-group {
            max-width: 60px;
          }
          .pill {
            align-items: center;
            background-color: $input-color;
            border-radius: 5px;
            display: flex;
            font-size: $font-sm;
            font-weight: 500;
            height: 30px;
            justify-content: center;
            min-width: 60px;
            padding: 6px;
            text-align: center;
            @include respondto(largeDesktopSm) {
              min-height: 30px;
            }
            @include respondto(laptop13) {
              font-size: 1.6rem;
            }
            @include respondto(phone) {
              font-size: 12px;
              font-weight: 400;
              height: 30px;
            }
          }
        }
      }
      &.desktop-titles {
        border-radius: 15px;
        box-shadow: none;
        margin-bottom: 10px;
        position: relative;
        &::after {
          background: $maingrey;
          bottom: 0;
          content: '';
          height: 1.5px;
          left: 2.4rem;
          position: absolute;
          right: 2.4rem;
          width: calc(100% - 4.8rem);
        }
      }
    }

    .reports-agents-item-total {
      background: $white;
      border-radius: 0 0 15px 15px;
      box-shadow: none;
      margin-bottom: 15px;
      padding: 10px 24px;
      width: 100%;
      @include respondto(laptop13) {
        border-radius: 15px;
      }
      @include respondto(phone) {
        padding: 15px 11px;
      }

      .slot-column-line {
        align-items: center;
        display: flex;
        gap: 20px;
        justify-content: space-between;
        padding-top: 20px;
        &:first-of-type {
          padding-top: 0px;
        }

        @include respondto(laptop13) {
          flex-direction: column;
        }

        .slot-column {
          color: $black;
          font-size: $font-sm;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

          &:first-of-type {
            max-width: 30%;
            width: 100%;

            @include respondto(laptop13) {
              max-width: 100%;
              text-align: center;
            }
          }
        }

        .column-item {
          width: 12%;

          @include respondto(laptop13) {
            width: 100%;
          }

          &:first-child {
            width: 30% !important;

            @include respondto(laptop13) {
              width: 100%;
            }
          }

          &:not(:first-child) {
            text-align: center;

            @include respondto(laptop13) {
              text-align: left;
            }
          }
        }

        .content-numbers {
          align-items: center;
          display: flex;
          flex-direction: row;
          font-size: 1.2rem;
          font-style: normal;
          font-weight: 300;
          gap: 3px;
          min-height: 30px;

          .pill {
            align-items: center;
            background-color: $input-color;
            border-radius: 5px;
            display: flex;
            font-size: $font-sm;
            font-weight: 500;
            height: 28px;
            justify-content: center;
            padding: 6px;
            width: 60px;
            @include respondto(largeDesktopSm) {
              height: 30px;
              padding: 6px;
            }
          }
        }
      }
    }

    .contact-details {
      display: flex;
    }

    .info-contact-details {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      width: 100%;

      @include respondto(laptop13) {
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
      }

      .name {
        color: $black;
        font-size: $font-sm;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        transition: all 0.3s ease-in-out;

        @include respondto(laptop13) {
          font-size: 1.6rem;
        }
        @include respondto(phone) {
          font-size: 1.2rem;
        }
        svg {
          height: 10px;
          margin-left: 8px;
          fill: $main-color;
          width: 10px;
        }
        &:hover {
          color: $main-color;
        }
      }
    }
  }
  .btns-container-wrapper {
    bottom: 0;
    left: 0;
    padding: 15px 0 35px;
    position: sticky;
    width: 100%;
    @include respondto(laptop13) {
      padding: 15px 0;
    }
  }
  .btns-container {
    align-items: center;
    background: $white;
    border-radius: 15px;
    box-shadow: $block-shadow;
    display: flex;
    gap: 17px;
    justify-content: flex-start;
    padding: 19px;
    width: 100%;

    @include respondto(phone) {
      flex-wrap: wrap;
    }
  }
}

.slot-dropdown {
  max-width: 352px;
  .dropdown-brands-container {
    .content-select {
      .header-select {
        background: $white;
        border: 1px solid $white;
        color: $black;
        font-size: 1.5rem;
        font-weight: 700;
        height: 44px;
        line-height: 2.3rem;
        &::after {
          content: url('../../assets/svg/arrowdrop.svg');
          height: 34px;
          top: 5px;
        }
      }
    }
  }
}
