@import '../../assets/styles/variables';

.filter-list-resident-complex {
  form {
    display: grid;
    grid-template-columns: 3% 7.317% 7.5% 12.439% 14.086% 14.086% 15.305% 9.817% 10.183% 7%;
    width: 100%;

    @include respondto(laptop13) {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    #formGridCheckbox {
      align-items: center;
      display: flex;

      .form-item-group-label {
        display: none;
        font-weight: 400;
        color: $black;
        margin: 0;

        @include respondto(laptop13) {
          display: flex;
        }
      }
    }

    .form-check-input {
      margin-right: 19px;

      @include respondto(largeDesktopSm) {
        margin-right: 0.99vw;
      }
    }
  }

  label {
    margin-bottom: 0 !important;
  }

  .filter-item {
    padding: 0 26px !important;

    @include respondto(onlyDesktop) {
      display: block;
      width: 100% !important;
      text-align: center;
    }

    &.arrows-label-icon label {
      cursor: pointer;
    }

    @include respondto(largeDesktopSm) {
      padding: 0 1.354vw !important;
    }

    @include respondto(laptop13) {
      border-right: none !important;
    }

    &:nth-child(2) {
      padding-left: 0 !important;
    }

    &:last-of-type {
      @include respondto(laptop13) {
        display: none;
      }
    }

    label {
      &::after {
        display: none;
      }
    }

    &:nth-of-type(2) {
      label {
        &::after {
          display: block;
        }
      }
    }

    &:nth-of-type(4) {
      label {
        &::after {
          display: block;
        }
      }
    }
  }
}
