@import '../../assets/styles/variables';

.add-agency-container {
  display: flex;
  flex-direction: column;
  gap: 35px;
  //   min-width: 768px;
  width: 100%;

  @include respondto(largeDesktopSm) {
    gap: 1.8229vw;
    // min-width: 40vw;
  }

  @include respondto(laptop13) {
    min-width: 100%;
  }

  @include respondto(phone) {
    gap: 20px;
  }

  .select-placeholder-text {
    color: $black;
    font-family: 'Poppins';
    font-size: $font-sm;
    font-weight: 500;
  }

  .form-container {
    background: $white;
    border-radius: 25px;
    box-shadow: $block-shadow;
    display: flex;
    gap: 48px;
    padding: 47px 35px 43px;

    @include respondto(largeDesktopSm) {
      border-radius: 1.302vw;
      gap: 2.5vw;
      padding: 2.4479vw 1.8229vw 2.2396vw;
    }

    @include respondto(laptop13) {
      border-radius: 25px;
      flex-direction: column;
    }

    @include respondto(phone) {
      flex-direction: column;
      gap: 2rem;
      padding: 1.5rem 1.1rem;
    }
    .title {
      color: $black;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 26px;
      white-space: nowrap;

      @include respondto(phone) {
        margin-bottom: 16px;
      }
    }
    .img-upload-block {
      align-items: center;
      display: flex;
      flex-direction: column;

      .upload-container {
        position: relative;

        .edit-btn,
        .delete-btn {
          background-color: $main-color;
          border-radius: 50%;
          height: 3.2rem;
          width: 3.2rem;

          @include respondto(largeDesktopSm) {
            height: 1.667vw;
            width: 1.667vw;
          }

          &:hover {
            background-color: $main-color-hover;
          }
        }

        .edit-btn {
          position: absolute;
          right: 0.5rem;
          top: 1.5rem;

          @include respondto(largeDesktopSm) {
            right: 0.26vw;
            top: 0.781vw;
          }
        }

        .delete-btn {
          bottom: 1rem;
          position: absolute;
          right: 1rem;

          @include respondto(largeDesktopSm) {
            bottom: 0.521vw;
            right: 0.521vw;
          }
        }
      }

      input[type='file'] {
        display: none;
      }

      .custom-file-upload {
        border-radius: 50%;
        cursor: pointer;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;

        @include respondto(largeDesktopSm) {
          margin-left: auto;
          margin-right: auto;
        }

        @include respondto(laptop13) {
          margin-left: 0;
          margin-right: 0;
        }

        align-items: center;
        background-color: $input-color;
        border: 1px solid $main-color;
        display: flex;
        height: 161px;
        justify-content: center;
        width: 161px;

        @include respondto(largeDesktopSm) {
          height: 8.385vw;
          width: 8.385vw;
        }

        svg {
          height: 6rem;
          fill: rgba($main-color, 0.3);
          stroke: rgba($main-color, 0.3);
          width: 6.9rem;

          @include respondto(largeDesktopSm) {
            height: 3.125vw;
            width: 3.594vw;
          }

          path {
            fill: rgba($main-color, 0.3);
            stroke: rgba($main-color, 0.3);
          }
        }

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }

    .form-block {
      width: 100%;

      form {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        gap: 3rem;

        @include respondto(phone) {
          gap: 10px;
        }
        .form-line-section-half {
          width: calc(50% - 1.5rem);
          @include respondto(phone) {
            width: 100%;
          }
        }

        .form-line-section {
          align-items: flex-start;
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          margin-bottom: 30px;
          width: 100%;

          @include respondto(largeDesktopSm) {
            gap: 1.563vw;
            margin-bottom: 1.563vw;
          }

          @include respondto(phone) {
            flex-direction: column;
            width: 100%;
          }

          .form-item-group-label {
            padding-right: 30px;
            text-align: right;
            width: 30%;
            @include respondto(laptop13) {
              width: 40%;
            }
            @include respondto(phone) {
              text-align: left;
            }
            @include respondto(phoneSm) {
              width: 100%;
            }
          }
          .phone-number {
            .form-item-group-label {
              line-height: 4.3rem;
              margin-bottom: auto;
              @include respondto(largeDesktopSm) {
                line-height: 2.2396vw;
              }
            }
          }

          .form-item-group,
          .input-group {
            align-items: center;
            display: flex;
            flex-direction: row;
            width: 100%;
            @include respondto(phoneSm) {
              flex-direction: column;
            }
            > div {
              width: 70%;
              @include respondto(laptop13) {
                width: 60%;
              }
              @include respondto(phoneSm) {
                width: 100%;
              }
              &.input-group-container {
                width: 70%;
                @include respondto(laptop13) {
                  width: 60%;
                }
                @include respondto(phoneSm) {
                  width: 100%;
                }
                > div,
                .form-item-group-block,
                .form-item-group,
                .input-group {
                  width: 100% !important;
                }
              }
            }

            .input-group,
            .form-item-group {
              width: 100%;
            }
          }

          .display-flex {
            @include respondto(phone) {
              flex-wrap: wrap;
            }
          }

          .form-item-group {
            textarea {
              height: 93px;

              @include respondto(largeDesktopSm) {
                height: 4.844vw;
              }
            }
          }

          .input-group {
            .form-item-group {
              margin-bottom: 11px;
            }

            .add-block {
              cursor: pointer;
            }
          }

          .reset-password {
            width: 100%;

            #change-password {
              color: $black;
              font-size: $font-lg;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin-bottom: 16px;

              @include respondto(largeDesktopSm) {
                margin-bottom: 0.833vw;
              }
            }
          }
        }
      }
    }
  }

  .input-group-remove-item,
  .phone-number {
    position: relative;

    .delete-btn {
      align-items: center;
      background-color: transparent;
      border: none;
      cursor: pointer;
      position: absolute;
      right: 6px;
      top: 50%;
      transform: translateY(-30%);

      @include respondto(largeDesktopSm) {
        right: 0.313vw;
      }

      svg {
        height: 20px;
        width: 20px;

        @include respondto(largeDesktopSm) {
          height: 1.042vw;
          width: 1.042vw;
        }

        path {
          fill: $main-color;
        }
      }
    }
  }

  .btns-container {
    align-items: center;
    background: $white;
    border-radius: 25px;
    box-shadow: 0px 27px 42.3px 0px rgba($black, 0.04);
    display: flex;
    gap: 17px;
    justify-content: center;
    padding: 23px;
    width: 100%;

    @include respondto(largeDesktopSm) {
      border-radius: 1.302vw;
      gap: 0.885vw;
      padding: 1.1979vw;
    }

    @include respondto(largeDesktopMd) {
      padding: 1.1979vw;
    }

    @include respondto(phone) {
      flex-wrap: wrap;
      padding: 15px 10px;
    }

    button {
      position: relative;
    }
  }
}

.with-icon {
  position: relative;
  .clear-icon {
    cursor: pointer;
    height: 10px;
    position: absolute;
    right: 4px;
    top: 10px;
    width: 20px;
  }

  .clear-icon svg {
    height: 10px;
    fill: $main-color;
    width: 10px;
  }
}
