@import '../../assets/styles/variables';

.ads-contacts-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
  @include respondto(largeDesktopSm) {
    padding: 0.521vw;
  }
  &.bg-blue {
    padding: 15px;
    @include respondto(largeDesktopSm) {
      padding: 0.781vw;
    }
    @include respondto(laptop13) {
      background: transparent;
      padding: 0;
    }

    @include respondto(phone) {
      margin-top: 5rem;
    }
  }
}

.add-contact {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 4rem;
  @include respondto(largeDesktopSm) {
    gap: 0.833vw;
    margin-bottom: 2.0833vw;
  }
  .form-item {
    width: calc(100% - 131px);
    @include respondto(largeDesktopSm) {
      width: calc(100% - 6.823vw);
    }
    @include respondto(phoneSm) {
      width: 100%;
    }
  }

  .form-item-button {
    margin-top: auto;
    width: 113px;
    @include respondto(largeDesktopSm) {
      width: 5.885vw;
    }
    @include respondto(phoneSm) {
      width: 100%;
    }

    .button-md {
      min-width: 100%;
      @include respondto(largeDesktopSm) {
        line-height: 2.188vw;
      }
    }
  }
}

.form-item-group-textarea {
  @include respondto(laptop13) {
    margin-bottom: 30px;
  }

  .textarea-lg {
    min-height: 135px;
    @include respondto(largeDesktopSm) {
      min-height: 7.031vw;
    }
  }
}

.ads-contact-item {
  box-shadow: none !important;
  padding: 15px;

  @include respondto(largeDesktopSm) {
    padding: 0.781vw;
  }

  @include respondto(laptop13) {
    padding: 15px 11px;
  }

  .form-item {
    margin-bottom: 10px;
    @include respondto(largeDesktopSm) {
      margin-bottom: 0.521vw;
    }
  }

  .form-item-control {
    border-radius: 10px;
  }

  &__bottom {
    margin-left: auto;
    width: calc(100% - 65px);

    @include respondto(largeDesktopSm) {
      width: calc(100% - 3.385vw);
    }

    @include respondto(phone) {
      width: 100%;
    }
  }
}
