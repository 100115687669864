@import '../../assets/styles/variables';

.activity-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  @include respondto(largeDesktopSm) {
    gap: 1.042vw;
  }

  &-item {
    border-radius: 1.5rem;
    box-shadow: $block-shadow;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    width: 100%;

    // @include respondto(largeDesktopSm) {
    //   padding: 0.99vw 1.042vw 1.094vw 1.875vw;
    // }

    @include respondto(laptop13) {
      gap: 1.5rem;
      padding: 15px 11px;
    }

    &.opacity {
      opacity: 0.5;
    }
    .icon {
      svg {
        width: 19px;
        @include respondto(largeDesktopSm) {
          width: 0.99vw;
        }
      }
    }

    &__general-info,
    &__date,
    &__info-user,
    &__info-labels {
      border-right: 1px solid $white;

      @include respondto(laptop13) {
        border: none;
      }
    }

    &__general-info {
      display: flex;
      flex-direction: row;
      gap: 10px 26px;
      padding-right: 20px;
      width: 38%;
      @include respondto(largeDesktopSm) {
        gap: 0.521vw 1.354vw;
        padding-right: 1.042vw;
      }
      @include respondto(laptop13) {
        order: 2;
        padding-right: 0;
        width: 100%;
      }

      .icon {
        @include respondto(laptop13) {
          display: none;
        }

        svg,
        img {
          width: 1.9rem;
          @include respondto(largeDesktopSm) {
            width: 0.99vw;
          }
        }

        svg {
          fill: $main-color;
          height: 1.9rem;
          @include respondto(largeDesktopSm) {
            height: 0.99vw;
          }
        }
      }

      .content {
        color: $black;
        font-size: $font-sm;
        font-weight: 300;
        line-height: normal;

        @include respondto(laptop13) {
          text-align: center;
          width: 100%;
        }

        p {
          font-weight: 300;
          margin-bottom: 9px;
          @include respondto(largeDesktopSm) {
            margin-bottom: 0.469vw;
          }
        }

        h3,
        .h3 {
          color: $black;
          font-size: $font-md;
          font-weight: 600;
          margin-bottom: 6px;
          @include respondto(largeDesktopSm) {
            margin-bottom: 0.313vw;
          }
        }

        .name {
          align-items: center;
          background-color: $white;
          border-radius: 4px;
          color: $black;
          display: inline-flex;
          font-size: $font-sm;
          font-weight: 500;
          gap: 10px;
          justify-content: center;
          line-height: normal;
          margin: 0 5px 5px 0;
          padding: 3px 8px;
          text-align: center;
          @include respondto(largeDesktopSm) {
            gap: 0.521vw;
            margin: 0 0.26vw 0.26vw 0;
          }
        }
      }
    }
    .label-item-rounded-info {
      background: $white;
    }

    &__date {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 18px 0 15px;
      width: 21%;
      @include respondto(laptop13) {
        order: 3;
        padding: 0;
        width: calc(50% - 1rem);
      }

      @include respondto(largeDesktopSm) {
        padding: 0 0.938vw 0 0.781vw;
      }

      @include respondto(phoneSm) {
        width: 100%;
      }

      table {
        color: $black;
        font-size: $font-md;
        font-weight: 300;
        width: 100%;

        @include respondto(laptop13) {
          font-size: 14px;
        }

        strong {
          font-weight: 500;
        }

        th {
          font-weight: 300;
          padding: 3px 10px 3px 0;

          @include respondto(largeDesktopSm) {
            padding: 0.156vw 0.521vw 0.156vw 0;
          }
        }

        td {
          font-weight: 500;
        }
      }
    }

    &__info-user {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 1.2rem;
      width: 13%;

      @include respondto(laptop13) {
        order: 1;
        width: 100%;
      }

      @include respondto(largeDesktopSm) {
        padding: 0 0.625vw;
      }

      .info-image {
        border: 1px solid $black;
        border-radius: 50%;
        height: 34px;
        margin: 0 auto;
        overflow: hidden;
        width: 34px;

        @include respondto(largeDesktopSm) {
          height: 1.7708vw;
          width: 1.7708vw;
        }

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      .info-name {
        color: $black;
        display: block;
        font-size: $font-sm;
        margin-top: 3px;
        text-align: center;
        @include respondto(largeDesktopSm) {
          margin-top: 0.156vw;
        }
      }
    }

    &__info-labels {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 20.145%;

      @include respondto(laptop13) {
        order: 4;
        padding: 0;
        width: calc(50% - 1rem);
      }

      @include respondto(phoneSm) {
        width: 1000%;
      }

      .info-details {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 11px;
        justify-content: center;
        @include respondto(largeDesktopSm) {
          gap: 0.5729vw;
        }
        @include respondto(laptop13) {
          flex-direction: row;
          justify-content: flex-end;
          width: 100%;
        }
      }
    }

    &__info-actions {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      padding-left: 10px;
      width: 7%;
      @include respondto(largeDesktopSm) {
        padding-left: 0.521vw;
      }
      @include respondto(laptop13) {
        border-top: 1px solid $input-check-border-device;
        order: 5;
        padding: 15px 0 0 0;
        width: 100%;
      }

      .action-buttons-list {
        align-items: flex-end;
        flex-direction: column;

        @include respondto(laptop13) {
          flex-direction: row;
          justify-content: flex-end;
          width: 100%;
        }
      }
    }
  }
}
