@import '../../assets/styles/variables';

.add-agent-container {
  display: flex;
  flex-direction: column;
  gap: 35px;
  min-width: 768px;
  width: 100%;

  @include respondto(largeDesktopSm) {
    gap: 1.8229vw;
    min-width: 40vw;
  }

  @include respondto(laptop13) {
    min-width: 100%;
  }

  @include respondto(phone) {
    gap: 20px;
  }

  .select-placeholder-text {
    color: $black;
    font-family: 'Poppins';
    font-size: $font-sm;
    font-weight: 500;
  }

  .form-container {
    background: $white;
    border-radius: 0 25px 25px 25px;
    box-shadow: $block-shadow;
    display: flex;
    gap: 48px;
    padding: 47px 35px 43px;

    @include respondto(largeDesktopSm) {
      border-radius: 0 1.302vw 1.302vw 1.302vw;
      gap: 2.5vw;
      padding: 2.4479vw 1.8229vw 2.2396vw;
    }

    @include respondto(laptop13) {
      border-radius: 25px;
    }

    @include respondto(phone) {
      flex-direction: column;
      gap: 20px;
      padding: 15px 11px;
    }

    .img-upload-block {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 50px;

      @include respondto(largeDesktopSm) {
        gap: 2.604vw;
      }

      @include respondto(phone) {
        gap: 20px;
      }

      .image-container {
        position: relative;
        width: 100%;

        .upload-container {
          height: 161px;
          margin-left: auto;
          margin-right: auto;
          margin-top: 0;
          position: relative;
          width: 161px;

          @include respondto(largeDesktopSm) {
            height: 8.385vw;
            width: 8.385vw;
          }

          @include respondto(laptopMd) {
            margin-top: 12px;
          }

          @include respondto(laptop13) {
            margin-top: 12px;
          }

          input[type='file'] {
            display: none;
          }

          .custom-file-upload {
            align-items: center;
            background-color: $input-color;
            border: 1px solid $main-color;
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            height: 161px;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
            overflow: hidden;
            width: 161px;

            @include respondto(largeDesktopSm) {
              height: 8.385vw;
              width: 8.385vw;
            }

            svg {
              height: 6rem;
              fill: rgba($main-color, 0.3);
              stroke: rgba($main-color, 0.3);
              width: 6.9rem;

              @include respondto(largeDesktopSm) {
                height: 3.125vw;
                width: 3.594vw;
              }

              path {
                fill: rgba($main-color, 0.3);
                stroke: rgba($main-color, 0.3);
              }
            }

            img {
              height: 100%;
              object-fit: cover;
              width: 100%;
            }
          }

          .edit-btn,
          .delete-btn {
            background-color: $main-color;
            border-radius: 50%;
            height: 3.2rem;
            width: 3.2rem;

            @include respondto(largeDesktopSm) {
              height: 1.667vw;
              width: 1.667vw;
            }

            &:hover {
              background-color: $main-color-hover;
            }
          }

          .edit-btn {
            position: absolute;
            right: 5px;
            top: 15px;

            @include respondto(largeDesktopSm) {
              right: 0.26vw;
              top: 0.781vw;
            }
          }

          .delete-btn {
            bottom: 10px;
            position: absolute;
            right: 5px;

            @include respondto(largeDesktopSm) {
              bottom: 0.521vw;
              right: 0.26vw;
            }
          }
        }
      }

      .radion-block {
        .form-check-input {
          border: 1px solid $main-color;
          height: 24px;
          width: 47px;

          @include respondto(largeDesktopSm) {
            height: 1.25vw;
            width: 2.4479vw;
          }

          &:checked {
            background-color: $main-color;
          }
        }

        label {
          color: $black;
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 300;
          line-height: 25px;
          padding-right: 10px;

          @include respondto(largeDesktopMd) {
            font-size: 0.781vw;
          }

          @include respondto(laptopMd) {
            font-size: 0.9722vw;
          }
        }
      }
    }

    .form-block {
      width: 100%;

      .title {
        color: $black;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 26px;

        @include respondto(phone) {
          margin-bottom: 16px;
        }
      }

      form {
        align-items: flex-start;
        display: flex;
        flex-direction: column;

        .form-line-section {
          align-items: flex-start;
          display: flex;
          gap: 20px;
          margin-bottom: 20px;
          max-width: 772px;
          width: 100%;

          @include respondto(largeDesktopSm) {
            gap: 1.042vw;
            margin-bottom: 1.042vw;
            max-width: 40.208vw;
          }

          @include respondto(laptop13) {
            flex-direction: column;
            gap: 15px;
            margin-bottom: 15px;
            max-width: 100%;
          }

          .display-flex {
            @include respondto(phone) {
              flex-wrap: wrap;
            }
          }

          .form-column-section {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;

            @include respondto(largeDesktopSm) {
              gap: 1.042vw;
            }
            @include respondto(laptop13) {
              gap: 15px;
            }
          }

          .form-item-group {
            max-width: 237px;
            width: 100%;

            @include respondto(largeDesktopSm) {
              max-width: 12.3438vw;
            }

            @include respondto(laptop13) {
              max-width: 100%;
            }

            textarea {
              height: 85px;

              @include respondto(largeDesktopSm) {
                height: 4.427vw;
              }
            }
          }

          &:nth-of-type(3) {
            margin-bottom: 25px;

            .form-item-group {
              max-width: 100%;
              width: 100%;

              input[type='textarea'] {
                height: 85px;
              }
            }
          }

          &:nth-of-type(6) {
            margin-bottom: 0;
          }

          .input-group {
            max-width: 237px;
            width: 100%;

            @include respondto(largeDesktopSm) {
              max-width: 12.3438vw;
            }

            @include respondto(laptop13) {
              max-width: 100%;
            }

            .add-block {
              cursor: pointer;
            }
          }

          .reset-password {
            width: 100%;

            #change-password {
              color: $black;
              font-size: $font-lg;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin-bottom: 16px;

              @include respondto(largeDesktopSm) {
                margin-bottom: 0.833vw;
              }
            }

            .input-with-icon {
              max-width: 237px;
              width: 100%;

              @include respondto(largeDesktopSm) {
                max-width: 12.3438vw;
              }

              @include respondto(laptop13) {
                max-width: 100%;
              }
            }

            .input-icon {
              cursor: pointer;
              height: 22px;
              position: absolute;
              right: 32px;
              transform: translateY(-50%);
              width: 22px;
            }

            .display-flex {
              gap: 30px;

              @include respondto(largeDesktopSm) {
                gap: 1.563vw;
              }
              @include respondto(laptop13) {
                gap: 15px;
              }
            }
          }
        }
      }
    }
  }

  .phone-number {
    position: relative;

    .delete-btn {
      align-items: center;
      background-color: transparent;
      border: none;
      cursor: pointer;
      position: absolute;
      right: 6px;
      top: 58%;
      transform: translateY(-30%);

      svg {
        height: 20px;
        width: 20px;

        path {
          fill: $main-color;
        }
      }
    }
  }

  .phone-number:not(:first-child) {
    margin-top: 20px;

    @include respondto(largeDesktopSm) {
      margin-top: 1.042vw;
    }
  }

  .btns-container {
    align-items: center;
    background: $white;
    border-radius: 20px;
    box-shadow: 0 27px 42.3px 0 rgba($black, 0.04);
    display: flex;
    gap: 17px;
    justify-content: center;
    padding: 23px;
    width: 100%;

    @include respondto(largeDesktopSm) {

      gap: 0.885vw;
      padding: 1.1979vw;
    }

    @include respondto(largeDesktopMd) {
      padding: 1.1979vw;
    }

    @include respondto(laptop13) {
      display: none;
    }

    @include respondto(phone) {
      flex-wrap: wrap;
      padding: 15px 10px;
    }
  }
}
