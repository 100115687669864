@import '../../assets/styles/variables';

.activities-modal {
  align-items: center;
  display: flex !important;
  justify-content: center !important;
  margin: 0 auto !important;
  .modal-dialog {
    bottom: auto !important;
    max-width: 744px !important;
    top: 0;
    width: 100%;
    @include respondto(phone) {
      max-width: 100%;
    }
    .modal-content {
      .modal-body {
        @include respondto(phoneSm) {
          padding: 40px 0 55px !important;
        }
      }
    }
  }
  .modal-warning {
    align-items: center;
    color: $black;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    padding: 10px 5px;
    width: 100%;

    span {
      padding: 10px;
    }
    svg {
      height: 80px;
      width: 80px;
      fill: $black;
      display: flex;
      margin: 0 20px;
    }
  }
  .contact-info {
    align-items: flex-start;
    border-top: 1px solid $azure;

    color: $black;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    padding: 20px 5px 10px;
    width: 100%;

    &__label {
      font-weight: 500;
    }
  }
  .modal-header {
    border-bottom: none;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0em;
    line-height: 1.3;
    padding: 0;
    text-align: center;
    span {
      width: 100%;
    }

  }
  .modal-content {
    background-color: $azure !important;
    border-radius: 20px !important;
    padding: 56px 72px 20px;
    @include respondto(desktopXLg){
      margin-bottom: 50px;
      margin-top: 100px;
    }
    @include respondto(phone) {
      background-color: $white !important;
      padding: 40px 10px 0;
    }
  }
  .close-btn-modal {
    cursor: pointer;
  }
  .add-activity-modal {
    background-color: $white;
    border-radius: 25px;
    display: flex;
    flex-direction: inherit;
    flex-wrap: wrap;
    gap: 20px 10px;
    padding: 35px 63px;
    @include respondto(phone) {
      padding: 20px 0 0;
    }
    > .form-item {
      width: 100%;
    }
    .flex-input {
      width: 100%;
      .flex-range {
        width: 100%;
      }
      .react-datepicker-wrapper {
        max-width: 100%;
        width: 100%;
      }
      .react-datepicker-wrapper:first-child {
        margin-right: 0;
      }
      &-md {
        width: calc(50% - 5px);
        @include respondto(phone) {
          width: 100%;
        }
        &:has(.react-datepicker-wrapper.hours) {
          width: 100%;
        }
      }
    }
    .input-group-flex-4 {
      .input-flex {
        .form-item {
          width: calc(33% - 10px);
          @include respondto(phone) {
            width: 30%;
          }
          &:nth-child(2) {
            width: calc(33% - 53px);
            @include respondto(phone) {
              width: calc(35% - 53px);
            }
          }
        }
      }
      .css-1xc3v61-indicatorContainer {
        @include respondto(phone) {
          padding: 8px 2px;
        }
      }
      .css-1fdsijx-ValueContainer {
        @include respondto(phone) {
          padding: 2px 2px 2px 8px;
        }
      }
    }
  }
  .flex-range {
    display: flex;
    justify-content: flex-start;
  }
  .react-datepicker-wrapper::after {
    background-repeat: no-repeat;
  }
  .react-datepicker-wrapper {
    max-width: calc(50% - 10px);
    min-width: calc(50% - 10px);
    &:first-child {
      margin-right: 70px;
      @include respondto(phone) {
        margin-right: 30px;
      }
    }
    @include respondto(phone) {
      max-width: calc(50% - 10px);
      min-width: calc(50% - 10px);
    }
    &.hours {
      margin-left: 1rem;
    }
  }
  .input-flex {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    &:not(:first-child) {
      padding-top: 20px;
    }
    @include respondto(phone) {
      gap: 4px;
    }
  }
  .close {
    align-items: center;
    background: $light-button-color;
    border: 1px solid $light-button-border;
    border-radius: 4px;
    display: flex;
    height: 34px;
    justify-content: center;
    transition: 0.3s ease-in-out;
    width: 34px;
    path {
      fill: $black;
    }
    &:hover {
      background: $light-button-color-hover;
    }
  }
  .add-notification {
    cursor: pointer;
    font-size: 12px;
    padding-top: 10px;
    width: max-content;
    &:hover {
      color: $main-color-hover;
      text-decoration: underline;
    }
  }
  .btns-container {
    align-items: center;
    align-items: center;
    display: flex;
    display: flex;
    gap: 30px;
    gap: 17px;
    justify-content: center;
    justify-content: center;
    padding: 23px;
    width: 100%;

    @include respondto(largeDesktopMd) {
      padding: 1.1979vw;
    }

    @include respondto(phone) {
      flex-wrap: wrap;
    }
    button {
      height: 34px;
    }
  }
}
