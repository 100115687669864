@import "../../assets/styles/variables";

.flex-container {
    gap: 41px;
}

.list-items-container {
    margin-bottom: 32px;
}

.announces-page {
    .main-wrapper-mobile {
        display: none;
    }

    @include respondto(laptop13) {
        .desktop-container {
            display: none;
        }

        .main-wrapper-mobile {
            display: block;
            width: 100%;
        }
    }
}