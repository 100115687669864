@import "../../assets/styles/variables";

.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 6px;
  margin-bottom: 0;
  padding: 0;
  width: 100%;

  &-item {
    align-items: center;
    background-color: $input-color;
    border-radius: 19px;
    display: inline-flex;
    padding: 3px 15px;
    justify-content: center;
    gap: 8px;

    .remove-item {
      align-items: center;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 14px;
      transition: 0.2s ease-in-out;
      width: 14px;

      img,
      svg {
        fill: $main-color;
        height: 12px;
        width: 12px;
      }

      &:hover {
        svg {
          fill: $main-color-hover;

          path {
            fill: $main-color-hover;
          }
        }
      }
    }

    &__title {
      color: $black;
      font-size: 1.4rem;
      font-weight: 500;

      @include respondto(largeDesktopMd) {
        font-size: 0.729vw;
      }
    }
  }
}