@import '../../assets/styles/variables';

.residential-complex-form-container {
  .form-item {
    margin-bottom: 17px;
    @include respondto(largeDesktopSm) {
      margin-bottom: 0.885vw;
    }
  }

  .row {
    --bs-gutter-x: 3rem;

    .flex-range {
      align-items: center;
      display: flex;
      gap: 9px;
      width: 100%;
      @include respondto(largeDesktopSm) {
        gap: 0.469vw;
      }
    }
  }
}

.fieldset-block {
  &__hr {
    border: none;
    border-bottom: 1px solid $whisper;
    margin: 18px 0 31px;
    opacity: 1;
    @include respondto(largeDesktopSm) {
      margin: 0.938vw 0 1.615vw;
    }
  }
}

.fieldset {
  &-br {
    margin-bottom: 0.3rem;
    position: relative;
    @include respondto(largeDesktopSm) {
      margin-bottom: 0.156vw;
    }
    &::after {
      background-color: $whisper;
      bottom: 0;
      content: '';
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
    }
  }
}

.form-group-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  .form-item {
    &.form-item-md {
      width: calc(50% - 0.5rem);
      @include respondto(largeDesktopSm) {
        width: calc(50% - 0.26vw);
      }
    }
  }
}

.fieldset-check {
  margin-top: 27px;
  @include respondto(largeDesktopSm) {
    margin-top: 1.406vw;
  }
  .form-item-group-label {
    margin-bottom: 22px;
    @include respondto(largeDesktopSm) {
      margin-bottom: 1.1458vw;
    }
  }
}
.block-level {
  display: flex;
  flex-wrap: wrap;
  gap: 0 20px;
  #formGridCheckbox {
    width: 40%;
  }
  .form-item-group {
    width: 15%;
  }
}
.form-item {
  &__input-sm {
    .form-item-group {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
    }

    .form-item-group-label {
      margin: 0 18px 0 0;
      @include respondto(largeDesktopSm) {
        margin: 0 0.938vw 0 0;
      }
    }

    .form-item-group-block,
    .form-item-control {
      width: 59px;
      @include respondto(largeDesktopSm) {
        width: 3.073vw;
      }
    }

    .valid-feedback,
    .invalid-feedback {
      width: 100%;
    }
  }

  .select-placeholder-text {
    color: $black;
    font-family: 'Poppins';
    font-size: $font-sm;
    font-weight: 500;
  }
}

.form-item-group-textarea {
  textarea {
    min-height: 121px;
    @include respondto(largeDesktopSm) {
      min-height: 6.302vw;
    }
  }
}
