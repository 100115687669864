@import '../../assets/styles/variables';

.form-item {
  &-group.multiselect {
    width: 100%;

    &-label {
      color: $black;
      font-size: $font-sm;
      font-weight: 300;
      line-height: 1;
      margin-bottom: 7px;
      @include respondto(largeDesktopSm) {
        margin-bottom: 0.3646vw;
      }
    }

    .form-item-control-select {
      background-color: $input-color;
      border: 1px solid $input-color;
      border-radius: 4px;
      color: rgba($black, 0.62) !important;
      font-size: $font-sm;
      line-height: 17px;
      min-height: 34px;
      width: 100%;

      @include respondto(largeDesktopSm) {
        border-radius: 0.2083vw;
        // min-height: 2.2396vw;
      }

      @include respondto(phone) {
        font-size: 16px;
      }

      &.react-select__control {
        &--is-focused {
          box-shadow: 0 0 0 1px $nav-color;
        }
      }

      .react-select {
        &__placeholder {
          color: $input-placeholder;
          font-size: $font-sm;
        }

        &__input {
          &-container {
            color: rgba($black, 0.62);
          }

          &__placeholder {
            color: $input-placeholder;
            font-size: $font-sm;
          }
        }

        &__menu {
          font-size: 16px !important;

          * {
            font-size: 20px !important;
          }
        }

        &__indicator {
          color: $gray;

          &-separator {
            display: none;
          }
        }

        &__multi-value {
          &__remove,
          &__label {
            background-color: $ghost-white;
          }

          &__label {
            color: $black;
            font-size: $font-sm;
          }

          &__remove {
            color: $black;

            svg {
              height: 16px;
              fill: $main-color;
              width: 16px;
            }
          }
        }
      }

      &:hover {
        border: 1px solid $ghost-white;
      }

      &::placeholder {
        color: $input-placeholder;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba($blue-genie, 0.25);
      }

      &.is-valid {
        border: 1px solid rgb(25, 135, 84);
      }

      &.is-invalid {
        border: 1px solid rgb(220, 53, 69);
      }

      &:disabled {
        background-color: $delta-ligthness;
        border: 1px solid $delta-ligthness;
      }
    }

    .form-item-control-select {
      background-color: $input-color;
      border: 1px solid $input-color;
      border-radius: 4px;
      color: rgba($black, 0.62) !important;
      font-size: $font-sm;
      line-height: 17px;
      min-height: 43px;
      width: 100%;

      @include respondto(largeDesktopSm) {
        border-radius: 0.2083vw;
        min-height: 2.2396vw;
      }

      @include respondto(phone) {
        font-size: 16px;
      }

      &.react-select__control {
        &--is-focused {
          box-shadow: 0 0 0 1px $nav-color;
        }
      }

      .react-select {
        &__placeholder {
          color: $input-placeholder;
          font-size: $font-sm;
        }

        &__input {
          &-container {
            color: rgba($black, 0.62);
          }

          &__placeholder {
            color: $input-placeholder;
            font-size: $font-sm;
          }
        }

        &__menu {
          font-size: 16px !important;

          * {
            font-size: 20px !important;
          }
        }

        &__indicator {
          color: $gray;

          &-separator {
            display: none;
          }
        }

        &__multi-value {
          &__remove,
          &__label {
            background-color: $ghost-white;
          }

          &__label {
            color: $black;
            font-size: 1.4rem;
            @include respondto(largeDesktopMd) {
              font-size: 0.729vw;
            }
            @include respondto(laptopMd) {
              font-size: 0.9028vw;
            }
          }

          &__remove {
            color: $black;

            svg {
              height: 16px;
              fill: $main-color;
              width: 16px;
            }
          }
        }
      }

      &:hover {
        border: 1px solid $ghost-white;
      }

      &::placeholder {
        color: $input-placeholder;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba($blue-genie, 0.25);
      }

      &.is-valid {
        border: 1px solid rgb(25, 135, 84);
      }

      &.is-invalid {
        border: 1px solid rgb(220, 53, 69);
      }

      &:disabled {
        background-color: $delta-ligthness;
        border: 1px solid $delta-ligthness;
      }
    }
  }
}

.react-select {
  &__menu {
    font-size: 16px;
  }
}

.invalid-feedback {
  &.display-block {
    display: block;
  }
}

.valid-feedback {
  &.display-block {
    display: block;
  }
}

.select-placeholder-text {
  color: $black;
  display: -webkit-box;
  font-weight: 500;
  overflow: hidden;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
