@import '../../assets/styles/variables';

.contact-info-block {
  display: flex;
  flex-direction: row;
  gap: 0 19px;
  width: 100%;

  @include respondto(phone) {
    flex-wrap: wrap;
  }

  @include respondto(largeDesktopSm) {
    gap: 0 0.99vw;
  }
  .remove-btn {
    align-items: center;
    background-color: $main-color;
    border-radius: 50%;
    display: flex;
    height: 30px;
    justify-content: center;
    margin-left: auto;
    min-width: 30px;
    transition: 0.2s ease-in-out;
    width: 30px;

    @include respondto(phoneSm) {
      position: absolute;
      right: 10px;
      top: 0;
    }

    svg {
      height: 8px;
      width: 8px;
    }

    &:hover {
      background-color: $main-color-hover;
    }
  }
  .img-block {
    border-radius: 50%;
    height: 45px;
    overflow: hidden;
    position: relative;
    width: 45px;

    @include respondto(largeDesktopSm) {
      height: 2.344vw;
      width: 2.344vw;
    }

    @include respondto(phone) {
      margin: 0 auto;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .info-contact {
    margin-bottom: 0;
    width: calc(100% - 99px);

    @include respondto(phone) {
      text-align: center;
      width: 100%;
    }

    @include respondto(largeDesktopSm) {
      width: calc(100% - 5.156vw);
    }

    .name {
      color: $black;
      display: block;
      font-size: $font-md;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 4px;
      @include respondto(largeDesktopSm) {
        margin-bottom: 0.2083vw;
      }
    }

    .info-item {
      display: flex;
      flex-wrap: wrap;
      gap: 5px 20px;
      margin-bottom: 8px;
      padding: 0;
      @include respondto(largeDesktopSm) {
        gap: 0.26vw 1.042vw;
        margin-bottom: 0.4167vw;
      }
      @include respondto(phone) {
        justify-content: center;
      }

      &__list-item {
        position: relative;

        &:not(:last-of-type) {
          padding-right: 20px;
          @include respondto(largeDesktopSm) {
            padding-right: 1.042vw;
          }
          &::after {
            background-color: $gainsboro;
            content: '';
            display: inline-block;
            height: 14px;
            position: absolute;
            right: 0;
            top: 5px;
            width: 1px;
          }
        }

        .number {
          align-items: center;
          display: flex;
          flex-direction: row;
          gap: 8px;
          @include respondto(largeDesktopSm) {
            gap: 0.4167vw;
          }
          img,
          svg {
            width: 15px;
            @include respondto(largeDesktopSm) {
              width: 0.781vw;
            }
          }

          svg {
            height: 15px;
            fill: $main-color;
            @include respondto(largeDesktopSm) {
              height: 0.781vw;
            }

            path {
              fill: $main-color;
            }
          }

          span {
            color: $black;
            font-size: $font-sm;
            font-weight: 500;
            transition: 0.3s ease-in-out;
          }

          &:hover {
            span {
              color: $main-color-hover;
            }
          }
        }
      }
    }
  }
}
