@import '../../assets/styles/variables';

.filter-list-tags {
  display: grid;
  grid-template-columns: 11.9% 32.81% 23.2% 20% 12%;
  width: 100%;

  @include respondto(laptop13) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .filter-item {
    border-right: 1px solid $bright-dusk;
    max-width: unset !important;
    padding: 0 30px !important;

    @include respondto(largeDesktopMd) {
      padding: 0 1.563vw !important;
    }

    @include respondto(laptopMd) {
      padding: 0 1.563vw !important;
    }

    @include respondto(laptop13) {
      border-right: none;
      padding: 0 !important;
    }

    &:last-of-type {
      border-right: 0 solid !important;
      padding-right: 0 !important;

      @include respondto(laptop13) {
        display: none;
      }
    }

    @include respondto(laptop13) {
      border-right: none !important;
    }

    label {
      color: $black;
      font-size: $font-md;
      font-weight: 500;
      line-height: normal;
      position: relative;
      @include respondto(laptopMd) {
        font-size: $font-sm;
      }
      @include respondto(laptop13) {
        font-weight: 400;
      }

      &::after {
        display: none;
      }
    }
  }

  .bottom-filter-section {
    align-items: flex-end;
    display: none;
    gap: 15px;
    justify-content: space-between;
    width: 100%;

    @include respondto(laptop13) {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 100%;
    }

    .form-btns-section {
      align-items: center;
      display: flex;
      gap: 20px;

      @include respondto(largeDesktopSm) {
        gap: 1.042vw;
      }

      @include respondto(laptop13) {
        gap: 10px;
        width: 100%;
      }

      .reset-btn {
        @include respondto(laptop13) {
          height: 30px;
          min-width: auto;
          width: 50% !important;
        }
      }

      .filter-btn {
        align-items: center;
        border: 1px solid $main-color;
        border-radius: 4px;
        display: flex;
        font-size: $font-sm;
        height: 30px;
        justify-content: center;
        position: relative;
        width: 223px;

        @include respondto(largeDesktopSm) {
          // border-radius: 0.521vw;
          // height: 2.292vw;
          width: 12.1354vw;
        }

        @include respondto(laptop13) {
          width: 50% !important;
        }
      }
    }
  }
}
