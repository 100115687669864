@import '../../assets/styles/variables';

.agents-container {
  .agents-list-filter {
    @include respondto(laptop13) {
      display: none;
    }
  }

  .spiner-container {
    margin-top: 250px;
  }

  .add-btn {
    z-index: 9;
  }
}

.delete-agent {
  .modal-footer {
    .button-blue {
      font-size: 1.6rem;
      font-weight: 400;
    }
  }
}

.agents-page-list {
  background-color: white;
  padding: 20px;
}
