@import '../../assets/styles/variables';

.ads-form-container {
  .col-ads-sm {
    max-width: 341px;

    @include respondto(phone) {
      max-width: 100%;
    }
  }

  .list-component-pagination {
    margin-top: 15px;
  }
}

.ads-select-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 49px;
  padding: 0;
  width: 100%;
  @include respondto(largeDesktopSm) {
    gap: 0.833vw;
    margin-bottom: 2.552vw;
  }

  &__item {
    align-items: center;
    background-color: $input-color;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 122px;
    justify-content: center;
    padding: 19px;
    width: 122px;

    @include respondto(largeDesktopSm) {
      border-radius: 1.042vw;
      height: 6.354vw;
      padding: 0.99vw;
      width: 6.354vw;
    }

    @include respondto(phone) {
      height: 11rem;
      padding: 5px;
      width: 11rem;
    }

    svg,
    img {
      margin-bottom: 18px;
      max-width: 42px;

      @include respondto(largeDesktopSm) {
        max-width: 2.188vw;
      }
    }

    svg {
      fill: $main-color;
      height: auto;
      width: 42px;

      @include respondto(largeDesktopSm) {
        width: 2.188vw;
      }
    }

    span {
      color: $black;
      font-size: $font-md;
      line-height: 120%;
    }

    &:hover,
    &[aria-selected='true'] {
      background-color: $nav-color;

      svg {
        fill: $white;
      }

      span {
        color: $white;
      }
    }
  }
}

.form-item-search {
  position: relative;

  .form-item-control {
    padding-right: 50px;
    @include respondto(largeDesktopSm) {
      padding-right: 2.604vw;
    }
  }

  .form-item-group-icon {
    background-color: transparent;
    height: 34px;
    position: absolute;
    right: 9px;
    top: -2px;
    @include respondto(largeDesktopSm) {
      right: 0.469vw;
      top: -0.104vw;
    }

    * {
      transition: 0.2s ease-in-out;
    }

    svg,
    img {
      height: 24px;
      width: 24px;
      @include respondto(largeDesktopSm) {
        height: 1.25vw;
        width: 1.25vw;
      }
    }

    &:hover {
      svg {
        path {
          fill: $main-color-hover;
        }
      }
    }
  }
}
