@import '../../assets/styles/variables';

.sugested-contacts-container {
  background: $azure;
  border-radius: 25px;
  max-width: 441px;
  padding: 30px;
  width: 100%;
  @include respondto(largeDesktopSm) {
    border-radius: 1.302vw;
    max-width: 26%;
    padding: 1.563vw;
  }

  @include respondto(laptop13) {
    background: transparent;
    border-radius: 0;
    max-width: 100%;
    padding: 0;
  }

  .sugested-contacts-wrapper {
    background: $white;
    border-radius: 25px;
    box-shadow: $block-shadow;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 27px 43px;
    @include respondto(largeDesktopSm) {
      border-radius: 1.302vw;
      padding: 1.406vw 2.2396vw;
    }
    @include respondto(phone) {
      padding: 15px 11px;
    }

    h4 {
      color: $black;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 26px;
      @include respondto(largeDesktopSm) {
        margin-bottom: 1.354vw;
      }
      @include respondto(phone) {
        margin-bottom: 16px;
      }
    }

    .sugested-contact-item {
      align-items: center;
      border-bottom: 1px solid $bright-dusk;
      display: flex;
      gap: 11px;
      margin-bottom: 21px;
      padding-bottom: 20px;
      position: relative;
      @include respondto(largeDesktopSm) {
        gap: 0.5729vw;
        margin-bottom: 1.094vw;
        padding-bottom: 1.042vw;
      }
      &:last-child {
        margin-bottom: 0;
      }

      &:last-of-type {
        border-bottom: none;
      }

      .img-block {
        border: 1px solid $main-color;
        border-radius: 50%;
        height: 46px;
        min-width: 46px;
        overflow: hidden;
        @include respondto(largeDesktopSm) {
          height: 2.3958vw;
          min-width: 2.3958vw;
        }

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      .info-contact {
        max-width: calc(100% - 47px);
        @include respondto(largeDesktopSm) {
          max-width: calc(100% - 2.4479vw);
        }

        .name {
          color: $black;
          font-size: $font-md;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 6px;
        }

        .number,
        .mail {
          align-items: center;
          color: $black;
          display: flex;
          font-size: $font-md;
          font-weight: 400;
          gap: 8px;
          line-height: normal;
          margin-bottom: 3px;
          transition: 0.3s ease-in-out;
          @include respondto(largeDesktopSm) {
            gap: 0.4167vw;
          }

          @include respondto(phone) {
            font-size: 1.2rem;
            gap: 5px;
          }
          svg {
            height: 13px;
            fill: $main-color;
            width: 13x;
            @include respondto(largeDesktopSm) {
              height: 0.677vw;
              width: 0.677vw;
            }
            @include respondto(phone) {
              height: 13px;
              width: 13px;
            }
            * {
              fill: $main-color;
            }
          }

          &:hover {
            color: $main-color-hover;
          }
        }

        .mail {
          margin-bottom: 0px;
        }
      }
    }
  }
}
