@import '../../assets/styles/variables';

.item-contracts-templates {
  gap: 57px;

  @include respondto(largeDesktopSm) {
    gap: 2.9688vw;
  }

  @include respondto(laptop13) {
    flex-direction: column;
    gap: 20px;
    padding: 15px 11px;
  }

  .line-border {
    @include respondto(laptop13) {
      margin: 0;
    }
  }

  .list-item-content {
    align-items: center;
    display: flex;
    flex-direction: row !important;
    gap: 0px;

    @include respondto(laptop13) {
      align-items: flex-start;
      flex-direction: column !important;
      gap: 17px;
    }
    .column-item {
      display: flex;
      flex-wrap: wrap;
      &:first-child {
        width: 8%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(2) {
        gap: 1rem;
        padding: 0 25px;
        width: 14%;

        @include respondto(laptop13) {
          padding: 0;
          width: 100%;
        }
      }
      &:nth-child(3) {
        gap: 1rem;
        padding: 0 25px;
        width: 69%;

        @include respondto(laptop13) {
          padding: 0;
          width: 100%;
        }
      }
    }
    .info-icons {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: flex-end;
      @include respondto(laptop13) {
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
