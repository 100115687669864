@import '../../assets/styles/variables';

.add-contract-template-form {
  .form-item-group {
    margin-bottom: 1.5rem;
  }

  .buttons-group {
    gap: 1.5rem;
    margin-top: 2.8rem;
    .button {
      min-width: 15rem;
      @include respondto(phone) {
        min-width: calc(50% - 0.8rem);
      }
    }
  }
  .error-message {
    font-size: 1.2rem;
    line-height: 120%;
    @include respondto(largeDesktopSm) {
      margin-top: 0.469vw;
    }
    @include respondto(largeDesktopMd) {
      font-size: 0.625vw;
    }
    @include respondto(laptopMd) {
      font-size: 0.764vw;
    }
    color: $red;
  }
  .form-editor {
    background: $input-color;
    border-radius: 4px;
    padding: 1rem;
    &.error {
      border: 1px solid $red;
    }

    .ck.ck-editor__top {
      .ck-sticky-panel {
        .ck-sticky-panel__content {
          border: none;
        }
      }
    }
    .ck-rounded-corners .ck.ck-toolbar,
    .ck.ck-toolbar.ck-rounded-corners {
      border-radius: 4px;
    }
    .ck.ck-button:active,
    a.ck.ck-button:active,
    .ck.ck-button:focus,
    a.ck.ck-button:focus,
    .ck.ck-button.ck-on:not(.ck-disabled):hover,
    a.ck.ck-button.ck-on:not(.ck-disabled):hover {
      background: $light-button-color;
      border: 1px solid $light-button-border;
      box-shadow: none;
    }
    .ck.ck-button.ck-on,
    a.ck.ck-button.ck-on {
      background: $light-button-color;
      border: 1px solid $light-button-border;
      color: $black;
    }
    .ck.ck-icon.ck-icon_inherit-color *:not([fill]) {
      fill: $black;
    }
    .ck-content {
      background: $input-color;
    }
    .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
      box-shadow: none;
    }
    .ck-editor__editable {
      border: none;
      box-shadow: none;
      min-height: 55vh;
      @include respondto(laptop13) {
        min-height: 20rem;
      }
    }
    .ck.ck-powered-by,
    .ck.ck-balloon-panel,
    .ck.ck-powered-by a,
    .ck.ck-balloon-panel.ck-balloon-panel_visible {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }
}
