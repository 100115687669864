@import '../../assets/styles/variables';

.gallery-modal {
  align-items: center;
  display: flex !important;
  justify-content: center !important;
  margin: 0 auto !important;

  @include respondto(laptop13) {
    height: 100% !important;
  }

  .modal-dialog {
    bottom: auto !important;
    max-width: 75vw !important;
    width: 100%;
    margin-top: 120px;

    @include respondto(laptop13) {
      max-width: 90% !important;
    }

    @include respondto(phone) {
      margin: auto !important;
      max-width: 100% !important;
    }

    .gallery-block .swiper-slide {
      padding-top: 0;
    }
    
    .swiper-slide figure {
      height: 630px;
      width: 1265px;
      // border-radius: 1.302vw;
    }
  }

  .modal-content {
    border-radius: 20px !important;
  }
}
