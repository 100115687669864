@import '../../assets/styles/variables';

.header-container {
  align-items: flex-start;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 30px;
  @include respondto(desktopXLg) {
    display: none !important;
    &:has(.back-btn) {
      display: block !important;
      margin-bottom: 20px;
      position: relative;
      width: 45px;
    }
  }

  &:has(.profile-data-container.active) {
    z-index: 9999;
  }

  .header-title {
    margin-right: auto;
    h2 {
      color: $black;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 600;

      @include respondto(largeDesktopMd) {
        font-size: 1.302vw;
      }
    }

    p {
      color: $black;
      font-size: $font-md;
      font-style: normal;
      font-weight: 300;
      line-height: 1.4;
    }
  }

  .header-breadcrumbs {
    a {
      color: #33475b73;
      font-size: 12px;
      font-weight: 300;
      line-height: 18px;
      text-align: left;

      &:not(:last-child) {
        padding-right: 22px;
        position: relative;

        &::before {
          background-image: url(../../assets/svg/arrow-breadcrumbs.svg);
          content: '';
          height: 18px;
          position: absolute;
          right: 2px;
          top: 0;
          width: 18px;
        }
      }
      &:last-child {
        color: #33475b;
      }
    }
  }
  .back-btn {
    align-items: center;
    background: $light-button-color;
    border: 1px solid $light-button-border;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    height: 34px;
    justify-content: center;
    padding: 0;
    width: 34px;
    // @include respondto(desktopXLg) {
    //   background: $main-color;
    //   height: 34px;
    //   min-width: 34px;
    //   z-index: 2;
    // }

    svg {
      height: 30px;
      width: 30px;

      path {
        fill: $black;
        // @include respondto(desktopXLg) {
        //   fill: $white;
        // }
      }
    }

    &:hover {
      background-color: $light-button-color-hover;
    }
  }

  .right-section {
    gap: 14px;
    justify-content: flex-end;
    max-width: 68%;
    width: 100%;

    &:has(.profile-data-container.active) {
      z-index: 9999;
    }

    button {
      //   align-items: center;
      //   background: $main-color;
      //   border: none;
      //   border-radius: 8px;
      //   color: $white;
      //   display: flex;
      //   font-size: 1.4rem;
      //   font-style: normal;
      //   font-weight: 500;
      height: 34px;
      //   justify-content: center;
      //   line-height: normal;
      //   min-width: 182px;

      //   &:hover {
      //     background: $main-color-hover;
      //   }
    }

    .search-container {
      align-items: center;
      background: $white;
      border-radius: 7px;
      display: flex;
      display: none;
      max-width: 390px;
      padding: 5px 13px 5px 11px;
      visibility: hidden;
      width: 100%;
      input {
        color: $black;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        width: 100%;
      }

      img,
      svg {
        height: 24px;
        width: 24px;
      }

      svg {
        fill: $icon-svg-fill;
      }
    }

    .profile-data-container {
      align-items: center;
      background: $white;
      border-radius: 7px;
      display: flex;
      gap: 18px;
      justify-content: center;
      max-width: 223px;
      padding: 5px 9px 4px 20px;
      width: 100%;

      &.active {
        position: relative;
        z-index: 999999;
      }

      .profile-item {
        align-items: center;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        min-height: 25px;
        min-width: 28px;
        transition: 0.3s ease-in-out;

        svg,
        img {
          height: 20px;
          width: 20px;
        }

        svg {
          fill: $black;
        }

        &.notification {
          &.active {
            position: relative;

            &::before {
              background-color: $main-color;
              border: 1px solid $white;
              border-radius: 50%;
              content: '';
              height: 10px;
              left: 6px;
              position: absolute;
              top: 0px;
              width: 10px;
            }
          }
        }
        &.account {
          .icon-container {
            align-items: center;
            background: $input-color;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            height: 2.5rem;
            width: 2.8rem;
            svg {
              height: 17px;
              margin: auto;
              width: 13px;
            }
          }
        }

        // &:nth-of-type(3) {
        //   &.active {
        //     position: relative;

        //     &::before {
        //       background-color: $red;
        //       border: 1px solid $bright-dusk;
        //       border-radius: 50%;
        //       content: '';
        //       height: 10px;
        //       left: 12px;
        //       position: absolute;
        //       top: 0;
        //       width: 10px;
        //     }
        //   }
        // }

        &:hover,
        &.active-click {
          background: $input-color;
        }
      }

      .profile-dropdown {
        align-items: center;
        display: flex;
        height: 39px;
        width: 66px;

        .icon-container {
          align-items: center;
          background: $nav-buttons;
          border-radius: 10px;
          display: flex;
          height: 39px;
          justify-content: center;
          width: 43px;

          svg {
            height: 22px;
            fill: $nav-icons;
            width: 16px;
          }
        }
      }
    }
  }
}

.header-container-mobile {
  align-items: center;
  background: $white;
  border-radius: 0 0 20px 20px;
  box-shadow: $block-shadow;
  display: none;
  justify-content: space-between;
  left: 0;
  max-width: 100vw;
  padding: 16px 16px 20px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
  @include respondto(desktopXLg) {
    display: flex;
  }

  .side-nav {
    align-items: center;
    background: $nav-color;
    border-radius: 7px;
    display: flex;
    height: 40px;
    justify-content: center;
    transition: 0.3s ease-in-out;
    width: 40px;

    &:hover {
      background: $nav-color-active;
    }
  }

  .title {
    color: $black;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    max-width: calc(100% - 191px);
    text-align: center;
    @include respondto(laptop13) {
      margin: auto auto auto 0.8rem;
      max-width: calc(100% - 16rem);
      padding-right: 1rem;
      text-align: left;
    }
    @include respondto(phone) {
      font-size: $font-md;
    }
    @include respondto(phoneSm) {
      font-size: $font-sm;
    }
  }

  .header-right {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  .search-button {
    background-color: $input-color;
    border-radius: 7px;
    height: 40px;
    transition: 0.3s ease-in-out;
    visibility: hidden;
    width: 40px;

    svg {
      fill: $black;
      height: 2.4rem;
      transition: 0.3s ease-in-out;
      width: 2.4rem;
    }

    &:hover {
      background-color: $main-color-hover;

      svg {
        fill: $white;

        path {
          fill: $white;
        }
      }
    }

    &[aria-expanded='true'] {
      @include respondto(desktopXLg) {
        background-color: $main-color-hover;
        svg {
          fill: $white;

          path {
            fill: $white;
          }
        }
      }
    }
  }

  .profile-header {
    align-items: center;
    background-color: $nav-buttons;
    border-radius: 14px;
    display: flex;
    height: 44px;
    justify-content: center;
    width: 44px;

    svg {
      fill: $nav-icons;
      height: 22px;
      width: 16px;
    }

    @include respondto(laptop13) {
      display: none;
    }
  }
}

body,
html {
  &:has(.mobile-nav-container.expanded) {
    @include respondto(desktopXLg) {
      overflow: hidden;
    }
  }
}

.mobile-nav-container {
  background: $nav-color;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  opacity: 0;
  padding: 29px 0 58px;
  position: absolute;
  transform: translate(-200vw, -120px);
  transition: all 0.3s ease-in-out;
  width: 100%;
  z-index: 999999;
  @include respondto(desktopXLg) {
    transform: translate(-200vw, 0);
  }

  .close-btn {
    padding-right: 15px;

    img {
      height: 50px;
      width: 50px;
    }
  }

  .logo {
    align-items: center;
    display: flex;
    gap: 8px;
    height: 51px;
    justify-content: flex-start;
    margin: 0 auto;
    padding-left: 33px;
    transition: all 0.3s ease-in-out;
    width: 100%;

    img {
      max-width: 100%;
    }
  }

  .accordion-container {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .nav-item {
      align-items: center;
      color: $white;
      display: flex;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: normal;
      margin: 10px 33px;
      transition: all 0.3s ease-in-out;

      img {
        margin-right: 22px;
        max-width: 20px;
      }

      &::before {
        background-color: $white;
        content: '';
        height: 30px;
        left: 0;
        position: absolute;
        width: 5px;
      }
    }

    .accordion-item {
      height: 45px;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      width: 100%;
      .accordion-button {
        background-color: transparent;
        color: $white;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 10px 33px;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        width: calc(100% - 66px);

        p {
          min-width: max-content;
        }

        img {
          margin-right: 22px;
          max-width: 25px;
        }

        &--after {
          align-items: center;
          background-color: $white;
          background-image: url('../../assets/svg/arrowaccordion.svg');
          background-size: contain;
          border-radius: 50%;
          display: flex;
          height: 20px;
          justify-content: center;
          margin-left: 10px;
          width: 20px;
        }
      }

      .accordion-body {
        ul {
          border-left: 1px solid $white;
          margin-left: 65px;
        }

        li {
          margin-bottom: 20px;

          .nav-item-sublink {
            color: $white;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            &.active {
              font-weight: 600;
              position: relative;

              &::before {
                background-color: $nav-color-active;
                content: url('../../assets/svg/arrowaccordionactive.svg');
                height: 23px;
                left: -33px;
                position: absolute;
                width: 20px;
              }
            }
          }
        }
      }

      &.active {
        background-color: $nav-color-active;
        height: auto;
        position: relative;
        transition: all 0.3s ease-in-out;

        &::before {
          background-color: $white;
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          width: 3px;
        }

        // .accordion-button {
        // &--after {
        //   background-color: $white;
        //   background-image: url('../../assets/svg/arrowaccordionactive.svg');
        // }
        // }
      }
    }
  }

  &.expanded {
    opacity: 1;
    transform: translate(-15px, -100px);
    transition: all 0.3s ease-in-out;
    @include respondto(desktopXLg) {
      transform: translate(-15px, 0);
    }

    @include respondto(phone) {
      width: 100%;
    }
  }
}

.dropdown-nav-more {
  position: inherit !important;

  .button-nav {
    background: $input-color;
    border: none;
    border-radius: 14px;
    color: $nav-icons;
    height: 40px;
    transition: 0.3s ease-in-out;
    width: 40px;

    svg {
      fill: $nav-icons;
      height: 2rem;
      transition: 0.3s ease-in-out;
      width: 2rem;
    }

    &:after {
      display: none;
    }

    &:hover {
      background: $nav-color-active;

      svg {
        fill: $white;
      }
    }

    &.blue-btn {
      background: $light-button-color;
      border: 1px solid $light-button-border;
      border-radius: 10px;

      svg {
        fill: $nav-icons;

        path {
          fill: $nav-icons;
        }
      }
      &:active, &.active {
        background-color: $light-button-color-hover;
        border: 1px solid $light-button-border;
      }
    }

    &[aria-expanded='true'] {
      @include respondto(desktopXLg) {
        background-color: $light-button-color-hover;

      }
    }
  }

  .dropdown-menu {
    border: none;
    border-radius: 0 0 2rem 2rem;
    box-shadow: $block-shadow;
    font-size: 1.6rem;
    left: 0 !important;
    max-width: 100vw;
    padding: 0 1.6rem;
    right: 0 !important;
    top: 100% !important;
    transform: none !important;
    width: 100vw;
    z-index: 999;

    &:has(.dropdown-modal-container.active) {
      border-radius: 0;
    }

    .dropdown-list {
      border-top: 1px solid rgba($gainsboro, 0.33);
      display: flex;
      flex-direction: row;
      padding: 1rem 0;

      &.dropdown-nav {
        padding: 1rem 0 2.8rem;
      }
    }

    .dropdown-item {
      align-items: center;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      padding: 1rem;

      &.has-info {
        .icon {
          position: relative;

          &::before {
            background: $main-color;
            border: 1px solid $white;
            border-radius: 50%;
            content: '';
            display: block;
            height: 10px;
            left: 0;
            position: absolute;
            top: -4px;
            width: 10px;
            z-index: 2;
          }
        }
      }

      svg {
        fill: $black;
        margin-bottom: .5rem;
        width: 20px;
        height: 20px;
        path {
          fill: $black;
        }
      }

      span {
        color: $black;
        font-size: 1.2rem;
      }

      &:hover,
      &.active {
        background: $light-button-color;
        color: $black;
      }
      .icon-lg {
        svg {
          height: 24px;
          width: 24px;
        }
      }
    }
  }
}

.main-wrapper {
  &:has(.header-container-mobile .search-modal.open),
  &:has(.header-container-mobile .dropdown-nav-more.show) {
    &::before {
      @include respondto(desktopXLg) {
        background: rgba($black, 0.3);
        bottom: 0;
        content: '';
        height: 100%;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 9999;
      }
    }
  }
}

.header-container-mobile {
  z-index: 9999;

  &:has(.search-modal.open, .dropdown-nav-more.show) {
    border-radius: 0;

    &::after {
      background-color: rgba($gainsboro, 0.33);
      content: '';
      height: 1px;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      top: 100%;
      width: calc(100% - 2.4rem);
      @include respondto(desktopXLg) {
        display: none;
      }
    }
  }

  @include respondto(desktopXLg) {
    .dropdown-modal-container {
      &.active {
        background: none;
        top: 100%;
        z-index: 999;

        .dropdown-modal.active {
          border-radius: 0 0 20px 20px;
          left: 0;
          max-width: 100vw;
          right: 0;
          top: -0.8rem;
          width: 100vw;
        }
      }

      .header-dropdown,
      &::before {
        display: none;
      }
    }
  }
}

.search-modal {
  background: $white;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 10px 10px 0 rgba($main-color, 0.15);
  left: 0;
  padding: 4.5rem 1.6rem;
  position: absolute;
  right: 0;
  top: 100%;
  width: 100vw;

  &:not(.open) {
    display: none;
  }

  &::before {
    background: rgba($gainsboro, 0.33);
    content: '';
    display: block;
    height: 1px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;
    width: calc(100% - 32px);
  }

  .search-container {
    display: flex;
    flex-wrap: wrap;

    input {
      background: $input-color;
      border-radius: 0.8rem;
      color: $black;
      font-size: 1.6rem;
      font-weight: 300;
      height: 5rem;
      line-height: 2.4rem;
      margin-right: 1rem;
      padding: 0.3rem 1.7rem;
      width: calc(100% - 6rem);

      &::placeholder {
        color: $black;
      }
    }

    .btn-squere-blue {
      border: 1px solid $main-color;
      background: $main-color;
      height: 5rem;
      width: 5rem;

      svg { 
        height: 2.4rem;
        fill: $white;
        width: 2.4rem;
        path{
          fill: $white;
        }
      }
      &:hover {
        background: $main-color-hover;
      }
    }
  }
}
