@import '../../assets/styles/variables';

.dropdown-modal-container {
  background: rgba($black, 0.3);
  inset: 0;
  opacity: 0;
  position: absolute;
  z-index: -1;

  &.active {
    background: rgba($black, 0.3);
    inset: 0;
    opacity: 1;
    position: absolute;
    z-index: 99999;
  }
}

.dropdown-modal {
  background: $white;
  border-radius: 10px;
  box-shadow: $block-shadow;
  max-width: 279px;
  padding: 18px 0 9px;
  position: absolute;
  top: -500px;
  width: 100%;
  @include respondto(desktopXLg) {
    padding: 0 0 21px;
  }

  &.active {
    height: fit-content;
    right: 43px;
    top: 70px;
  }

  .header-dropdown {
    align-items: center;
    background-color: $white;
    color: $black;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    gap: 10px;
    justify-content: center;
    letter-spacing: 0;
    line-height: 24px;
    padding-bottom: 15px;
    text-align: left;

    span {
      align-items: center;
      background: $main-color;
      border-radius: 50%;
      color: $white;
      display: flex;
      font-size: 12px;
      font-weight: 500;
      height: 23px;
      justify-content: center;
      line-height: 18px;
      width: 23px;
    }
  }

  .view-all-container {
    margin-top: 10px;

    button {
      align-items: center;
      background: $main-color;
      border: 1px solid $main-color;
      border: none;
      border-radius: 4px;
      color: $white;
      display: flex;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      height: 34px !important;
      justify-content: center;
      line-height: normal;
      margin: 0 auto;
      padding: 0 35px;

      &:hover {
        background: $white;
        border: 1px solid $main-color;
        color: $main-color;
      }
    }
  }

  .notifications-container {
    position: relative;

    &::before {
      content: url('../../assets/svg/notifyarrow.svg');
      position: absolute;
      right: 78px;
      top: -29px;
      @include respondto(desktopXLg) {
        display: none;
      }
    }

    .notification-item-list {
      background: $input-color;
      padding: 0 20px;

      .content-container {
        border-bottom: 1px solid $whisper-second;
        padding: 16px 0;
      }

      &:nth-last-child(2) {
        .content-container {
          border-bottom: none;
        }
      }

      p {
        color: $black;
        font-family: 'Poppins';
        font-size: $font-md;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
      }

      span {
        color: $black;
        font-size: $font-sm;
        font-weight: 300;
        line-height: 18px;
        text-align: left;
      }
    }
  }

  .profile-container {
    position: relative;

    &::before {
      content: url('../../assets/svg/notifyarrow.svg');
      position: absolute;
      right: 13px;
      top: -29px;
      @include respondto(desktopXLg) {
        display: none;
      }
    }

    .profile-item-list {
      cursor: pointer;
      padding: 0 20px;

      .content-container {
        border-bottom: 1px solid $whisper-second;
        padding: 16px 0;
      }

      &:last-child {
        .content-container {
          border-bottom: none;
        }
      }

      p {
        color: $black;
        font-size: $font-md;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
      }

      span {
        color: $black;
        font-size: $font-sm;
        font-weight: 300;
        line-height: 18px;
        text-align: left;
      }
    }
  }

  .activity-calendar-container {
    position: relative;

    &::before {
      content: url('../../assets/svg/notifyarrow.svg');
      left: 130px;
      position: absolute;
      top: -29px;
      @include respondto(desktopXLg) {
        display: none;
      }
    }

    .calendar-dates {
      align-items: center;
      background: $nav-color;
      box-shadow: $block-shadow;
      color: $white;
      display: flex;
      font-size: 13px;
      padding: 0 15px 0 20px;

      .day-date {
        align-items: center;
        border-right: 1px solid rgba($white,0.1);
        display: flex;
        font-weight: 500;
        gap: 9px;
        padding: 12px 16px 10px 0;
        @include respondto(desktopXLg) {
          width: 34%;
        }
        > div {
          align-items: center;
          display: flex;
          flex-direction: row;
          gap: 9px;
          @include respondto(desktopXLg) {
            margin: 0 auto;
          }
        }
        span {
          align-items: center;
          background: $white;
          border-radius: 50%;
          color: $black;
          display: flex;
          height: 35px;
          justify-content: center;
          width: 35px;
        }
      }

      .week-date {
        border-right: 1px solid rgba($white,0.1);
        display: flex;
        flex-direction: column;
        font-weight: 300;
        padding: 12px 15px 10px 12px;
        @include respondto(desktopXLg) {
          margin: 0 auto;
          padding: 12px 15px 10px;
          width: 30%;
        }
        > div {
          display: flex;
          flex-direction: column;
          @include respondto(desktopXLg) {
            margin: 0 auto;
          }
        }

        span {
          font-weight: 500;
        }
      }

      .month-date {
        display: flex;
        flex-direction: column;
        font-weight: 300;
        padding: 12px 0 10px 15px;
        > div {
          display: flex;
          flex-direction: column;
          @include respondto(desktopXLg) {
            margin: 0 auto;
          }
        }
        @include respondto(desktopXLg) {
          width: 35%;
        }
        span {
          font-weight: 500;
        }
      }
    }

    .calendar-list-item {
      background: #F8F9FB;
      display: block;
      font-size: 13px;
      padding: 0 15px;

      .content-container {
        align-items: center;
        border-bottom: 1px solid $whisper-second;
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
      }

      &:nth-last-child(2) {
        .content-container {
          border-bottom: none;
        }
      }

      .list-line {
        color: $black;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-weight: 300;
        gap: 4px;

        span {
          color: $main-color;
          font-size: 13px;
          font-weight: 500;
        }
      }

      .arrow-block {
        align-items: center;
        background: $white;
        border-radius: 50%;
        display: flex;
        height: 28px;
        justify-content: center;
        transition: 0.2s ease-in-out;
        width: 28px;
        @include respondto(desktopXLg) {
          background: $main-color;
        }
        svg {
          height: 24px;
          fill: $black;
          width: 24px;
          @include respondto(desktopXLg) {
            fill: $white;
          }
        }

        img {
          transform: rotate(-90deg);
        }
      }

      &:hover {
        .arrow-block {
          background: $nav-color;

          svg {
            fill: $white;
          }
        }
      }
    }
  }
}
