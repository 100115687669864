@import '../../assets/styles/variables';

.form-item {
  &-group {
    width: 100%;

    &-placeholder {
      color: $input-placeholder;
      font-size: 1.2rem;
      font-weight: 300;
      line-height: 15px;
      margin-bottom: 5px;

      @include respondto(largeDesktopSm) {

        margin-bottom: 0.26vw;
      }
    }

    label {
      color: $black;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 300;
      line-height: 1;
      margin-bottom: 6px;

      @include respondto(largeDesktopSm) {

        margin-bottom: 0.313vw;
      }
    }

    .error {
      .form-item-control-select {
        border: 1px solid $red;
      }
    }

    .error-message {
      color: $red;
    }
  }

  .form-item-group-block {
    width: 100%;
    &.error {
      input {
        border: 1px solid $red;
      }
    }
  }

  &-control {
    background-color: $input-color;
    border: 1px solid $input-color;
    border-radius: 7px;
    color: rgba($black, 0.62);
    display: block;
    font-size: $font-sm;
    line-height: 17px;
    min-height: 3.4rem;
    padding: 5px 14px;
    width: 100%;

    @include respondto(largeDesktopSm) {
      padding: 0.26vw 0.729vw;
    }

    &::placeholder {
      color: var($black, 0.62);
    }

    &:focus {
      box-shadow: 0 0 0 1px $nav-color;
    }

    &.is-valid {
      border: 1px solid rgb(25, 135, 84);
    }

    &:not(:placeholder-shown) {
      color: $black !important;
      font-family: Poppins;
      font-weight: 500;
      text-align: left;
    }
    &.is-invalid {
      border: 1px solid rgb(220, 53, 69);
    }

    &:disabled {
      background-color: $delta-ligthness;
      border: 1px solid $delta-ligthness;
    }

    &[type='number'] {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      background-color: transparent;
      background-image: url('../../assets/svg/clear.svg');
      border-radius: 0;
      cursor: pointer;
      height: 10px;
      width: 10px;
    }
  }

  .form-item-info {
    color: $input-placeholder;
    font-size: $font-sm;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

    a {
      color: $main-color;
      font-size: $font-sm;
      font-weight: 400;
      line-height: 120%;
      text-decoration-line: underline;

    }
  }

  &.mb-lg {
    margin-bottom: 32px;
    @include respondto(largeDesktopSm) {
      margin-bottom: 1.667vw;
    }
  }
}

.invalid-feedback {
  &.display-block {
    display: block;
  }
}

.valid-feedback {
  &.display-block {
    display: block;
  }
}

.form-item-icon-arrow {
  position: relative;

  &::after {
    background-image: url('../../assets/svg/inputarrow.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0;
    content: '';
    height: 100%;
    position: absolute;
    position: absolute;
    right: 22px;
    top: 0;
    width: 7px;
  }
}

.input-with-icon {
  position: relative;
}

.eye-icon {
  cursor: pointer;
  height: 24px;
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  @include respondto(largeDesktopSm) {
    height: 1.25vw;
    right: 0.2083vw;
    width: 1.25vw;
  }
}

.eye-icon svg {
  height: 20px;
  fill: $main-color;
  width: 20px;
}

.clear-icon {
  cursor: pointer;
  height: 10px;
  position: absolute;
  right: 4px;
  top: calc(50% - 12px);
  width: 20px;
}

.clear-icon svg {
  height: 10px;
  fill: $main-color;
  width: 10px;
}
