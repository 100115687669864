@import '../../assets/styles/variables';

.agent-item {
  gap: 41px;
  @include respondto(largeDesktopSm) {
    gap: 2.1354vw;
  }
  @include respondto(laptop13) {
    flex-direction: column;
    gap: 15px;
  }

  @include respondto(phone) {
    padding: 15px 11px;
  }

  .line-border {
    @include respondto(laptop13) {
      margin: 5px 0;
    }
  }

  .list-item-content {
    align-items: center;
    display: flex;
    flex-direction: row !important;
    gap: 0;

    @include respondto(laptop13) {
      flex-direction: column !important;
      gap: 20px;
    }

    .contact-details {
      display: flex;

      @include respondto(laptop13) {
        align-items: center;
        flex-direction: column;
        gap: 10px;
        width: 100%;
      }
    }

    .column-item {
      padding: 0 4.1rem;
      @include respondto(largeDesktopSm) {
        padding: 0 2.1354vw;
      }
      @include respondto(laptop13) {
        padding: 0;
      }
      &:first-child {
        padding-left: 1.5rem;
        width: 34.5%;
        @include respondto(largeDesktopSm) {
          padding-left: 0.781vw;
        }
        @include respondto(laptop13) {
          padding-left: 0;
          width: 100%;
        }
      }
      &:nth-child(2) {
        width: 11.5%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(3) {
        width: 11.5%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(4) {
        width: 11.2%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(5) {
        width: 11.5%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(6) {
        width: 11%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
    }

    .info-img {
      border-radius: 50%;
      cursor: pointer;
      height: 46px;
      margin-right: 24px;
      overflow: hidden;
      width: 46px;
      @include respondto(largeDesktopSm) {
        height: 2.3958vw;
        margin-right: 1.25vw;
        width: 2.3958vw;
      }
      @include respondto(laptop13) {
        margin-right: 0;
      }

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .info-contact-details {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      width: calc(100% - 70px);
      @include respondto(largeDesktopSm) {
        width: calc(100% - 3.646vw);
      }
      @include respondto(laptop13) {
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
      }

      .name {
        color: $black;
        font-size: $font-md;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 4px;

        @include respondto(laptop13) {
          font-size: 1.6rem;
        }
        @include respondto(phone) {
          font-size: 1.2rem;
        }
      }

      .flex-details {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 5px 17px;
        @include respondto(largeDesktopSm) {
          gap: 0.26vw 0.885vw;
        }
        @include respondto(laptop13) {
          align-items: center;
          flex-direction: column;
          justify-content: center;
        }

        .number,
        .mail {
          align-items: center;
          color: $black;
          display: flex;
          font-size: $font-md;
          font-weight: 500;
          gap: 8px;
          line-height: 2.1rem;
          @include respondto(largeDesktopSm) {
            gap: 0.4167vw;
          }

          svg {
            height: 15px;
            fill: $main-color;
            width: 15px;
            @include respondto(largeDesktopMd) {
              height: 0.781vw;
              width: 0.781vw;
            }
            @include respondto(laptopMd) {
              height: 1.0417vw;
              width: 1.0417vw;
            }
            path {
              fill: $main-color;
            }
          }

          @include respondto(phone) {
            font-size: 12px;
          }

          &:hover {
            color: $main-color-hover;
          }
        }
      }

      .mail {
        margin-bottom: 0;
      }
    }

    .info-number {
      align-items: center;
      background-color: $input-color;
      border-radius: 5px;
      color: $black;
      display: flex;
      font-size: $font-sm;
      font-style: normal;
      font-weight: 400;
      height: 28px;
      justify-content: center;
      line-height: normal;
      text-align: center;
      width: 73px;
      @include respondto(largeDesktopSm) {
        width: 3.802vw;
      }
      @include respondto(laptop13) {
        font-size: 1.6rem;
        width: 89px;
      }

      @include respondto(phone) {
        font-size: 1.2rem;
        font-weight: 400;
      }
    }

    .label-info {
      width: fit-content;
    }
    .label-info-inactive,
    .label-info-active {
      font-size: $font-md;
    }

    .info-icons {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-right: auto;
      max-width: 114px;

      @include respondto(largeDesktopMd) {
        gap: 0.521vw;
        max-width: 5.9375vw;
      }

      @include respondto(laptopMd) {
        gap: 0.521vw;
        max-width: 5.9375vw;
      }

      @include respondto(laptop13) {
        flex-direction: row;
        justify-content: center;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
