@import '../../assets/styles/variables';

.section-container {
  display: flex;
  flex-wrap: wrap;
}

.ads {
  &-general-request {
    &-container {
      .action-select {
        margin-bottom: 1.5rem;
        .form-item-group .form-item-control-select {
          border-radius: 4px;
        }
      }
      .associated-contacts {
        padding-top: 2rem;
        .heading {
          color: $black;
          font-size: $font-md;
          font-weight: 300;
          margin-bottom: 15px;
          @include respondto(largeDesktopSm) {
            margin-bottom: 0.781vw;
          }
        }

        &-list {
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;
          margin: 0;
          padding: 0;
          @include respondto(largeDesktopSm) {
            gap: 0.521vw;
          }
          .label-item-rounded-info-sm {
            font-weight: 300;
            height: auto;
            line-height: 1;
            padding: 5.5px 10px;
            @include respondto(largeDesktopSm) {
              padding: 0.286vw 0.521vw;
            }
          }
        }
      }
      .user-general-info {
        align-items: center;
        border-bottom: 1px solid $platinum;
        border-top: 1px solid $platinum;
        display: flex;
        flex-direction: row;
        gap: 1.3rem;
        margin: 1.5rem 0;
        padding: 1rem 0;
        @include respondto(largeDesktopSm) {
          gap: 0.677vw;
          margin: 0.781vw 0;
          padding: 0.521vw 0;
        }
        .image-block {
          background-color: $white;
          border: 1px solid $nav-color;
          border-radius: 50%;
          height: 4.6rem;
          overflow: hidden;
          width: 4.66rem;
          @include respondto(largeDesktopSm) {
            height: 2.3958vw;
            width: 2.3958vw;
          }
          img {
            height: 100%;
            object-fit: cover;
            width: 100%;
          }
        }

        .content-info {
          width: calc(100% - 7.3rem);
          @include respondto(largeDesktopSm) {
            width: calc(100% - 3.802vw);
          }
          .name {
            color: $black;
            font-size: $font-md;
            font-weight: 500;
          }

          .info {
            color: $black;
            font-size: $font-sm;
          }
        }
      }
    }
    &__heading {
      color: $black;
      font-size: $font-lg;
      font-weight: 600;
      line-height: 1.4;
      margin-bottom: 10px;

      @include respondto(largeDesktopMd) {
        line-height: 110%;
      }

      @include respondto(phone) {
        line-height: 110%;
        margin-bottom: 12px;
        text-align: center;
      }
    }
    &-sm {
      margin-right: auto;
      position: relative;
      width: 350px;

      @include respondto(largeDesktopSm) {
        width: 18.229vw;
      }
      @include respondto(laptopMd) {
        width: 22vw;
      }
      @include respondto(laptop13) {
        margin-bottom: 21px;
        width: 100%;
      }
    }

    &-info {
      font-size: $font-md;
      line-height: 1.5rem;
      padding-top: 10px;

      table {
        width: 100%;
      }

      &__heading {
        padding: 4px 4px 4px 0;

        @include respondto(largeDesktopSm) {
          padding: 0.2083vw 0.2083vw 0.2083vw 0;
        }
      }

      &__content {
        align-items: flex-end;
        color: $black;
        display: flex;
        font-weight: 500;
        justify-content: flex-end;
        text-align: right;

        .link {
          color: $main-color;
          cursor: pointer;
          position: relative;
          width: max-content;

          &:after {
            border-bottom: 1px solid;
            bottom: 0;
            content: '';
            left: 0;
            margin: 0 auto;
            opacity: 0;
            position: absolute;
            right: 0;
            transition: 0.3s ease-in-out;
            width: 0;
          }

          &:hover,
          &:focus {
            color: $main-color-hover;

            &::after {
              opacity: 1;
              width: 100%;
            }
          }
        }
      }

      &-bottom {
        @include respondto(phone) {
          padding: 0 15px 20px 15px;
        }
      }

      &-more {
        @include respondto(desktop) {
          display: none;
        }
      }
    }
  }
}
