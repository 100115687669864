@import '../../assets/styles/variables';

.alphabet-container {
  align-items: center;
  background: $white;
  border-radius: 15px;
  box-shadow: $block-shadow;
  flex-direction: column;
  gap: 13px;
  padding: 15px;
  width: 55px;
  @include respondto(largeDesktopSm) {
    border-radius: 0.781vw;
    gap: 0.677vw;
    padding: 0.781vw;
    width: 2.865vw;
  }
  @include respondto(laptop13) {
    padding: 21px 15px 19px;
    width: 100%;
  }
  .img-block {
    cursor: pointer;
    margin-bottom: 1rem;
    @include respondto(largeDesktopSm) {
      margin-bottom: 0.521vw;
    }
    @include respondto(laptop13) {
      margin-bottom: 0;
      text-align: center;
      width: calc(12.5% - 17px);
    }
    svg {
      fill: $black;
      height: 24px;
      transition: 0.1s ease-in-out;
      width: 24px;
      @include respondto(largeDesktopSm) {
        height: 1.25vw;
        width: 1.25vw;
      }
      @include respondto(laptop13) {
        height: 19px;
        width: 19px;
      }
      path {
        fill: $black;
        transition: 0.1s ease-in-out;
      }
    }
    &:hover,
    &.active {
      svg {
        fill: $main-color;
        path {
          fill: $main-color;
        }
      }
    }
  }
  .alphabet-title {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2.1rem;
    margin-bottom: 0.1rem;
    @include respondto(onlyDesktop) {
      display: none;
    }
  }
  .alphabet-list {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 5px;
    @include respondto(largeDesktopSm) {
      gap: 0.26vw;
    }
    @include respondto(laptop13) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 15px 19px;
      width: 100%;
    }
    span {
      color: $black;
      cursor: pointer;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      @include respondto(largeDesktopMd) {
        font-size: 0.729vw;
        line-height: 1.25vw;
      }
      @include respondto(laptopMd) {
        font-size: 0.9028vw;
        line-height: 1.25vw;
      }
      @include respondto(laptop13) {
        width: calc(12.5% - 17px);
      }
      &:hover {
        color: $main-color-second;
      }
      &.active {
        color: $main-color;
      }
    }
  }
}
