@import '../../assets/styles/variables';

.filter-container-category {
  background: $azure;
  border-radius: 25px;
  margin-bottom: 35px;
  padding: 15px;
  width: 100%;

  .row {
    flex-wrap: nowrap;
    gap: 15px;
    justify-content: space-between;
    margin-left: 0px;
    margin-right: 0px;
    position: relative;
    z-index: 9;

    .col-sm-2,
    .col-sm-10 {
      flex: 0 1 auto;
    }
  }

  .tab-section {
    background: $white;
    border-radius: 25px;
    box-shadow: 0px 27px 42.3px 0px rgba($black, 0.04);
    max-width: 232px;
    padding: 21px 0px;

    @include respondto(laptopMd) {
      max-width: 14.0833vw;
    }

    .nav-link {
      align-items: center;
      border-radius: 0px;
      color: $black;
      display: flex;
      font-size: 1.3rem;
      font-style: normal;
      font-weight: 400;
      justify-content: space-between;
      line-height: normal;
      margin-bottom: 5px;
      padding: 8px 20px;
      transition: none !important;
      &.nav-link-has-sub {
        margin-bottom: 0;
      }

      &.active {
        --bs-nav-pills-link-active-bg: $main-color;
        font-weight: 600;
        position: relative;
        transition: none !important;

        &::after {
          content: url('../../assets/svg/arrowtab.svg');
          position: absolute;
          right: -10px;
          top: 12px;
        }
      }
    }
    .nav-link-has-sub {
      margin-bottom: 0;
    }
    .nav-item-dropdown {
      a {
        padding: 5px 20px 5px 30px;
        margin-bottom: 0;
      }
    }
  }

  .filter-form-section {
    background: $white;
    border-radius: 25px;
    box-shadow: 0px 27px 42.3px 0px rgba($black, 0.04);
    padding: 15px;
    &.closed-filters {
      max-height: 228px;
      @include respondto(laptopMd) {
        max-height: 220px;
      }
    }

  }
}
