@import '../../assets/styles/variables';

.prices-history-container {
  max-width: 1113px;
  width: 100%;

  @include respondto(laptop13) {
    max-width: 1440px;
  }

  .table-item {
    background: $white;
    border-radius: 15px;
    box-shadow: 0px 27px 42.3px 0px rgba($black, 0.04);
    margin-bottom: 20px;
    overflow: hidden;
    width: 100%;
    @include respondto(largeDesktopSm) {
      // border-radius: 1.302vw;
      margin-bottom: 1.042vw;
    }
    .prices-header {
      align-items: center;
      justify-content: space-between;
      padding: 33px 57px 29px 36px;
      @include respondto(largeDesktopSm) {
        padding: 1.7188vw 2.9688vw 1.51vw 1.875vw;
      }
      @include respondto(laptop13) {
        padding: 1.7391vw 1.3043vw;
      }

      @include respondto(phone) {
        flex-wrap: wrap;
        justify-content: center;
        padding: 20px;
        text-align: left;
      }

      .title-section {
        font-weight: 700;
        // @include respondto(laptop13) {
        //   font-size: 1.6rem;
        // }
        @include respondto(phone) {
          // font-size: 1.4rem;
          margin: 0 auto 2.3rem 0;
        }
      }
    }

    table {
      background: $white;
      border-collapse: separate;
      border-spacing: 0;
      --bs-table-striped-bg: #fff;
      --bs-table-hover-bg: inherit;

      thead {
        > * > * {
          background-color: $table-head-bg;
        }
      }

      > tbody > tr:nth-of-type(even) > * {
        background-color: $table-body-even-bg !important;
      }

      @include respondto(laptop13) {
        width: 100%;
      }

      tr {
        th {
          border-width: 0px !important;
          color: $table-head-font-color;
          font-size: $font-md;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding: 10px 5px;
          text-align: left;

          &:first-child {
            border-radius: 5px 0px 0px 5px;
            padding-left: 1rem;
            text-align: left;
          }

          &:last-child {
            border-radius: 0px 5px 5px 0px;
          }
        }

        td {
          border-width: 0px !important;
          color: $black;
          font-size: $font-md;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 0.5rem 1rem;
          text-align: left;
          vertical-align: middle;

          &:first-child {
            border-radius: 5px 0px 0px 5px;
            text-align: left;
          }

          &:last-child {
            border-radius: 0px 5px 5px 0px;
          }

          span {
            align-items: center;
            display: flex;
            height: 39px;
            justify-content: flex-start;
            margin: 0;
            min-width: 73px;
            width: fit-content;
            @include respondto(largeDesktopSm) {
              height: 2.0313vw;
              min-width: 3.802vw;
            }
            @include respondto(phone) {
              font-size: 1.2rem;
              height: 30px;
              min-width: 57px;
            }
          }

          a {
            color: $black;
            font-size: $font-md;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            position: relative;
            transition: 0.3s ease-in-out;

            @include respondto(phone) {
              font-size: 1.2rem;
            }

            svg {
              height: 1.2rem;
              fill: $main-color;
              margin-left: 0.9rem;
              width: 1.2rem;
              @include respondto(largeDesktopSm) {
                height: 0.625vw;
                margin-left: 0.469vw;
                width: 0.625vw;
              }
            }

            &:hover {
              color: $main-color-hover;
            }
          }
        }
      }
    }
  }
}
