@import '../../assets/styles/variables';

.request-property-list-imoap.property-list {
  padding: 1.9rem 1.6rem;
  form {
    display: grid;
    grid-template-columns: 5% 17.4759% 16.586% 15.952% 15% 13% 15%;
    @include respondto(laptop13) {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .filter-item {
      border-right: none;

      @include respondto(laptop13) {
        max-width: 100%;
      }
      &:nth-child(2) {
        padding-left: 0;
      }
      &:last-child {
        align-items: flex-end;
        justify-content: flex-end;
        text-align: right;
      }
    }
    .arrows-label-icon {
      cursor: pointer;
      label {
        cursor: pointer;
      }
    }

    .react-select__value-container {
      @include respondto(largeDesktopSm) {
        padding: 0 0.26vw;
      }
    }
    .react-datepicker-wrapper {
      &::after {
        display: none;
      }
    }
    .form-item-group {
      .form-item-control-select {
        .react-select__indicator {
          padding: 1px;
          @include respondto(largeDesktopSm) {
            padding: 0.1vw;
          }
        }
      }
    }

    .react-datepicker-wrapper input,
    .form-item-control {
      @include respondto(largeDesktopSm) {
        padding: 0.1vw 0.26vw;
      }
    }
  }
}
