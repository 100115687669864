@import '../../assets/styles/variables';

.form-item {
  &-group.multiselect {
    width: 100%;

    &-label {
      color: $black;
      font-size: $font-md;
      font-weight: 300;
      line-height: 1;
      margin-bottom: 7px;
      @include respondto(largeDesktopSm) {
        margin-bottom: 0.3646vw;
      }

    }

    .form-item-control-select {
      background-color: $input-color;
      border: 1px solid $input-color;
      border-radius: 4px;
      color: rgba($black, 0.62) !important;
      font-size: $font-sm;
      line-height: 17px;
      min-height: 34px;
      width: 100%;

      @include respondto(phone) {
        font-size: $font-md;
      }

      &.react-select__control {
        &--is-focused {
          box-shadow: 0 0 0 1px $nav-color;
        }
      }

      .react-select {
        &__placeholder {
          color: $input-placeholder;
          font-size: $font-sm;
        }

        &__input {
          &-container {
            color: rgba($black, 0.62);
          }

          &__placeholder {
            color: $input-placeholder;
            font-size: $font-sm;
          }
        }

        &__menu {
          font-size:1.2rem !important;

          * {
            font-size: 1.2rem !important;
          }
        }

        &__indicator {
          color: $gray;

          &-separator {
            display: none;
          }
        }

        &__multi-value {
          &__remove,
          &__label {
            background-color: $ghost-white;
          }

          &__label {
            color: $black;
            font-size: $font-sm;
          }

          &__remove {
            color: $black;

            svg {
              height: 16px;
              fill: $main-color;
              width: 16px;
            }
          }
        }
      }

      &:hover {
        border: 1px solid $ghost-white;
      }

      &::placeholder {
        color: $input-placeholder;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba($blue-genie, 0.25);
      }

      &.is-valid {
        border: 1px solid rgb(25, 135, 84);
      }

      &.is-invalid {
        border: 1px solid rgb(220, 53, 69);
      }

      &:disabled {
        background-color: $delta-ligthness;
        border: 1px solid $delta-ligthness;
      }
    }
  }
}

.react-select {
  &__menu {
    font-size: 16px;
    max-width: 450px;
    @include respondto(phone) {
      max-width: 87vw;
    }
    &-list {
      max-width: 450px;
      @include respondto(phone) {
        max-width: 87vw;
      }
    }
  }
  &__option {
    min-width: auto;
    .sugested-properties-item {
      border-bottom: 1px solid #dfdbdb;
      display: flex;
      gap: 10px;
      padding-bottom: 10px;
      width: 100%;

      .img-block {
        height: 50px;
        overflow: hidden;
        width: 80px;
        @include respondto(largeDesktopSm) {
          border-radius: 0.3646vw;
          height: 2.604vw;
          width: 4.1667vw;
        }
        @include respondto(phone) {
          height: 15.104vw;
          width: 30%;
        }
        img {
          border-radius: 7px;
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
      .price {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        // flex-direction: column;
        font-weight: 500;
        gap: 5px;
        justify-content: flex-start;
      }
      .info-properties {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: flex-start;
        width: calc(100% - 90px);

        @include respondto(largeDesktopSm) {
          width: calc(100% - 4.635vw);
        }
        @include respondto(phone) {
          width: calc(70% - 10px);
        }
        * {
          line-height: 1.3;
        }
        .price {
          font-size: 1.3rem !important;
          span {
            font-size: 1.3rem !important;
            @include respondto(largeDesktopMd) {
              font-size: 0.677vw !important;
            }
            @include respondto(laptopMd) {
              font-size: 0.9028vw !important;
            }
          }
          @include respondto(largeDesktopMd) {
            font-size: 0.677vw !important;
          }
          @include respondto(laptopMd) {
            font-size: 0.9028vw !important;
          }
        }
        .details {
          font-size: 1.3rem !important;
          margin-bottom: 0.5rem;
        }
        .label-item-rounded-info {
          font-size: 1.3rem !important;
          @include respondto(largeDesktopMd) {
            font-size: 0.677vw !important;
          }
          @include respondto(laptopMd) {
            font-size: 0.9028vw !important;
          }
          @include respondto(phone) {
            font-size: 1.2rem !important;
          }
        }
      }
    }
  }
}

.invalid-feedback {
  &.display-block {
    display: block;
  }
}

.valid-feedback {
  &.display-block {
    display: block;
  }
}

.select-placeholder-text {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
