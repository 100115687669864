@import '../../assets/styles/variables';

.contact-edit-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  &--info {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: stretch;
    margin-bottom: 1rem;
    padding-bottom: 3rem;
    @include respondto(laptop13) {
      padding-bottom: 1rem;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $platinum;
    }
    @include respondto(phone) {
      flex-direction: column;
    }
  }
  &--header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    svg {
      cursor: pointer;
      transform: scale(1.2);
      fill: $main-color;
      height: 18px;
      width: 18px;
      path {
        fill: $main-color;
      }
    }
  }
  &--title {
    color: $black;
    font-size: $font-lg;
    font-weight: 600;
    padding: 1rem 0;
    width: 100%;
    @include respondto(laptop13) {
      padding: 0.5rem;
    }

    @include respondto(phone) {
      font-size: 1.6rem;
    }
  }
  &--left {
    display: flex;
    flex-direction: column;
    img {
      border: 1px solid $main-color;
      border-radius: 50%;
      height: 12rem;
      margin: 0 auto;
      margin-bottom: 2rem;
      object-fit: cover;
      overflow: hidden;
      width: 12rem;
    }
    @include respondto(phone) {
      width: 100%;
    }
  }
  &--right {
    border-left: 1px solid $platinum;
    margin-left: 2rem;
    padding-left: 2rem;
    @include respondto(phone) {
      border: none;
      margin-left: 0;
      margin-top: 2rem;
      padding-left: 0;
      width: 100%;
    }
  }

  .contact-edit-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .contact-edit-activities {
    width: 100%;
  }
  .contact-edit-properties {
    width: 100%;
  }
  .contact-edit-item {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
  .activity-list {
    gap: 1rem;
    width: 100%;
    &--header {
      color: $black;
      display: grid;
      font-size: $font-md;
      font-weight: 600;
      grid-template-columns: 8% 9% 53% 20% 10%;
      padding-bottom: 2rem;
      padding: 1.5rem;
      width: 100%;

      @include respondto(laptop13) {
        display: none;
      }
      &-item {
        &:last-child {
          text-align: right;
        }
      }
    }
    &-item {
      align-items: center;
      border-radius: 1.5rem;
      box-shadow: none;
      display: grid;
      gap: 0;
      grid-template-columns: 8% 9% 53% 20% 10%;
      padding: 1.5rem 2.4rem;
      width: 100%;
      @include respondto(laptop13) {
        grid-template-columns: 15% 8% 33% 30% 10%;
      }
      @include respondto(phone) {
        display: flex;
        flex-wrap: wrap;
      }
      h3,
      .h3 {
        font-size: $font-md;

        @include respondto(laptop13) {
          font-size: 1.5rem;
        }
      }
      &--column {
        .icon {
          // margin: auto;
          max-height: 4rem;
          max-width: 4rem;
        }
        .info-details {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 0.5rem;
        }
        .action-buttons-list {
          justify-content: flex-end;
        }

        @include respondto(phone) {
          &:first-child {
            order: 1;
          }
          &:last-child {
            order: 2;
          }
          &:nth-child(3) {
            width: calc(100% - 5.5rem);
          }
        }
      }
      &.bg-blue {
        .label-item-rounded-info,
        .label-item-rounded-info-sm {
          background: $white;
        }
      }
    }
  }
  .property-list {
    display: flex !important;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    .label-item-rounded-info-sm {
      height: auto;
    }
    &--header {
      color: $black;
      display: grid;
      font-size: 1.3rem;
      font-weight: 600;
      grid-template-columns: 5% 10% 17% 21% 32% 14%;
      padding-bottom: 2rem;
      padding: 1.5rem 1.1rem;
      width: 100%;
      @include respondto(laptop13) {
        display: none;
      }
      &-item {
        &:last-child {
          text-align: right;
        }
      }
    }

    &-item {
      align-items: center;
      border-radius: 1.5rem;
      display: grid;
      gap: 0;
      grid-template-columns: 5% 10% 17% 21% 32% 14%;
      padding: 1.5rem 1.1rem;
      width: 100%;
      @include respondto(laptop13) {
        display: flex;
        flex-wrap: wrap;
      }
      @include respondto(phone) {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        padding: 1rem;
      }
      &.bg-blue {
        .label-item-rounded-info,
        .label-item-rounded-info-sm {
          background: $white;
        }
      }
      .info-adress {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 0.2rem;
        .display-flex {
          gap: 0.2rem;
        }
      }
      &--column {
        @include respondto(laptop13) {
          margin-bottom: 0.5rem;
        }
        &:first-child {
          align-items: center;
          display: flex;
          @include respondto(laptop13) {
            justify-content: center;
          }
          .label-item-rounded-info {
            min-width: 44px;
            padding: 5px;
            @include respondto(largeDesktopSm) {
              min-width: 2.292vw;
            }
            @include respondto(phone) {
              min-width: 30px;
            }
          }
        }
        &:last-child {
          @include respondto(laptop13) {
            border-top: 1px solid rgba($main-color, 0.3);
            margin-top: 1rem;
            padding-top: 1rem;
          }
        }
        @include respondto(laptop13) {
          width: 100%;
        }

        .label-item-mobile {
          @include respondto(laptop13) {
            background: $info-labels !important;
          }
        }
        .image {
          border-radius: 8px;
          height: 100px;
          width: 100px;
          @include respondto(largeDesktopSm) {
            border-radius: 0.4167vw;
            height: 5.208vw;
            width: 5.208vw;
          }
          @include respondto(laptop13) {
            margin: auto auto 1rem;
          }
          img {
            border-radius: 4px;
            height: 100%;
            object-fit: cover;
            width: 100%;
          }
        }
        .info-properties {
          // align-items: center;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          gap: 0.5rem;
          .display-flex {
            align-items: center;
            gap: 0.5rem;
          }
        }
        .action-buttons-list {
          justify-content: flex-end;
          @include respondto(phone) {
            width: 100%;
          }
        }
      }
    }
  }
}
