@import '../../assets/styles/variables';
.modal-backdrop:has(+ .email-templates-modal-center.show) {
  --bs-backdrop-zindex: 9999 !important;
}
.email-templates-modal-center {
  --bs-modal-width: 744px;
  --bs-modal-bg: $azure;
  --bs-modal-zindex: 99999999 !important;
  bottom: 0;
  left: 0;
  padding-top: 90px;
  right: 0;
  top: 0;

  &.show {
    align-items: center;
    display: flex !important;
    justify-content: center;
  }

  .modal-title {
    color: $black;
    font-size: 1.8rem;
    font-weight: 600;

    // @include respondto(phone) {
    //   font-size: 20px;
    // }
  }

  .modal-dialog {
    margin: auto;
    max-width: 744px;
  }

  .modal-content {
    background-color: $azure;
    border: none;
    border-radius: 20px;
    @include respondto(laptop13) {
      background-color: $white;
    }
  }

  .modal-body {
    background-color: $white;
    border-radius: 25px;
    box-shadow: $block-shadow;
    margin: 0 76px 72px;
    padding: 35px 65px 34px 63px;
    width: calc(100% - 152px);

    @include respondto(phone) {
      border-radius: 0 0 20px 20px;
      margin: 0;
      padding: 30px 20px;
      width: 100%;
    }
  }

  .modal-header {
    border-bottom: none;
    justify-content: center;
    padding: 5.2rem 1rem 2.5rem;
    text-align: center;

    @include respondto(phone) {
      padding: 1.5rem 6rem;
    }
  }

  .container {
    padding: 0;
  }

  .form-item {
    margin-bottom: 20px;
  }

  .form-item-button {
    margin-top: 14px;
  }

  textarea {
    min-height: 149px;
  }

  .form-item-group-block.error > textarea {
    border: 1px solid $red;
  }

  .button-blue {
    margin: 0 auto;
  }

  .form-item-control-select {
    padding-left: 4px;
  }
}

.close-modal {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  height: 40px;
  position: absolute;
  right: 5px;
  top: 5px;
  transition: 0.3s ease-in-out;
  width: 40px;

  * {
    transition: 0.3s ease-in-out;
  }

  svg {
    height: 20px;
    width: 20px;
  }

  &:hover {
    svg {
      fill: $main-color;

      path {
        fill: $main-color;
      }
    }
  }
}
