@import '../../assets/styles/variables';

.form-item {
  &-group {
    width: 100%;

    &-label {
      color: $black;
      font-size: $font-sm;
      font-weight: 300;
      line-height: 1;
      margin-bottom: 7px;
      @include respondto(largeDesktopSm) {
        margin-bottom: 0.3646vw;
      }
      // @include respondto(largeDesktopMd) {
      //   font-size: 0.781vw;
      // }
      // @include respondto(laptopMd) {
      //   font-size: 0.9722vw;
      // }
    }

    .form-item-control-select {
      background-color: $input-color;
      border: 1px solid $input-color;
      border-radius: 4px;
      color: rgba($black, 0.62) !important;
      font-size: $font-sm;
      line-height: 17px;
      min-height: 34px;
      width: 100%;

      &.react-select__control {
        &--is-focused {
          box-shadow: 0 0 0 1px $nav-color;
        }
      }

      .react-select {
        &__placeholder {
          color: $input-placeholder;
          font-size: $font-sm;
        }

        &__input {
          &-container {
            color: rgba($black, 0.62);
          }

          &__placeholder {
            color: $input-placeholder;
            font-size: 1.3rem;
          }
        }

        &__menu {
          font-size: $font-sm !important;

          * {
            font-size: 13px !important;
          }
        }

        &__indicator {
          color: $gray;

          &-separator {
            display: none;
          }
        }

        &__clear-indicator {
          color: $main-color !important;
        }
        &__multi-value {
          &__remove,
          &__label {
            background-color: $input-color;
          }

          &__label {
            color: $black;
            font-size: $font-sm;
          }

          &__remove {
            color: $black;

            svg {
              height: 16px;
              fill: $nav-color;
              width: 16px;
            }
          }
        }
        &__value-container {
          @include respondto(largeDesktopSm) {
            padding: 0 0.26vw;
          }
        }
      }

      &:hover {
        border: 1px solid $input-color;
      }

      &::placeholder {
        color: $input-placeholder;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba($main-color, 0.25);
      }

      &.is-valid {
        border: 1px solid rgb(25, 135, 84);
      }

      &.is-invalid {
        border: 1px solid rgb(220, 53, 69);
      }

      &:disabled {
        background-color: $delta-ligthness;
        border: 1px solid $delta-ligthness;
      }
    }
  }
}

.react-select {
  &__menu {
    font-size: $font-sm;
  }
  &__indicator {
    svg {
      @include respondto(largeDesktopSm) {
        height: 1.042vw;
        width: 1.042vw;
      }
    }
  }
  &__value-container {
    flex-wrap: nowrap;
    @include respondto(largeDesktopSm) {
      padding: 0 0.4167vw;
    }
  }
  &__input-container {
    @include respondto(largeDesktopSm) {
      margin: 0.104vw !important;
      padding-bottom: 0.104vw !important;
      padding-top: 0.104vw !important;
    }
  }
}
.select-blue {
  .form-item-control-select {
    background-color: $input-bg;
    border: 1px solid $input-border-color;
    .react-select {
      &__single-value {
        color: $input-font-color !important;
      }
      &__placeholder,
      &__input-container {
        color: $input-font-color;
      }
      &__indicators {
        svg {
          path {
            fill: $input-font-color;
          }
        }
      }
    }
  }
}

.invalid-feedback {
  &.display-block {
    display: block;
  }
}

.valid-feedback {
  &.display-block {
    display: block;
  }
}
