@import '../../assets/styles/variables';

.agent-permision-container {
  background: $azure;
  border-radius: 25px;
  max-width: 564px;
  padding: 24px 25px 26px 28px;
  width: 100%;

  @include respondto(largeDesktopSm) {
    border-radius: 1.302vw;
    max-width: 29.375vw;
    padding: 1.25vw 1.302vw 1.354vw 1.4583vw;
  }

  @include respondto(laptop13) {
    background: transparent;
    border-radius: 0;
    max-width: 100%;
    padding: 0;
  }

  .agent-permision-wrapper {
    background: $white;
    border-radius: 20px;
    box-shadow: $block-shadow;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 27px 43px;
    @include respondto(largeDesktopSm) {
      border-radius: 1.042vw;
      padding: 1.406vw 2.2396vw;
    }
    @include respondto(laptop13) {
      padding: 15px 30px;
    }
    @include respondto(phone) {
      padding: 15px 20px;
    }

    h4 {
      color: $black;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 36px;
      @include respondto(largeDesktopSm) {
        font-size: 0.938vw;
        margin-bottom: 1.875vw;
      }
      @include respondto(phone) {
        margin-bottom: 2rem;
      }
    }

    .accordion-section {
      border-left: 1px solid $gainsboro-second;
      margin-bottom: 44px;
      padding-left: 27px;
      @include respondto(largeDesktopSm) {
        margin-bottom: 2.292vw;
        padding-left: 1.406vw;
      }
    }

    .accordion-section-list {
      display: flex;
      flex-direction: column;
      gap: 19px;
      @include respondto(largeDesktopSm) {
        gap: 0.99vw;
      }

      input {
        border: 1px solid $input-check-border;
        border-radius: 4px;
        box-shadow: 0 0 0 0.5px $input-check-border;
        height: 22px;
        width: 22px;
        @include respondto(largeDesktopSm) {
          border-radius: 0.2083vw;
          height: 1.1458vw;
          width: 1.1458vw;
        }

        @include respondto(desktopXLg) {
          box-shadow: none;
        }

        &:checked {
          background-color: $main-color;
          border: 1px solid $main-color;
          box-shadow: 0 0 0 0.5px $main-color;

          @include respondto(desktopXLg) {
            box-shadow: none;
          }
        }
      }

      label {
        color: $black;
        font-size: $font-lg;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin-left: 20px;
        @include respondto(largeDesktopSm) {
          font-size: 0.833vw;
          margin-left: 1.042vw;
        }
        @include respondto(phoneSm) {
          margin-left: 0;
        }
      }

      .multiple-checkbox-container {
        .dropdown-header {
          max-width: 100%;
          position: relative;
          width: fit-content;

          .indeterminate {
            .form-check-input[type='checkbox']:checked[type='checkbox'] {
              --bs-form-check-bg-image: url('../../assets/svg/minusicon.svg');
            }
          }

          .dropdown-icon {
            height: 7px;
            position: absolute;
            right: -25px;
            top: 9px;
            width: 11px;
            @include respondto(largeDesktopSm) {
              height: 0.3646vw;
              right: -1.302vw;
              top: 0.469vw;
              width: 0.5729vw;
            }
          }

          .toggle-icon {
            align-items: center;
            background: $main-color;
            border: 4px solid $white;
            border-radius: 7px;
            cursor: pointer;
            display: flex;
            height: 30px;
            justify-content: center;
            left: -42px;
            position: absolute;
            top: -1px;
            width: 30px;
            @include respondto(largeDesktopSm) {
              border: 0.2083vw solid $white;
              border-radius: 0.3646vw;
              height: 1.563vw;
              left: -2.188vw;
              // top: -0.156vw;
              width: 1.563vw;
            }

            img {
              max-width: 100%;

              &:first-of-type {
                display: block;
              }

              &:last-of-type {
                display: none;
              }
            }
          }
        }

        .extensive-container {
          border-left: 1px solid $gainsboro-second;
          display: flex;
          flex-direction: column;
          gap: 18px;
          margin-left: 12px;
          max-height: 0;
          overflow: hidden;
          padding-left: 33px;
          transition: all 0.3s ease-in-out;
          @include respondto(largeDesktopSm) {
            gap: 0.938vw;
            margin-left: 0.625vw;
            padding-left: 1.7188vw;
          }
          @include respondto(phoneSm) {
            padding-left: 20px;
          }
        }

        &.active {
          .dropdown-header {
            &::after {
              transform: rotate(180deg);
            }
          }

          .extensive-container {
            margin-top: 25px;
            max-height: 300px;
            @include respondto(largeDesktopSm) {
              margin-top: 1.302vw;
            }
          }

          .toggle-icon {
            img {
              &:first-of-type {
                display: none;
              }

              &:last-of-type {
                display: block;
              }
            }
          }
        }
      }
    }

    .btns-section {
      align-items: center;
      display: flex;
      gap: 13px;
      @include respondto(largeDesktopSm) {
        gap: 0.677vw;
      }
      @include respondto(phone) {
        flex-wrap: wrap;
        justify-content: center;
      }

      .button-blue {
        min-width: 183px;
        @include respondto(largeDesktopSm) {
          min-width: 9.531vw;
        }
        @include respondto(phone) {
          padding: 0 0.3rem;
        }
      }

      .reset-btn {
        min-width: 152.932px;
        @include respondto(largeDesktopSm) {
          min-width: 7.917vw;
        }
        @include respondto(phone) {
          padding: 0 0.3rem;
        }
      }
    }
  }
}
