@import '../../assets/styles/variables';

.section-container {
  display: flex;
  flex-wrap: wrap;
}

.ads {
  &-general {
    &-sm {
      margin-right: auto;
      position: relative;
      width: 350px;

      @include respondto(largeDesktopSm) {
        width: 18.229vw;
      }
      @include respondto(laptopMd) {
        width: 22vw;
      }
      @include respondto(laptop13) {
        margin-bottom: 21px;
        width: 100%;
      }
    }

    &-info {
      font-size: $font-md;
      line-height: 1.5rem;

      table {
        width: 100%;
      }

      &__heading {
        padding: 5px 5px 5px 0;

        @include respondto(largeDesktopSm) {
          padding: 0.26vw 0.26vw 0.26vw 0;
        }
      }

      &__content {
        align-items: flex-end;
        color: $black;
        display: flex;
        font-weight: 500;
        justify-content: flex-end;
        text-align: right;

        .link {
          color: $main-color;
          cursor: pointer;
          position: relative;
          width: max-content;

          &:after {
            border-bottom: 1px solid;
            bottom: 0;
            content: '';
            left: 0;
            margin: 0 auto;
            opacity: 0;
            position: absolute;
            right: 0;
            transition: 0.3s ease-in-out;
            width: 0;
          }

          &:hover,
          &:focus {
            color: $main-color-hover;

            &::after {
              opacity: 1;
              width: 100%;
            }
          }
        }
      }

      &-bottom {
        @include respondto(phone) {
          padding: 20px 15px;
        }
      }

      &-more {
        @include respondto(desktop) {
          display: none;
        }
      }
    }
  }

  &-heading {
    color: $black;
    font-size: 2.4rem;
    font-weight: 600;

    @include respondto(largeDesktopMd) {
      font-size: 1.25vw;
    }

    @include respondto(phone) {
      font-size: 1.6rem;
      margin-bottom: 22px;
      text-align: center;
    }
  }
}

.user-general-info {
  align-items: center;
  border-bottom: 1px solid $platinum;
  border-top: 1px solid $platinum;
  display: flex;
  flex-direction: row;
  gap: 1.3rem;
  margin: 2.1rem 0 3rem;
  padding: 1rem 0;
  @include respondto(largeDesktopSm) {
    gap: 0.677vw;
    margin: 1.094vw 0 1.563vw;
    padding: 0.521vw 0;
  }
  .image-block {
    background-color: $white;
    border: 1px solid $nav-color;
    border-radius: 50%;
    height: 4.6rem;
    overflow: hidden;
    width: 4.6rem;
    @include respondto(largeDesktopSm) {
      height: 2.3958vw;
      width: 2.3958vw;
    }
    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }

  .content-info {
    width: calc(100% - 7.3rem);
    @include respondto(largeDesktopSm) {
      width: calc(100% - 3.802vw);
    }
    .name {
      color: $black;
      font-size: $font-md;
      font-weight: 500;
    }

    .info {
      color: $black;
      font-size: $font-sm;
    }
  }
}

.associated-contacts {
  .heading {
    color: $black;
    font-size: $font-md;
    font-weight: 500;
    margin-bottom: 15px;
    @include respondto(largeDesktopSm) {
      margin-bottom: 0.781vw;
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0;
    padding: 0;
    @include respondto(largeDesktopSm) {
      gap: 0.521vw;
    }
    .label-item-rounded-info-sm {
      font-weight: 300;
      height: auto;
      line-height: 1;
      padding: 5.5px 10px;
      @include respondto(largeDesktopSm) {
        padding: 0.286vw 0.521vw;
      }
    }
  }
}
