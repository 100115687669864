@import '../../assets/styles/variables';

.particular-item {
  align-items: center;
  background: $white;
  border-radius: 20px;
  box-shadow: 0px 27px 42.3px 0px rgba($black, 0.04);
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
  padding: 15px 25px 15px 24px;
  width: 100%;

  @include respondto(largeDesktopSm) {
    border-radius: 1.042vw;
    gap: 1.042vw;
    margin-bottom: 0.781vw;
  }

  @include respondto(laptop13) {
    flex-direction: column;
  }

  &.active {
    position: relative;

    &::before {
      background: $main-color;
      border-radius: 20px;
      content: '';
      height: 98%;
      left: -5px;
      position: absolute;
      top: 0;
      width: 40px;
      z-index: -1;
    }
  }

  .checkbox-container {
    max-width: 20px;
    min-width: 20px;
    width: 100%;

    .form-check-input {
      height: 20px;
      width: 20px;
    }
  }

  .list-item-content {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: space-between;
    width: 100%;
    @include respondto(largeDesktopSm) {
      gap: 1.25vw;
    }
    @include respondto(laptop13) {
      flex-direction: column;
      gap: 10px;
    }

    .item-container {
      display: flex;
      flex-direction: column;
      gap: 15px;
      max-width: 100%;
      width: 100%;

      @include respondto(laptop13) {
        max-width: 100%;
      }
    }

    .list-item-header {
      align-items: center;
      display: flex;
      gap: 0;
      justify-content: flex-start;
      width: 100%;

      @include respondto(laptop13) {
        flex-direction: column;
        gap: 3px;
      }
      .column-item {
        margin: 0 36px;
        @include respondto(largeDesktopSm) {
          margin: 0 1.875vw;
          &:first-child {
            margin: 0 1.875vw 0 0;
          }
        }

        @include respondto(onlyDesktop) {
          width: 14.5%;
        }
        @include respondto(desktopXLg) {
          width: 15%;
        }
        @include respondto(laptop13) {
          margin: 0;
          width: 100%;
        }
      }

      .label-item-info-lg {
        width: 100%;
        @include respondto(laptop13) {
          height: auto;
          width: 50%;
        }
      }
    }

    .item-details {
      align-items: flex-start;
      display: flex;
      width: 100%;

      @include respondto(laptop13) {
        flex-direction: column;
      }

      .item-info {
        width: 100%;
        &:nth-child(1) {
          width: 20%;
          @include respondto(onlyDesktop) {
            padding-right: 1rem;
          }
          @include respondto(laptop13) {
            width: 100%;
          }
        }
        &:nth-child(2) {
          width: 19.5%;
          @include respondto(onlyDesktop) {
            padding-right: 1rem;
          }
          @include respondto(laptop13) {
            width: 100%;
          }
        }
        ul {
          padding-left: 0;

          @include respondto(laptop13) {
            margin-bottom: 0px;
            padding-left: 0;
          }

          li {
            color: $black;
            font-size: $font-md;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 5px;

            @include respondto(phone) {
              font-size: 12px;
            }
            span {
              font-weight: 500;
            }

            .sugestion-text {
              color: $main-color;
              padding-left: 0.5rem;
            }
          }
        }
      }

      .item-info-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 15px;
      }

      .item-description {
        color: $black;
        font-size: $font-md;
        font-weight: 300;
        line-height: normal;
        max-width: 701px;
        width: 100%;
        width: 35%;
        @include respondto(laptop13) {
          width: 100%;
        }
        @include respondto(laptop13) {
          max-width: 100%;
        }
        @include respondto(phone) {
          font-size: 12px;
        }
      }
    }

    .item-icon-links {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 15px;

      @include respondto(laptop13) {
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
      }
    }
  }
}
