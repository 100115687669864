.button {
  &-blue,
  &-red,
  &-border,
  &-border-gray {
    align-items: center;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    font-size: 1.2rem;
    font-weight: 500;
    gap: 0 13px;
    height: 34px;
    justify-content: center;
    line-height: 32px;
    padding: 0 2rem;
    transition: 0.3s ease-in-out;
    width: fit-content;
    @include respondto(largeDesktopSm) {
      gap: 0 0.677vw;
      padding: 0 0.26vw;
    }
    svg {
      transition: all 0.3s ease-in-out;
    }

    &[aria-disabled='true'],
    &:disabled {
      background-color: $silver;
      border: 1px solid $silver;
      color: $input-color;
      cursor: inherit;
    }
  }

  &-sm {
    border-radius: 4px;
    font-size: 1.2rem;
    height: 30px;
    line-height: 28px;
    padding: 0 2rem;

    @include respondto(largeDesktopSm) {
      padding: 0 1.042vw;
    }
  }

  &-md {
    border-radius: 7px;
    font-size: 1.2rem;
    height: 34px;
    line-height: 32px;
    // min-width: 223px;
    padding: 0 2rem;

    @include respondto(largeDesktopSm) {
      // min-width: 11.615vw;
      padding: 0 1.042vw;
    }
  }

  &-lg {
    border-radius: 7px;
    font-size: 1.3rem;
    height: 34px;
    line-height: 32px;
    // min-width: 223px;
    padding: 0 2rem;

    // @include respondto(largeDesktopSm) {
    //   min-width: 11.615vw;
    // }
  }

  &-blue {
    background-color: $main-color;
    border: 1px solid $main-color;
    color: $white;
    font-weight: 500;

    svg {
      fill: $white;

      path {
        fill: $white;
      }
    }

    &:hover:not(:disabled):not([aria-disabled='true']) {
      background-color: $white;
      border: 1px solid $main-color;
      color: $main-color;
      svg {
        fill: $main-color;

        path {
          fill: $main-color;
        }
      }
    }
  }

  &-red {
    background-color: $red;
    border: 1px solid $red;
    color: $white;
    font-weight: 600;

    svg {
      fill: $white;

      path {
        fill: $white;
      }
    }
  }

  &-border {
    background-color: $white;
    border: 1px solid $main-color;
    color: $main-color;
    font-weight: 600;

    svg {
      fill: $main-color;

      path {
        fill: $main-color;
      }
    }

    &:hover:not(:disabled):not([aria-disabled='true']) {
      background-color: $main-color;
      border: 1px solid $main-color;
      color: $white;

      svg {
        fill: $white;

        path {
          fill: $white;
        }
      }
    }
  }

  &-border-gray {
    border: 1px solid $main-color;
    color: $main-color;
    font-weight: 400;

    &:hover:not(:disabled):not([aria-disabled='true']) {
      background-color: $main-color;
      border: 1px solid $main-color;
      color: $white;

      svg {
        fill: $white;

        path {
          fill: $white;
        }
      }
    }
  }
}

button {
  transition: all 0.3s ease-in-out;
}

.btn-squere {
  &-blue,
  &-border {
    align-items: center;
    background-color: $light-button-color;
    border: 1px solid $light-button-border;
    border-radius: 0.7rem;
    display: flex;
    height: 3rem;
    justify-content: center;
    padding: 0;
    transition: 0.3s ease-in-out;
    width: 3rem;

    svg {
      transition: 0.3s ease-in-out;
      fill: $light-button-font;

      path {
        fill: $light-button-font;
      }
    }
    &:hover {
      background: $main-color-hover;
      border: 2px solid $main-color-hover;
    }
  }

  &-blue {
    background-color: $light-button-color;
    border: 1px solid $light-button-border;

    svg {
      fill: $light-button-font;
      height: 1.5rem;
      width: 1.5rem;

      path {
        fill: $light-button-font;
      }
    }
  }

  &-border {
    svg {
      fill: $black;
      height: 2.4rem;
      width: 2.4rem;

      path {
        fill: $black;
      }
    }
  }
}

.reset-btn {
  align-items: center;
  background: $white;
  border: 1px solid $main-color;
  border-radius: 0.7rem;
  color: $main-color;
  display: flex;
  font-size: 1.3rem;
  font-weight: 400;
  height: 34px;
  justify-content: center;
  line-height: 32px;
  // min-width: 223px;
  padding: 0 2rem;
  transition: 0.2s ease-in-out;

  &:hover {
    background: $main-color;
    color: $white;
  }
}

.add-block {
  align-items: center;
  background: transparent;
  border: none;
  color: $main-color;
  display: flex;
  font-size: $font-sm;
  font-style: normal;
  font-weight: 500;
  gap: 7px;
  line-height: 0px;
  text-align: center;
  transition: all 0.3s ease-in-out;

  svg,
  img {
    height: 24px;
    transition: all 0.3s ease-in-out;
    width: 24px;
    @include respondto(largeDesktopSm) {
      height: 1.25vw;
      width: 1.25vw;
    }
  }

  svg {
    fill: $main-color;
    transition: all 0.3s ease-in-out;
    path {
      fill: $main-color;
    }
  }

  &:hover {
    color: $main-color-hover;
    text-decoration: underline;
    svg {
      fill: $main-color-hover;

      path {
        fill: $main-color-hover;
      }
    }
  }
}
