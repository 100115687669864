@import '../../assets/styles/variables';

.requests-filter-container {
  background: $azure;
  border-radius: 25px;
  margin-bottom: 35px;
  padding: 20px;
  position: relative;
  width: 100%;
  z-index: 4;

  @include respondto(largeDesktopSm) {
    border-radius: 1.302vw;
    margin-bottom: 1.8229vw;
    padding: 1.042vw;
  }

  @include respondto(laptop13) {
    background: $white;
    border-radius: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .date-column {
    gap: 0 !important;
    position: relative;
  }
  .react-datepicker-popper {
    display: none !important;
  }
  .range-calendar {
    left: 0;
    position: absolute;
    top: 50px;
    z-index: 8;
  }
  .rdrDateRangePickerWrapper {
    box-shadow: $block-shadow;
  }

  .header-section {
    .btns-section {
      button {
        background-color: $white !important;
      }
    }
  }

  .filter-container {
    background: $white;
    border-radius: 25px;
    box-shadow: $block-shadow;
    padding: 20px;

    @include respondto(largeDesktopSm) {
      border-radius: 1.302vw;
      padding: 1.042vw;
    }

    @include respondto(laptop13) {
      border-radius: 0;
      box-shadow: none;
      margin-bottom: 0px;
      padding: 0;
    }
    .flex-container-request {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      width: 100%;
    }
    .form-line-section {
      display: flex;
      flex-direction: column;
      gap: 10px;
      // justify-content: flex-start;
      margin-bottom: 10px;
      // margin-right: auto;
      // max-width: 1425px;
      width: calc(20% - 20px);
      @include respondto(largeDesktopSm) {
        // gap: 3.125vw;
        margin-bottom: 0.885vw;
      }

      @include respondto(laptop13) {
        flex-direction: column;
        gap: 20px;
        width: 100%;
      }

      .form-item-group {
        width: 100%;
        // max-width: 232px;
        // @include respondto(largeDesktopSm) {
        //   width: 20%;
        // }
        @include respondto(laptop13) {
          max-width: 100%;
        }
        label {
          font-size: $font-sm;
          margin-bottom: 5px;
        }
      }
      .form-item-control {
        border-radius: 5px;
        font-size: $font-sm;
        line-height: 16px;
        min-height: 30px;
        padding: 5px 15px;
      }
      .form-check {
        input[type='checkbox'] {
          border-radius: 3px;
          height: 16px;
          width: 16px;
          &:checked[type='checkbox'] {
            background-size: 10px;
          }
        }
        .form-check-label {
          font-size: $font-sm;
        }
      }
      .form-item-group {
        .form-item-control-select {
          border-radius: 5px;
          font-size: $font-sm;
          min-height: 28px;
          .react-select__indicator {
            padding: 2px;
          }
          .react-select__placeholder {
            font-size: $font-sm;
          }
        }
      }
      .react-datepicker-wrapper {
        height: 30px;
        input {
          border-radius: 5px;
          font-size: $font-sm;
          font-weight: 400;
          height: 30px;
        }
        &::after {
          height: 15px;
          right: 5px;
          top: 5px;
          width: 15px;
        }
      }

      .date-picker {
        // max-width: 232px;
        // @include respondto(largeDesktopSm) {
        //   // max-width: 12.3438vw;
        //   width: 20%;
        // }
        @include respondto(laptop13) {
          max-width: 100%;
        }
        label {
          font-size: $font-sm;
          line-height: 1;
          margin-bottom: 5px;
          margin-top: 0;
        }
      }

      &:nth-of-type(4) {
        align-items: flex-end;
      }
    }
    .form-btns-section {
      align-items: center;
      display: flex;
      gap: 20px;
      margin-left: auto;
      @include respondto(largeDesktopSm) {
        gap: 1.042vw;
        justify-content: flex-end;
        width: 44%;
      }
      .reset-btn,
      .filter-btn {
        border-radius: 4px;
        font-size: $font-sm;
        height: 30px;
        line-height: 28px;
        min-width: 150px;
        @include respondto(phoneSm) {
          min-width: 14rem;
        }
      }
    }
    #formGridCheckbox {
      // margin-right: auto;
      // max-width: 237px;
      width: 100%;
    }
  }
}
