@import "../../assets/styles/variables";
.flex-add-contact {
    gap: 32px;
    justify-content: space-between;
    @include respondto(largeDesktopSm) {
        gap: 1.667vw;
    }
    @include respondto(laptop13) {
        flex-direction: column;
    }
}
