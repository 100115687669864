@import '../../../assets/styles/variables';

.property-filters-container {
  .date-column {
    gap: 0 !important;
    position: relative;
  }
  .react-datepicker-popper {
    display: none !important;
  }
  .range-calendar {
    position: absolute;

    right: 0;
    top: 50px;
    z-index: 8;
  }
  .rdrDateRangePickerWrapper {
    box-shadow: $block-shadow;
  }
}

.filter-form-container {
  .header-section {
    justify-content: space-between;
    margin-bottom: 10px;

    @include respondto(laptop13) {
      justify-content: center;
    }

    .title-section {
      color: $black;
      font-size: $font-md;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @include respondto(laptop13) {
        font-size: 1.4rem;
      }
    }

    .btns-section {
      align-items: center;
      display: flex;
      gap: 20px;
      position: absolute;
      right: 15px;
      top: 10px;
      @include respondto(laptopMd) {
        right: 7px;
        top: 7px;
      }
      .btn-squere-blue {
        background: $white;
        border: 1px solid $main-color;
        height: 3rem;
        width: 3rem;
        svg {
          fill: none;
          path {
            fill: $main-color;
          }
        }
        &:hover {
          background: $main-color;
          svg {
            fill: none;
            path {
              fill: $white;
            }
          }
        }
      }
      .btn-blue {
        background: $main-color;
        border: 1px solid $main-color;
        border-radius: 0.7rem;
        height: 3rem;
        width: 3rem;
        svg {
          fill: none;
          height: 18px;
          width: 18px;
          path {
            fill: $white;
          }
        }
        &:hover {
          background: $white;
          svg {
            fill: none;
            path {
              fill: $main-color;
            }
          }
        }
      }

      @include respondto(laptop13) {
        display: none;
      }
      .inverse {
        transform: rotateX(180deg);
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;

    .flex-rows {
      column-gap: 15px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      row-gap: 15px;
      width: 100%;

      &.short-filters {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        .filters-column {
          padding: 0;
        }
        > .form-group,
        > .form-item-group,
        > #formGridCheckbox {
          background-color: $input-color;
          border-radius: 10px;
          padding: 10px;
          width: calc(20% - 12px);
        }
        #formGridCheckbox {
          margin-bottom: 6px;
          margin-top: auto;
        }
      }

      @include respondto(laptop13) {
        gap: 10px;
      }
      .filters-column {
        align-content: flex-start;
        background: $input-color;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: 10px;
        width: calc(20% - 12px);
        @include respondto(laptop13) {
          width: 100%;
        }
        .form-item-group {
          width: 100%;
          @include respondto(laptop13) {
            max-width: 100%;
          }
        }
      }
      .form-item-group {
        label {
          font-size: $font-sm;
          margin-bottom: 5px;
        }
      }
      .form-item-control {
        background: $white;
        border-radius: 5px;
        font-size: $font-sm;
        line-height: 16px;
        min-height: 30px;
        padding: 5px;
      }
      .form-check {
        input[type='checkbox'] {
          border-radius: 3px;
          height: 16px;
          width: 16px;
          &:checked[type='checkbox'] {
            background-size: 10px;
          }
        }
        .form-check-label {
          font-size: $font-sm;
        }
      }
      .form-item-group {
        .form-item-control-select {
          background: $white;
          border-radius: 5px;
          font-size: $font-sm;
          min-height: 30px;
          .react-select__indicator {
            padding: 4px;
          }
          .react-select__placeholder {
            font-size: $font-sm;
          }
        }
      }
      .react-datepicker-wrapper {
        height: 30px;
        input {
          background: $white;
          border-radius: 5px;
          font-size: $font-sm;
          font-weight: 400;
          height: 30px;
          padding: 0 5px;
        }
        &::after {
          height: 15px;
          right: 5px;
          top: 5px;
          width: 15px;
        }
      }

      .date-picker {
        @include respondto(laptop13) {
          max-width: 100%;
        }
        label {
          font-size: $font-sm;
          line-height: 1;
          margin-bottom: 5px;
          margin-top: 0;
        }
      }
      .form-item-group,
      #formGridCheckbox {
        width: 100%;

        @include respondto(laptop13) {
          max-width: 100%;
        }
      }

      .form-group {
        width: 100%;

        @include respondto(laptop13) {
          max-width: 100%;
        }
      }
      .flex-input-column {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      > #formGridCheckbox,
      > .flex-input,
      > .form-item-group,
      > .form-group {
        max-width: 100%;
        @include respondto(laptop13) {
          max-width: 100%;
        }
      }

      label:not(.form-check-label) {
        color: $black;
        font-size: $font-sm;
        font-style: normal;
        font-weight: 300;
        line-height: 1;
        margin-bottom: 5px;
      }
      .form-check {
        input[type='checkbox'] {
          border-radius: 2px;
          height: 16px;
          width: 16px;
        }
      }
      label.form-check-label {
        font-size: $font-sm;
        font-style: normal;
        font-weight: 300;
        line-height: 1;
      }

      .flex-input {
        align-items: center;
        display: flex;
        gap: 9px;
        width: 100%;

        .flex-columns {
          display: flex;
          gap: 25px;
          width: 100%;

          @include respondto(largeDesktopSm) {
            gap: 1.302vw;
          }

          @include respondto(laptop13) {
            flex-direction: column;
            gap: 10px;
          }

          .form-line-section {
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: 28px;
            margin-bottom: 17px;
            width: 100%;

            @include respondto(largeDesktopSm) {
              gap: 1.4583vw;
              margin-bottom: 0.885vw;
            }

            @include respondto(laptop13) {
              gap: 10px;
              margin-bottom: 10px;
            }

            .form-item-group {
              max-width: 237px;
              width: 100%;
              @include respondto(largeDesktopSm) {
                max-width: 12.3438vw;
              }
              @include respondto(laptop13) {
                max-width: 100%;
              }
            }

            .form-group {
              max-width: 237px;
              width: 100%;

              @include respondto(laptop13) {
                max-width: 100%;
              }
            }

            label {
              color: $black;
              font-size: $font-sm;
              font-style: normal;
              font-weight: 300;
              line-height: 1;
              margin-bottom: 10px;
            }

            .react-datepicker-wrapper input,
            .form-item-group
              .form-item-control-select
              .react-select__placeholder,
            .form-item-group
              .form-item-control-select
              .react-select__input-container,
            .form-item-control,
            .react-select__value-container,
            .react-select__single-value {
              font-size: $font-sm;
              &::placeholder {
                font-size: $font-sm;
              }
            }

            .flex-input {
              align-items: center;
              display: flex;
              max-width: 237px;

              @include respondto(laptop13) {
                max-width: 100%;
              }

              gap: 9px;
              width: 100%;

              input {
                width: 100%;
              }

              .react-select__control {
                svg {
                  transform: rotate(-90deg);
                }
              }
            }

            #formGridCheckbox {
              width: 100%;

              @include respondto(laptop13) {
                max-width: 100%;
              }
            }
          }
        }

        input,
        .react-select__value-container,
        .react-select__single-value {
          font-size: $font-sm;
          width: 100%;
        }

        .react-select__control {
          svg {
            transform: rotate(-90deg);
          }
        }
      }

      #formGridCheckbox {
        display: flex;
        width: 100%;

        @include respondto(laptop13) {
          max-width: 100%;
        }
      }
    }

    .bottom-filter-section {
      align-items: flex-end;
      display: flex;
      gap: 15px;
      justify-content: space-between;
      width: 100%;

      @include respondto(largeDesktopSm) {
        gap: 0.781vw;
      }

      @include respondto(laptop13) {
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;
        max-width: 100%;
      }

      .form-line-section {
        display: flex;
        gap: 25px;

        @include respondto(largeDesktopSm) {
          gap: 1.302vw;
          min-width: 39%;
        }

        @include respondto(laptop13) {
          gap: 10px;
          margin-bottom: 20px;
          width: 100%;
        }
      }

      .form-btns-section {
        align-items: center;
        display: flex;
        gap: 20px;

        @include respondto(largeDesktopSm) {
          gap: 1.042vw;
        }

        @include respondto(laptop13) {
          gap: 10px;
          width: 100%;
        }
        .reset-btn {
          border-radius: 5px;
          font-size: $font-sm;
          height: 30px;
          min-width: 150px;
          @include respondto(laptop13) {
            min-width: auto;
            width: 50% !important;
          }
        }
        .filter-btn {
          align-items: center;
          border-radius: 5px;
          display: flex;
          font-size: $font-sm;
          height: 30px;
          justify-content: center;
          position: relative;
          width: 150px;

          @include respondto(laptop13) {
            width: 50% !important;
          }
        }
      }
    }
  }
  .react-select__value-container,
  .react-select__single-value {
    font-size: $font-sm;
    width: 100%;
  }
  .react-datepicker__input-container input,
  .select-placeholder-text {
    color: $black;
    font-size: $font-sm;
    font-weight: 500;
    width: 100%;
    &::placeholder {
      font-weight: 400;
    }
  }
}
