@import '../../assets/styles/variables';

.agent-list {
  .ads-form-tabs.nav-tabs,
  .ads-form-tabs.nav-tabs .nav-item {
    background-color: $white;
  }
  .spiner-container {
    padding-top: 250px;
  }

  .radion-block {
    visibility: hidden;
  }

  .btns-container {
    @include respondto(laptop13) {
      display: flex;
    }
  }
  .list-component-pagination {
    margin-top: 0;
  }
}
