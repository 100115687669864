@import '../../assets/styles/variables';

.new-evaluation-list-filter {
  margin-bottom: 2rem;
  position: relative;
  z-index: 5;

  .filter-item-top {
    margin-bottom: 3rem;
    width: 33.33%;
    @include respondto(laptop13) {
      width: 100%;
    }
  }

  .range-calendar {
    left: 0;
    right: inherit;
  }
  .filter-form-container {
    display: grid;
    gap: 15px 0;
    grid-template-columns: 20% 20% 20% 20% 20%;

    @include respondto(onlyDesktop) {
      background: $input-color;
      border-radius: 10px;
      margin-bottom: 2rem;
      padding: 15px;
    }
    @include respondto(laptop13) {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-bottom: 20px;
    }
    .react-datepicker-wrapper input,
    .form-item-group .form-item-control-select,
    input {
      @include respondto(onlyDesktop) {
        background: $white;
      }
    }
  }
  .select-arrow-right {
    .react-select__control {
      svg {
        transform: rotate(-90deg);
      }
    }
  }
  .flex-input {
    display: flex;
    flex-direction: row;
    gap: 10px;
    .form-item {
      width: calc(50% - 5px);
    }
  }
  .form-item-control {
    min-height: 30px;
    padding: 5px 14px;
    @include respondto(largeDesktopSm) {
      padding: 0.26vw 0.729vw;
    }
  }
  .react-datepicker-wrapper {
    input {
      height: 30px;
      padding: 5px 14px;
    }
  }
  .form-item-group {
    .form-item-control-select {
      font-size: 12px;
      min-height: 30px;
      label {
        font-size: $font-sm;
        font-weight: 300;
        margin-bottom: 5px;
      }

      .react-select__indicator {
        padding: 4px;
        @include respondto(largeDesktopSm) {
          padding: 0.2083vw;
        }
      }
      .react-select__placeholder {
        font-size: 12px;
      }
    }
  }

  .form-check-label {
    font-size: $font-sm;
    font-style: normal;
    font-weight: 300;
  }
  .form-group {
    label {
      font-size: $font-sm;
      font-style: normal;
      font-weight: 300;
      line-height: 1;
      margin-bottom: 5px;
    }
  }

  input {
    &::placeholder {
      color: $input-placeholder;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.4px;
    }
  }

  .filter-item {
    padding-right: 20px;
    @include respondto(laptop13) {
      padding-right: 0;
    }

    label {
      font-size: 12px;
      font-weight: 300;

      &::after {
        display: none;
      }
    }

    p {
      color: $black;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .filters-column {
    align-content: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }

  .more-filters {
    margin-left: auto;
    max-width: 17.7rem;
    @include respondto(laptop13) {
      max-width: 100%;
    }
    .form-item-group {
      .form-item-control-select.react-select__control--is-focused {
        box-shadow: none;
      }
      .form-item-control-select {
        background: $white;
        border: 1px solid $main-color;
        .react-select__placeholder,
        .react-select__input-container {
          color: $main-color;
        }
        .react-select__single-value {
          color: $main-color !important;
        }
        .react-select__indicator {
          color: $main-color;
        }
        &:hover {
          background: $main-color;
          .react-select__placeholder,
          .react-select__input-container {
            color: $white;
          }
          .react-select__single-value {
            color: $white !important;
          }
          .react-select__indicator {
            color: $white;
            svg {
              fill: $white;
              stroke: $white;
            }
          }
        }
      }
    }
  }
  .flex-input-row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    .form-item-group {
      width: calc(50% - 0.5rem);
    }
  }
  .filtre-buttons {
    gap: 19px;
    .button {
      min-width: 11.4rem;
      @include respondto(laptop13) {
        width: calc(50% - 1rem);
      }
    }
  }
}
