@import '../../assets/styles/variables';

.filter-container-category {
  background: $azure;
  border-radius: 25px;
  margin-bottom: 35px;
  padding: 15px;
  width: 100%;

  .row {
    flex-wrap: nowrap;
    gap: 15px;
    justify-content: space-between;
    margin-left: 0px;
    margin-right: 0px;

    @include respondto(largeDesktopSm) {
      gap: 0.781vw;
    }

    .col-sm-2,
    .col-sm-10 {
      flex: 0 1 auto;
    }
  }

  .tab-section {
    background: $white;
    border-radius: 15px;
    box-shadow: $block-shadow;
    max-width: 232px;
    padding: 15px 0px;
    width: 232px;
    @include respondto(largeDesktopSm) {
      padding: 0.781vw 0;
    }

    @include respondto(laptopMd) {
      max-width: 14.0833vw;
    }

    .nav-link {
      border-radius: 0px;
      color: $black;
      font-size: 1.3rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 5px;
      padding: 8px 20px;
      transition: none !important;

      &.active {
        background: $nav-color-active;
        font-weight: 600;
        position: relative;
        transition: none !important;

        &::after {
          background-image: url('../../assets/svg/arrowtab.svg');
          background-repeat: no-repeat;
          content: '';
          height: 31px;
          position: absolute;
          right: -9px;
          top: calc(50% - 1.5rem);
          width: 18px;
        }
      }
    }
  }

  .filter-form-section {
    background: $white;
    border-radius: 15px;
    box-shadow: $block-shadow;
    padding: 20px;
    width: calc(100% - 262px);
    @include respondto(largeDesktopSm) {
      padding: 1.042vw;
    }

    @include respondto(laptopMd) {
      width: calc(100% - 16.0833vw);
    }
  }
}
