@import '../../assets/styles/variables';

.full-calendar-container {
  background-color: $white;
  border-radius: 10px;
  box-shadow: $block-shadow;
  font-size: $font-md;
  max-width: 1216px;
  padding: 20px;
  position: relative;
  width: 100%;

  @include respondto(laptop13) {
    max-width: 100%;
  }
  .fc .fc-toolbar {
    @include respondto(phoneSm) {
      flex-direction: column;
      gap: 7px;
    }
  }

  .fc .fc-col-header-cell-cushion {
    @include respondto(phoneSm) {
      font-size: 0.8rem;
      font-weight: 600;
    }
  }
  .fc .fc-daygrid-body {
    max-width: 100%;
  }
  .fc .fc-scrollgrid table {
    max-width: 100%;
    min-width: 100%;
  }
  .fc-toolbar-title {
    font-size: $font-lg;
    font-weight: 500;
    @include respondto(phone) {
      font-size: 1.1rem;
      font-weight: 600;
    }
  }

  .fc-view-harness {
    @include respondto(phone) {
      min-height: 360px;
    }
  }
  .fc-toolbar-title {
    text-transform: capitalize;
  }
  a {
    color: $black;
  }
  .fc .fc-button {
    font-size: $font-md;
    @include respondto(phone) {
      font-size: 1.1rem;
    }
  }
  .fc-button-primary {
    background-color: $calendar-prev-bg !important;
    border: none !important;
    color: $black !important;
  }
  .fc-button-active {
    background-color: $main-color !important;
    border: none !important;
    color: $white !important;
  }
}
