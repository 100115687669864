@import '../../assets/styles/variables';

.item-contracts {
  gap: 0;
  @include respondto(largeDesktopSm) {
    gap: 0;
  }
  @include respondto(laptop13) {
    flex-direction: column;
    gap: 15px;
  }

  .list-item-content {
    align-items: center;
    display: flex;
    flex-direction: row;
    @include respondto(laptop13) {
      flex-direction: column;
      gap: 10px;
    }
    strong {
      font-weight: 500;
    }
    .label-item-mark,
    .label-item-tag {
      height: auto;
      min-height: 28px;
      @include respondto(laptop13) {
        background: transparent;
        font-size: 16px;
        height: auto;
        min-height: auto;
        padding: 0;
      }
      @include respondto(phone) {
        font-size: 12px;
      }
    }

    .column-item {
      font-size: 12px;
      line-height: 18px;
      padding: 0 25px;
      @include respondto(largeDesktopSm) {
        padding: 0 1.302vw;
      }
      @include respondto(laptop13) {
        font-size: 16px;
        padding: 0;
      }

      @include respondto(phone) {
        font-size: 12px;
      }
      &:first-child {
        padding-right: 0;
        width: 6.5%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }

      &:nth-child(2) {
        width: 30%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(3) {
        width: 10.5%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(4) {
        width: 16%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(5) {
        width: 13.1%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(6) {
        width: 13.1%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(7) {
        padding-right: 10px;
        width: 11%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
    }
    .label-item-link {
      width: fit-content;
    }
    .info-agent {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 5px;
      .info-image {
        border-radius: 50%;
        height: 34px;
        overflow: hidden;
        width: 34px;
        @include respondto(laptop13) {
          height: 52px;
          width: 52px;
        }
        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }
    .info-icons {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: flex-end;
      @include respondto(laptop13) {
        justify-content: center;
        width: 100%;
      }
    }
  }
}
