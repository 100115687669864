@use 'sass:math';
// Libraries
@import 'libs/reset';
@import '~bootstrap/scss/bootstrap';
@import 'react-datepicker/dist/react-datepicker.css';
@import 'react-calendar/dist/Calendar.css';
@import 'react-tooltip/dist/react-tooltip.css';

// Abstracts
@import 'fonts';
@import 'variables';

// General
@import 'general-classes';
@import 'buttons';

//Pages
@import '../../containers/Home/Home.scss';
@import '../../containers/Login/Login.scss';
@import '../../containers/Announces/Announces.scss';

//Components

/* Global styles */
body {
  background-color: $maingrey;
  font-family: $mainFont;
  font-size: 1.3rem;
  max-width: 100vw;
  overflow-x: hidden;
}

:root {
  --ck-focus-outer-shadow-geometry: 0 0 0 3px;
  --ck-focus-outer-shadow: var(--ck-focus-outer-shadow-geometry) #33475b;
  --ck-focus-disabled-outer-shadow: var(--ck-focus-outer-shadow-geometry) #33475b;
  --ck-focus-error-outer-shadow: var(--ck-focus-outer-shadow-geometry) #33475b;
  --ck-focus-ring: 1px solid #33475b;

}