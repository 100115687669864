@import '../../assets/styles/variables';

.agent-profile {
  .spiner-container {
    padding-top: 250px;
  }

  .radion-block {
    height: 0;
    visibility: hidden;
  }

  .btns-container {
    @include respondto(laptop13) {
      display: flex;
    }
    .reset-btn {
      @include respondto(phone) {
        min-width: 223px;
      }
    }
  }
}
