@import '../../assets/styles/variables';

.activities-modal {
  align-items: center;
  display: flex !important;
  justify-content: center !important;
  margin: 0 auto !important;
  @media screen and (min-width: 1081px) and (max-width: 1920px) {
    .form-item-control {
      font-size: $font-sm;
      line-height: 17px;
    }
    .form-item-group .form-item-control-select {
      font-size: $font-sm;
    }
    .form-item-group.multiselect
      .form-item-control-select
      .react-select__placeholder {
      align-items: center;
      display: flex;
      font-size: $font-sm;
    }
    .form-item-group .form-item-control-select .react-select__placeholder {
      font-size: $font-sm;
    }
    .react-datepicker-wrapper input {
      font-size: $font-sm;
      padding: 12px 30px 12px 8px;
    }
    .form-item-group label {
      font-size: $font-sm;
    }
    .date-picker label {
      font-size: $font-sm;
    }
    .react-datepicker-wrapper {
      &::after {
        height: 18px;
        right: 8px;
        top: 5px;
        width: 20px;
      }
    }
    .react-datepicker-wrapper .hours {
      &::after {
        right: 8px;
        top: 5px;
      }
    }
    .form-check label {
      font-size: $font-md;
    }
    .form-check-input[type='checkbox'][type='checkbox'] {
      border-radius: 4px;
      height: 20px;
      width: 20px;
    }
  }

  .modal-dialog {
    bottom: auto !important;
    max-width: 744px !important;
    top: 0;
    width: 100%;
    @include respondto(phone) {
      max-width: 100%;
    }
  }

  @include respondto(phone) {
    padding: 0 !important;
  }

  .modal-header {
    border-bottom: none;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.3;
    padding: 0;
    text-align: center;

    span {
      width: 100%;
    }

  }

  .modal-content {
    background-color: $azure !important;
    border-radius: 20px !important;
    padding: 56px 72px 20px;
    @include respondto(desktopXLg){
      margin-bottom: 50px;
      margin-top: 100px;
    }
    @include respondto(phone) {
      background-color: $white !important;
      margin-bottom: 100px;
      padding: 40px 10px 0;
    }
  }

  // .close-btn-modal {
  //   cursor: pointer;
  //   top: -100px;
  // }

  .add-activity-modal {
    background-color: $white;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 35px 63px;
    @include respondto(phone) {
      padding: 20px 10px 0;
    }
  }

  .flex-date {
    display: flex;
    gap: 1rem;
  }

  .flex-range {
    display: flex;
    justify-content: flex-start;
  }

  .react-datepicker-wrapper::after {
    background-repeat: no-repeat;
  }

  .react-datepicker-wrapper {
    max-width: calc(50% - 35px);
    min-width: calc(50% - 35px);

    &:first-child {
      margin-right: 70px;
      @include respondto(phone) {
        margin-right: 30px;
      }
    }

    @include respondto(phone) {
      max-width: calc(50% - 15px);
      min-width: calc(50% - 15px);
    }
  }

  .input-flex {
    display: flex;
    gap: 10px;
    justify-content: space-between;

    &:not(:first-child) {
      padding-top: 20px;
    }
  }

  .close {
    align-items: center;
    background: $light-button-color;
    border: 1px solid $light-button-border;
    border-radius: 4px;
    color: $black;
    display: flex;
    height: 34px;
    justify-content: center;
    transition: 0.3s ease-in-out;
    width: 34px;

    path {
      fill: $black;
    }
    &:hover {
      background: $light-button-color-hover;
    }
  }

  .add-notification {
    color: $main-color;
    cursor: pointer;
    font-size: $font-sm !important;
    padding-top: 10px;
    width: max-content;
    &:hover {
      color: $main-color-hover;
      text-decoration: underline;
    }
  }

  .btns-container {
    align-items: center;
    display: flex;
    gap: 17px;
    justify-content: center;
    padding: 23px;
    width: 100%;

    @include respondto(largeDesktopMd) {
      padding: 1.1979vw;
    }

    @include respondto(phone) {
      flex-wrap: wrap;
    }

    button {
      @media screen and (max-width: 531px) {
        min-width: 223px !important;
      }
      height: 34px;
    }
  }
}


.react-datepicker-wrapper.hours {
  margin-left: 0 !important;
  position: relative;

  &::after {
    background-image: url('../../assets/svg/hourpicker.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    right: 8px;
    top: 6px;
  }
}
