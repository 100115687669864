@import '../../assets/styles/variables';
.radius-dropdown {
  .form-item {
    .error-message {
      color: $red;
    }
    &-group {
      width: 100%;
      .rotate-arrow {
        .react-select__dropdown-indicator {
          transform: rotate(-90deg);
        }
      }

      .form-item-control-select {
        align-items: center;
        background-color: transparent;
        border: none;
        color: $white;
        display: flex;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        min-height: 40px;
        padding-left: 44px;
        padding-right: 17px;
        width: 100%;

        @include respondto(phone) {
          font-size: 16px;
        }

        .react-select__indicator {
          padding: 2px;
        }

        &.react-select__control--menu {
          &-is-open {
            .rotate {
              transform: rotateX(180deg);
              path {
                height: 6px;
                width: 10px;
              }
            }
          }
        }
        .rotate {
          transform: none;
        }
        .react-select__single-value {
          color: $white !important;
        }
        .react-select {
          &__placeholder {
            color: $white;
            font-size: $font-sm;
          }

          &__input {
            &-container {
              color: rgba($black, 0.62);
            }

            &__placeholder {
              color: $white;
              font-size: $font-sm;
            }
          }

          &__menu {
            font-size: 16px !important;

            @include respondto(largeDesktopSm) {
              font-size: 0.833vw !important;
            }

            @include respondto(laptopMd) {
              font-size: 1.0417vw;
            }

            * {
              font-size: 20px !important;

              @include respondto(largeDesktopSm) {
                font-size: 1.042vw !important;
              }
            }
          }

          &__indicator {
            color: $gray;

            &-separator {
              display: none;
            }
          }
          &__clear-indicator {
            color: $main-color !important;
          }
          &__multi-value {
            &__remove,
            &__label {
              background-color: $ghost-white;
            }

            &__label {
              color: $black;
              font-size: $font-sm;
            }

            &__remove {
              color: $black;

              svg {
                height: 16px;
                fill: $main-color;
                width: 16px;
              }
            }
          }
        }

        &:hover {
          border: none;
          box-shadow: none;
          cursor: pointer;
        }

        &::placeholder {
          color: $white;
        }

        &:focus {
          box-shadow: none;
        }
        &.react-select__control {
          &--is-focused {
            box-shadow: none;
          }
        }

        &.is-valid {
          border: 1px solid rgb(25, 135, 84);
        }

        &.is-invalid {
          border: 1px solid rgb(220, 53, 69);
        }

        &:disabled {
          background-color: $delta-ligthness;
          border: 1px solid $delta-ligthness;
        }
      }
    }
  }

  .react-select {
    &__menu {
      color: $input-placeholder;
      font-size: 16px;

      @include respondto(largeDesktopSm) {
        font-size: 0.833vw;
      }

      @include respondto(laptopMd) {
        font-size: 0.9028vw;
      }
    }
  }

  .invalid-feedback {
    &.display-block {
      display: block;
    }
  }

  .valid-feedback {
    &.display-block {
      display: block;
    }
  }

  .react-select__menu {
    min-width: max-content;
    z-index: 999999;
  }
  .react-select__option {
    min-width: max-content;
  }
}
