@import "../../assets/styles/variables";

.main-wrapper {
    .requests-filter-container {
        @include respondto(laptop13) {
            display: none;
        }
    }

    .request-list-filter {
        @include respondto(laptop13) {
            display: none;
        }
    }
}