@import '../../assets/styles/variables';

.fixed-actions-container {
  background: $white;
  border-radius: 20px 20px 0px 0px;
  bottom: 0px;
  box-shadow: $block-shadow;
  left: 0px;
  max-width: 100vw;
  padding: 19px 30px 13px;
  position: fixed;
  width: 100%;
  z-index: 9999;

  .action-list {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .action-item-btn {
      align-items: center;
      color: $black;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-weight: 400;
      gap: 4px;
      line-height: normal;
      transition: 0.1s ease-in-out;

      svg,
      path {
        fill: $black;
        transition: 0.1s ease-in-out;
      }

      &.disabled {
        color: rgba($black, 0.39);
        pointer-events: none;

        svg,
        path {
          fill: rgba($black, 0.39);
        }
      }

      &:hover {
        color: $black;
        font-weight: 600;
        svg,
        path {
          fill: $main-color;
        }
      }
    }

    .add-btn {
      bottom: 105px;
      position: sticky;
      right: 16px;
      z-index: 9;
    }
  }
}
