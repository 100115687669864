@import '../../assets/styles/variables';

.action-buttons-list {
  display: flex;
  flex-wrap: wrap;
  gap: 11px;
}

.button-square {
  &-sm,
  &-md,
  &-lg {
    align-items: center;
    background-color: $light-button-color;
    border: 1px solid $light-button-border;
    display: flex;
    justify-content: center;
    transition: 0.3s ease-in-out;
    svg {
      fill: $light-button-font;
      path {
        fill: $light-button-font;
      }
    }
    &:hover {
      background-color: $light-button-color-hover;

      svg {
        fill: $light-button-font !important;

        path {
          fill: $light-button-font !important;
        }
      }
    }
  }

  &-sm {
    border-radius: 5px;
    height: 26px;
    width: 26px;

    svg,
    img {
      display: block;
      height: auto;
      width: 15px;
    }
  }
  &-lg {
    border-radius: 7px;
    height: 34px;
    width: 34px;
  }

  &-md {
    border-radius: 7px;
    height: 30px;
    width: 30px;

    svg,
    img {
      display: block;
      height: auto;
      width: 20px;
    }
  }
}
