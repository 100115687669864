@import '../../assets/styles/variables';

.dropdown-brands-container {
  position: relative;
  width: 100%;

  .slots-container & {
    display: block !important;
    margin-bottom: 20px;
  }

  .content-select {
    .header-select {
      align-items: center;
      background: $light-button-font;
      border: 1px solid $light-button-font;
      border-radius: 10px;
      color: $white;
      display: flex;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      gap: 10px;
      height: 34px;
      line-height: normal;
      padding: 5px 20px;
      position: relative;

      &::after {
        content: url('../../assets/svg/arrowdropwhite.svg');
        height: 30px;
        position: absolute;
        right: 10px;
        top: 0;
      }
      img {
        max-height: 20px;
        max-width: 20px;
      }
    }

    .options-select {
      background: $white;
      border-radius: 0px 0px 15px 15px;
      height: 0px;
      left: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      width: 100%;
      z-index: 9;

      ul {
        margin: 0;
        padding: 0;
      }

      li {
        align-items: center;
        color: $black;
        cursor: pointer;
        display: flex;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 500;
        gap: 11px;
        line-height: normal;
        margin-bottom: 10px;
        padding: 1rem 1.5rem;
        transition: 0.3s ease-in-out;
        @include respondto(phone) {
          // font-size: 1.4rem;
          padding: 1rem 0.5rem;
        }
        img {
          max-height: 20px;
          max-width: 20px;
        }
        &:hover {
          background: $light-button-font;
          color: $white;
        }
      }
    }

    &.open {
      .options-select {
        box-shadow: $block-shadow;
        height: fit-content;
        padding: 10px 5px;
      }

      .header-select {
        &::after {
          right: 10px;
          top: 4px;
          transform: rotate(180deg);
        }
      }
    }
  }
}
