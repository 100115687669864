@import "../../assets/styles/variables";
.flex-slot-allocation {
    gap: 18px;
    justify-content: space-between;
    max-height: 1500px;
    @include respondto(largeDesktopSm) {
        gap: 0.938vw;
        max-height: max-content;
    }
    // @include respondto(laptopMd) {
    //     flex-direction: column-reverse !important;
    // }
    @include respondto(laptop13) {
        flex-direction: column-reverse !important;
        gap: 35px;
        max-height: 100%;
    }
    .swiper-dashboard-container {
        max-height: 87rem;
        @include respondto(largeDesktopSm) {
            max-height: 45.313vw;
        }
        @include respondto(laptop13) {
            display: none !important;
        }
    }
}
