@import "../../assets/styles/variables";

.search-container-modal {
    display: flex;
    align-items: center;
    gap: 10px;

    .search-btn {
        border-radius: 8px;
        background: $main-color;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .form-item-group {
        width: 95%;

        input {
            height: 50px;
            @include respondto(largeDesktopSm) {
                height: 2.604vw;
            }
        }
    }
}