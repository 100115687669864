@import "../../assets/styles/variables";

.heading-block-sm {
    align-items: center;
    color: $black;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 12px;
    @include respondto(largeDesktopSm) {
        gap: 0.625vw;
        margin-bottom: 0.625vw;
    }
    .title {
        color: $black;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 1.8rem;

    }

    span {
        font-size: 1.2rem;
        line-height: 18px;
        letter-spacing: -0.36px;
        @include respondto(largeDesktopSm){
            line-height: 1.1;
        }
        @include respondto(largeDesktopMd) {
            font-size: 0.625vw;
        }
        @include respondto(laptopMd) {
            font-size: 0.764vw;
        }
    }
}