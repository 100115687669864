@import '../../assets/styles/variables';

.slots-use {
  &-section {
    .bg-white {
      border: 20px;
    }
    .date-column {
      gap: 0 !important;
      position: relative;
    }
    .react-datepicker-popper {
      display: none !important;
    }
    .range-calendar {
      position: absolute;
      left: 0;
      top: 50px;
      z-index: 8;

      @include respondto(laptop13) {
        bottom: 80px;
        top: inherit;
      }
    }
    .rdrDateRangePickerWrapper {
      box-shadow: 0px 27px 42.3px 0px rgba($black, 0.04);
    }
    button{
      .spiner-container {
        position: relative;
      }
      &.reset-btn{
        .spinner{
          border-color: #ff7a45 #ff7a45 #ff7a45 transparent;
        }
        &:hover{
          .spinner{
            border-color: #fff #fff #fff transparent;
          }
        }
      }
      &.submit-btn{
        .spinner{
          border-color: #fff #fff #fff transparent;
        }
        &:hover{
          .spinner{
            border-color: #ff7a45 #ff7a45 #ff7a45 transparent;
          }
        }
      }

    }

  }
  &-left {
    margin-right: 2.4rem;
    padding: 3rem 3rem 3.9rem;
    width: calc(27.1% - 2.5rem);

    @include respondto(largeDesktopSm) {
      margin-right: 1.25vw;
      padding: 1.563vw 1.563vw 2.0313vw;
      width: calc(27.1% - 1.25vw);
    }

    @include respondto(laptop13) {
      margin-bottom: 3rem;
      margin-right: auto;
      width: 100%;
    }

    @include respondto(phone) {
      padding: 1.5rem;
    }

    .heading-block {
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-bottom: 30px;
      @include respondto(largeDesktopSm) {
        margin-bottom: 1.1979vw;
      }
      @include respondto(laptop13) {
        margin-bottom: 2rem;
      }
      @include respondto(phone) {
        font-size: 14px;
      }
    }
    .date-picker label,
    .form-item-group-label,
    label {
      font-size: 1.3rem;
      font-weight: 300;
      line-height: 20px;
    }

    .form-check-label {
      font-size: 1.2rem;

      @include respondto(largeDesktopMd) {
        font-size: 1.1rem;
      }
    }

    .form-block {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 15px;
      min-height: calc(100% - 60px);
    }

    .form-item {
      width: 100%;
      .link {
        cursor: pointer;
        width: max-content;
        &:hover {
          text-decoration: underline;
        }
      }

      p {
        color: $black;
        font-size: 1.2rem;
        line-height: 120%;
        margin-top: 9px;
        @include respondto(largeDesktopSm) {
          margin-top: 0.469vw;
        }

      }
    }
    .form-group {
      display: flex;
      flex-wrap: wrap;
      gap: 15px 10px;
      margin-bottom: 30px;
      margin-top: 9px;
      @include respondto(laptop13) {
        margin-bottom: 1rem;
      }
      .form-item-group-label {
        width: 100%;
      }
      .form-check {
        width: calc(50% - 5px);
        @include respondto(phoneSm) {
          width: 100%;
        }
        input[type='checkbox'] {
          border-radius: 3px;
          height: 20px;
          width: 20px;
          @include respondto(laptopMd) {
            height: 18px;
            width: 18px;
          }
        }
      }
    }

    .buttons-group {
      display: flex;
      flex-wrap: wrap;
      gap: 17px;
      justify-content: center;
      margin: auto 0 0;
      padding: 0;
      width: 100%;

      .button-md,
      .reset-btn {
        min-width: calc(50% - 9px);
      }
    }
  }

  &-right {
    margin-left: 19px;
    padding: 49px 43px;
    width: calc(71.9% - 19px);
    @include respondto(largeDesktopSm) {
      margin-left: 0.99vw;
      width: calc(71.9% - 0.99vw);
    }
    @include respondto(laptop13) {
      margin-left: auto;
      width: 100%;
    }

    .bg-white {
      box-shadow: $block-shadow;
    }
  }
}
