@import '../../assets/styles/variables';

.contracts-templates-list-filter {
  border-radius: 20px;
  box-shadow: $block-shadow;
  margin-bottom: 40px;
  padding: 22px 30px;
  position: relative;
  z-index: 5;
  @include respondto(laptop13) {
    border-radius: 0;
    box-shadow: none !important;
    margin-bottom: 0;
    padding: 0;
  }
  .form-check-input {
    margin-right: 60px !important;
    @include respondto(largeDesktopSm) {
      margin-right: 3.125vw;
    }
  }

  form {
    display: grid;
    grid-template-columns: 24.3% 12.8% 15.4% 47.5%;
    @include respondto(laptop13) {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
  .form-btns-section {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    gap: 19px;
    justify-content: flex-end;
    @include respondto(phone) {
      gap: 10px;
    }
  }
  .react-datepicker-wrapper {
    input {
      height: 30px;
      padding: 5px 14px;
      @include respondto(largeDesktopSm) {
        padding: 0.26vw 0.729vw;
      }
    }
    &::after {
      height: 15px;
      right: 5px;
      top: 6px;
      width: 15px;
    }
  }

  .filter-btn,
  .reset-btn {
    border-radius: 4px;
    font-size: $font-sm;
    font-weight: 500;
    height: 30px;
    min-width: 117px;
    position: relative;
    text-align: center;
    @include respondto(phone) {
      min-width: calc(50% - 5px);
    }
  }
  .flex-input {
    display: flex;
    flex-direction: row;
    gap: 10px;
    .form-item {
      width: calc(50% - 5px);
    }
  }
  .form-item-control {
    min-height: 30px;
    padding: 5px 14px;
    @include respondto(largeDesktopSm) {
      padding: 0.26vw 0.729vw;
    }
  }
  .form-item-group {
    .form-item-control-select {
      font-size: 12px;
      min-height: 30px;

      .react-select__indicator {
        padding: 4px;
        @include respondto(largeDesktopSm) {
          padding: 0.2083vw;
        }
      }
      .react-select__placeholder {
        font-size: 12px;
      }
    }
  }

  input {
    &::placeholder {
      color: $input-placeholder;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.4px;
    }
  }

  .filter-item {
    padding-right: 20px;
    @include respondto(laptop13) {
      padding-right: 0;
    }

    label {
      font-size: 12px;
      font-weight: 300;

      &::after {
        display: none;
      }
    }

    p {
      color: $black;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
