@import '../../assets/styles/variables';

.filter-list-company {
  form {
    display: grid;
    grid-template-columns: 3.5% 15.3% 17% 18.1% 18.1% 18.2% 9.6%;
    width: 100%;

    @include respondto(laptop13) {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }

  #formGridCheckbox {
    align-items: center;
    display: flex;

    .form-item-group-label {
      color: $black;
      display: none;
      font-weight: 400;
      margin: 0;

      @include respondto(laptop13) {
        display: flex;
      }
    }
  }

  .date-column {
    gap: 0 !important;
    position: relative;
  }

  .react-datepicker-popper {
    display: none !important;
  }

  .range-calendar {
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 8;

    @include respondto(laptop13) {
      bottom: 80px;
      top: inherit;
    }
  }

  .rdrDateRangePickerWrapper {
    box-shadow: 0 27px 42.3px 0 rgba($black, 0.04);
  }

  .filter-item {
    max-width: unset !important;
    padding: 0 29px !important;

    @include respondto(largeDesktopMd) {
      padding: 0 1.51vw !important;
    }

    @include respondto(laptopMd) {
      padding: 0 1.51vw !important;
    }

    .filter-btn {
      align-items: center;
      background: $main-color;
      border-radius: 4px;
      display: flex;
      font-size: $font-sm;
      gap: 3px;
      justify-content: center;
      margin-top: 3.4rem;
      min-height: 30px;
      width: 100%;

      @include respondto(largeDesktopSm) {
        // border-radius: 0.521vw;
        margin-top: 1.7708vw;
        // min-height: 2.2396vw;
      }

      svg {
        height: 1rem;
        width: 1rem;

        @include respondto(largeDesktopMd) {
          height: 0.521vw;
          width: 0.521vw;
        }

        @include respondto(laptopMd) {
          display: none;
        }

        path {
          fill: white;
        }
      }
    }

    label {
      &::after {
        display: none;
      }
    }

    &:nth-of-type(2) {
      padding-left: 0 !important;

      label {
        &::after {
          display: block;
        }
      }
    }

    &:last-of-type {
      padding-right: 0 !important;

      label {
        &::after {
          display: block;
        }
      }
    }

    p {
      color: $black;
      font-size: $font-md;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      @include respondto(laptop13) {
        display: none;
      }
    }

    @include respondto(laptop13) {
      border-right: none !important;
      padding: 0 !important;
    }
  }

  .bottom-filter-section {
    align-items: flex-end;
    display: none;
    gap: 15px;
    justify-content: space-between;
    width: 100%;

    @include respondto(laptop13) {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 100%;
    }

    .form-btns-section {
      align-items: center;
      display: flex;
      gap: 20px;

      @include respondto(largeDesktopSm) {
        gap: 1.042vw;
      }

      @include respondto(laptop13) {
        gap: 10px;
        width: 100%;
      }

      .reset-btn {
        @include respondto(laptop13) {
          min-width: auto;
          width: 50% !important;
        }
      }

      .filter-btn {
        align-items: center;
        border: 1px solid $main-color;
        border-radius: 4px;
        display: flex;
        font-size: $font-sm;
        height: 30px;
        justify-content: center;
        position: relative;
        width: 223px;

        @include respondto(largeDesktopSm) {
          // border-radius: 0.521vw;
          // height: 2.292vw;
          width: 12.1354vw;
        }

        @include respondto(laptop13) {
          width: 50% !important;
        }
      }
    }
  }
}
