@import '../../assets/styles/variables';

.agent-profile-tabs {
  .tabs-container {
    align-items: center;
    display: flex;
    gap: 15px;
    max-width: 478px;
    position: relative;
    width: 100%;

    @include respondto(laptop13) {
      gap: 0;
      margin-bottom: 20px;
      max-width: 100%;
      width: 100%;
    }

    .tab-item {
      align-items: center;
      color: rgba($black, 0.5);
      display: flex;
      font-size: $font-sm;
      font-style: normal;
      font-weight: 500;
      height: 74px;
      justify-content: flex-start;
      line-height: 20px;
      padding: 9px 13px;
      position: relative;
      width: 170px;

      @include respondto(laptop13) {
        background: none;
        border-bottom: 4px solid $whisper-second;
        border-radius: 0px;
        height: 65px;
        width: 50%;
      }

      cursor: pointer;
      .tab-icon {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        @include respondto(laptop13) {
          display: none;
        }
        svg {
          height: 100%;
          width: calc(100% + 30px);
        }

        svg,
        path {
          fill: $tab-color;
          stroke: $tab-color;
        }
      }

      p {
        font-size: $font-sm;
        position: relative;
        z-index: 3;

        @include respondto(laptop13) {
          font-size: 14px;
        }
      }

      &.active {
        color: $black;
        z-index: 2;
        .tab-icon {
          z-index: 2;
          svg,
          path {
            fill: $white;
            stroke: $white;
          }
        }

        @include respondto(laptop13) {
          background: none;
          border-bottom: 4px solid $main-color;
          border-radius: 0px;
        }
      }
    }

    .selected-list {
      left: -30px;
      padding-left: 3rem;
      position: relative;
      .tab-icon {
        svg {
          width: calc(100% + 45px);
        }
      }
      @include respondto(laptop13) {
        left: 0;
        padding-left: 0;
      }
    }
  }
}
