@import '../../assets/styles/variables';

.request-email-modal {
  align-items: center;
  display: flex !important;
  justify-content: center !important;
  margin: 0 auto !important;
  .modal-dialog {
    bottom: auto !important;
    max-width: 60vw !important;
    top: 0;
    width: 100%;
    @include respondto(phone) {
      max-width: 100%;
    }
  }
  .modal-content {
    background-color: $white !important;
    border-radius: 20px !important;
    padding: 35px 52px;
    // @include respondto(desktopXLg) {
    //   margin-top: 300px;
    // }
    @include respondto(phone) {
      margin-bottom: 200px;
      margin-top: 100px;
    }
  }
  .modal-body {
    padding: 0 !important;
  }
  .close-btn-modal {
    cursor: pointer;

  }
  .modal-header {
    border-bottom: none;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0em;
    line-height: 1.3;
    padding: 0;
    text-align: center;
    span {
      width: 100%;
    }

  }
  .request-modal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__title {
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 3.6rem;
    }
    &__subtitle {
      font-size: 1.6rem;
      font-weight: 4600;
      line-height: 2.4rem;
      margin-top: -8px;
    }
    &__contacts {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    &__contact-item {
      align-items: center;
      background-color: #f5f5fd;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      max-width: max-content;
      padding: 17px 20px;
      .img-block {
        border-radius: 50%;
        margin-right: 20px;
        min-height: 70px;
        min-width: 70px;
        overflow: hidden;
        width: 70px;
        @include respondto(largeDesktopSm) {
          height: 3.646vw;
          width: 3.646vw;
        }
        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      .info-contact {
        margin-right: auto;
        max-width: -webkit-fill-available;
        .name {
          color: $black;
          font-size: 1.8rem;
          font-weight: 600;
          line-height: 2.7rem;
        }
        .number {
          align-items: center;
          color: $black;
          display: flex;
          font-size: 1.4rem;
          font-weight: 500;
          gap: 8px;
          line-height: 2.1rem;
        }
        .info-item {
          display: flex;
          gap: 10px;
          padding-top: 7px;
        }
      }
    }
    &__properties {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    &__property-item {
      align-items: stretch;
      background-color: #f5f5fd;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      max-width: 100%;
      .img-block {
        border-radius: 15px 0 0 15px;
        margin-right: 26px;
        min-height: -webkit-fill-available;
        min-width: 250px;
        overflow: hidden;
        width: 250px;
        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
        @include respondto(phone) {
          border-radius: 15px 15px 0 0;
          margin-right: 0;
          width: 100%;
        }
      }
      @include respondto(phone) {
        flex-direction: column;
      }
      .info-contact {
        display: flex;
        flex-direction: column;
        gap: 7px;
        margin-right: auto;
        max-width: -webkit-fill-available;
        padding: 17px 20px;
        .name {
          color: $black;
          display: -webkit-box;
          font-size: 1.8rem;
          font-weight: 600;
          line-height: 2.7rem;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .description {
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-size: 1.4rem;
          font-weight: 300;
          line-height: 2.1rem;
          overflow: hidden;
        }
        .price {
          font-size: 1.8rem;
          font-weight: 600;
          line-height: 2.4rem;
          @include respondto(phone) {
            font-size: 1.4rem !important;
            line-height: 1.9rem !important;
          }
        }
        .info-item {
          display: flex;
          gap: 10px;
          padding-top: 7px;
        }
        .pills-details {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          gap: 10px;

          .label-item-tag {
            background: $white;
            border-radius: 5px;
            font-weight: 300;

            @include respondto(phone) {
              font-size: 12px !important;
            }
          }
        }
      }
      @include respondto(phone) {
        flex-direction: column;
      }
    }
    .btns-container {
      align-items: center;
      align-items: center;
      display: flex;
      display: flex;
      gap: 30px;
      gap: 17px;
      justify-content: center;
      justify-content: center;
      padding: 23px;
      width: 100%;

      @include respondto(largeDesktopMd) {
        padding: 1.1979vw;
      }

      @include respondto(phone) {
        flex-wrap: wrap;
      }
      button {
        border-radius: 7px;
        font-weight: 600;
        @media screen and (max-width: 531px) {
          min-width: 223px !important;
        }
        height: 34px;
      }
      .reset-btn {
        border: 1px solid $main-color;
        &:hover {
          background: $main-color;
          color: $white;
        }
      }
    }
  }
}
.filter-list-container {
  &.request-list-filter-no-check {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
    border-radius: 15px;

    form {
      .filter-item {
        label {
          margin-bottom: 0;
        }
      }
    }
  }
}
