@import '../../assets/styles/variables';

.filter-list-alerts {
  form {
    display: grid;
    grid-template-columns: 3% 11.9% 23.81% 14.2% 17% 30%;
    width: 100%;

    @include respondto(laptop13) {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .form-check-input {
      margin-right: 24px;
      @include respondto(largeDesktopSm) {
        margin-right: 1.25vw;
      }
    }
    .filter-item {
      &:last-of-type {
        @include respondto(onlyDesktop) {
          text-align: right;
        }
      }
    }
  }

  .filter-item {
    max-width: unset !important;
    padding: 0 30px !important;

    @include respondto(largeDesktopSm) {
      padding: 0 1.563vw !important;
    }

    @include respondto(laptop13) {
      border-right: none !important;
      padding: 0 !important;
    }
    &:nth-child(2) {
      padding-left: 0 !important;
    }
    &:last-of-type {
      @include respondto(laptop13) {
        display: none;
      }
    }

    &:nth-of-type(4) {
      label {
        &::after {
          display: none;
        }
      }
    }

    &:nth-of-type(5) {
      border-right: none;
    }

    &:nth-of-type(6) {
      padding-right: 0px !important;
      text-align: right;

      label {
        &::after {
          display: none;
        }
      }
    }
  }
}
