@import '../../assets/styles/variables';

.filter-list-header-container {
  .filter-list-header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }

  .tabs-container {
    align-items: center;
    display: flex;
    gap: 15px;
    max-width: 478px;
    position: relative;
    width: 100%;

    @include respondto(laptopMd) {
      max-width: 32.896vw;
    }

    @include respondto(laptop13) {
      gap: 0;
      max-width: 100%;
    }

    .tab-item {
      align-items: center;
      border-radius: 15px;
      color: rgba($black, 0.5);
      cursor: pointer;
      display: flex;
      font-size: $font-sm;
      font-style: normal;
      font-weight: 500;
      height: 74px;
      justify-content: flex-start;
      line-height: 20px;
      padding: 9px 13px;
      position: relative;
      width: 170px;

      @include respondto(laptop13) {
        background: none;
        border-bottom: 4px solid $whisper-second;
        border-radius: 0;
        height: 65px;
        margin-bottom: 25px;
        width: 50%;
      }
      .tab-icon {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        @include respondto(laptop13) {
          display: none;
        }
        svg {
          height: 100%;
          width: calc(100% + 30px);
        }

        svg,
        path {
          fill: $tab-color;
          stroke: $tab-color;
        }
      }

      p {
        display: list-item;
        font-size: $font-sm;
        margin-left: 15px;
        position: relative;
        z-index: 3;
        @include respondto(largeDesktopSm) {
          margin-left: 0.781vw;
        }
        @include respondto(phone) {
          font-size: $font-sm;
        }
      }

      &.active {
        color: $black;
        z-index: 2;
        .tab-icon {
          z-index: 2;
          svg,
          path {
            fill: $white;
            stroke: $white;
          }
        }

        @include respondto(laptop13) {
          background: none;
          border-bottom: 4px solid $main-color;
        }
      }
    }

    .selected-list {
      left: -30px;
      padding-left: 3rem;
      position: relative;
      .tab-icon {
        svg {
          width: calc(100% + 50px);
        }
      }

      @include respondto(laptop13) {
        left: 0;
        padding-left: 0;
      }
    }
  }

  .pagination-list-container {
    align-items: center;
    background: $white;
    border-radius: 15px;
    box-shadow: $block-shadow;
    display: flex;
    gap: 5px;
    height: 59px;
    justify-content: space-between;
    max-width: 1064px;
    padding: 15px;
    width: 100%;
    @include respondto(largeDesktopSm) {
      height: auto;
      max-height: 59px;
    }
    @include respondto(laptopMd) {
      max-height: 49px;
      padding: 10px;
    }

    @include respondto(laptop13) {
      display: none;
    }

    .action-select {
      @include respondto(laptop13) {
        display: none;
      }
      .form-item-group {
        height: 100%;
        .form-item-control-select.react-select__control--is-focused {
          box-shadow: none;
        }
      }
      .form-item-control-select {
        align-items: center;
        background: $light-button-color;
        border: 1px solid $light-button-border;
        border-radius: 4px;
        display: flex;
        font-size: $font-md;
        font-style: normal;
        font-weight: 500;
        justify-content: flex-start;
        line-height: 20px;
        max-height: 30px;
        min-height: 30px;
        min-width: 100%;
        padding: 0 0 0 8px;
        width: 100%;

        .react-select__placeholder {
          font-size: $font-md;
          font-weight: 500;
          margin: 0;
        }
        .react-select__indicators {
          max-height: 100%;
          .react-select__indicator {
            padding: 5px;
          }
        }
        .react-select__indicator {
          svg {
            height: 16px;
            padding: 0;
            width: 16px;
            @include respondto(largeDesktopSm) {
              height: 0.833vw;
              width: 0.833vw;
            }
          }
        }

        .react-select__placeholder {
          color: $light-button-font;
        }

        .react-select__indicator {
          color: $light-button-font;
        }

        .react-select__single-value {
          color: $light-button-font;
        }
        &:hover {
          background: $light-button-color-hover;
        }
      }
    }

    .list-info-text {
      color: $black;
      font-size: $font-md;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      @include respondto(laptop13) {
        display: none;
      }
    }

    .page-number-container {
      background: $input-bg;
      border: 1px solid $input-border-color;
      border-radius: 4px;
      color: $input-font-color;
      position: relative;
      transition: 0.3s ease-in-out;
      &:hover,
      &:focus {
        background: $light-button-color-hover;
      }

      &::after {
        background-image: url('../../assets/svg/pagenumber.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        bottom: 2px;
        content: '';
        height: 16px;
        position: absolute;
        right: 15px;
        width: 16px;
        z-index: 0;
      }

      &::before {
        background-image: url('../../assets/svg/pagenumber.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        height: 16px;
        position: absolute;
        right: 15px;
        top: 2px;
        transform: rotate(180deg);
        width: 16px;
        z-index: 0;
      }

      select {
        background: none;
        border: none;
        border-radius: 4px;
        color: $input-font-color;
        font-size: $font-md;
        font-style: normal;
        font-weight: 500;
        height: 30px;
        line-height: 20px;
        padding: 2px 10px;
        position: relative;
        width: 75px;
        z-index: 1;
        &:focus {
          box-shadow: 0 0 0.1rem 0.1rem rgba($nav-color, 1);
        }
      }
      &:hover {
        background: $light-button-color-hover;
      }
    }

    .pagination {
      gap: 12px;
      margin-bottom: 0;

      .page-item {
        .page-link {
          align-items: center;
          background: $light-button-color;
          border: none;
          border-radius: 7px;
          color: $dark-charcoal;
          display: flex;
          font-size: $font-md;
          font-weight: 600;
          height: 30px;
          justify-content: center;
          padding: 0 !important;
          width: 30px;

          span {
            color: $text-color-pagination;
            font-size: 2.4rem;

            @include respondto(largeDesktopSm) {
              font-size: 1.25vw;
            }
          }
        }

        &.active {
          .page-link {
            background: $main-color;
            color: $white;
          }
        }

        &:not(.active):not(.ellipsis) {
          .page-link {
            &:hover {
              background: $main-color-hover;
              color: $white !important;
            }
          }
        }

        &.ellipsis {
          cursor: none;

          span {
            font-size: $font-md !important;
          }

          .page-link {
            background: transparent;

            span {
              color: $text-color-pagination;
            }
          }
        }
      }
    }
  }

  .pagination-container {
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  .list-filter {
    &-pagination {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0;
      width: 100%;

      ul {
        display: flex;
        gap: 7px;
        margin-bottom: 0;
        padding-left: 0;

        li {
          align-items: center;
          background: $input-color;
          border: none;
          border-radius: 7px;
          color: $black;
          display: flex;
          font-size: $font-md;
          font-weight: 600;
          height: 30px;
          justify-content: center;
          min-width: 30px;
          padding: 0 !important;

          a {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            width: 100%;
          }

          &.break {
            background: transparent;
            &:hover {
              background: transparent;
              color: $main-color;
            }
          }

          &.selected,
          &:hover {
            background: $main-color;
            color: $white;
          }
        }
      }

      .previous,
      .next {
        align-items: center;
        background: $input-color;
        border: none;
        border-radius: 7px;
        color: $dark-charcoal;
        display: flex;
        font-size: $font-md;
        font-weight: 600;
        height: 30px;
        justify-content: center;
        padding: 0 !important;
        position: relative;
        transition:
          color 0.15s ease-in-out,
          background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out,
          box-shadow 0.15s ease-in-out;
        width: 30px;

        span {
          color: $text-color-pagination;
          font-size: 1.3rem;
          // @include respondto(largeDesktopMd) {
          //   font-size: 1.25vw;
          // }
          // @include respondto(laptopMd) {
          //   font-size: 1.3889vw;
          // }
        }

        svg {
          height: 2.4rem;
          fill: $text-color-pagination;
          margin: auto;
          width: 2.4rem;
          // @include respondto(largeDesktopSm) {
          //   height: 1.7708vw;
          //   width: 1.25vw;
          // }
        }

        &:hover {
          background: $main-color;
          color: $white;

          span {
            color: $white;
          }

          svg {
            fill: $white;

            path {
              fill: $white;
            }
          }
        }
      }
    }
  }
}
