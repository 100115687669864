@import '../../assets/styles/variables';

.streets-modal {
  align-items: center;
  display: flex !important;
  justify-content: center !important;
  margin: 0 auto !important;
  .modal-dialog {
    bottom: auto !important;
    max-width: 744px !important;
    top: 0;
    width: 100%;
    @include respondto(phone) {
      max-width: 100%;
    }
  }
  .modal-warning {
    align-items: center;
    background: $azure;
    border: 1px solid $azure;
    border-radius: 10px;
    color: $black;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    padding: 10px 5px;

    span {
      padding: 10px;
    }
    svg {
      height: 80px;
      width: 80px;
      fill: $black;
      display: flex;
      margin: 0 20px;
    }
  }
  .modal-header {
    border-bottom: none;
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.3;
    padding: 0;
    text-align: center;
    span {
      width: 100%;
    }

  }
  .modal-content {
    background-color: $azure !important;
    border-radius: 20px !important;
    padding: 56px 72px 20px;
    @include respondto(desktopXLg){
    margin-bottom: 50px;
    margin-top: 100px;
    }
    @include respondto(phone) {
      background-color: $white !important;
      padding: 40px 10px 0;
    }
  }
  .close-btn-modal {
    cursor: pointer;
  
  }
  .add-street-modal {
    background-color: $white;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 35px 63px;
    @include respondto(phone) {
      padding: 20px 10px 0;
    }
  }
  .flex-range {
    display: flex;
    justify-content: flex-start;
  }
  .react-datepicker-wrapper::after {
    background-repeat: no-repeat;
  }
  .react-datepicker-wrapper {
    max-width: calc(50% - 35px);
    min-width: calc(50% - 35px);
    &:first-child {
      margin-right: 70px;
      @include respondto(phone) {
        margin-right: 30px;
      }
    }
    @include respondto(phone) {
      max-width: calc(50% - 15px);
      min-width: calc(50% - 15px);
    }
  }
  .input-flex {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    &:not(:first-child) {
      padding-top: 20px;
    }
  }
  .close {
    align-items: center;
    background: #e9e9fe;
    border-radius: 4px;
    display: flex;
    height: 43px;
    justify-content: center;
    transition: 0.3s ease-in-out;
    width: 43px;
    path {
      fill: $black;
    }
  }

  .btns-container {
    align-items: center;
    display: flex;
    gap: 17px;
    justify-content: center;
    padding: 23px;
    width: 100%;

    @include respondto(largeDesktopMd) {
      padding: 1.1979vw;
    }

    @include respondto(phone) {
      flex-wrap: wrap;
    }
    .button-blue {
      position: relative;
    }
    button {
      height: 34px;
    }
  }
}
