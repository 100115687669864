@import "../../assets/styles/variables";

.filters-aligning-block {
  display: flex;
  flex-wrap: wrap;
  max-width: 704px;
  margin-bottom: 2.2rem;
  gap: 1rem;
  width: 100%;
  @include respondto(largeDesktopSm) {
    max-width: 36.667vw;
    margin-bottom: 1.1458vw;
    gap: 0.521vw;
  }
  @include respondto(laptop13) {
    max-width: 100%;
  }
}
.fieldset-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .form-item-group {
    margin-bottom: 1rem;
    width: calc(50% - 3rem);
    @include respondto(largeDesktopSm) {
      margin-bottom: 0.521vw;
      width: calc(50% - 1.563vw);
    }
    @include respondto(phoneSm) {
      width: 100%;
    }
  }
   .icon-minus {
    background-color: $main-color;
    display: block;
    height: .2rem;
    margin: 2rem auto;
    width: 2rem;
    @include respondto(largeDesktopSm) {
      height: 0.104vw;
      margin: 1.042vw auto;
      width: 1.042vw;
    }
    @include respondto(phoneSm) {
      display: none;
    }
   }
}
.fieldset-block-bottom {
  .form-item-group {
    margin-bottom: 1.7rem;
  }
}