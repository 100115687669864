@import '../../assets/styles/variables';

.ads-form-container {
  .ads-form-tabs {
    width: fit-content;
  }
}

.bg-gray {
  background-color: $input-color;
}

.checkbox-group {
  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 9px;
    width: 100%;
    @include respondto(largeDesktopSm) {
      gap: 0.469vw;
    }
    &__item {
      border-radius: 10px;
      min-height: 428px;
      padding: 20px 30px;

      @include respondto(largeDesktopSm) {
        border-radius: 0.521vw;
        min-height: 22.292vw;
        padding: 1.042vw 1.563vw;
      }

      @include respondto(laptop13) {
        min-height: auto;
      }

      &-sm {
        width: calc(25% - 9px);

        @include respondto(largeDesktopSm) {
          width: calc(25% - 0.469vw);
        }

        @include respondto(laptop13) {
          width: calc(50% - 9px);
        }
        @include respondto(phone) {
          width: 100%;
        }
      }
    }
  }

  &-fieldset {
    .heading-block {
      font-size: $font-lg;
      line-height: 2;
      margin-bottom: 2.5rem;
      @include respondto(largeDesktopSm) {
        margin-bottom: 1.302vw;
      }
    }

    .form-item {
      margin-bottom: 12px;
      @include respondto(largeDesktopSm) {
        margin-bottom: 0.625vw;
      }
    }
  }
}
