@import '../../assets/styles/variables';

.flex-add-activity {
  gap: 41px;
  justify-content: space-between;
  @include respondto(largeDesktopSm) {
    gap: 2.1354vw;
  }
  @include respondto(laptop13) {
    // flex-direction: column;
    flex-wrap: wrap;
  }

  .btns-container-mobile {
    align-items: center;
    background: $white;
    border-radius: 20px;
    box-shadow: 0 27px 42.3px 0 rgba($black, 0.04);
    display: none;
    gap: 17px;
    justify-content: center;
    padding: 15px;
    width: 100%;

    .button-blue {
      position: relative;
    }

    @include respondto(laptop13) {
      display: flex;
      button {
        min-width: 223px;
      }
    }

    @include respondto(phone) {
      flex-wrap: wrap;
      padding: 15px 10px;
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
  .react-datepicker-wrapper.hours {
    margin-left: 0;
  }
}
