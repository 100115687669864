@import '../../assets/styles/variables';

body {
  &:has(.ads-general-buttons) {
    @include respondto(phone) {
      margin-bottom: 70px;
    }
  }
}

.form-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 17px;
  justify-content: center;
  margin: 30px 0;
  padding: 23px;
  width: 100%;

  @include respondto(phone) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.ads-general-buttons {
  flex-wrap: nowrap;

  @include respondto(phone) {
    border-radius: 0 !important;
    bottom: 0 !important;
    flex-wrap: nowrap !important;
    left: 0;
    margin: 0 !important;
    padding: 0 !important;
    position: fixed;
    right: 0;
    z-index: 9999;
  }

  .ads-general-button {
    @include respondto(phone) {
      flex-direction: row;
    }
    &-back {
      display: none;
      @include respondto(phone) {
        display: flex;
      }
    }
    &-next,
    &-save,
    &-back {
      cursor: pointer;
      position: relative;
      @include respondto(phone) {
        background-color: $white;
        border: none;
        color: $black;
        flex-direction: column;
        font-size: 14px;
        font-weight: 400;
        gap: 0;
        line-height: normal;
        min-width: fit-content;
        padding: 19px 0 13px;
        height: auto;
        width: 100%;

        &:hover:not(:disabled):not([aria-disabled='true']) {
          background-color: transparent;
          border: none;
          color: $main-color-hover;

          svg {
            fill: $main-color-hover;

            path {
              fill: $main-color-hover;
            }
          }
        }
      }

      svg {
        fill: inherit;

        path {
          fill: inherit;
        }

        @include respondto(phone) {
          height: 24px;
          width: 24px;
        }
      }
    }

    &-next {
      @include respondto(phone) {
        order: 3;
      }
    }

    &-save {
      @include respondto(phone) {
        order: 2;
      }
    }
    &-back {
      @include respondto(phone) {
        order: 1;
      }
    }
  }

  .icon {
    height: 24px;
    position: relative;
    @include respondto(not-phone) {
      display: none;
    }
    &.loading {
      @include respondto(not-phone) {
        display: block;
      }
    }
  }
}
