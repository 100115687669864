@import '../../assets/styles/variables';

.activity-item {
  gap: 36px;
  @include respondto(largeDesktopSm) {
    gap: 1.875vw;
  }
  @include respondto(laptop13) {
    flex-direction: column;
    gap: 15px;
  }

  .line-border {
    @include respondto(laptop13) {
      margin: 5px 0;
    }
  }

  &.opacity {
    opacity: 0.5;
  }
  .list-item-content {
    align-items: center;
    display: flex;
    flex-direction: row !important;
    gap: 20px;
    justify-content: space-between;
    @include respondto(largeDesktopSm) {
      gap: 1.042vw;
    }
    @include respondto(laptop13) {
      flex-direction: column !important;
      gap: 15px;
    }

    .column-item {
      padding: 0 28px;
      @include respondto(largeDesktopSm) {
        padding: 0 1.406vw;
      }
      @include respondto(laptop13) {
        padding: 0;
      }
      &:first-child {
        width: 10.7%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(2) {
        width: 13%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(3) {
        width: 12%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(4) {
        width: 15.5%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(5) {
        width: 13.2%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(6) {
        width: 13.2%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(7) {
        width: 13.42%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
    }

    .label-item-tag {

      @include respondto(laptop13) {
        font-weight: 400;
      }
      @include respondto(phone) {
        font-size: 12px !important;
      }
    }

    .info-type {
      align-items: center;
      color: $black;
      display: flex;
      font-size: 1.3rem;
      font-weight: 400;
      gap: 8px;

      @include respondto(laptop13) {
        font-size: 1.6rem !important;
        font-weight: 400 !important;
      }

      @include respondto(phone) {
        font-size: 12px !important;
      }

      img {
        height: 2rem;
        width: 2rem;

        @include respondto(phone) {
          height: 1.8rem;
          width: 1.8rem;
        }
      }
    }
    .info-contacts {
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      justify-content: flex-start;
      @include respondto(laptop13) {
        width: 50%;
      }
      .label-item-mobile {
        @include respondto(laptop13) {
          width: 100% !important;
        }
      }
    }
    .info-title {
      color: $black;
      font-size: 1.3rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      @include respondto(laptop13) {
        font-size: 1.6rem !important;
        font-weight: 400 !important;
      }

      @include respondto(phone) {
        font-size: 12px !important;
      }
    }

    .info-date {
      align-items: center;
      color: $black;
      display: flex;
      flex-direction: column;
      font-size: 1.3rem;
      font-style: normal;
      font-weight: 400;
      gap: 4px;
      line-height: normal;
      @include respondto(largeDesktopSm) {
        gap: 0.2083vw;
      }

      @include respondto(laptop13) {
        flex-wrap: wrap;
        font-size: 1.6rem !important;
        font-weight: 400 !important;
        gap: 10px;
        width: 50%;

        .label-item-mobile {
          font-size: 1.6rem;
          width: 100% !important;
        }
      }

      @include respondto(phone) {
        font-size: 1.2rem !important;
      }
    }

    .info-icons {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 10px;
      @include respondto(largeDesktopSm) {
        gap: 0.521vw;
      }
      @include respondto(laptop13) {
        flex-direction: row;
        justify-content: center;
        width: 100%;
      }
    }
  }
}
