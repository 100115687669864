@import '../../assets/styles/variables';

.company-item {
  gap: 30px;

  @include respondto(largeDesktopSm) {
    gap: 1.51vw;
  }

  @include respondto(laptop13) {
    flex-direction: column;
    gap: 15px;
    padding: 15px 11px;
  }
  .list-item-content {
    .column-item {
      padding: 0 29px;
      @include respondto(largeDesktopSm) {
        padding: 0 1.51vw;
      }
      @include respondto(laptop13) {
        padding: 0;
      }
      &:first-child {
        padding-left: 0;
        width: 16%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(2) {
        width: 17.5%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(3) {
        width: 19%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(4) {
        width: 19%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(5) {
        width: 18.2%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
    }
  }

  .line-border {
    @include respondto(laptop13) {
      margin: 5px 0;
    }
  }

  .list-item-content {
    align-items: center;
    display: flex;
    flex-direction: row;
    word-wrap: anywhere;
    gap: 0;

    @include respondto(laptop13) {
      flex-direction: column;
      gap: 10px;
    }

    @include respondto(phone) {
      gap: 10px;
    }

    .info {
      color: $black;
      font-size: $font-sm;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      @include respondto(laptop13) {
        font-size: 1.6rem;
      }

      @include respondto(phone) {
        font-size: 1.4rem;
      }
    }

    a {
      color: $black;
      transition: 0.3s ease-in-out;

      &:hover {
        color: $main-color !important;
      }
    }

    .info-icons {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 7%;
      @include respondto(laptop13) {
        width: 100%;
      }
      @include respondto(largeDesktopSm) {
        gap: 0.521vw;
      }

      @include respondto(laptop13) {
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }
  }
}
