@import '../../assets/styles/variables';

.ads-form-container-block.ads-form-request {
  .form-item-group .form-item-control-select,
  .react-datepicker-wrapper input {
    border-radius: 4px;
  }
  .heading-block {
    margin-bottom: 7px;
  }
  .subheading-block {
    padding-bottom: 1.7rem;
    padding-top: 3rem;
  }
  .form-item {
    margin-bottom: 0;
    @include respondto(largeDesktopSm) {
      margin-bottom: 0.885vw;
    }
  }

  .row {
    --bs-gutter-x: 3rem;
    align-items: flex-start;
    .flex-range {
      align-items: center;
      display: flex;
      gap: 9px;
      width: 100%;
      @include respondto(largeDesktopSm) {
        gap: 0.469vw;
      }
    }
  }

  .contract-group-container,
  .buget-group-container {
    column-gap: 25px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 17px;
    .form-item {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      width: 31%;
    }
    @include respondto(laptop13) {
      flex-direction: column;
      flex-wrap: wrap;
      & > div {
        width: 100%;
        &::after {
          display: none;
        }
      }
      .form-item {
        min-width: 100%;
        padding: 0 !important;
        width: 100% !important;
      }
    }
    .form-item-group .form-item-control-select,
    .react-datepicker-wrapper input {
      border-radius: 4px;
    }
  }

  .buget-group-container {
    .form-item-textarea {
      width: 100%;
    }
  }
}

.fieldset-block {
  &__hr {
    border: none;
    border-bottom: 1px solid $whisper;
    margin: 18px 0 31px;
    opacity: 1;
    @include respondto(largeDesktopSm) {
      margin: 0.938vw 0 1.615vw;
    }
  }
  p {
    font-size: $font-md;
    font-weight: 300;
    line-height: normal;
  }
}

.filters-aligning {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px 0px;
  justify-content: flex-start;
  @include respondto(largeDesktopSm) {
    gap: 0.521vw 0;
  }
}
