@import '../../assets/styles/variables';

.announces-list-item {
  align-items: center;
  background: $white;
  border-left: 5px solid $white;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  gap: 20px;
  margin-bottom: 0;
  padding: 10px 10px 10px 10px;
  width: 100%;

  @include respondto(largeDesktopSm) {
    gap: 1.042vw;
  }

  @include respondto(laptop13) {
    border-left: none;
    gap: 15px;
    padding: 15px 11px;
  }
  .form-check-input {
    margin-top: 0;
  }
  .line-border {
    @include respondto(laptop13) {
      margin: 5px 0;
    }
  }

  &.active {
    border-left: 5px solid $main-color;

    @include respondto(laptop13) {
      border-left: none;
    }
  }

  &.expanded {
    border: 1px solid $main-color;
    border-left: 5px solid $main-color;
    padding: 14px 14px 14px 10px;
    @include respondto(laptop13) {
      border: none;
    }
  }

  .checkbox-container {
    max-width: 20px;
    min-width: 20px;
    width: 100%;

    .form-check-input {
      height: 20px;
      width: 100%;
      &[type='checkbox'] {
        border-radius: 4px;
      }
    }
  }

  .list-item-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem 0;
    justify-content: flex-start;
    width: 100%;
    @include respondto(laptop13) {
      gap: 1rem;
    }
    .label-item-mark,
    .label-item-tag {
      width: fit-content;
    }
    .label-item {
      &-link,
      &-tag,
      &-info,
      &-info-lg {
        height: 28px;
        min-height: 28px;
        padding: 3px 8px;
      }
    }

    .info-icons {
      margin-left: auto;
    }
    .list-item-header {
      align-items: center;
      display: flex;
      gap: 18.46px;
      justify-content: space-between;
    }

    .list-item-body {
      align-items: center;
      display: flex;
      gap: 34px;
      .img-container {
        border-radius: 15px;

        @include respondto(largeDesktopSm) {
          border-radius: 0.781vw;
        }

        img {
          border-radius: 15px;
          height: 165px;
          min-width: 165px;
          object-fit: cover;
          width: 165px;
        }
      }

      .description-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;

        @include respondto(largeDesktopSm) {
          gap: 1.042vw;
        }
      }

      .item-details {
        align-items: center;
        display: flex;
        gap: 20px;
        justify-content: space-between;

        @include respondto(largeDesktopSm) {
          gap: 1.042vw;
        }

        .item-info {
          max-width: 450px;
          width: 100%;
        }

        .item-tile {
          color: $black;
          font-size: $font-md;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 4px;
        }

        .item-price {
          color: $black;
          font-size: $font-md;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 9px;

          span {
            color: $main-color;
            font-weight: 500;
          }
        }

        .item-info-tags {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          @include respondto(largeDesktopSm) {
            gap: 0.521vw;
          }
        }

        .item-description {
          color: $black;
          font-size: $font-sm;
          font-weight: 300;
          line-height: normal;
          max-width: 630px;
          width: 100%;
        }

        .item-description .text-container {
          display: block;
          -webkit-box-orient: vertical;
          max-height: unset;
          overflow: hidden;
        }

        .item-description .text-container.collapsed {
          display: -webkit-box;
          max-height: 93px;
        }

        .toggle-button {
          background: none;
          color: $main-color;
          cursor: pointer;
          &:hover {
            color: $main-color-hover;
            text-decoration: underline;
          }
        }

        .item-icon-links {
          column-gap: 15px;
          display: grid;
          grid-template-columns: 30px;
          row-gap: 15px;
          @include respondto(largeDesktopSm) {
            column-gap: 0.781vw;
            grid-template-columns: 2.4479vw;
            row-gap: 0.781vw;
          }
        }
      }

      .item-links {
        align-items: center;
        display: flex;
        gap: 10px;
        @include respondto(largeDesktopSm) {
          gap: 0.521vw;
        }
      }
    }
  }
  .button-square-md {
    @include respondto(onlyDesktop) {
      height: 30px;
      width: 30px;
      svg,
      img {
        width: 15px;
      }
    }
  }
}
