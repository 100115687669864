@import '../../assets/styles/variables';

.property-list {
  form {
    display: grid;
    grid-template-columns: 3% 2.8% 8.4759% 7.586% 6.952% 7.037% 7.378% 4.635% 12.927% 10.195% 7.439% 7.866% 10.2499% auto;
    @include respondto(laptop13) {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .filter-item {
      @include respondto(laptop13) {
        border-right: none;
        max-width: 100%;
      }
      &:nth-child(2) {
        padding-left: 0;
      }
    }
    .arrows-label-icon {
      cursor: pointer;
      label {
        cursor: pointer;
      }
    }

    .react-select__value-container {
      @include respondto(largeDesktopSm) {
        padding: 0 0.26vw;
      }
    }
    .react-datepicker-wrapper {
      &::after {
        display: none;
      }
    }
    .form-item-group {
      .form-item-control-select {
        .react-select__indicator {
          padding: 1px;
          @include respondto(largeDesktopSm) {
            padding: 0.1vw;
          }
        }
      }
    }

    .react-datepicker-wrapper input,
    .form-item-control {
      @include respondto(largeDesktopSm) {
        padding: 0.1vw 0.26vw;
      }
    }
  }
}
