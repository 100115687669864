@import '../../assets/styles/variables';

.upload-files {
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px 30px;

    @include respondto(largeDesktopSm) {
      gap: 0.781vw 1.2vw;
    }

    @include respondto(phone) {
      gap: 1.2vw;
    }

    &-item {
      max-width: 11rem;
      width: calc(33.33% - 20px);

      @include respondto(largeDesktopSm) {
        width: calc(33.33% - 0.82vw);
      }

      @include respondto(phone) {
        width: calc(33.33% - 0.82vw);
      }

      &-lg {
        background: $white;
        border: 0.5px solid #e3e3e3;
        border-radius: 0.4rem;
        display: flex;
        flex-direction: row;
        padding: 0.5rem;
        width: 100%;
        .upload-files__list-image_title {
          margin: auto auto auto 0;
          max-width: calc(100% - 4rem);
          @include respondto(largeDesktopSm) {
            line-height: 1.25vw;
            max-width: calc(100% - 2.0833vw);
          }
          p {
            font-size: $font-sm;
            line-height: 1.8rem;
          }
        }
      }
    }

    .action-buttons-list {
      gap: 10px;

      @include respondto(largeDesktopSm) {
        gap: 0.421vw;
      }
    }
  }

  .sortable-root {
    display: 'flex';
    flex-wrap: 'wrap';
    user-select: 'none';
  }
  .sortable-dragged {
    box-shadow: '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)';
    button {
      opacity: 0;
    }
  }
  &__list-image {
    border-radius: 4px;
    display: block;
    margin-bottom: 4px;
    overflow: hidden;
    padding-top: 100%;
    position: relative;
    width: 100%;
    @include respondto(largeDesktopSm) {
      border-radius: 0.2083vw;
      margin-bottom: 0.2083vw;
    }
    img {
      bottom: 0;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }

    &_title {
      color: $black-shade;
      font-size: $font-sm;
      font-weight: 400;
      line-height: 18px;
      word-wrap: break-word;
      margin-bottom: 5px;
      width: 100%;
      @include respondto(largeDesktopSm) {
        line-height: 1.2;
        margin-bottom: 0.26vw;
      }
    }
  }
}

.ads-file {
  &-drop-zone {
    background: $input-color;
    border: 1px dashed rgba($black, 0.3);
    border-radius: 4px;
    margin: 0 0 21px;
    padding: 30px;
    text-align: center;
    width: 100%;
    @include respondto(largeDesktopSm) {
      border-radius: 0.2083vw;
      margin: 0 0 1.094vw;
      padding: 1.563vw;
    }
    .file-drop-zone__icon {
      display: block;
      margin: 0 auto 26px;
      @include respondto(largeDesktopSm) {
        margin: 0 auto 1.354vw;
      }
      svg {
        height: 6rem;
        width: 6.9rem;
        @include respondto(largeDesktopSm) {
          height: 3.125vw;
          width: 3.594vw;
        }
        path {
          fill: $black;
        }
      }
    }
    .link {
      color: $main-color-hover !important;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
    .file-drop-zone__content {
      span {
        color: $black-shade;
        display: block;
        font-size: $font-md;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 10px;
        @include respondto(largeDesktopSm) {
          line-height: 2;
          margin-bottom: 0.521vw;
        }
      }

      a {
        color: $main-color;
        font-size: $font-md;
        font-weight: 500;
        line-height: 2;
        text-decoration-line: underline;

        &:hover {
          color: $main-color-hover;
        }
      }

      p {
        color: $dim-gray-2;
        font-size: $font-sm;
        font-weight: 400;
        line-height: 1.2;
        text-align: center;
      }
    }
  }

  .heading-images {
    color: $dim-gray-2;
    font-size: $font-md;
    font-weight: 500;
    margin-bottom: 10px;
    @include respondto(largeDesktopSm) {
      margin-bottom: 0.521vw;
    }
  }
}

.file-item-length {
  color: $dim-gray-2;
  font-size: $font-md;
  font-weight: 500;
  margin-bottom: 8px;
  @include respondto(largeDesktopSm) {
    margin-bottom: 0.4167vw;
  }
}

.file-item-load {
  &-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 0 27px;
    padding: 0;
    @include respondto(largeDesktopSm) {
      gap: 0.521vw;
      margin: 0 0 1.406vw;
    }
  }

  &-item {
    background: $white;
    border: 0.5px solid #e3e3e3;
    border-radius: 4px;
    color: $black-shade;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 18px;
    padding: 8px 30px 8px 12px;
    position: relative;
    @include respondto(largeDesktopSm) {
      border-radius: 0.2083vw;
      line-height: 1.2;
      padding: 0.4167vw 1.563vw 0.4167vw 0.625vw;
    }
    @include respondto(largeDesktopMd) {
      font-size: 0.625vw;
    }
    @include respondto(laptopMd) {
      font-size: 0.764vw;
    }
    .remove {
      background-color: transparent;
      border: none;
      bottom: 0;
      height: 100%;
      line-height: 26px;
      position: absolute;
      right: 0;
      top: 0;
      width: 36px;
      @include respondto(largeDesktopSm) {
        // line-height: 1.7708vw;
        width: 1.875vw;
      }
      * {
        transition: 0.3s ease-in;
      }

      svg,
      img {
        height: 16px;
        width: 16px;
        @include respondto(largeDesktopSm) {
          height: 0.833vw;
          width: 0.833vw;
        }
      }

      &:hover {
        svg {
          fill: $main-color-hover;

          path {
            fill: $main-color-hover;
          }
        }
      }
    }

    .progress-item {
      bottom: -2px;
      left: 0;
      position: absolute;
      right: 0;

      .progress,
      .progress-stacked {
        --bs-progress-bg: transparent;
        height: 3px;
      }

      .bg-info,
      .progress-bar {
        background-color: $main-color;
        border-radius: 10px;
      }
    }
  }
}
