@import '../../assets/styles/variables';

.companies-modal-center {
  --bs-modal-width: 744px;
  --bs-modal-bg: $azure;
  bottom: 0;
  left: 0;
  padding-top: 90px;
  right: 0;
  top: 0;
  @include respondto(onlyDesktop) {
    z-index: 999999 !important;
  }
  @include respondto(laptop13) {
    --bs-modal-bg: $white;
    border-radius: 25px 25px 0 0;
    padding-top: 190px;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999991 !important;
  }

  &.show {
    align-items: center;
    display: flex !important;
    justify-content: center;
  }

  .modal-title {
    color: $black;
    font-size: 34px;
    font-weight: 600;
    @include respondto(largeDesktopSm) {
      font-size: 1.7708vw;
    }
    @include respondto(laptop13) {
      font-size: 24px;
    }
    @include respondto(phone) {
      font-size: 20px;
    }
  }

  .modal-dialog {
    margin: auto;
    max-width: 744px;
    @include respondto(largeDesktopSm) {
      max-width: 38.75vw;
      width: 100%;
    }
    @include respondto(laptop13) {
      max-width: 100%;
      width: 100%;
    }
  }

  .modal-content {
    background-color: $azure;
    border: none;
    border-radius: 20px;
    @include respondto(largeDesktopSm) {
      border-radius: 1.042vw;
    }
    @include respondto(phone) {
      background-color: $white;
    }
  }

  .modal-body {
    background-color: $white;
    border-radius: 25px;
    box-shadow: $block-shadow;
    margin: 0 76px 72px;
    padding: 35px 65px 34px 63px;
    width: calc(100% - 152px);
    @include respondto(largeDesktopSm) {
      border-radius: 1.302vw;
      margin: 0 3.958vw 3.75vw;
      padding: 1.8229vw 3.385vw 1.7708vw 3.281vw;
      width: calc(100% - 7.917vw);
    }
    @include respondto(laptop13) {
      border-radius: 0 0 20px 20px;
      box-shadow: none;
      margin: 0;
      padding: 10px 20px 100px 20px;
      width: 100%;
    }
  }

  .modal-header {
    border-bottom: none;
    justify-content: center;
    padding: 5.2rem 1rem 4.5rem;
    text-align: center;
    @include respondto(largeDesktopSm) {
      padding: 2.708vw 0.521vw 2.344vw;
    }
    @include respondto(laptop13) {
      background-color: $white;
      border-radius: 25px 25px 0 0;
      padding: 1.5rem 6rem;
    }
  }

  .container {
    padding: 0;
  }

  .form-item {
    margin-bottom: 20px;
    @include respondto(largeDesktopSm) {
      margin-bottom: 1.042vw;
    }
  }

  .buttons {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    @include respondto(laptop13) {
      gap: 12px;
    }
    .button-md {
      min-width: 223px;
      @include respondto(largeDesktopSm) {
        min-width: 11.615vw;
      }
      @include respondto(laptop13) {
        min-width: 100%;
      }
    }
    .form-item-button {
      width: calc(50% - 8px);
      @include respondto(laptop13) {
        width: calc(50% - 6px);
      }
    }
  }

  .form-item-button {
    margin-top: 14px;
  }

  textarea {
    min-height: 149px;
  }
}
.form-item-control {
  border-radius: 4px;
}

.close-modal {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  height: 40px;
  position: absolute;
  right: 5px;
  top: 5px;
  transition: 0.3s ease-in-out;
  width: 40px;

  * {
    transition: 0.3s ease-in-out;
  }

  svg {
    height: 20px;
    width: 20px;
  }

  &:hover {
    svg {
      fill: $main-color;

      path {
        fill: $main-color;
      }
    }
  }
}

.modal-backdrop:has(+ .companies-modal-center.show) {
  @include respondto(onlyDesktop) {
    --bs-backdrop-zindex: 99999;
  }
}
