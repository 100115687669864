@import '../../assets/styles/variables';

.item-email {
  gap: 24px;
  // padding: 14px 17px 15px 23px !important;
  @include respondto(largeDesktopSm) {
    gap: 1.25vw;
    // padding: 0.729vw 0.885vw 0.781vw 1.1979vw !important;
  }
  @include respondto(laptop13) {
    flex-direction: column;
    gap: 15px;
  }

  .line-border {
    @include respondto(laptop13) {
      margin: 5px 0;
    }
  }

  .list-item-content {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 0;
    @include respondto(laptop13) {
      flex-direction: column;
      gap: 10px;
    }

    @include respondto(phone) {
      gap: 10px;
    }

    .column-item {
      @include respondto(largeDesktopSm) {
        padding: 0 1.563vw;
      }
      &:first-child {
        width: 12%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(2) {
        width: 20.5%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(3) {
        width: 14.5%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(4) {
        width: 17.5%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(5) {
        width: 23%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
    }

    .info-text {
      color: $black;
      font-size: $font-sm;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      @include respondto(phone) {
        font-size: 1.2rem;
      }
    }

    .info-icons {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-left: auto;
      margin-right: 20px;
      // max-width: 105px;

      @include respondto(largeDesktopSm) {
        gap: 0.521vw;
        margin-right: 1.042vw;
        // max-width: 5.4688vw;
      }

      @include respondto(laptop13) {
        justify-content: center;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
