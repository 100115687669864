@import '../../../assets/styles/variables';

.announces-card-container {
  background: $white;
  border-radius: 20px;
  box-shadow: $block-shadow;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 15px;
  width: 100%;

  .checkbox-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;

    .line {
      background-color: $input-check-border-device;
      height: 1px;
      width: 100%;
    }

    .form-check-input {
      border-color: $input-check-border-device;
      box-shadow: none;
      height: 20px;
      margin-left: 22px;
      margin-right: 22px;
      width: 20px;

      &[type='checkbox'] {
        border-radius: 4px;
      }
    }
  }

  .item-details-container {
    display: flex;
    flex-direction: column;

    .img-title-section {
      align-items: center;
      display: flex;
      gap: 23px;
      margin-bottom: 23px;
      .img-container {
        cursor: pointer;
        height: 0;
        min-width: calc(50% - 12px);
        padding-top: calc(50% - 12px);
        position: relative;
      }
      img {
        border-radius: 8px;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
      }

      .details-container {
        .title-text {
          color: $black;
          font-size: $font-md;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 15px;
          @include respondto(phone) {
            font-size: 1.2rem;
          }
        }

        .rent-text {
          align-items: center;
          color: $main-color;
          display: flex;
          flex-wrap: wrap;
          font-size: $font-md;
          font-style: normal;
          font-weight: 500;
          gap: 10px;
          line-height: 0;
          @include respondto(phone) {
            font-size: 1.2rem;
          }
          @include respondto(phoneSm) {
            align-items: flex-start;
            flex-direction: column;
          }

          span {
            color: $black;
            font-size: $font-md;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            @include respondto(phone) {
              font-size: 1.2rem;
            }
          }
        }
      }
    }

    .description-text {
      color: $black;
      font-size: $font-md;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-bottom: 23px;
      @include respondto(phone) {
        font-size: 1.2rem;
      }
    }

    .description-text .text-container {
      display: block;
      -webkit-box-orient: vertical;
      max-height: unset;
      overflow: hidden;
    }

    .description-text .text-container.collapsed {
      display: -webkit-box;
      max-height: 100px;
      @include respondto(phone) {
        max-height: 75px;
      }
    }

    .toggle-button {
      background: none;
      color: $main-color;
      cursor: pointer;
      &:hover {
        color: $main-color-hover;
        text-decoration: underline;
      }
    }

    .pills-details {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 15px;
    }

    .line-divide {
      background-color: $input-color;
      height: 1px;
      margin-bottom: 14px;
      margin-top: 17px;
      width: 100%;
    }

    .about-details {
      display: flex;
      flex-direction: column;
      gap: 3px;
      margin-bottom: 20px;

      .item-detail {
        align-items: center;
        display: flex;
        justify-content: space-between;

        span {
          color: $black;
          font-size: $font-md;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          width: 50%;

          @include respondto(phone) {
            font-size: 1.2rem;
          }
        }

        .label-item-tag {
          box-shadow: none;
          width: 50%;
        }
      }
    }

    .links-pils {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    .actions-container {
      margin-bottom: 10px;

      .title-section {
        color: $header-title;
        font-size: $font-lg;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 0;
        text-align: right;
        @include respondto(phone) {
          font-size: 1.3rem;
        }
      }

      .icons-container {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 12px 22px;
        justify-content: center;
        @include respondto(phone) {
          gap: 1.563vw 2.865vw;
        }
      }
    }
  }
}
