@import '../../assets/styles/variables';

.map-page {
  &-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 26px;
    width: 100%;
    @media screen and (max-width: 1081px) {
      gap: 16px;
      margin-top: -7px;
    }
    .bg-white {
      border: 20px;
    }

    &__filters {
      min-width: 100%;

      .filters-wrapper {
        align-items: flex-start;
        display: flex;
        flex-direction: row !important;
        flex-wrap: wrap;
        gap: 24px;

        &.opened {
          max-height: 100%;
        }
        @media screen and (max-width: 1081px) {
          max-height: min-content !important;
          min-height: max-content !important;
        }
      }
      @media screen and (max-width: 1081px) {
        background: $white;
        border-radius: 0;
        box-shadow: 0px 27px 42.3px 0px rgba(51, 71, 91, 0.04);
        height: 100vh;
        left: 0;
        padding: 20px 16px;
        position: absolute;
        top: 0;
        width: 100vw;
        z-index: 10000;

        &--top {
          align-items: center;
          display: flex;
          justify-content: space-between;
          position: relative;
          width: 100%;
          &::before {
            background-image: url('../../assets/svg/filters-icon.svg');
            content: '';
            height: 15px;
            left: 0;
            position: absolute;
            top: 4px;
            width: 15px;
          }
          span {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            padding-left: 25px;
            text-align: left;
          }
          .close-btn-modal {
            cursor: pointer;
          }
        }
        form {
          flex-wrap: nowrap !important;
          margin-right: -10px;
          max-height: 100%;
          overflow-y: auto;
          padding: 15px 10px 80px 0 !important;
          width: calc(100% + 10px) !important;
          &::-webkit-scrollbar {
            height: 5px;
            padding-bottom: 20px;
            padding-top: 30px;
            width: 6px;
          }

          &::-webkit-scrollbar-track {
            background-color: $scroll-bar-light;
            border-radius: 3px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            box-shadow: inset 0 0 60px rgba($scroll-bar-dark, 1);
          }
        }
        .bottom-filter-btns {
          align-items: center;
          background: $white;
          bottom: 0 !important;
          box-shadow: 0px 9px 40px 0px #6f6bf926;
          display: flex;
          height: 60px !important;
          justify-content: center;
          margin-left: -15px;
          position: fixed !important;
          width: 100vw !important;
          z-index: 9999;
        }
      }
    }

    &__mobile-filters {
      align-items: center;
      background-color: $white;
      border-radius: 5px;
      box-shadow: 0px 15px 20px 0px #0000000a;
      cursor: pointer;
      display: flex;
      gap: 11px;
      height: 30px;
      justify-content: flex-end;
      min-width: 100%;
      padding: 6px;
      position: relative;
      &::before {
        background-image: url('../../assets/svg/filters-icon.svg');
        content: '';
        height: 15px;
        left: 7px;
        position: absolute;
        top: 8px;
        width: 15px;
      }

      span {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
      }
      .count {
        align-items: center;
        background-color: $main-color;
        border-radius: 50%;
        color: $white;
        display: flex;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        height: 18px;
        justify-content: center;
        line-height: 12px;
        text-align: left;
        width: 18px;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-height: 770px;
      max-width: 588px;
      min-height: 770px;
      min-width: 588px;
      padding: 0 !important;
      position: relative;

      @media screen and (max-width: 1081px) {
        border-radius: 0 !important;
        bottom: 0;
        height: 165px;
        left: 0;
        max-width: 100%;
        min-width: 100%;
        padding: 5px 0px 0 !important;
        position: absolute;
        transition: all 0.5s;
        width: 100%;
        z-index: 1;
        &.expanded {
          height: 320px;
          transition: all 0.5s;
        }
      }

      &--top {
        align-items: center;
        display: flex;
        gap: 14px;
        justify-content: flex-start;
        padding: 15px 30px 0;

        @media screen and (max-width: 1081px) {
          display: none;
        }
      }
      &--title {
        color: $black;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
      }
      &--count {
        align-items: center;
        background-color: $input-color;
        border-radius: 13px;
        color: $black;
        display: flex;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        height: 26px;
        justify-content: center;
        line-height: 18px;
        padding: 0 10px;
        text-align: left;
      }
      &--sort {
        align-items: center;
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        padding: 0 30px;

        @media screen and (max-width: 1081px) {
          display: none;
        }

        label {
          font-family: Poppins;
          font-size: 10px;
          font-weight: 300;
          text-align: left;
        }
        .form-item-group {
          max-width: 177px;
        }
      }
    }

    &__items {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      margin-right: 10px;
      max-height: 666px;
      min-height: auto;
      overflow-y: auto;
      position: relative;
      @media screen and (max-width: 1081px) {
        min-height: 100%;
      }

      &--show {
        align-items: center;
        background-color: $white;
        border-radius: 12px 12px 0 0;
        cursor: pointer;
        display: flex;
        height: 25px;
        justify-content: center;
        left: calc(50% - 29px);
        position: absolute;
        top: -25px;
        width: 55px;
        svg {
          fill: $black;
        }
      }

      &::-webkit-scrollbar {
        height: 5px;
        padding-bottom: 20px;
        padding-top: 30px;
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: $scroll-bar-light;
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        box-shadow: inset 0 0 60px rgba($scroll-bar-dark, 1);
      }
    }
    &__item {
      align-items: center;
      cursor: pointer;
      display: flex;
      gap: 15px;
      padding: 15px 0 15px 30px;
      position: relative;
      &.active {
        background-color: $azure;

        .label-item-tag {
          background-color: $white !important;
        }
      }
      @media screen and (max-width: 1081px) {
        gap: 10px;
        padding: 10px 0 10px 15px;
      }
      &::after {
        border-bottom: 1px solid #efeff0;
        bottom: 0;
        content: '';
        left: 0;
        margin: 0 18px 0 30px;
        position: absolute;
        width: calc(100% - 48px);

        @media screen and (max-width: 1081px) {
          margin: 0 10px 0 15px;
          width: calc(100% - 25px);
        }
      }
      .img-container {
        border-radius: 5px;
        height: 86px;
        min-width: 86px;
        overflow: hidden;
        width: 86px;

        @media screen and (max-width: 1081px) {
          height: 50px;
          min-width: 50px;
          width: 50px;
        }
        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      .details-container {
        display: flex;
        flex-direction: column;
        gap: 2px;

        .title-text {
          @media screen and (max-width: 1081px) {
            display: -webkit-box;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            text-align: left;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
        .price-text {
          @media screen and (max-width: 1081px) {
            font-family: Poppins;
            font-size: 13px;
            font-weight: 500;
            line-height: 19.5px;
            text-align: left;
          }
        }
        .locality-text {
          color: $black;
          font-family: Poppins;
          font-size: 13px;
          font-weight: 300;
          line-height: 12px;
          padding-left: 17px;
          position: relative;
          text-align: left;
          &::before {
            background-image: url('../../assets/svg/location.svg');
            content: '';
            height: 12px;
            left: 0;
            position: absolute;
            top: 0;
            width: 12px;
          }
          @media screen and (max-width: 1081px) {
            display: none;
          }
        }
        .item-info-tags {
          align-items: center;
          display: flex;
          gap: 5px;
          padding: 4px 0;
        }
        .label-item-tag {
          background-color: $input-color;
          border-radius: 8px;
          font-family: Poppins;
          font-size: 12px;
          font-weight: 300;
          line-height: 12px;
          min-height: 24px;
          padding: 4px 8px;
          text-align: left;
          width: max-content;
          &:nth-child(n + 4) {
            display: none;
          }
          @media screen and (max-width: 1081px) {
            border-radius: 4px;
            font-family: Poppins;
            font-size: 10px;
            font-weight: 300;
            line-height: 15px;
            max-height: 15px;
            min-height: 15px;
            padding: 0 8px;
            text-align: left;
          }
        }
      }
    }

    &__map {
      height: 100% !important;
      max-height: 770px;
      max-width: calc(100% - 614px);
      min-width: calc(100% - 614px);
      width: calc(100% - 614px);

      @media screen and (max-width: 1081px) {
        margin-left: -15px;
        max-width: 100vw;
        min-width: 100vw;
        width: 100vw;
      }

      &--wrapper {
        height: 100%;
        position: relative;
        width: 100%;
        @media screen and (max-width: 1081px) {
          position: inherit;
        }
      }
      .leaflet-div-icon {
        background: none;
        border: none;

        .point {
          align-items: flex-start;
          background-image: url(../../assets/svg/bg-marker.svg);
          background-repeat: no-repeat;
          background-size: 100% 41px;
          color: #ffffff;
          display: flex;
          font-family: Poppins;
          font-size: 13px;
          font-weight: 500;
          height: 41px;
          justify-content: center;
          line-height: 19.5px;
          min-width: 64px;
          padding-top: 7px;
          width: max-content;

          &.active {
            background-image: url(../../assets/svg/bg-marker-active.svg);
          }
        }
      }
      .leaflet-popup-close-button,
      .leaflet-popup-tip-container {
        display: none;
      }
      .leaflet-popup {
        width: 281px;
      }
      .leaflet-popup-content {
        margin: 0;
        padding: 20px;
        width: 281px !important;
      }
      .point__item {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        min-width: 100%;

        .title-text {
          border-bottom: 1px solid #0000001a;
          color: $black;
          font-family: Poppins;
          font-size: 13px;
          font-weight: 500;
          line-height: 19.5px;
          padding-bottom: 10px;
          text-align: left;
          width: 100%;
        }
        .img-container {
          border-radius: 10px;
          height: 50px;
          overflow: hidden;
          width: 50px;

          img {
            height: 100%;
            object-fit: cover;
            width: 100%;
          }
        }

        .price-text {
          color: $black;
          font-family: Poppins;
          font-size: 13px;
          font-weight: 500;
          line-height: 19.5px;
          text-align: left;
        }
        .details-container {
          display: flex;
          flex-direction: column;
          gap: 2px;

          .item-info-tags {
            align-items: center;
            display: flex;
            gap: 5px;
            padding: 4px 0;
          }
          .label-item-tag {
            background-color: $input-color;
            border-radius: 8px;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 300;
            line-height: 12px;
            min-height: 24px;
            padding: 4px 8px;
            text-align: left;
            width: max-content;
          }
        }
      }
      .leaflet-container {
        border-radius: 20px;
        height: 100% !important;
        min-width: 100%;
        overflow: hidden;
        width: 100%;
        z-index: 0;

        @media screen and (max-width: 1081px) {
          border-radius: 0;
        }

        .leaflet-marker-icon {
          min-height: max-content !important;
          width: auto !important;
          z-index: 999;
        }
      }

      .map-page-section__modal {
        background: $white;
        border-radius: 20px;
        box-shadow: 0px 27px 42.3px 0px rgba(51, 71, 91, 0.04);
        height: 100%;
        left: 0;
        min-height: 100%;
        padding: 25px 10px 25px 25px;
        position: absolute;
        top: 0;
        width: 334px;
        z-index: 3;
        @media screen and (max-width: 1081px) {
          height: 100vh;
          padding: 16px;
          width: 100vw;
          z-index: 9999;
        }

        .back-btn__wrapper {
          align-items: center;
          background: $white;
          bottom: 0;
          box-shadow: 0px 9px 40px 0px #6f6bf926;
          display: flex;
          height: 60px;
          justify-content: center;
          left: 0;
          margin-left: -15px;
          position: fixed;
          width: 100vw;
          .back-btn {
            align-items: center;
            background-color: $white;
            border: 1px solid $main-color;
            border-radius: 10px;
            color: $main-color;
            display: flex;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 400;
            height: 36px;
            justify-content: center;
            line-height: 18px;
            text-align: center;
            width: 137px;
          }
        }
        &--content {
          display: flex;
          flex-direction: column;
          max-height: calc(100% - 100px);
          overflow-y: auto;
          padding-right: 15px;
          &::-webkit-scrollbar {
            height: 5px;
            padding-bottom: 20px;
            padding-top: 30px;
            width: 6px;
          }

          &::-webkit-scrollbar-track {
            background-color: $scroll-bar-light;
            border-radius: 3px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            box-shadow: inset 0 0 60px rgba($scroll-bar-dark, 1);
          }
          @media screen and (max-width: 1081px) {
            max-height: 100%;
            padding: 0 0 100px;
          }
        }
        &--top {
          color: $black;
          font-family: Poppins;
          font-size: 15px;
          font-weight: 500;
          line-height: 22.5px;
          margin-bottom: 21px;
          padding-right: 70px;
          position: relative;
          text-align: left;

          .close-btn-modal {
            cursor: pointer;
            height: 46px;
            position: absolute;
            right: 0;
            top: -10px;
            width: 46px;
            @media screen and (max-width: 1081px) {
              height: 15px;
              right: 0;
              top: 0;
              width: 15px;
            }
          }
        }
        .item-info-tags {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          justify-content: flex-start;
          margin: 13px 0 20px;
        }
        .label-item-tag {
          border-radius: 8px;
          max-height: 24px;
          max-width: max-content;
          min-height: 24px;
          padding: 0 8px;
        }
        .description-text {
          color: $black;
          font-family: Poppins;
          font-size: 12px;
          font-weight: 300;
          line-height: 18px;
          margin-top: 16px;
          text-align: left;
        }
        .price-text {
          color: $black;
          font-family: Poppins;
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          margin-top: 30px;
          text-align: left;
        }

        .locality-text {
          color: $black;
          font-family: Poppins;
          font-size: 13px;
          font-weight: 300;
          line-height: 12px;
          padding-left: 17px;
          position: relative;
          text-align: left;
          &::before {
            background-image: url('../../assets/svg/location.svg');
            content: '';
            height: 12px;
            left: 0;
            position: absolute;
            top: 0;
            width: 12px;
          }
        }
      }
      .cluster-icon {
        align-items: center;
        background-color: #425b75;
        border-radius: 50%;
        color: $white;
        display: flex;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 700;
        justify-content: center;
        line-height: 21px;
        min-height: 36px;
        min-width: 36px;
        outline: 5px solid #1d195640;
      }
      .radius-dropdown {
        align-items: flex-start;
        background-color: #425b75;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        height: 40px;
        justify-content: flex-start;
        left: 40px;
        position: absolute;
        top: 25px;
        width: 208px;
        z-index: 2;

        @media screen and (max-width: 1081px) {
          border-radius: 7px;
          left: 16px;
          top: 16px;
        }
        &.opened {
          border-radius: 10px 10px 0 0;
          @media screen and (max-width: 1081px) {
            border-radius: 7px 7px 0 0;
          }
        }
        &__value {
          align-items: center;
          color: $white;
          display: flex;
          font-family: Poppins;
          font-size: 12px;
          font-weight: 700;
          line-height: 18px;
          min-height: 40px;
          position: relative;
          text-align: left;
          width: 100%;

          &::before {
            background-image: url('../../assets/svg/radius.svg');
            content: '';
            height: 18px;
            left: 15px;
            position: absolute;
            top: 11px;
            width: 18px;
          }
        }
      }
    }

    .filter-container {
      background: $white;
      border-radius: 25px;
      box-shadow: 0px 27px 42.3px 0px rgba($black, 0.04);
      padding: 20px;

      form {
        align-items: flex-start;
        display: flex;
        flex-direction: row !important;
        flex-wrap: wrap;
        gap: 24px;
        min-height: 115px;
        padding-right: 200px;
        position: relative;
        width: 100%;
        @include respondto(laptop13) {
          flex-direction: column !important;
          gap: 17px;
        }
        .form-item {
          width: 177px;
        }
        .form-item-group {
          label {
            font-size: 12px;
            margin-bottom: 5px;
          }
        }
        .form-item-control {
          border-radius: 5px;
          font-size: $font-sm;
          line-height: 16px;
          min-height: 30px;
          padding: 5px 15px;
        }
        .date-column {
          gap: 0 !important;
          position: relative;
        }
        .react-datepicker-popper {
          display: none !important;
        }
        .range-calendar {
          position: absolute;

          right: 0;
          top: 50px;
          z-index: 8;
        }
        .rdrDateRangePickerWrapper {
          box-shadow: 0px 27px 42.3px 0px rgba($black, 0.04);
        }
        .flex-input {
          display: flex;
          gap: 6px;
          max-width: 177px;
          min-width: 177px;
          @media screen and (max-width: 1081px) {
            max-width: 100%;
            min-width: 100%;
          }
          .form-item-group {
            max-width: calc((100% - 6px) / 2);
            min-width: calc((100% - 6px) / 2);
          }
        }
        .form-group {
          @media screen and (max-width: 1081px) {
            max-width: 100%;
            min-width: 100%;
          }
        }
        .form-item-group {
          max-width: 177px;
          min-width: 177px;
          @media screen and (max-width: 1081px) {
            max-width: 100%;
            min-width: 100%;
          }
          .form-item-control-select {
            border-radius: 5px;
            font-size: $font-sm;
            min-height: 28px;
            .react-select__indicator {
              padding: 4px;
            }
            .react-select__placeholder {
              font-size: $font-sm;
            }
          }
        }
      }
      .bottom-filter-section {
        position: absolute;
        right: 0;
        top: 17px;
      }
      .bottom-filter-btns {
        bottom: 17px;
        display: flex;
        flex-direction: row;
        gap: 20px;
        position: absolute;
        right: 0;
      }
      .button-blue {
        padding: 0 13px;
        .btn-blue-spinner {
          margin-left: 0;
        }
      }
      .more-btn {
        padding: 0 5px 0 13px;
        span {
          padding-right: 34px;
          position: relative;
          &::after {
            background-image: url(../../assets/svg/arrow-orange.svg);
            content: '';
            height: 20px;
            position: absolute;
            right: 0;
            top: 5px;
            width: 20px;
          }
        }
      }
      .reset-btn:hover {
        span::after {
          background-image: url(../../assets/svg/arrow-white.svg);
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  }
}
