@import '../../assets/styles/variables';

img {
  max-width: 100%;
}
.ads {
  &-form-container {
    &-left {
      z-index: 2;
      @include respondto(laptop13) {
        width: 100% !important;
      }
      .form-item {
        margin-bottom: 15px;
        @include respondto(largeDesktopSm) {
          margin-bottom: 0.625vw;
        }
        .link {
          cursor: pointer;
          width: max-content;
          &:hover {
            text-decoration: underline;
          }
        }
        @include respondto(phone) {
          margin-bottom: 10px;
        }
      }
      .heading-block {
        margin-bottom: 2.5rem;
        @include respondto(largeDesktopSm) {
          margin-bottom: 1.302vw;
        }
      }
    }
    &-bottom {
      margin-top: 21px !important;
      @include respondto(largeDesktopSm) {
        margin-top: 1.094vw !important;
      }
    }
    &-map {
      z-index: 1;
      @include respondto(laptop13) {
        width: 100% !important;
      }
      @include respondto(phone) {
        text-align: center;
      }

      .map-localizare {
        border-radius: 20px;
        height: 511px;
        max-width: 100%;
        overflow: hidden;
        width: 100%;
        @include respondto(largeDesktopSm) {
          border-radius: 1.042vw;
          height: 26.615vw;
        }
        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
        .leaflet-container {
          height: 511px;
          max-width: 100%;
          width: 100%;
          @include respondto(largeDesktopSm) {
            height: 26.615vw;
          }
          .leaflet-marker-icon {
            width: auto !important;
          }
        }
      }
      &__nav-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        width: 100%;
        @include respondto(largeDesktopSm) {
          gap: 0.781vw;
        }
        @include respondto(phone) {
          flex-direction: column;
          justify-content: center;
        }
        .button-md {
          @include respondto(phone) {
            margin-left: auto;
            margin-right: auto;
          }
          &.button-blue {
            min-width: 16.5rem;
            @include respondto(largeDesktopSm) {
              min-width: 8.594vw;
            }
            @include respondto(phone) {
              min-width: 223px;
            }
          }
        }
      }
    }
    .without-bg {
      box-shadow: none;
      padding: 28px 0 24px;
      @include respondto(largeDesktopSm) {
        padding: 1.4583vw 0 1.25vw;
      }
      .flex-btns {
        justify-content: flex-start;
        width: 100%;
        @include respondto(laptop13) {
          justify-content: center;
        }
      }
      .heading-block {
        font-size: $font-md;
        font-weight: 400;
      }
      button {
        font-size: $font-md;
        font-weight: 600;
        height: 34px;
        line-height: 24px;
        min-width: max-content;
      }
    }
  }
}
