@import '../../assets/styles/variables';

.item-alert {
  gap: 24px;
  @include respondto(largeDesktopSm) {
    gap: 1.25vw;
  }
  @include respondto(laptop13) {
    flex-direction: column;
    gap: 15px;
  }

  .line-border {
    @include respondto(laptop13) {
      margin: 5px 0;
    }
  }

  .list-item-content {
    align-items: center;
    display: flex;
    flex-direction: row;
    @include respondto(laptop13) {
      flex-direction: column;
      gap: 10px;
    }

    .column-item {
      padding: 0 30px;
      @include respondto(largeDesktopSm) {
        padding: 0 1.563vw;
      }
      @include respondto(laptop13) {
        padding: 0;
      }
      &:first-child {
        padding-left: 0;
        width: 12%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }

      &:nth-child(2) {
        width: 24.5%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(3) {
        width: 15%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(4) {
        width: 18%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
    }

    .info-nr {
      color: $black;
      font-size: $font-md;
      font-weight: 400;
      line-height: normal;
    }

    .info-title {
      color: $black;
      font-size: $font-md;
      font-weight: 400;
      line-height: normal;
    }

    .info-anounces-nr {
      color: $main-color;
      font-size: $font-md;
      font-weight: 400;
      line-height: normal;
      transition: 0.3s ease-in-out;

      &:hover {
        color: $main-color-hover;
        text-decoration: underline;
      }
    }

    .info-date {
      color: $black;
      font-size: $font-md;
      font-weight: 400;
      line-height: normal;
    }

    .info-icons {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: auto;
      @include respondto(largeDesktopSm) {
        gap: 0.521vw;
      }
      @include respondto(laptop13) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
