@import '../../assets/styles/variables';

.filter-activity-list {
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  form {
    @include respondto(onlyDesktop) {
      display: grid !important;
      grid-template-columns: 3% 10.5% 12.44% 12.15% 14.75% 12.92% 12.92% 12.92% 8.3%;
      width: 100%;
    }

    @include respondto(laptop13) {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .form-check-input {
      margin-right: 36px;
    }
  }
  #formGridCheckbox {
    display: flex;
    .mobile-text {
      font-weight: 400;
    }
  }
  .date-column {
    gap: 0 !important;
    position: relative;
  }
  .react-datepicker-popper {
    display: none !important;
  }
  .range-calendar {
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 8;

    @include respondto(laptop13) {
      bottom: 80px;
      top: inherit;
    }
  }
  .rdrDateRangePickerWrapper {
    box-shadow: 0px 27px 42.3px 0px rgba($black, 0.04);
  }
  .bottom-filter-section {
    align-items: flex-end;
    display: none;
    gap: 15px;
    justify-content: space-between;
    width: 100%;

    @include respondto(laptop13) {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 100%;
    }

    .form-line-section {
      display: flex;
      gap: 25px;

      @include respondto(largeDesktopSm) {
        min-width: 39%;
      }

      @include respondto(laptop13) {
        gap: 10px;
        margin-bottom: 20px;
        width: 100%;
      }
    }

    .form-btns-section {
      align-items: center;
      display: flex;
      gap: 20px;

      @include respondto(laptop13) {
        gap: 10px;
        width: 100%;
      }
      .reset-btn {
        height: 30px;
        @include respondto(laptop13) {
          min-width: auto;
          width: 50% !important;
        }
      }
      .filter-btn {
        align-items: center;
        border-radius: 4px;
        display: flex;
        font-size: $font-sm;
        height: 30px;
        justify-content: center;
        position: relative;
        width: 223px;

        @include respondto(laptop13) {
          width: 50% !important;
        }
      }
    }
  }
  .filter-item {
    max-width: unset !important;
    padding: 0 28px !important;

    @include respondto(largeDesktopSm) {
      padding: 0 1.406vw !important;
    }
    &:last-of-type {
      padding-right: 20px !important;
      @include respondto(largeDesktopSm) {
        padding-right: 1.042vw !important;
      }
    }

    .filter-btn {
      align-items: center;
      background: $main-color;
      border-radius: 4px;
      display: flex;
      font-size: $font-sm;
      gap: 3px;
      justify-content: center;
      margin-top: 2.6rem;
      min-height: 30px;
      width: 100%;
    }
    &:last-of-type {
      @include respondto(laptop13) {
        display: none;
      }
    }
    .flex-column {
      gap: 10px;
    }
    &.arrows-label-icon {
      label {
        cursor: pointer;
      }
    }
    label {
      &::after {
        display: none;
      }
    }

    &:nth-of-type(2) {
      label {
        &::after {
          display: block;
        }
      }
    }

    &:nth-of-type(3) {
      label {
        &::after {
          display: block;
        }
      }
    }

    &:nth-of-type(8) {
      label {
        &::after {
          display: block;
        }
      }
    }
  }
  .form-item-control-select {
    .react-select__indicator {
      img {
        height: 2.4rem;
        width: 2.4rem;
      }
    }
  }
  .search-container {
    .form-item-group-block {
      position: relative;

      &::before {
        background-image: url('../../assets/svg/Search.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        height: 24px;
        position: absolute;
        right: 0.5rem;
        top: 0.9rem;
        width: 24px;
      }
    }
  }
}

@include respondto(laptop13) {
  .mobile-select-all {
    align-items: center !important;
    color: $black !important;
    display: flex !important;
    font-size: 1.5rem !important;
    font-weight: 500 !important;
    justify-content: center !important;
  }
}
