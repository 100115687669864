@import '../../assets/styles/variables';
.modal-backdrop.show {
  &:has(.announce-search-modal.modal.show) {
    --bs-backdrop-zindex: 99999;
  }
}

.announce-search-modal {
  --bs-modal-zindex: 999999 !important;
  align-items: center;
  display: flex !important;
  justify-content: center;
  margin: 0 auto;

  .modal-dialog {
    --bs-modal-width: 1050px;
    bottom: auto !important;
    top: 0;
    width: 100%;
    @include respondto(phone) {
      max-width: 100%;
    }
  }

  @include respondto(phone) {
    padding: 0 !important;
  }

  .modal-header {
    border-bottom: none;
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 2.4rem;
    margin-bottom: 2.4rem;
    padding: 0;
    text-align: center;
    @include respondto(phone) {
      margin-bottom: 2rem;
    }
    span {
      width: 100%;
      svg {
        height: 2.4rem;
        margin-right: 8px;
        width: 2.4rem;
      }
    }
  }
  .modal-body {
    --bs-modal-padding: 0;
  }
  .modal-dialog {
    max-height: 100%;
  }
  .modal-content {
    background-color: $white;
    border-radius: 20px;
    padding: 42px 30px 15px;
    @include respondto(desktopXLg) {
      margin-bottom: 50px;
      margin-top: 180px;
    }
    @include respondto(phone) {
      margin-top: 100px;
      padding: 30px 15px 15px;
    }
  }

  .close-btn-modal {
    align-items: center;
    background: $white;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: 5px;
    top: 5px;
    transition: 0.3s ease-in-out;
    width: 40px;
    &:hover {
      background: $main-color;
    }
  }
}
.announce-search-modal__content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  .reports-cards-container {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .notification-item {
    align-items: center;
    cursor: pointer;
    justify-content: center;
    &.active {
      outline: 2px solid $main-color;
    }
    p {
      min-height: auto;
    }
  }
}
