@import '../../assets/styles/variables';

.agents-list-filter {
  .form-item,
  .alphabet-wrapper {
    margin-bottom: 15px;
  }

  #formGridCheckbox {
    .form-item-group-label {
      display: none;

      @include respondto(laptop13) {
        color: $black;
        display: flex;
        font-weight: 500;
        margin: 0;
      }
    }

    @include respondto(laptop13) {
      align-items: center;
      display: flex;
    }
  }

  .form-check-input {
    margin-right: 38px !important;
    @include respondto(largeDesktopMd) {
      margin-right: 1.9792vw !important;
    }
  }

  .form-item-control {
    @include respondto(largeDesktopSm) {
      border-radius: 4px;
    }
  }

  .input-center {
    padding: 0.7rem 0;
    @include respondto(largeDesktopSm) {
      padding: 0.3646vw 0;
    }
  }

  form {
    display: grid !important;
    grid-template-columns: 4% 33% 11% 11% 11% 11% 11% 5.5%;

    @include respondto(laptop13) {
      display: flex !important;
      flex-direction: column;
      gap: 10px;
    }

    .form-item-group {
      @include respondto(laptop13) {
        width: 100%;
      }
    }
  }

  .filter-item {
    padding: 0 4.1rem !important;

    @include respondto(onlyDesktop) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .form-item-group {
        label {
          margin-bottom: 0;
        }
      }
    }

    @include respondto(largeDesktopSm) {
      padding: 0 2.1354vw !important;
    }

    @include respondto(laptop13) {
      border-right: none !important;
      max-width: 100% !important;
      padding: 0 !important;
    }

    &:nth-of-type(2) {
      max-width: 100%;
      padding-left: 0 !important;
    }

    &:nth-child(9) {
      border-right: none;
    }

    .search-container {
      @include respondto(onlyDesktop) {
        align-items: center;
        display: flex;
        flex-direction: row;
        position: relative;
      }

      .form-item-group-label {
        margin: 0 2rem 0 0;
        @include respondto(largeDesktopSm) {
          margin-right: 1.042vw;
        }
      }

      .form-item-group {
        visibility: visible;
        width: 89%;

        @include respondto(desktopLg) {
          width: 100%;
        }
      }

      .form-item-group-block {
        position: relative;
        visibility: visible;
      }

      input {
        padding-left: 115px;
        padding-right: 50px;
        @include respondto(largeDesktopSm) {
          text-overflow: ellipsis;
        }
      }

      .search-before {
        color: $black;
        font-size: $font-sm;
        font-weight: 300;
        left: 15px;
        line-height: normal;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .icon {
        background-color: transparent;
        position: absolute;
        right: 10px;
        top: 2px;
      }
    }

    &:last-child {
      padding-right: 0 !important;
    }

    label {
      &::after {
        display: none;
      }
    }

    .form-item-group-block {
      visibility: hidden;

      &.undefined {
        display: none;
      }
    }

    .form-item-control-select {
      display: none;
    }

    .form-item-group {
      text-align: center;

      @include respondto(laptop13) {
        display: none;
      }
    }

    p {
      color: $black;
      font-size: 1.3rem;
      font-weight: 500;
      line-height: normal;
      text-align: right;

      @include respondto(laptop13) {
        display: none;
      }
    }
  }

  .bottom-filter-section {
    align-items: flex-end;
    display: flex;
    gap: 15px;
    justify-content: space-between;
    width: 100%;

    @include respondto(largeDesktopSm) {
      gap: 0.781vw;
    }

    @include respondto(laptop13) {
      align-items: flex-start;
      flex-direction: column;
      gap: 10px;
      max-width: 100%;
    }

    .form-line-section {
      display: flex;
      gap: 25px;

      @include respondto(largeDesktopSm) {
        gap: 1.302vw;
        min-width: 39%;
      }

      @include respondto(laptop13) {
        gap: 10px;
        margin-bottom: 20px;
        width: 100%;
      }
    }

    .form-btns-section {
      align-items: center;
      display: none;
      gap: 10px;
      width: 100%;

      @include respondto(laptop13) {
        display: flex;
      }

      .reset-btn {
        min-width: auto;
        width: 50% !important;
      }

      .filter-btn {
        align-items: center;
        border: 1px solid $main-color;
        border-radius: 4px;
        display: flex;
        font-size: $font-sm;
        height: 30px;
        justify-content: center;
        position: relative;
        width: 50% !important;
      }
    }
  }
}
