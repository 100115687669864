@import '../../assets/styles/variables';

.suggest {
  align-items: center;
  background-color: $main-color;
  border-radius: 10px;
  bottom: 30px;
  cursor: pointer;
  display: flex;
  gap: 13px;
  height: 50px;
  justify-content: center;
  position: fixed;
  right: 30px;
  transition: all 0.5s;
  width: 50px;
  z-index: 9999;
  @media screen and (max-width: 768px) {
    bottom: 15px;
    right: 15px;
  }

  p {
    color: $white;
    display: none;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    transition-delay: 0.5s;
    transition: all 0.5s;
  }
  &:hover {
    transition: all 0.5s;
    width: 162px;
    p {
      display: block;
      transition-delay: 0.5s;
      transition: all 0.5s;
    }
  }
}
.suggest-modal {
  .modal-dialog {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    margin: 0 auto;
    width: 100vw;
  }
  .modal-content {
    border-radius: 20px;
  }
  .modal-body {
    padding: 40px;
    position: relative;
    @media screen and (max-width: 768px) {
      padding: 25px 15px;
    }
  }
  .close-btn-modal {
    cursor: pointer;
    height: 15px;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 15px;
    svg path {
      fill: #939393;
    }
  }
  &__form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    .form-item {
      width: 100%;
    }
    .form-item-half {
      max-width: calc((100% - 15px) / 2);
    }

    .btns-container {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;
      button {
        min-width: 149px;
      }
    }
  }
  &__top {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    width: 100%;

    p {
      color: $black;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: left;
    }
  }
}
