@import '../../assets/styles/variables';

.property {
  &-left {
    margin-right: 24px;
    padding: 29px 56px 39px;
    width: calc(33.33% - 25px);

    @include respondto(largeDesktopSm) {
      margin-right: 1.25vw;
      padding: 1.51vw 1.563vw 2.0313vw;
      width: calc(33.33% - 1.25vw);
    }

    @include respondto(laptop13) {
      margin-bottom: 3rem;
      margin-right: auto;
      width: 100%;
    }

    @include respondto(phone) {
      padding: 15px;
    }

    .heading-block {
      margin-bottom: 23px;
      @include respondto(largeDesktopSm) {
        margin-bottom: 1.1979vw;
      }
    }

    .form-block {
      display: flex;
      flex-wrap: wrap;
      gap: 12px !important;
    }

    .form-item {
      width: 100%;
      .link {
        cursor: pointer;
        width: max-content;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &-center {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-top: 5rem;
    position: relative;
    width: 100%;
    &.loading {
      min-height: 10rem;
    }
  }

  &-right {
    margin-left: 19px;
    width: calc(66.66% - 19px);
    @include respondto(largeDesktopSm) {
      margin-left: 0.99vw;
      width: calc(66.66% - 0.99vw);
    }
    @include respondto(laptop13) {
      margin-left: auto;
      width: 100%;
    }

    &.bg-blue {
      @include respondto(laptop13) {
        background: transparent;
        border-radius: 0;
      }
    }

    .block-buttons {
      margin-bottom: 26px;
      @include respondto(largeDesktopSm) {
        margin-bottom: 1.354vw;
      }
    }

    .bg-white {
      box-shadow: $block-shadow;
    }

    .map {
      border-radius: 20px;
      height: 506px;
      overflow: hidden;
      width: 100%;
      @include respondto(largeDesktopSm) {
        border-radius: 1.042vw;
        height: 26.354vw;
      }
      @include respondto(laptop13) {
        height: 34rem;
      }

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
      .leaflet-container {
        height: 658px;
        width: 100%;
        @include respondto(largeDesktopSm) {
          height: 34.271vw;
        }
        @include respondto(laptop13) {
          height: 34rem;
        }
        .leaflet-marker-icon {
          width: auto !important;
        }
      }
    }
  }
}

.block-buttons {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
  padding: 33px 37px;
  @include respondto(largeDesktopSm) {
    gap: 0.677vw;
    padding: 1.7188vw 1.9271vw;
  }
  @include respondto(laptop13) {
    justify-content: center;
    padding: 15px;
    text-align: center;
  }

  .heading-block {
    margin: 0 35px 0 0;
    @include respondto(largeDesktopSm) {
      margin: 0 1.5229vw 0 0;
    }
    @include respondto(laptop13) {
      margin: 0;
    }
  }
}

.form-item {
  p {
    color: $black;
    font-size: 1.2rem;
    line-height: 120%;
    margin-top: 9px;
    @include respondto(largeDesktopSm) {
      margin-top: 0.469vw;
    }
    @include respondto(largeDesktopMd) {
      font-size: 0.625vw;
    }
    @include respondto(laptopMd) {
      font-size: 0.764vw;
    }
  }
}

.form-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 17px;
  justify-content: center;
  margin: 30px 0;
  padding: 23px;
  width: 100%;
  @include respondto(largeDesktopSm) {
    gap: 0.885vw;
    margin: 1.563vw 0;
    padding: 1.1979vw;
  }
  .button-back {
    display: none;
    @include respondto(desktopXLg) {
      display: flex;
    }
  }
  .button-spin {
    position: relative;
    .btn-blue-spinner .spinner {
      @include respondto(laptop13) {
        border-color: $white $white $white transparent !important;
      }
    }
  }
}
