@import "../../assets/styles/variables";

.properties {
    &-head {
        display: flex;
        flex-direction: row;
        margin-bottom: 1.2rem;
        padding: 0 1.5rem;
        width: 100%;
        @include respondto(largeDesktopSm){
            margin-bottom: 0.625vw;

        }
        @include respondto(laptop13){
            display: none;
        }
        &-item {
            color: $dark-gray;
            font-size: $font-md;
            font-weight: 500;
            line-height: 1.5;
            width: 15.1%;
         
            &:nth-child(1) {
                width: 16.9rem;
                @include respondto(largeDesktopSm){
                    width: 8.802vw;
                }
            }
            &:nth-child(2) {
                padding: 0 5rem;
                width: 30%;
                @include respondto(largeDesktopSm) {
                    padding: 0 2.604vw;
                }
            }
            &:nth-child(3) {
                padding: 0 5rem 0 7.3rem;
                width: 31%;
                @include respondto(largeDesktopSm) {
                    padding: 0 2.604vw 0 3.802vw;
                }
            }
            &:nth-child(4) {
                padding: 0 1rem 0 4.2rem;
                width: 14%;
                @include respondto(largeDesktopSm) {
                    padding: 0 0.521vw 0 2.188vw;
                }
            }
            &:nth-child(5) {
                padding: 0 0 0 3.9rem;
                width: calc(25% -  16.9rem);
                @include respondto(largeDesktopSm) {
                    padding: 0 0 0 2.0313vw;
                    width: calc(25% -  8.802vw);
                }
            }
        }
    }
    &-list {
        &-item {
            display: flex;
            flex-wrap: wrap;
            font-size: $font-md;
            &.bg-blue {
                border-radius: 1.5rem;
                padding: 1.5rem;
                margin-bottom: 1.5rem;
                @include respondto(largeDesktopSm) {
                    padding: 0.781vw;
                    margin-bottom: 0.781vw;
                }
                @include respondto(laptop13) {
                    padding: 15px 11px;
                }
            }
            @include respondto(phone) {
                font-size: 1.2rem;
            }
            strong {
                font-weight: 500;
            }
            table {
                width: 100%;
                tr,
                td {
                    @include respondto(laptop13) {
                        display: block;
                        text-align: center;
                    }
                }

                th {
                    padding: .2rem .2rem .2rem 0;
                    @include respondto(largeDesktopSm){
                        padding: 0.104vw 0.104vw 0.104vw 0;
                    }
                    @include respondto(laptop13) {
                        display: block;
                        text-align: center;
                        padding: .2rem 0;
                        width: 100%;
                    }
                }
            }
            &__address,
            &__info,
            &__price,
            &__button {
                display: flex;
                flex-direction: column;
                justify-content: center;

            }
            &__image {
                width: 16.9rem;
                @include respondto(largeDesktopSm){
                    width: 8.802vw;
                }
                @include respondto(laptop13) {
                    margin-bottom: 1rem;
                    width: 100%;
                }
                .image {
                    border-radius: .7rem;
                    height: 8.1rem;
                    position: relative;
                    overflow: hidden;
                    width: 11.9rem;
        
                    @include respondto(laptop13){
                       margin: 0 auto 0;
                    }
                    img {
                        height: 100%;
                        object-fit: cover;
                        width: 100%;
                    }
                    .label-info-active-sm {
                        background: $nav-color;
                        color: $white;
                        top: 1.1rem;
                        left: 1.1rem;
                        position: absolute;
                        z-index: 2;
                        @include respondto(largeDesktopSm){
                            top: 0.5729vw;
                            left: 0.5729vw;
                        }
                    }
                }
            }
            &__address {
                border-right: 1px solid $white;
                padding: 0 5rem;
                width: 30%;
                @include respondto(largeDesktopSm){
                    padding: 0 2.604vw;
                }
                @include respondto(laptop13){
                    border-right: none;
                    padding: 0;
                    width: 100%;
                }
                .properties-list-item__content {
                    @include respondto(laptop13){
                        margin:  0 auto;
                        width: 100%;
                    }
                }
                
            }
            &__info {
                border-right: 1px solid $white;
                padding: 0 5rem 0 7.3rem;
                width: 31%;
                @include respondto(largeDesktopSm){
                    padding: 0 2.604vw 0 3.802vw;
                }
                @include respondto(laptop13){
                    border-right: none;
                    padding: 0;
                    width: 100%;
                }
                .properties-list-item__content {
                    @include respondto(laptop13){
                        margin:  0 auto;
                        width: 100%;
                    }
                }
                
            }
            &__price {
                border-right: 1px solid $white;
                padding: 0 .5rem 0 4.2rem;
                width: 14%;
                @include respondto(largeDesktopSm){
                    padding: 0  0.26vw 0 2.188vw;
                }
                @include respondto(laptop13){
                    border-right: none;
                    text-align: center;
                    padding: 0;
                    margin: 1rem auto 2rem;
                    width: 100%;
                }
                
            }
            &__button {
                padding: 0 0 0 3.9rem;
                width: calc(25% -  16.9rem);

                @include respondto(largeDesktopSm){
                    padding: 0 0 0 2.0313vw;
                    width: calc(25% -  8.802vw);
                }
                @include respondto(laptop13){
                    border-top: 1px solid $white;
                    padding: 1rem 0 0 0;
                    width: 100%;
                }
                .properties-list-item__content {
                    gap: 1rem;
                    @include respondto(largeDesktopSm){
                        gap: 0.521vw;
                    }
                    @include respondto(laptop13) {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                    }  
                }
            }
        }
    }
}