@import '../../assets/styles/variables';

.notifications-cards-container {
  gap: 15px;
  margin-bottom: 20px;

  @include respondto(laptop13) {
    flex-wrap: wrap;
    margin-bottom: 23px;
  }

  @include respondto(phone) {
    gap: 8px;
  }

  .notification-item {
    align-items: flex-start;
    background: $white;
    border-radius: 20px;
    // box-shadow: $block-shadow;
    display: flex;
    flex-direction: column;
    max-width: 25rem;
    padding: 20px;
    transition: all 0.3s ease-in-out;
    width: calc(33.33% - 24px);

    @include respondto(phone) {
      width: calc(33.33% - 8px);
    }
    @include respondto(phoneSm) {
      border-radius: 20px;
      max-width: 100%;
      padding: 15px 20px;
      width: calc(50% - 4px);
    }

    svg {
      height: 3rem;
      margin-bottom: 12px;
      max-width: 3rem;
      transition: all 0.3s ease-in-out;

      @include respondto(phone) {
        height: 2rem;
        margin-bottom: 5px;
        width: 2rem;
      }
      path {
        transition: all 0.3s ease-in-out;
        &:first-child {
          fill: $black;
        }
        &:not(:first-child) {
          fill: $white;
        }
      }
    }

    span {
      color: $black;
      display: block;
      font-size: 3.8rem;
      font-weight: 600;
      line-height: 1.1;
      margin-bottom: 5px;
      transition: all 0.3s ease-in-out;

      @include respondto(phone) {
        font-size: 3.8rem;
      }
    }

    p {
      color: $black;
      font-size: 1.3rem;
      font-weight: 400;
      line-height: normal;
      transition: all 0.3s ease-in-out;
      @include respondto(phone) {
        max-width: 104px;
      }
    }

    &:nth-child(5) {
      @include respondto(laptop13) {
        display: none;
      }
    }

    // @include respondto(laptop13) {
    //     max-width: 100%;
    // }

    &:hover {
      background-color: $light-button-color;

      //   p,
      //   span {
      //     color: $white;
      //   }

      //   svg {
      //     path {
      //       &:first-child {
      //         fill: $white;
      //       }
      //       &:not(:first-child) {
      //         fill: $main-color;
      //       }
      //     }
      //   }
    }
  }
}
