@import '../../assets/styles/variables';

.section-container {
  display: flex;
  flex-wrap: wrap;
}

.scan__wrapper {
  justify-content: center;
  min-height: 90vh;
  .button-blue.button-md {
    position: relative;
  }
}
