@import '../../assets/styles/variables';

.filter-list-email {
  .form-item,
  .alphabet-wrapper {
    margin-bottom: 15px;
  }

  #formGridCheckbox {
    .form-item-group-label {
      display: none;

      @include respondto(laptop13) {
        color: $black;
        display: flex;
        font-weight: 500;
        margin: 0;
      }
    }

    @include respondto(laptop13) {
      align-items: center;
      display: flex;
    }
  }

  form {
    display: grid;
    grid-template-columns: 3% 11.9% 19.81% 14.2% 17% 22% 12%;
    width: 100%;

    @include respondto(laptop13) {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .form-check-input {
      margin-right: 43px;
      @include respondto(largeDesktopSm) {
        margin-right: 2.2396vw;
      }
    }
  }

  .filter-item {
    max-width: unset !important;
    padding: 0 25px !important;

    @include respondto(largeDesktopMd) {
      padding: 0 1.302vw !important;
    }

    @include respondto(laptopMd) {
      padding: 0 1.302vw !important;
    }

    .form-item-group-block {
      display: none;
    }

    .form-item-control-select {
      display: none;
    }
  }
}
