@import '../../assets/styles/variables';

.modal-backdrop:has(+ .doc-modal.show) {
  --bs-backdrop-zindex: 9999 !important;
}

.doc-modal {
  --bs-modal-bg: $azure;
  --bs-modal-zindex: 99999999 !important;

  &.show {
    align-items: center;
    display: flex !important;
    justify-content: center;
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-footer {
    border-top: none;
    justify-content: center;

    .download-btn {
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  .modal-dialog {
    max-width: 1200px;
    max-height: 100vh;
    width: 100%;
  }

  .modal-title {
    color: $black;
    font-size: 2.2rem;
    font-weight: 500;

  }

  .close-modal {
    background-color: transparent;
    border: none;
    border-radius: 50%;
    height: 40px;
    position: absolute;
    right: 5px;
    top: 5px;
    transition: 0.3s ease-in-out;
    width: 40px;
    z-index: 1;

    svg {
      height: 20px;
      width: 20px;
    }

    &:hover {
      svg {
        fill: $main-color;
      }
    }
  }

  .modal-content {
    border: none;
    border-radius: 2rem;
    padding: 2rem;
    text-align: center;
    @include respondto(phone) {
      padding: 1rem 0;
    }
  }

}
