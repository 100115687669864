@import '../../assets/styles/variables';

.modal-body {
  .filter-list-email {
    .form-item-group-label {
      display: none;
    }

    #id-input {
      .form-item-group-label {
        display: inline-block;
        margin-top: 15px;
      }
    }

    form {
      gap: 0;
    }
  }
}
