@import "../../assets/styles/variables";

.flex-property-updates {
    gap: 18px;
    justify-content: space-between;
    max-height: 940px;
    @include respondto(largeDesktopSm) {
        gap: 0.938vw;
        max-height: 48.958vw;
    }
    @include respondto(laptop13) {
        flex-direction: column;
        max-height: 100%;
    }

    @include respondto(phone) {
        padding-bottom: 8rem;
    }

    .swiper-dashboard-container {
        max-height: 75%;
        @include respondto(laptop13) {
            flex-direction: row !important;
        }
        @include respondto(phone) {
            flex-wrap: wrap !important;
            max-height: inherit;
        }
        .swiper {
            @include respondto(laptop13) {
                max-width: calc(100% - 200px);

            }
            @include respondto(phone) {
               max-width: 100%;
               order: 1;
            }
            .swiper-slide {
                @include respondto(phone) {
                    height: auto;
                }  
            }
        }
        .slider__prev {
            @include respondto(phone) {
                order: 2;
                margin-right: 0;
            } 
        }
        .slider__next {
            @include respondto(phone) {
                order: 3;
                margin-left: 0;
            } 
        }
    }
}