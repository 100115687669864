@import '../../assets/styles/variables';

.contact-list-filter {
  .form-check-input {
    margin-right: 60px !important;
    @include respondto(largeDesktopSm) {
      margin-right: 3.125vw;
    }
  }

  form {
    display: grid;
    grid-template-columns: 4% 52% 32% 11%;
    @include respondto(laptop13) {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }

  .filter-item {
    &:nth-of-type(2) {
      max-width: 100%;
      padding-right: 20%;

      @include respondto(largeDesktopSm) {
        padding-right: 8.5vw;
      }

      .search-container {
        position: relative;
        font-size: 1.3rem;

        input {
          font-size: 1.2rem;
          padding-left: 145px;
          padding-right: 50px;
          min-height: 30px;
          // @include respondto(largeDesktopSm) {
          //   padding-left: 7.552vw;
          //   padding-right: 2.604vw;
          // }
        }

        .form-item-group-block {
          position: relative;
        }

        span {
          color: $black;
          font-size: 1.3rem;
          font-style: normal;
          font-weight: 300;
          left: 25px;
          line-height: normal;
          position: absolute;
          top: 5px;
          z-index: 2;
        }

        .icon {
          background-color: transparent;
          border: none;
          height: 15px;
          position: absolute;
          right: 10px;
          top: 8px;
          width: 15px;
            
          // @include respondto(largeDesktopSm) {
          //   height: 1.25vw;
          //   right: 0.521vw;
          //   top: 50%;
          //   transform: translate(0, -50%);
          //   width: 1.25vw;
          // }
          img,
          svg {
            width: 15px;
            height: 15px;

          }
        }
      }
    }

    &:nth-of-type(3) {
      max-width: 100%;
      padding-left: 40%;
      padding-right: 69px;

      @include respondto(largeDesktopSm) {
        padding-left: 10.47vw;
        padding-right: 3.6vw;
      }
    }

    &:nth-of-type(4) {
      max-width: 100%;
      padding-left: 70px;

      @include respondto(largeDesktopSm) {
        padding-left: 3.646vw;
      }
    }

    label {
      &::after {
        display: none;
      }
    }

    p {
      color: $black;
      font-size: 1.3rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
