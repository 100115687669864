@import '../../assets/styles/variables';

.alocated-slots-container {
  width: 100%;

  .table-item {
    background: $white;
    border-radius: 25px;
    box-shadow: 0 27px 42.3px 0 rgba($black, 0.04);
    margin-bottom: 20px;
    overflow: hidden;
    width: 100%;
    @include respondto(largeDesktopSm) {
      border-radius: 1.302vw;
      margin-bottom: 1.042vw;
    }
    .slots-header {
      align-items: center;
      justify-content: space-between;
      padding: 15px;

      @include respondto(phone) {
        flex-wrap: wrap;
        justify-content: center;
        padding: 20px;
        text-align: center;
      }

      .title-section {
        font-size: 2rem;
        font-weight: 700;

        @include respondto(phone) {
          font-size: 1.4rem;
          margin: 0 auto 1.3rem 0;
        }
      }

      .alocated-filters {
        align-items: center;
        display: flex;
        gap: 12px;
        justify-content: flex-end;
        max-width: 65%;
        width: 100%;

        @include respondto(phone) {
          gap: 10px;
          max-width: 100%;
          width: 100%;
        }
        @include respondto(phoneSm) {
          flex-direction: column;
        }

        .filter-container {
          position: relative;
          width: 100%;

          @include respondto(phone) {
            display: none;
            // width: 50%;
          }

          .form-item-control-button {
            background-color: $main-color;
            border-radius: 5px;
            height: 32px;
            position: absolute;
            right: 4px;
            top: 4px;
            width: 32px;
            z-index: 9;

            &:hover {
              background-color: $main-color-hover;
            }
          }
        }

        .form-item-control-filter {
          align-items: center;
          background: $white;
          border: 2px solid $bright-dusk;
          border-radius: 10px;
          display: flex;
          font-size: 1.3rem;
          height: 40px;
          justify-content: center;
          margin-left: auto;
          padding: 9px 5px 9px 26px;
          transition: width 0.3s ease-in-out;
          width: 152px;

          @include respondto(phone) {
            width: 100%;
          }

          &:focus {
            width: 100%;
          }
        }

        .btn-blue {
          align-items: center;
          border-radius: 7px;
          display: flex;
          font-size: 1.6rem;
          font-weight: 600;
          height: 40px;
          justify-content: center;
          line-height: 2.4rem;
          min-width: 181px;
          padding: 9px;

          @include respondto(phone) {
            min-width: 50%;
          }
          @include respondto(phoneSm) {
            width: 100%;
          }
        }
        .dropdown-brands-container {
          display: block;
          max-width: 277px;
          @include respondto(phone) {
            width: 50%;
          }
          @include respondto(phoneSm) {
            width: 100%;
          }
          // .content-select {
          //   .header-select {
          //     font-size: 1.6rem;
          //   }
          // }
        }
      }
    }

    table {
      background: $white;
      border-collapse: separate;
      border-spacing: 0;
      --bs-table-striped-bg: rgb(239 241 246);
      --bs-table-hover-bg: inherit;
      border-collapse: collapse;
      thead {
        > * > * {
          background-color: rgb(239 241 246);
        }
      }
      tbody {
        &::before {
          content: '';
          display: table-row;
          height: 10px;
        }
      }

      > tbody > tr:nth-of-type(odd) > * {
        background-color: $table-body-even-bg !important;
      }

      @include respondto(laptop13) {
        min-width: 900px;
        width: 100%;
      }

      .total-item {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 8px;
        justify-content: flex-start;
        margin: 0 auto;
        span {
          font-size: 1.2rem;
          font-weight: 600;
          height: auto !important;
          line-height: 1;
        }

        .total-value {
          align-items: center;
          background-color: $white;
          border-radius: 5px;
          display: flex;
          font-weight: 400;
          justify-content: center;
          min-height: 28px;
          min-width: 73px;
          width: fit-content;
        }
        p {
          color: $black;
          font-size: 1.2rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 0;

          @include respondto(phone) {
            font-size: 1.2rem;
          }
        }
      }

      tr {
        &:first-child {
          position: relative;
          &::after {
            background: $maingrey;
            bottom: 0;
            content: '';
            display: block;
            height: 1px;
            left: 2.4rem;
            position: absolute;
            right: 2.4rem;
            width: calc(100% - 4.8rem);
          }
        }
        th {
          border: none !important;
          color: $black;
          font-size: 1.3rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding: 10px 0;
          // text-align: center;

          &:first-child {
            border-radius: 10px 0 0 0;
            padding-left: 2.4rem;
            text-align: left;

            @include respondto(laptop13) {
              left: 0;
              position: sticky;
            }

            @include respondto(phone) {
              padding-left: 1rem;
            }
          }

          &:last-child {
            border-radius: 0 10px 0 0;
          }
        }
        &.totals-row {
          th:first-child {
            border-radius: 0 0 0 10px;
          }

          th:last-child {
            border-radius: 0 0 10px 0;
          }
        }
        &:first-child {
          th {
            span {
              display: inline-block;
              min-width: 73px;
              text-align: center;
            }
          }
        }
        td {
          border-width: 0 !important;
          color: $black;
          font-size: 1.2rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 10px 0;
          text-align: center;
          vertical-align: sub;

          &:first-child {
            border-radius: 10px 0 0 10px;
            padding-left: 2.5rem;
            text-align: left;
            @include respondto(largeDesktopSm) {
              padding-left: 1.302vw;
            }
            @include respondto(laptop13) {
              left: 0;
              position: sticky;
            }

            @include respondto(phone) {
              padding-left: 1rem;
            }
          }

          &:last-child {
            border-radius: 0 10px 10px 0;
          }

          span {
            align-items: center;
            background-color: $white;
            border-radius: 5px;
            display: flex;
            height: 28px;
            justify-content: center;
            min-width: 73px;
            width: fit-content;

            @include respondto(phone) {
              font-size: 1.2rem;
              height: 30px;
              min-width: 57px;
            }
          }

          a {
            color: $black;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            position: relative;
            transition: 0.3s ease-in-out;

            svg {
              height: 0.8rem;
              fill: $main-color;
              margin-left: 0.9rem;
              width: 0.8rem;
            }

            &:hover {
              color: $main-color-hover;
            }
          }
        }

        &:nth-child(even) span {
          background-color: $table-body-even-bg;
        }
      }
    }
  }

  .alocated-table {
    margin: 0 6px;
    @include respondto(largeDesktopSm) {
      margin: 0 0.313vw;
    }
    @include respondto(laptop13) {
      overflow: auto;
    }
  }

  .alocated-list-totals {
    padding-top: 15px;
    @include respondto(laptop13) {
      overflow: scroll;
    }

    tr {
      td {
        padding: 10px 0 !important;

        &:first-child {
          border-radius: 0 !important;
          font-weight: 500;
          min-width: 30%;
          padding-left: 4rem !important;
          vertical-align: middle;

          @include respondto(phone) {
            padding-left: 1rem !important;
          }
        }

        &:last-child {
          border-radius: 0 !important;
        }

        span {
          background-color: transparent !important;
        }
      }
    }
  }
}
