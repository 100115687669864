@import '../../assets/styles/variables';

.contact-filter-list-container {
  gap: 15px;
  justify-content: space-between;
  z-index: 1;
  .list-wrapper {
    width: 100%;
  }

  .contact-list-filter {
    @include respondto(laptop13) {
      display: none;
    }
  }
  > .alphabet-wrapper {
    @include respondto(laptop13) {
      display: none;
    }
  }
}

.main-wrapper {
  .contacts-filter-container {
    z-index: 2;
    @include respondto(laptop13) {
      display: none;
    }
  }

  .add-btn {
    z-index: 9;
  }
}
