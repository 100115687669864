@import '../../assets/styles/variables';

.actions-container {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .action-item {
    align-items: center;
    background: $white;
    border: 1px solid rgba($main-color, 0.33);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    width: 100%;

    p {
      color: $black;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    img,
    svg {
      height: 20px;
      width: 20px;
    }
  }
}
