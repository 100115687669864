@import '../../assets/styles/variables';

.sugested-activity-container {
  background: $azure;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  height: max-content;
  max-width: 734px;
  padding: 30px;
  width: 100%;
  @include respondto(largeDesktopSm) {
    border-radius: 1.302vw;
    gap: 1.302vw;
    max-width: 38.229vw;
    padding: 1.563vw;
  }

  @include respondto(laptop13) {
    background: transparent;
    border-radius: 0;
    max-width: 100%;
    padding: 0;
  }

  .sugested-item {
    background: $white;
    border-radius: 15px;
    box-shadow: $block-shadow;
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    padding: 15px;

    @include respondto(largeDesktopSm) {
      border-radius: 0.781vw;
      padding: 0.781vw;
    }

    @include respondto(phone) {
      padding: 15px 11px;
    }

    h4 {
      color: $black;
      font-size: $font-lg;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 0;
    }

    .price {
      color: $black;
      font-size: $font-sm;
      font-style: normal;
      font-weight: 600;
    }

    .remove-btn {
      align-items: center;
      background-color: $main-color;
      border-radius: 50%;
      display: flex;
      height: 30px;
      justify-content: center;
      margin-left: auto;
      min-width: 30px;
      transition: 0.2s ease-in-out;
      width: 30px;

      @include respondto(phoneSm) {
        position: absolute;
        right: 10px;
        top: 0;
      }

      svg {
        height: 12px;
        width: 12px;
      }

      &:hover {
        background-color: $main-color-hover;
      }
    }

    .sugested-properties-item {
      align-items: center;
      border-bottom: 1px solid #dfdbdb;
      display: flex;
      justify-content: space-between;
      margin-bottom: 19px;
      margin-top: 19px;
      padding-bottom: 19px;
      position: relative;

      @include respondto(phoneSm) {
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 15px;
        padding-bottom: 15px;
      }

      &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 16px;
      }
      &:not(:first-of-type) {
        margin-top: 0;
      }

      .img-block {
        border-radius: 10px;
        height: 80px;
        margin-right: 15px;
        overflow: hidden;
        width: 121px;
        @include respondto(largeDesktopSm) {
          border-radius: 0.521vw;
          height: 4.1667vw;
          width: 6.302vw;
        }
        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      .info-properties {
        display: flex;
        flex-direction: column;
        gap: 5px;
        max-width: calc(100% - 209px);
        @include respondto(largeDesktopSm) {
          gap: 0.26vw;
          max-width: calc(100% - 10.885vw);
        }
        @include respondto(phoneSm) {
          max-width: 100%;
        }
      }

      .details {
        color: $black;
        font-size: $font-md;
        font-weight: 400;

        // @include respondto(largeDesktopMd) {
        //   font-size: 0.833vw;
        // }
        // @include respondto(laptopMd) {
        //   font-size: 1.0417vw;
        // }
      }
    }

    .applications-wrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      position: relative;
      @include respondto(phoneSm) {
        padding-right: 5rem;
      }

      .info-content {
        width: calc(100% - 45px);
        @include respondto(largeDesktopSm) {
          width: calc(100% - 2.344vw);
        }
        @include respondto(phoneSm) {
          width: 100%;
        }
      }

      p {
        color: $black;
        font-size: $font-md;
        font-weight: 400;
        margin-bottom: 9px;
        @include respondto(largeDesktopSm) {
          margin-bottom: 0.469vw;
        }
      }

      .info-details {
        align-items: center;
        display: flex;
        gap: 15px;
        margin-top: 10px;
        @include respondto(largeDesktopSm) {
          gap: 0.781vw;
          margin-top: 0.521vw;
        }
        @include respondto(phone) {
          flex-wrap: wrap;
        }
      }
    }

    .sugested-contact-item {
      align-items: center;
      border-bottom: 1px solid #dfdbdb;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      margin-top: 15px;
      padding-bottom: 15px;
      position: relative;

      @include respondto(phoneSm) {
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 15px;
        padding-bottom: 15px;
      }

      &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 12px;
      }
      &:not(:first-of-type) {
        margin-top: 0;
      }

      .img-block {
        border-radius: 50%;
        height: 46px;
        margin-right: 20px;
        overflow: hidden;
        width: 46px;

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      .info-contact {
        max-width: calc(100% - 108px);

        @include respondto(phoneSm) {
          max-width: 100%;
        }
        .name {
          color: $black;
          font-size: $font-md;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 6px;
          @include respondto(largeDesktopSm) {
            margin-bottom: 0.313vw;
          }
        }

        .info-item {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          gap: 9px;
          @include respondto(largeDesktopSm) {
            gap: 0.469vw;
          }
          .number,
          .mail {
            align-items: center;
            color: $black;
            display: flex;
            font-size: $font-sm;
            font-weight: 500;
            gap: 8px;
            line-height: normal;
            margin-bottom: 3px;
            transition: 0.3s ease-in-out;
            @include respondto(largeDesktopSm) {
              gap: 0.4167vw;
              margin-bottom: 0.156vw;
            }
            @include respondto(phone) {
              font-size: 1.2rem;
            }

            svg {
              height: 15px;
              fill: $main-color;
              width: 15px;
              @include respondto(largeDesktopSm) {
                height: 0.781vw;
                width: 0.781vw;
              }
              path {
                fill: $main-color;
              }
            }

            &:hover {
              color: $main-color-hover;
            }
          }

          .mail {
            margin-bottom: 0px;

            &:not(:last-child) {
              border-right: 1px solid $bright-dusk;
              padding-right: 9px;
              @include respondto(largeDesktopSm) {
                padding-right: 0.469vw;
              }
            }
          }

          .number {
            &:not(:last-child) {
              border-right: 1px solid $bright-dusk;
              padding-right: 9px;
              @include respondto(largeDesktopSm) {
                padding-right: 0.469vw;
              }
            }
          }
        }
      }
    }
  }
}
