@import '../../assets/styles/variables';
.flex-btns {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
  justify-content: space-around;
  width: max-content;
  @include respondto(largeDesktopSm) {
    gap: 0.677vw;
  }
  @include respondto(laptop13) {
    gap: 15px;
  }
}
