@import '../../assets/styles/variables';
.container {
  &:has(.login-container) {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 4rem);
    padding: 2rem;
  }
}

.login-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: auto;
  position: relative;
  width: 100%;
  .loginbgimg {
    bottom: 0px;
    height: 100%;
    left: -35px;
    position: fixed;
    right: 0px;
    @include respondto(phone) {
      max-height: 100vh;
    }
  }

  .form-conatiner {
    background: $white;
    border-radius: 40px;
    box-shadow: $block-shadow;
    max-width: 707px;
    padding: 96px 108px 98px 109px;
    position: relative;
    width: 100%;
    z-index: 2;
    @include respondto(largeDesktopSm) {
      border-radius: 2.0833vw;
      padding: 5vw 5.625vw 5.104vw 5.677vw;
    }
    @include respondto(laptop13) {
      padding: 5vw 5.625vw 5.104vw 5.677vw;
    }
    @include respondto(phone) {
      border-radius: 25px;
      max-width: 40rem;
      padding: 30px;
    }

    @include respondto(phoneSm) {
      padding: 15px;
    }
  }

  .form-title-block {
    align-items: center;
    display: flex;
    gap: 17px;
    justify-content: center;
    margin-bottom: 77px;

    @include respondto(phone) {
      margin-bottom: 30px;
    }

    .logo {
      svg {
        height: 51px;
        width: 51px;

        path {
          fill: $nav-color;
        }
      }
    }

    .title {
      color: $black;
      font-size: 3.2rem;
      font-style: normal;
      font-weight: 200;
      line-height: normal;
    }
  }

  .inputs-field {
    margin-bottom: 43px;
    position: relative;

    @include respondto(phone) {
      margin-bottom: 30px;
    }

    &:last-of-type {
      margin-bottom: 33px;
    }

    input {
      border-bottom: 1px solid $light-shade-gray;
      color: $black;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 200;
      line-height: normal;
      padding: 0 0 1.7rem 3.7rem;
      width: 100%;

      &.has-error {
        border-bottom: 1px solid $red;
      }
    }

    .error-message {
      color: $red;
      text-align: left;
    }

    ::placeholder {
      color: $dim-gray;
    }

    .icon {
      left: 0;
      position: absolute;
      top: 0.3rem;
      width: 2rem;
    }
  }

  .footer-login {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 52px;

    @include respondto(phone) {
      margin-bottom: 30px;
    }

    @include respondto(phoneSm) {
      gap: 10px;
    }

    .forgot-password {
      color: $main-color;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;

      @include respondto(phone) {
        font-size: 1.4rem;
      }

      &:hover {
        color: $main-color-hover;
        text-decoration: underline;
      }
    }
  }

  .custom-checkbox {
    color: #9d9d9d;
    cursor: pointer;
    display: block;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 35px;
    position: relative;

    @include respondto(phoneSm) {
      padding-left: 25px;
    }

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    a {
      color: #5e5e5e;
      text-decoration-line: underline;
    }

    input {
      cursor: pointer;
      height: 0;
      opacity: 0;
      position: absolute;
      width: 0;
    }

    .checkmark {
      background: $white;
      border: 1px solid #aeaeae;
      border-radius: 5px;
      height: 20px;
      left: 0;
      position: absolute;
      top: 0;
      width: 20px;
    }
  }

  .custom-checkbox input:checked ~ .checkmark {
    background: $main-color;
  }

  .checkmark:after {
    content: '';
    display: none;
    position: absolute;
  }

  .custom-checkbox input:checked ~ .checkmark:after {
    display: block;
  }

  .custom-checkbox .checkmark:after {
    border: solid $white;
    border-width: 0 2px 2px 0;
    height: 11px;
    left: 6px;
    top: 2px;
    transform: rotate(45deg);
    width: 7px;
  }

  .submit-button {
    align-items: center;
    background: $main-color;
    border: 1px solid $main-color;
    border-radius: 28px;
    color: $white;
    display: flex;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    gap: 5px;
    height: 56px;
    justify-content: center;
    line-height: normal;
    margin: 0 auto;
    min-width: 150px;
    padding: 12px;
    transition: 0.3s ease-in-out;

    @include respondto(phone) {
      font-size: 1.6rem;
      height: 40px;
    }

    &:hover {
      background-color: $white;
      color: $main-color;
    }

    &:disabled {
      background: $dim-gray;
    }
  }
}
