@import "../../assets/styles/variables";

.swiper-dashboard-container {
    max-width: 568px;
    width: 100%;
    max-height: 68%;
    margin-right: -29px;
    margin-bottom: 4rem;
    @include respondto(largeDesktopSm) {
        max-width: 29.583vw;
        margin-right: -1.51vw;
        max-height: 58rem;
    }
    @include respondto(laptopMd) {
        margin-right: 0;
    }
    @include respondto(laptop13) {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 0;
        max-height: 100%;
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .swiper {
        width: 100%;
        height: 100%;
        margin-bottom: 10px;
        box-shadow: 0 24px 41px -30px rgba(111, 107, 249, 0.15);
    }

    .swiper-slide {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: 0 auto;
        box-shadow: $block-shadow;
        background: $white;
        border-radius: 15px;
        padding: 15px;
        color: $black;

        @include respondto(laptop13) {
            height: 100%;
            width: 100% !important;
        }

        .slider-header-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 15px;
            margin-bottom: 10px;
         
            @include respondto(phone) {
                flex-wrap: wrap;
            }
            h6 {
                font-size: 1.8rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                display: flex;
                align-items: center;
                gap: 11px;
                img {
                    max-width: 2rem;
                }
            }

            .btn-blue {
                border-radius: 7px;
                min-width: 125px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                @include respondto(desktopSm) {
                    min-width: 10rem;
                }
            }
        }

        .slider-table {
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            width: 100%;

            @include respondto(phoneSm) {
                flex-direction: column;
            }

            .column-item-swiper {
                width: calc(50% - .5rem);
            }

            .rwo-item {
                width: 100%;
                height: 30px;
                border-radius: 7px;
                background: $table-body-even-bg;
                padding: 4px 10px;
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 6px;

                span {
                    font-weight: 300;
                }
            }
        }
        &:has(.btn-blue.active),
        &.active {
            background: $nav-color;
            color: $white;
            position: relative;
            z-index: 2;

            &:after {
                position: absolute;
                content: '';
                background-color: $nav-color;
                top: calc(50% - 15.5px);
                border-radius: 6px;
                transform: rotate(50deg);
                height: 27px;
                width: 27px;
                left: -10px;
                z-index: 1;
            }

            .rwo-item {
                background: $nav-color-active;
            }

            .btn-blue {
                display: none;
            }
        }
    }

    .slider__prev,
    .slider__next {
        cursor: pointer;
        border-radius: 25px;
        background: $white;
        box-shadow: $block-shadow;
        width: 60px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s ease-in-out;
        margin: 0 auto 10px;
        // @include respondto(largeDesktopSm) {
        //     border-radius: 1.302vw;
        //     height: 1.9792vw;
        //     margin: 0 auto 1.042vw;
        //     width: 3.958vw;
        // }
        &.swiper-button-disabled {
            opacity: .5;
            cursor: inherit;
        }

        &:hover {
            &:not(.swiper-button-disabled) {
                background: $main-color-hover;

                svg {
                    fill: $white;
                }
            }
        }

        svg {
            fill: $main-color;
            transition: .3s ease-in-out;
        }

        img,
        svg {
            @include respondto(laptop13) {
                transform: rotate(-85deg);
            }
        }
    }

    .slider__next {

        img,
        svg {
            transform: rotate(180deg);

            @include respondto(laptop13) {
                transform: rotate(85deg);
            }
        }
    }
}