@import '../../assets/styles/variables';

.company-container {
  .flex-edit-company {
    gap: 32px;
    justify-content: space-between;

    @include respondto(largeDesktopSm) {
      gap: 1.667vw;
    }
  }

  .spiner-container {
    margin-top: 250px;
  }
}

.edit-company-container {
  display: flex;
  flex-direction: column;
  gap: 35px;
  min-width: 768px;
  width: 100%;

  @include respondto(laptop13) {
    min-width: 100%;
  }

  @include respondto(phone) {
    gap: 20px;
  }

  .form-container {
    background: $white;
    border-radius: 25px;
    box-shadow: 0 27px 42.3px 0 rgba($black, 0.04);
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 47px;

    @include respondto(phone) {
      padding: 15px 11px;
    }

    .title {
      color: $black;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 26px;
      margin: auto;
      max-width: 72%;
      width: 100%;
      @include respondto(laptop13) {
        max-width: 100%;
      }
      @include respondto(phone) {
        margin-bottom: 16px;
      }
    }

    form {
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      gap: 0 30px;
      justify-content: center;

      .form-line-section {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        margin-bottom: 30px;
        width: calc(36% - 15px);

        @include respondto(laptop13) {
          flex-direction: column;
          max-width: 100%;
          width: 100%;
        }

        .display-flex {
          @include respondto(phone) {
            flex-wrap: wrap;
          }
        }

        .form-item-group {
          width: 100%;

          @include respondto(laptop13) {
            max-width: 100%;
          }
        }

        .input-group {
          width: 100%;

          @include respondto(laptop13) {
            max-width: 100%;
          }

          .form-item-group {
            margin-bottom: 11px;
          }
        }
      }
    }
  }

  .btns-container {
    align-items: center;
    background: $white;
    border-radius: 25px;
    box-shadow: 0 27px 42.3px 0 rgba($black, 0.04);
    display: flex;
    gap: 17px;
    justify-content: center;
    padding: 23px;
    width: 100%;

    @include respondto(phone) {
      flex-wrap: wrap;
      padding: 15px 10px;
    }
  }
}
