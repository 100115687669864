@import '../../assets/styles/variables';

.price-evaluation-grafic {
  background: $input-color;
  border-radius: 1rem;
  margin-bottom: 3rem;
  padding: 3rem;
  @include respondto(laptop13) {
    background: transparent;
    padding: 0;
  }
  .price-evaluation-grafic-container {
    background: $white;
    border-radius: 1rem;
    margin: 0 auto;
    max-width: 94.4rem;
    padding: 3rem;
    @include respondto(laptop13) {
      background: transparent;
      padding: 0;
    }
  }
  .search-container {
    margin-bottom: 3rem;
    .form-item-group-label {
      font-size: 1.4rem;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .form-item-group-block {
      position: relative;

      .form-item-control {
        border-radius: 10px;
        color: $black;
        height: 4rem;
        padding-left: 4rem;
        &::placeholder {
          color: $black;
        }
      }
      .icon {
        left: 6px;
        position: absolute;
        top: 8px;
        svg,
        path {
          transition: all 0.3s ease-in-out;
        }
        &:hover {
          svg {
            fill: $main-color;
            path {
              fill: $main-color;
            }
          }
        }
      }
    }
  }
  .heading-lg {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2.1rem;
  }
  .heading-sm {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.8rem;
  }
  &__table {
    color: $black;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.8rem;
    margin-bottom: 3rem;
    overflow: auto;
    &::-webkit-scrollbar {
      height: 5px;
      padding-bottom: 20px;
      padding-top: 30px;
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: $scroll-bar-light;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      box-shadow: inset 0 0 60px rgba($scroll-bar-dark, 1);
    }
    table {
      min-width: 850px;
      width: 100%;
      th {
        width: 100%;
      }
    }
    tr {
      display: flex;
      flex-direction: row;
    }
    .table-flex {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      justify-content: space-between;
    }
    td {
      width: 440px;
    }
    .td-left,
    .td-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(50% - 220px);
      .label-green,
      .label-yellow,
      .label-red {
        margin: 3px 0;
      }
    }
    .lable-list {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    .td-right {
      position: relative;
      text-align: right;
      .label-green,
      .label-yellow,
      .label-red {
        margin-left: auto;
      }
      p {
        max-width: 158px;
      }
    }
    th {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 20px;
    }
    td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      height: 74px;
      position: relative;
      vertical-align: middle;
    }
    .triangle {
      margin-bottom: -1px !important;
      position: relative;
      z-index: 2;
      &-top-red {
        border-bottom: 74px solid #c23a2c;
        border-left: 44px solid transparent;
        border-right: 44px solid transparent;
        display: block;
        height: 0;
        margin: auto;
        width: 0;
      }
      &-second-red {
        border-bottom: 74px solid #e84c3d;
        border-left: 44px solid transparent;
        border-right: 44px solid transparent;
        display: block;
        height: 0;
        margin: auto;
        width: 176px;
      }
      &-third-yellow {
        border-bottom: 74px solid #f59d1f;
        border-left: 44px solid transparent;
        border-right: 44px solid transparent;
        display: block;
        height: 0;
        margin: auto;
        position: relative;
        width: 264px;
        .label-white {
          background: $white;
          border-radius: 5px;
          color: #323335;
          display: block;
          font-size: 1.6rem;
          font-weight: 600;
          left: 0;
          line-height: 2.4rem;
          margin: 20px auto;
          padding: 3px 10px;
          position: absolute;
          right: 0;
          width: fit-content;
        }
      }
      &-fourth-green {
        border-bottom: 74px solid #4aba70;
        border-left: 44px solid transparent;
        border-right: 44px solid transparent;
        display: block;
        height: 0;
        margin: auto;
        width: 352px;
      }
      &-fifth-green {
        border-bottom: 74px solid #26af5f;
        border-left: 44px solid transparent;
        border-right: 44px solid transparent;
        display: block;
        height: 0;
        margin: auto;
        width: 440px;
      }
    }
    .label-red {
      background: #c23a2c1a;
      color: #c23a2c;
    }
    .label-yellow {
      background: #f59d1f1a;
      color: #f59d1f;
    }
    .label-green {
      background: #4aba701a;
      color: #4aba70;
    }
    .label-green,
    .label-red,
    .label-yellow {
      border-radius: 4px;
      display: block;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      padding: 0 5px;
      text-align: center;
      width: 48px;
    }
  }
  .applied-filtres {
    .heading-lg {
      margin-bottom: 2rem;
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 2.5rem;
      &__item {
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
        max-width: 39.3rem;
        padding-bottom: 5px;
        width: calc(50% - 1.5rem);
        @include respondto(phone) {
          max-width: 100%;
          width: 100%;
        }
        &-title {
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.8rem;
          width: 60%;
          @include respondto(phoneSm) {
            width: 50%;
          }
        }
        &-label {
          width: 40%;
          @include respondto(phoneSm) {
            width: 50%;
          }
          .label-item-tag {
            font-weight: 400;
            justify-content: center;
            text-align: center;
          }
        }
      }
    }
  }
  &__buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }
  .button {
    gap: 5px;
    svg {
      height: 1.8rem;
      width: 1.8rem;
    }
  }
}
.conclusions-evaluation {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 30px;
  .heading-lg {
    width: 100%;
  }
  &__list {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 2rem;
    width: calc(50% - 0.8rem);
    @include respondto(phone) {
      width: 100%;
    }
    .label-item-tag {
      background: $white;
      border-radius: 0.8rem;
      font-size: 1.3rem;
      font-weight: 500;
      min-height: 2.4rem;
      padding: 0 0.8rem;
    }
    &-left {
      background: $input-color;
    }
    &-right {
      background: #4aba701a;
    }
  }
  &-list__item {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &-title {
      font-size: 1.3rem;
      font-weight: 400;
      padding-right: 0.5rem;
      width: 50%;
    }
  }
}
