@import '../../assets/styles/variables';

.item-tag {
  gap: 54px;
  @include respondto(largeDesktopSm) {
    gap: 2.813vw;
  }
  @include respondto(laptop13) {
    flex-direction: column;
    gap: 15px;
  }

  .list-item-content {
    align-items: center;
    display: flex;
    flex-direction: row;
    @include respondto(laptop13) {
      align-items: flex-end;
      flex-direction: column;
      gap: 10px;
    }

    .column-item {
      padding: 0 30px;
      @include respondto(largeDesktopSm) {
        padding: 0 1.563vw;
      }
      @include respondto(laptop13) {
        align-items: center;
        display: flex;
        gap: 10px;
        justify-content: space-between;
        padding: 0;
        width: 100%;
      }

      &:first-child {
        padding-left: 0;
        width: 11%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }

      &:nth-child(2) {
        width: 33%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }

      &:nth-child(3) {
        width: 23%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }

      &:nth-child(4) {
        width: 20%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
    }

    .label-item-tag {
      font-size: $font-md;
      justify-content: center;
      min-width: 40px;
      width: fit-content;
      @include respondto(largeDesktopSm) {
        height: 2.0833vw;
        min-width: 2.0833vw;
      }
      @include respondto(laptop13) {
        box-shadow: 0 27px 42.3px 0 rgba($black, 0.04);
        justify-content: flex-start;
        width: 50%;
      }
    }

    .info-bold {
      color: $black;
      font-size: $font-md;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      @include respondto(laptop13) {
        font-size: 1.6rem;
        font-weight: 400;
      }
      @include respondto(phone) {
        font-size: 1.2rem;
      }
    }

    .info-text,
    .info-date {
      color: $black;
      font-size: $font-md;
      font-style: normal;
      font-weight: 300;
      line-height: normal;

      @include respondto(laptop13) {
        font-weight: 400;
      }
      @include respondto(phone) {
        font-size: 1.2rem;
      }

      a {
        color: $main-color;
        &:hover {
          color: $main-color-hover;
          text-decoration: underline;
        }
      }
    }

    .info-icons {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 10px;
      @include respondto(largeDesktopSm) {
        gap: 0.521vw;
      }
      @include respondto(laptop13) {
        flex-direction: row;
        justify-content: center;
        width: 100%;
      }
    }
  }
}
