@import '../../assets/styles/variables';

.ads-form-container {
  &--left {
    @include respondto(phone) {
      text-align: center;
    }
  }
  &--right {
    .button-md {
      border-radius: 4px;
      margin-left: auto;
      @include respondto(phone) {
        margin-right: auto;
        margin-bottom: 2.5rem;
      }
    }
  }
}

.list-items-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.request-item {
  gap: 20px;
  @include respondto(largeDesktopSm) {
    gap: 1.042vw;
  }
  @include respondto(laptop13) {
    flex-direction: column;
    gap: 15px;
    padding: 15px 11px;
  }

  .line-border {
    @include respondto(laptop13) {
      margin: 5px 0;
    }
  }

  .profile-column {
    @include respondto(laptop13) {
      order: -1;
    }

    .mobile-text {
      @include respondto(laptop13) {
        display: none;
      }
    }

    .info-pofile-container {
      @include respondto(laptop13) {
        justify-content: center;
        width: 100%;
      }
    }
  }

  .list-item-content {
    align-items: center;
    display: flex;
    flex-direction: row !important;
    gap: 0;

    @include respondto(laptop13) {
      flex-direction: column !important;
      gap: 10px;
    }

    .column-item {
      padding: 0 !important;
      @include respondto(largeDesktopSm) {
        padding: 0 !important;
      }
      @include respondto(laptop13) {
        padding: 0;
      }
      &:first-child {
        padding: 0;
        width: 3.5% !important;
        @include respondto(laptop13) {
          width: 100% !important;
        }
      }
      &:nth-child(2) {
        text-align: center;
        width: 15% !important;
        @include respondto(laptop13) {
          width: 100% !important;
        }
      }
      &:nth-child(3) {
        width: 25.3% !important;
        @include respondto(laptop13) {
          width: 100% !important;
        }
      }
      &:nth-child(4) {
        text-align: center;
        width: 10% !important;
        @include respondto(laptop13) {
          width: 100% !important;
        }
      }
      &:nth-child(5) {
        text-align: center;
        width: 10% !important;
        @include respondto(laptop13) {
          width: 100% !important;
        }
      }
      &:nth-child(6) {
        text-align: center;
        width: 10% !important;
        @include respondto(laptop13) {
          width: 100% !important;
        }
      }
      &:nth-child(7) {
        text-align: center;
        width: 10.5% !important;
        @include respondto(laptop13) {
          width: 100% !important;
        }
      }
      &:nth-child(8) {
        width: 10% !important;
        @include respondto(laptop13) {
          width: 100% !important;
        }
      }
    }

    .info-id {
      color: $black;
      font-size: $font-md;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .info-date-modify {
      color: $black;
      font-size: $font-md;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .info-pofile-container {
      align-items: center;
      display: flex;
      gap: 20px;
      padding-left: 20px;
      @include respondto(largeDesktopSm) {
        gap: 1.042vw;
      }
      @include respondto(laptop13) {
        flex-direction: column;
        gap: 5px;
      }

      .info-img {
        border-radius: 50%;
        height: 64px;
        max-width: 64px;
        min-width: 64px;
        overflow: hidden;
        @include respondto(largeDesktopSm) {
          height: 3.333vw;
          max-width: 3.333vw;
          min-width: 3.333vw;
        }
        @include respondto(laptop13) {
          margin-left: auto;
          margin-right: auto;
        }

        img {
          height: 100%;
          min-height: 100%;
          min-width: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      .info-contact-details {
        display: flex;
        flex-direction: column;
        max-width: calc(100% - 84px);
        @include respondto(largeDesktopSm) {
          max-width: calc(100% - 4.375vw);
        }
        @include respondto(laptop13) {
          align-items: center;
          gap: 5px;
          justify-content: center;
          max-width: 100%;
          text-align: center;
        }

        .name {
          color: $black;
          font-size: $font-md;
          font-style: normal;
          font-weight: 600;
          line-height: 1.2;
          margin-bottom: 4px;

          @include respondto(phone) {
            font-size: 14px;
          }
        }

        .phone-numbers,
        .mail {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          margin-bottom: 5px;
          @include respondto(largeDesktopSm) {
            gap: 0.4167vw;
          }
          svg {
            height: 15px;
            fill: $main-color;
            width: 15px;
            @include respondto(largeDesktopMd) {
              height: 0.781vw;
              width: 0.781vw;
            }
            @include respondto(laptopMd) {
              height: 1.0417vw;
              width: 1.0417vw;
            }
            @include respondto(phone) {
              height: 1.3rem;
              width: 1.3rem;
            }
            path {
              fill: $main-color;
            }
          }

          @include respondto(laptop13) {
            gap: 5px;
            justify-content: center;
          }

          span,
          a {
            border-right: 1px solid $gainsboro;
            color: $black;
            display: flex;
            font-size: $font-md;
            font-style: normal;
            font-weight: 500;
            gap: 8px;
            line-height: normal;
            padding-right: 10px;
            transition: 0.2s ease-in-out;
            @include respondto(largeDesktopSm) {
              gap: 0.4167vw;
              padding-right: 0.521vw;
            }

            @include respondto(laptop13) {
              border-right: none;
              padding: 0 !important;
            }

            @include respondto(phone) {
              font-size: 1.2rem;
            }

            &:first-of-type {
              padding-left: 0;
            }

            &:last-of-type {
              border-right: none;
              padding-right: 0;
            }

            svg {
              height: 15px;
              fill: $main-color;
              width: 15px;
              @include respondto(largeDesktopMd) {
                height: 0.781vw;
                width: 0.781vw;
              }
              @include respondto(laptopMd) {
                height: 1.0417vw;
                width: 1.0417vw;
              }
              path {
                fill: $main-color;
              }
            }

            &:hover {
              color: $main-color-hover;
            }
          }
        }

        .mail {
          margin-bottom: 0;
        }
      }
    }

    .info-type,
    .info-transaction {
      color: $black;
      font-size: $font-md;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      @include respondto(phone) {
        font-size: 1.2rem;
      }
    }

    .info-search,
    .info-budget {
      color: rgba($main-color, 0.7);
      font-size: $font-md;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .info-agent {
      align-items: center;
      color: $black;
      display: flex;
      flex-direction: column;
      font-size: $font-md;
      font-style: normal;
      font-weight: 400;
      gap: 5px;
      line-height: normal;

      @include respondto(phone) {
        font-size: 1.2rem;
        gap: 7px;
      }

      .info-image {
        border-radius: 50%;
        height: 5.2rem;
        overflow: hidden;
        width: 5.2rem;
        @include respondto(largeDesktopSm) {
          height: 2.708vw;
          width: 2.708vw;
        }
        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }

    .info-icons {
      margin-left: auto;
      @include respondto(laptop13) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
      }
    }
  }
}

.request-list-filter {
  .form-check-input {
    margin-right: 21px !important;
    @include respondto(largeDesktopSm) {
      margin-right: 1.094vw !important;
    }
  }
  &.request-list-filter-no-check {
    form {
      display: grid;
      grid-template-columns: 4% 15% 25% 10% 10% 10% 10% 10% 5% !important;
    }
    .filter-item {
      padding: 0 5px !important;
      @include respondto(largeDesktopSm) {
        padding: 0 5px !important;
      }
      &:first-child {
        padding-left: 0 !important;
      }
    }
  }

  .filter-item {
    max-width: unset !important;
    padding: 0 26px !important;
    text-align: center !important;

    @include respondto(largeDesktopSm) {
      padding: 0 1.25vw !important;
    }

    .form-item-control {
      padding: 12px 10px;
      @include respondto(largeDesktopSm) {
        padding: 0.1vw 0.521vw;
      }
    }
  }
}
