@import '../../assets/styles/variables';

.contracts-templates-list-item {
  @include respondto(laptop13) {
    display: none;
  }
  .form-check-input {
    margin-right: 21px !important;
    @include respondto(largeDesktopSm) {
      margin-right: 1.094vw !important;
    }
  }

  form {
    display: grid;
    grid-template-columns: 8% 14% 69% 8% !important;
    .filter-item {
      label {
        margin-bottom: 0;
      }
    }
  }

  .filter-item {
    max-width: unset !important;
    padding: 0 25px !important;

    @include respondto(largeDesktopSm) {
      padding: 0 1.302vw !important;
    }

    .form-item-control {
      padding: 12px 10px;
      @include respondto(largeDesktopSm) {
        padding: 0.1vw 0.521vw;
      }
    }

    &:nth-of-type(1) {
      padding-left: 5px !important;
    }
  }
}
