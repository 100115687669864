@import '../../assets/styles/variables';

.side-nav-container {
  background: $nav-color;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 64px;
  margin-left: -20px;
  padding: 29px 0px;
  position: fixed;
  top: 0;
  transition: all 0.3s ease-in-out;
  width: 44px;
  z-index: 10;
  @media screen and (min-width: 1925px) {
    border-radius: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  @include respondto(desktopXLg) {
    display: none;
  }

  .logo {
    align-items: center;
    display: flex;
    gap: 21px;
    height: 25px;
    justify-content: flex-start;
    margin: 0 auto 0 10px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    width: 25px;

    img {
      max-height: 25px;
      max-width: 100%;
    }
  }

  .expand-button {
    align-items: center;
    background-color: $white;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    filter: drop-shadow(0px 27px 42.3px rgba($black, 0.23));
    height: 40px;
    justify-content: center;
    position: absolute;
    right: -19px;
    top: 75px;
    transition: all 0.3s ease-in-out;
    width: 40px;

    svg {
      fill: $black;
      transition: 0.3s ease-in-out;
    }

    img {
      transition: all 0.3s ease-in-out;
    }
  }

  .accordion-container {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .nav-item {
      align-items: center;
      color: $white;
      display: flex;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 20px 33px;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      width: 40px;

      img {
        margin-right: 22px;
      }

      &::before {
        background-color: $white;
        content: '';
        height: 30px;
        left: 0px;
        position: absolute;
        width: 5px;
      }
    }

    .accordion-item {
      height: 45px;
      overflow: hidden;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: $nav-color-active;
      }
      .accordion-button {
        background-color: transparent;
        color: $white;
        cursor: pointer;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 12px;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        width: 40px;

        p {
          min-width: max-content;
        }

        img {
          margin-right: 12px;
          width: 20px;
        }

        &--after {
          align-items: center;
          background-color: $white;
          background-image: url('../../assets/svg/arrowaccordion.svg');
          background-size: contain;
          border-radius: 50%;
          display: flex;
          height: 20px;
          justify-content: center;
          margin-left: 7px;
          width: 20px;
        }
      }

      .accordion-body {
        &:not(.active) {
          opacity: 0;
          transform: scale(0);
          visibility: hidden;
        }

        ul {
          border-left: 1px solid $white;
          margin: 20px 0 40px 20px;
        }

        li {
          margin-bottom: 20px;

          .nav-item-sublink {
            color: $white;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            position: relative;

            &::after {
              background-color: $white;
              bottom: 0;
              content: '';
              height: 1px;
              left: 0;
              margin: 0 auto;
              position: absolute;
              right: 0;
              transition: 0.2s ease-in-out;
              width: 0;
            }

            &:hover {
              &:not(.active) {
                &::after {
                  width: 100%;
                }
              }
            }

            &.active {
              font-weight: 600;
              position: relative;

              &::before {
                background-color: $nav-color-active;
                content: url('../../assets/svg/arrowaccordionactive.svg');
                height: 23px;
                left: -33px;
                position: absolute;
                width: 20px;
              }
            }
          }
        }
      }

      &.active {
        background-color: $nav-color-active;
        height: auto;
        position: relative;
        transition: all 0.2s ease-in-out;

        &::before {
          background-color: $white;
          content: '';
          height: 100%;
          left: 0px;
          position: absolute;
          width: 3px;
        }

        // .accordion-button {
        //   &--after {
        //     background-color: $white;
        //     background-image: url('../../assets/svg/arrowaccordionactive.svg');
        //   }
        // }

        .accordion-body {
          opacity: 1;
          transform: scaleY(1);
          visibility: visible;
        }
      }
    }
  }

  .accordion-container-scroll {
    height: 100%;
    max-height: calc(100vh - 15rem);
    overflow: auto;

    &::-webkit-scrollbar {
      height: 5px;
      padding-bottom: 20px;
      padding-top: 30px;
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: $white;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      box-shadow: inset 0 0 60px rgba($nav-color, 1);
    }
  }

  &.expanded {
    transition: all 0.3s ease-in-out;
    width: 246px;

    .expand-button {
      img,
      svg {
        transform: scale(1);
        transition: all 0.3s ease-in-out;
      }
    }

    .accordion-button {
      transition: all 0.3s ease-in-out;
      width: calc(100% - 22px) !important;
    }

    .nav-item {
      transition: all 0.3s ease-in-out;
      width: calc(100% - 22px) !important;
    }

    .logo {
      transition: all 0.3s ease-in-out;
      width: auto;
    }
  }
}
