@import '../../assets/styles/variables';

.section-container {
  display: flex;
  flex-wrap: wrap;
}

.ads {
  &-general {
    &-lg {
      width: calc(100% - 380px);

      @include respondto(largeDesktopSm) {
        width: calc(100% - 19.844vw);
      }
      @include respondto(laptopMd) {
        width: calc(100% - 23vw);
      }
      @include respondto(laptop13) {
        width: 100%;
      }

      > .bg-blue {
        @include respondto(phone) {
          background: transparent;
          border-radius: 0;
          padding: 0;
        }
      }
    }

    &-container {
      padding: 28px 30px 44px;

      @include respondto(largeDesktopSm) {
        padding: 1.458vw 1.563vw 2.292vw;
      }

      @include respondto(phone) {
        padding: 22px 0 0;
      }
    }
  }

  &-container {
    color: $black;
    font-size: $font-md;
    min-height: 70vh;
    padding: 40px 51px 25px 42px;
    position: relative;

    @include respondto(largeDesktopSm) {
      padding: 2.083vw 2.656vw 1.302vw 2.188vw;
    }

    @include respondto(phone) {
      padding: 21px 15px 20px;
    }

    .container {
      padding: 0;
    }

    .heading-block {
      margin-bottom: 25px;
      @include respondto(largeDesktopSm) {
        margin-bottom: 1.302vw;
      }
    }

    .subheading-block {
      color: $black;
      font-size: $font-lg;
      font-weight: 600;
      line-height: normal;

      // @include respondto(laptop13) {
      //   font-size: 1.8rem;
      // }
    }

    .content-block {
      font-size: $font-md;
      margin-bottom: 26px;
      @include respondto(largeDesktopSm) {
        margin-bottom: 1.354vw;
      }

      &.mb-6 {
        margin-bottom: 6rem;
        @include respondto(largeDesktopSm) {
          margin-bottom: 3.125vw;
        }
      }

      &.mb-36 {
        margin-bottom: 36px;
        @include respondto(largeDesktopSm) {
          margin-bottom: 1.875vw;
        }
      }

      &.mb-42 {
        margin-bottom: 42px;
        @include respondto(largeDesktopSm) {
          margin-bottom: 2.188vw;
        }
      }

      .heading-block {
        margin-bottom: 19px;
        @include respondto(largeDesktopSm) {
          margin-bottom: 0.99vw;
        }
      }

      a {
        color: $main-color;
        text-decoration: underline;

        &:hover {
          color: $main-color-hover;
        }
      }
      .link {
        color: $main-color;
        cursor: pointer;
        text-decoration: underline;

        &:hover {
          color: $main-color-hover;
        }
      }
    }

    .row-16 {
      --bs-gutter-x: 1.6rem;
    }

    .row-93 {
      @include respondto(largeDesktopXlg) {
        --bs-gutter-x: 9.3rem;
      }
      @include respondto(largeDesktopSm) {
        --bs-gutter-x: 4.844vw;
      }
    }

    .row-38 {
      @include respondto(largeDesktopXlg) {
        --bs-gutter-x: 3.8rem;
      }
      @include respondto(largeDesktopSm) {
        --bs-gutter-x: 1.9792vw;
      }
    }

    .row-52 {
      @include respondto(largeDesktopXlg) {
        --bs-gutter-x: 5.2rem;
      }
      @include respondto(largeDesktopSm) {
        --bs-gutter-x: 2.708vw;
      }
    }

    .row-53 {
      @include respondto(largeDesktopXlg) {
        --bs-gutter-x: 5.3rem;
      }
      @include respondto(largeDesktopSm) {
        --bs-gutter-x: 2.76vw;
      }
    }

    .row-54 {
      @include respondto(largeDesktopXlg) {
        --bs-gutter-x: 5.4rem;
      }
      @include respondto(largeDesktopSm) {
        --bs-gutter-x: 2.813vw;
      }
    }
    .activity-list-item {
      &.bg-blue {
        border-radius: 1.5rem;
        box-shadow: none;
        padding: 1.5rem;
      }
    }
  }

  &-form-tabs {
    .nav-item {
      background: $white;
      border-radius: 10px;
    }

    &.nav-tabs {
      background-color: $input-color;
      border-bottom: none;
      border-radius: 10px;
      gap: 1px;
      margin: 0 0 30px;
      width: 100%;
      @include respondto(largeDesktopSm) {
        border-radius: 0.521vw;
        margin: 0 0 1.563vw;
      }
      .nav-item {
        background-color: $input-color;

        &:not(:last-child) {
          position: relative;

          &::after {
            background-color: $light-slate-blue;
            bottom: 0;
            content: '';
            display: block;
            height: 22px;
            margin: auto;
            position: absolute;
            right: -1px;
            top: 0;
            width: 1px;
          }
        }
      }

      .nav-link {
        border-radius: 7px;
        color: $dark-gray;
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 2rem;
        margin-bottom: 0;
        padding: 6px 15px;

        &.active {
          background-color: $main-color;
          border-color: $main-color;
          box-shadow: 0px 4px 4px 0px rgba($black, 0.12);
          color: $white;
        }

        &:hover,
        &:focus {
          border-color: transparent;
        }
      }
    }
  }

  &-form-container-map {
    .map {
      border-radius: 25px;
      height: 511px;
      margin-bottom: 2.8rem;
      margin-top: 1.1rem;
      overflow: hidden;
      width: 100%;
      @include respondto(largeDesktopSm) {
        border-radius: 1.302vw;
        height: 26.615vw;
        margin-bottom: 1.4583vw;
        margin-top: 0.5729vw;
      }
      @include respondto(phone) {
        border-radius: 10px;
        height: 306px;
        margin-bottom: 19px;
        margin-top: 14px;
      }

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    &__nav {
      p {
        font-size: 1.8rem;
        line-height: 2.2rem;
        margin-bottom: 2.8rem;
        @include respondto(largeDesktopSm) {
          line-height: 1.2;
          margin-bottom: 1.4583vw;
        }
        @include respondto(largeDesktopMd) {
          font-size: 0.938vw;
        }
        @include respondto(laptopMd) {
          font-size: 1.1111vw;
        }
      }
    }
  }
}
