@import '../../assets/styles/variables';

.labels-modal-center {
  --bs-modal-width: 900px;
  --bs-modal-bg: $azure;
  bottom: 0;
  left: 0;
  padding-top: 90px;
  right: 0;
  top: 0;
  @include respondto(onlyDesktop) {
    z-index: 999999 !important;
  }
  @include respondto(laptop13) {
    --bs-modal-bg: $azure;
    border-radius: 25px 25px 0 0;
    padding-top: 190px;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999991 !important;
  }

  &.show {
    align-items: center;
    display: flex !important;
    justify-content: center;
  }

  .modal-title {
    color: $black;
    font-size: 2rem;
    font-weight: 600;
    // @include respondto(largeDesktopSm) {
    //   font-size: 1.7708vw;
    // }
    // @include respondto(laptop13) {
    //   font-size: 24px;
    // }
    // @include respondto(phone) {
    //   font-size: 20px;
    // }
  }

  .modal-subtitle {
    color: $black;
    font-size: $font-md;
    font-weight: 400;
    text-align: center;
  }

  .modal-dialog {
    margin: auto;
    max-width: 900px;
    @include respondto(largeDesktopSm) {
      width: 100%;
    }
    @include respondto(laptop13) {
      max-width: 100%;
      width: 100%;
    }
  }

  .modal-content {
    background-color: $azure;
    border: none;
    border-radius: 20px;
    @include respondto(largeDesktopSm) {
      border-radius: 1.042vw;
    }
    @include respondto(phone) {
      background-color: $white;
    }
  }

  .modal-body {
    background-color: $white;
    border-radius: 25px;
    box-shadow: $block-shadow;
    margin: 0 76px 72px;
    padding: 44px;
    @include respondto(largeDesktopSm) {
      border-radius: 1.302vw;
      margin: 20px 108px 55px 108px;
    }
    @include respondto(laptop13) {
      box-shadow: none;
      margin: 20px 0 0 0;
      padding: 10px 20px 40px 20px;
      width: 100%;
    }
  }

  .modal-header {
    border-bottom: none;
    justify-content: center;
    padding: 30px 30px 10px;
    text-align: center;
    @include respondto(laptop13) {
      border-radius: 25px 25px 0 0;
    }
  }

  .container {
    padding: 0;
  }

  .form-item {
    margin-bottom: 30px;

    .heading {
      font-size: 15px;
      margin-bottom: 24px;
    }

    .add-block {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 10px;

      .quantity {
        align-items: center;
        display: flex;

        .label-item-rounded-info-sm {
          background: $input-color;
          font-size: 12px;
          font-weight: 400;
          padding: 7px 10px !important;
          @include respondto(largeDesktopSm) {
            padding: 0.286vw 0.521vw;
          }
        }
      }

      .close {
        height: 2.4rem;
        width: 2.4rem;
        @include respondto(largeDesktopSm) {
          height: 1.25vw;
          width: 1.25vw;
        }

        svg {
          fill: $text-color-pagination;
          height: 2.4rem;
          width: 2.4rem;
          @include respondto(largeDesktopSm) {
            height: 1.25vw;
            width: 1.25vw;
          }

          path {
            fill: $text-color-pagination;
          }
        }

        &:hover {
          cursor: pointer;

          svg {
            fill: $main-color-hover;

            path {
              fill: $main-color-hover;
            }
          }
        }
      }
    }
  }

  .associated-labels {
    .heading {
      color: $black;
      font-size: 1.6rem;
      font-weight: 500;
      margin-bottom: 15px;

      @include respondto(largeDesktopSm) {
        margin-bottom: 0.781vw;
      }
      @include respondto(largeDesktopMd) {
        font-size: 0.833vw;
      }
      @include respondto(laptopMd) {
        font-size: 0.9722vw;
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      gap: 7px;
      margin: 0;
      padding: 0;

      .form-check {
        &-input {
          border-radius: 4px !important;
          height: 16px;
          margin-left: unset;
          width: 16px;
        }

        label {
          font-size: 12px;
        }
      }

      .label-item-rounded-info-sm {
        background: $input-color;
        padding: 7px 10px !important;
        @include respondto(largeDesktopSm) {
          padding: 0.286vw 0.521vw;
        }
      }
    }
  }

  .buttons {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    @include respondto(laptop13) {
      gap: 12px;
    }

    .button-md {
      min-width: 223px;
      @include respondto(largeDesktopSm) {
        min-width: 11.615vw;
      }
      @include respondto(laptop13) {
        min-width: 100%;
      }
    }
  }

  .form-item-button {
    margin-top: 14px;
  }

  textarea {
    min-height: 149px;
  }
}

.form-item-control {
  border-radius: 4px;
}

.close-modal {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  height: 40px;
  position: absolute;
  right: 5px;
  top: 5px;
  transition: 0.3s ease-in-out;
  width: 40px;

  * {
    transition: 0.3s ease-in-out;
  }

  svg {
    height: 20px;
    width: 20px;
  }

  &:hover {
    svg {
      fill: $main-color;

      path {
        fill: $main-color;
      }
    }
  }
}

.modal-backdrop:has(+ .labels-modal-center.show) {
  @include respondto(onlyDesktop) {
    --bs-backdrop-zindex: 99999;
  }
}
