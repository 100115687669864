@import '../../assets/styles/variables';

.activity-calendar-container {
  max-width: 451px;
  width: 100%;

  @include respondto(desktopXLg) {
    max-width: 100%;
  }
  .custom-select {
    .form-item-control-select {
      background-color: $white !important;
      min-height: 3.4rem;
    }
    .react-select__indicator {
      display: none;
    }
    .react-select__indicators {
      margin-right: 5rem;
    }
    .react-select__value-container {
      font-size: $font-sm !important;
      padding: 0 1.6rem !important;
    }
  }
  .filter-agent-container {
    margin-bottom: 18px;
    position: relative;
    width: 100%;

    .form-item-group {
      label {
        color: $black;
        font-size: $font-sm;
        font-style: normal;
        font-weight: 300;
      }

      .form-item-control {
        background: $white;
        border-radius: 10px;
        @include respondto(largeDesktopSm) {
          height: 42px;
        }
      }
    }

    .arrow-btn {
      align-items: center;
      background: $light-button-color;
      border-radius: 15px;
      border-radius: 50%;
      display: flex;
      height: 29px;
      justify-content: center;
      position: absolute;
      right: 15px;
      top: 24px;
      width: 29px;
    }
  }

  .calendar-container {
    width: 100%;

    .react-calendar {
      background: $white;
      border: none;
      border-radius: 10px;
      box-shadow: $block-shadow;
      padding: 24px 36px;
      width: 100%;

      @include respondto(phone) {
        padding: 15px;
      }

      .react-calendar__navigation {
        @include respondto(phone) {
          height: auto;
          margin-bottom: 1.5rem;
        }
        align-items: center;
        margin-bottom: 2.3rem;
      }

      .react-calendar__navigation__prev2-button,
      .react-calendar__navigation__next2-button {
        display: none;
      }
      .react-calendar__navigation__prev-button {
        margin-right: 1.2rem;
        order: 2;
      }
      .react-calendar__navigation__next-button {
        order: 3;
      }
      .react-calendar__navigation__prev-button,
      .react-calendar__navigation__next-button {
        background: $calendar-prev-bg;
        border-radius: 50%;
        box-shadow: 0px 2px 8px 0px rgba(190, 190, 190, 0.15);
        color: $black;
        font-size: 22px;
        height: 32px;
        min-width: 32px;
        width: 32px;
      }

      .react-calendar__navigation__label {
        color: $black;
        display: flex;
        font-family: Poppins;
        font-size: $font-lg;
        font-weight: 500;
        justify-content: flex-start;
        line-height: normal;
        order: 1;
        text-transform: capitalize;

        @include respondto(phone) {
          font-size: 2rem;
        }
      }

      .react-calendar__tile--active {
        background: $main-color !important;
        color: $white !important;
      }

      .react-calendar__tile--now {
        background: $input-bg;
      }
      .react-calendar__year-view__months__month {
        font-size: $font-md !important;
      }
      .react-calendar__month-view__weekdays__weekday {
        color: #696969;
        font-family: Poppins;
        font-size: $font-md;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        @include respondto(phone) {
          font-size: 1.3rem;
        }

        abbr {
          text-decoration: none;
        }
      }

      .react-calendar__tile {
        color: $black;
        font-family: Poppins;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        @include respondto(phone) {
          font-size: 1.6rem;
        }
        &:disabled {
          background: $input-color;
        }
      }

      .react-calendar__year-view,
      .react-calendar__decade-view,
      .react-calendar__century-view {
        .react-calendar__tile {
          @include respondto(phone) {
            padding: 1rem;
          }
        }
      }
    }
  }
}
