@import '../../assets/styles/variables';

.pagination-container {
  align-items: center;
  display: flex;
  gap: 57px;
  justify-content: center;
  @include respondto(largeDesktopSm) {
    gap: 2.9688vw;
  }
  @include respondto(laptop13) {
    margin-bottom: 4rem;
  }

  .pagination {
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 0;
    @include respondto(largeDesktopSm) {
      gap: 0.625vw;
    }
    .page-item {
      .page-link {
        align-items: center;
        background: $light-button-color;
        border: none;
        border-radius: 7px;
        color: $dark-charcoal;
        display: flex;
        font-size: 1.3rem;
        font-weight: 600;
        height: 42px;
        justify-content: center;
        padding: 0 !important;
        width: 42px;
        @include respondto(largeDesktopSm) {
          border-radius: 0.3646vw;
          height: 2.188vw;
          width: 2.188vw;
        }
        @include respondto(largeDesktopMd) {
          font-size: 0.677vw;
        }
        @include respondto(laptopMd) {
          font-size: 0.8333vw;
        }
        span {
          color: $text-color-pagination;
          font-size: 2.4rem;
          @include respondto(largeDesktopMd) {
            font-size: 1.25vw;
          }
          @include respondto(laptopMd) {
            font-size: 1.3889vw;
          }
        }

        svg {
          height: 3.4rem;
          fill: $text-color-pagination;
          margin: auto;
          width: 2.4rem;
          @include respondto(largeDesktopSm) {
            height: 1.7708vw;
            width: 1.25vw;
          }
        }
      }

      &.active {
        .page-link {
          background: $main-color;
          color: $white;

          span {
            color: $white;
          }
        }
      }

      &:not(.active):not(.ellipsis) {
        .page-link {
          &:hover {
            background: $main-color-hover;
            color: $white;

            span {
              color: $white;
            }

            svg {
              fill: $white;

              path {
                fill: $white;
              }
            }
          }
        }
      }

      &.ellipsis {
        cursor: default;

        span {
          font-size: 1.3rem !important;
          @include respondto(largeDesktopMd) {
            font-size: 1.25vw;
          }
          @include respondto(laptopMd) {
            font-size: 1.3889vw;
          }
        }

        .page-link {
          background: transparent;
          box-shadow: none !important;
          cursor: default;

          span {
            color: $text-color-pagination;
          }
        }
      }
    }
  }

  .page-number-container {
    background: $input-bg;
    border: 1px solid $input-border-color;
    border-radius: 4px;
    color: $input-font-color;
    position: relative;
    @include respondto(largeDesktopSm) {
      border-radius: 0.521vw;
    }

    &::after {
      bottom: 2px;
      content: url('../../assets/svg/pagenumber.svg');
      height: 16px;
      position: absolute;
      right: 15px;
      width: 16px;
      z-index: 0;
      @include respondto(largeDesktopSm) {
        bottom: 0.521vw;
        height: 0.833vw;
        right: 0.781vw;
        width: 0.833vw;
      }
    }

    &::before {
      content: url('../../assets/svg/pagenumber.svg');
      height: 16px;
      position: absolute;
      right: 15px;
      top: 2px;
      transform: rotate(180deg);
      width: 16px;
      z-index: 0;
      @include respondto(largeDesktopSm) {
        height: 0.833vw;
        right: 0.781vw;
        top: 0.521vw;
        width: 0.833vw;
      }
    }

    select {
      background: none;
      border: none;
      color: $input-font-color;
      font-size: $font-md;
      font-weight: 500;
      height: 30px;
      line-height: 20px;
      padding: 8px 16px;
      position: relative;
      width: 75px;
      z-index: 1;
      @include respondto(largeDesktopSm) {
        // height: 2.292vw;
        // line-height: 1.042vw;
        padding: 0.4167vw 0.833vw;
        width: 3.9063vw;
      }
      // @include respondto(largeDesktopMd) {
      //   font-size: 0.833vw;
      // }
      // @include respondto(laptopMd) {
      //   font-size: 1.0417vw;
      // }
    }
  }
}

.list-component {
  &-pagination {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: -10px 0 53px;
    width: 100%;

    @include respondto(laptop13) {
      margin: -10px 0 36px;
    }

    ul {
      display: flex;
      gap: 10px;
      @include respondto(largeDesktopSm) {
        gap: 0.521vw;
      }
      li {
        align-items: center;
        background: $white;
        border: none;
        border-radius: 7px;
        color: $black;
        display: flex;
        font-size: 1.3rem;
        font-weight: 600;
        height: 30px;
        justify-content: center;
        padding: 0 !important;
        width: 30px;
        // @include respondto(largeDesktopSm) {
        //   border-radius: 0.3646vw;
        //   height: 2.188vw;
        //   width: 2.188vw;
        // }
        // @include respondto(largeDesktopMd) {
        //   font-size: 0.677vw;
        // }
        // @include respondto(laptopMd) {
        //   font-size: 0.8333vw;
        // }
        a {
          align-items: center;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          width: 100%;
        }

        &.break {
          background: transparent;
          &:hover {
            background: transparent;
            color: $main-color;
          }
        }

        &.selected,
        &:hover {
          background: $main-color;
          color: $white;
        }
      }
    }

    .previous,
    .next {
      align-items: center;
      background: $white;
      border: none;
      border-radius: 7px;
      color: $black;
      display: flex;
      font-size: 1.3rem;
      font-weight: 600;
      height: 30px;
      justify-content: center;
      min-width: 30px;
      padding: 0 !important;
      position: relative;
      transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
      // @include respondto(largeDesktopSm) {
      //   border-radius: 0.3646vw;
      //   height: 2.188vw;
      //   width: 2.188vw;
      // }
      // @include respondto(largeDesktopMd) {
      //   font-size: 0.677vw;
      // }
      // @include respondto(laptopMd) {
      //   font-size: 0.8333vw;
      // }
      span {
        color: $black;
        font-size: 1.3rem;
        // @include respondto(largeDesktopMd) {
        //   font-size: 1.25vw;
        // }
        // @include respondto(laptopMd) {
        //   font-size: 1.3889vw;
        // }
      }

      svg {
        height: 2.4rem;
        fill: $text-color-pagination;
        margin: auto;
        width: 2.4rem;
        // @include respondto(largeDesktopSm) {
        //   height: 1.7708vw;
        //   width: 1.25vw;
        // }
      }

      &:hover {
        background: $main-color;
        color: $white;

        span {
          color: $white;
        }

        svg {
          fill: $white;

          path {
            fill: $white;
          }
        }
      }
    }
  }
}
