@import '../../assets/styles/variables';

.particular-list-item {
  background: $white;
  border-radius: 0 15px 15px 15px;
  box-shadow: 0 27px 42.3px 0 rgba($black, 0.04);
  margin-bottom: 35px;
  padding: 15px 24px;
  @include respondto(largeDesktopSm) {
    margin-bottom: 1.8229vw;
    // padding: 1.25vw 1.4583vw;
  }
  @include respondto(laptop13) {
    display: none;
  }

  form {
    display: grid;
    grid-template-columns: 3% 15.6% 17.8% 17.7% 15.6%;
    width: 100%;
    @include respondto(laptop13) {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .filter-item {
      border-right: 1px solid $bright-dusk;
      max-width: 100%;
      padding: 0 36px;
      width: 100%;

      @include respondto(largeDesktopSm) {
        padding: 0 1.875vw;
      }

      &:last-of-type {
        border-right: none;
        padding-right: 0;
        text-align: left;
      }

      &:nth-of-type(2) {
        padding-left: 0;
      }

      label {
        color: $black;
        font-size: $font-md;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        position: relative;
      }

      .flex-input {
        display: flex;
        gap: 7.95px;
      }
    }

    .form-check-input {
      height: 20px;
      margin-right: 24px;
      margin-top: 0;
      width: 20px;

      // @include respondto(largeDesktopSm) {
      //   height: 1.563vw;
      //   margin-right: 2.1354vw;
      //   width: 1.563vw;
      // }
    }
  }
}
