@import '../../assets/styles/variables';

.ads-form-container-block {
  .form-item-flex {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 35px;
    margin-bottom: 45px;
    @include respondto(largeDesktopSm) {
      gap: 0.521vw 1.8229vw;
      margin-bottom: 2.344vw;
    }
    .heading-block {
      margin: 0;
      width: auto;
    }

    .form-item-group {
      max-width: 95px;
      width: auto;
      @include respondto(largeDesktopSm) {
        max-width: 4.948vw;
      }
    }
  }

  .form-item {
    gap: 10px;
    @include respondto(largeDesktopSm) {
      gap: 0.521vw;
    }
  }
  .check-group-container {
    margin-top: 9px;
    @include respondto(largeDesktopSm) {
      margin-top: 0.469vw;
    }
  }
}

.span-title {
  font-size: $font-md;
  font-weight: 400;
  height: 44px;
  line-height: 24px;
  min-width: max-content;
  @include respondto(largeDesktopSm) {
    height: 2.292vw;
    line-height: 1.25vw;
  }
}

.check-group-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 68px;

  @include respondto(largeDesktopSm) {
    gap: 0.521vw 3.49vw;
  }

  @include respondto(laptop13) {
    gap: 10px;
  }

  .form-item {
    display: flex;
    flex-wrap: wrap;
    gap: 21px;
    margin-bottom: 14px;
    position: relative;
    @include respondto(largeDesktopSm) {
      gap: 1.094vw;
      margin-bottom: 0.729vw;
    }

    &:not(:last-child) {
      padding-right: 67px;

      @include respondto(largeDesktopSm) {
        padding-right: 3.49vw;
      }

      @include respondto(laptop13) {
        padding-right: 10px;
      }

      &::after {
        background-color: $whisper;
        bottom: 0;
        content: '';
        display: block;
        height: 42px;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        @include respondto(largeDesktopSm) {
          height: 2.188vw;
        }
        @include respondto(laptop13) {
          display: none;
        }
      }
    }

    .form-item-group {
      width: 178px;
      @include respondto(largeDesktopSm) {
        width: 9.271vw;
      }
    }
  }
  .form-check {
    .form-check-input[type=radio] {
      top: -3px;
    }
  }
}

.commision-group-container {
  display: flex;
  gap: 10px;
  @include respondto(largeDesktopSm) {
    gap: 0.521vw;
  }
  .form-item {
    display: flex;
    flex-wrap: wrap;
    gap: 21px;
    margin-bottom: 14px;
    padding-right: 20px;

    position: relative;
    @include respondto(largeDesktopSm) {
      gap: 1.094vw;
      margin-bottom: 0.729vw;
      padding-right: 1.042vw;
    }
    &:not(:first-child) {
      padding-left: 20px;
      @include respondto(largeDesktopSm) {
        padding-left: 1.042vw;
      }
    }
    .form-item {
      padding: 0;
      width: 100%;
    }
  }
  @include respondto(laptop13) {
    flex-wrap: wrap;
    & > div {
      width: 100%;
      &::after {
        display: none;
      }
      .form-item {
        padding: 0 !important;
        width: 100%;
      }
    }
  }
}

.grafic {
  &-container {
    .heading-block {
      margin-bottom: 3.6rem;
      @include respondto(largeDesktopSm) {
        margin-bottom: 1.875vw;
      }
    }
  }

  &-block {
    margin-bottom: 57px;
    @include respondto(largeDesktopSm) {
      margin-bottom: 2.9688vw;
    }
  }
}

.apexcharts-toolbar {
  display: none !important;
}
