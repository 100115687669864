@import '../../assets/styles/variables';

.property-item {
  gap: 15px;
  // padding: 15px;

  @include respondto(laptop13) {
    flex-direction: column;
    padding: 15px;
  }
  &-options {
    align-items: stretch;
    background-color: $white;
    border-radius: 20px;
    display: flex;
    gap: 15px;
    padding: 15px;
    width: 100%;
    @include respondto(laptop13) {
      display: none;
    }
    &__column {
      background-color: $input-color;
      border-radius: 10px;
      min-height: 100%;
      padding: 15px;
      width: calc((100% - 30px) / 3);
    }
    &__title {
      color: $black;
      display: block;
      font-size: $font-md;
      font-weight: 600;
      padding-bottom: 8px;
      &.active {
        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }
    &__desc {
      color: $black;
      display: block;
      font-size: $font-sm;
      font-weight: 300;
    }
    &__row {
      align-items: center;
      display: flex;
      width: 100%;
    }
    &__header {
      align-items: center;
      display: flex;
      max-width: 100%;
      padding-bottom: 10px;
      width: max-content;
      .property-item-options__title {
        cursor: pointer;
        padding-bottom: 0;
      }
      .property-item-options__title:first-child {
        border-right: 1px solid $main-color;
        margin-right: 15px;
        padding-right: 15px;
      }
    }
    &__label {
      color: $black;
      display: block;
      font-size: $font-sm;
      font-weight: 400;
      width: 160px;
    }
    &__value {
      background-color: $white;
      border-radius: 4px;
      color: $black;
      display: block;
      font-size: $font-sm;
      font-weight: 400;
      padding: 5px 15px;
    }
    &__list {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: flex-start;

      &--item {
        color: $black;
        display: block;
        font-size: $font-sm;
        font-weight: 400;
      }
    }
  }
  .line-border {
    background-color: $input-color;
    @include respondto(laptop13) {
      margin: 5px 0;
    }
  }
  .label-item-tag {
    @include respondto(laptop13) {
      box-shadow: none;
    }
  }
  .label-item-mobile {
    @include respondto(laptop13) {
      box-shadow: none;
    }
  }
  .label-item-rounded-info-sm {
    background-color: $input-color;
    display: block !important;
    font-weight: 300;
    height: max-content;
  }
  .icons-container {
    display: none;
    .icons-title {
      color: $header-title;
      font-size: $font-sm;
      font-weight: 600;
      padding: 15px 0 10px;
      width: 100%;
    }
    @include respondto(laptop13) {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      width: 100%;
    }
  }
  .contact-list {
    display: flex !important;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .contact-item {
    font-weight: 300 !important;
    height: min-content;
    @include respondto(laptop13) {
      font-weight: 400 !important;
    }
  }
  .bottom-border {
    height: auto;
  }
  .checkbox-container {
    max-width: 20px;
    min-width: 20px;
    width: 100%;

    .form-check-input {
      height: 20px;
      width: 100%;
      &[type='checkbox'] {
        border-radius: 4px;
      }
    }
  }

  .gap-10 {
    @include respondto(largeDesktopSm) {
      gap: 0.21vw;
    }
  }
  .info-photo {
    border-radius: 8px;
    height: 100px;
    overflow: hidden;
    width: 100px;
    @include respondto(largeDesktopSm) {
      border-radius: 0.4167vw;
      height: 5.208vw;
      width: 5.208vw;
    }
    img {
      cursor: pointer;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
  &-more {
    @include respondto(onlyDesktop) {
      display: none;
    }
  }

  .list-item-content {
    align-items: center;
    color: $black;
    display: flex;
    flex-direction: row;
    font-size: $font-sm;
    font-style: normal;
    font-weight: 300;
    gap: 0;
    justify-content: space-between;
    line-height: normal;

    @include respondto(laptop13) {
      align-items: flex-start;
      flex-direction: column;
      gap: 3px;
      margin-top: 10px;
    }
    .column-content {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      @include respondto(laptop13) {
        gap: 3px;
        width: 50%;
        .label-item-mobile {
          width: 100% !important;
        }
      }
    }
    .column-item {
      padding: 0 9px;
      @include respondto(largeDesktopSm) {
        padding: 0px 0.469vw;
      }
      @include respondto(laptop13) {
        padding: 0;
      }
      &:first-child {
        padding-left: 0;
        width: 3.798%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(2) {
        width: 8.6879%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(3) {
        width: 6.75%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(4) {
        width: 7.331%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(5) {
        width: 7.362%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(6) {
        width: 7.783%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(7) {
        width: 4.875%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(10) {
        width: 7.784%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
      &:nth-child(11) {
        width: 8.286%;
        @include respondto(laptop13) {
          width: 100%;
        }
        .label-item-tag {
          width: fit-content;
        }
      }
      &:nth-child(12) {
        width: 10.2499%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }
    }
  }

  .info {
    &-photo {
      margin: 0 auto;
      max-width: 100%;

      @include respondto(laptop13) {
        margin: 0 0 0 auto;
      }
      img {
        width: 100%;
      }
    }

    &-adress {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0 9px;
      width: 13.553%;
      @include respondto(laptop13) {
        display: flex;
        flex-wrap: wrap;
        gap: 3px;
        padding: 0;
        width: 100%;
      }

      .display-flex {
        @include respondto(laptop13) {
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
        @include respondto(onlyDesktop) {
          display: flex;
          flex-direction: column;
        }
      }

      span {
        display: block;
        @include respondto(onlyDesktop) {
          display: inline;
        }
        @include respondto(laptop13) {
          color: $black;
          display: block;
          font-size: 1.6rem;
          font-weight: 500;
          line-height: normal;
        }

        @include respondto(phone) {
          font-size: 12px;
        }
        &.label-item-mobile {
          @include respondto(laptop13) {
            font-weight: normal;
          }
        }
      }
    }

    &-data {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0 9px;
      width: 10.834%;
      @include respondto(laptop13) {
        gap: 3px;
        padding: 0;
        width: 100%;
      }

      .display-flex {
        @include respondto(onlyDesktop) {
          display: flex;
          flex-direction: column;
          width: 100%;

          &:last-of-type {
            margin-bottom: 0px;
          }
        }
        @include respondto(laptop13) {
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0px;
          width: 100%;
        }
      }

      span {
        display: block;

        @include respondto(laptop13) {
          color: $black;
          display: block;
          font-size: 1.6rem;
          font-weight: 500;
        }

        @include respondto(phone) {
          font-size: 1.2rem;
        }
        &.label-item-mobile {
          @include respondto(laptop13) {
            font-weight: normal;
          }
        }
      }
    }

    &-agent {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 5px;
      min-width: 52px;
      @include respondto(laptop13) {
        font-size: 1.6rem;
      }
      @include respondto(phone) {
        font-size: 1.2rem;
      }

      .info-image {
        border-radius: 50%;
        height: 4.6rem;
        overflow: hidden;
        width: 4.6rem;
        @include respondto(largeDesktopSm) {
          height: 2.3958vw;
          width: 2.3958vw;
        }
        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }

    &-price,
    &-year,
    &-level,
    &-room {
      text-align: center;
      word-break: break-word;
      @include respondto(laptop13) {
        text-align: left;
      }
    }

    &-icons {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: flex-end;
      min-width: 47px;
      width: auto;
      @include respondto(laptop13) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
      }
    }
  }

  &-details {
    width: 100%;
    .column-photo {
      @include respondto(laptop13) {
        display: none;
      }
    }
  }
  &-top {
    @include respondto(onlyDesktop) {
      display: none;
    }
    .img-title-section {
      align-items: center;
      display: flex;
      gap: 23px;
      margin-bottom: 23px;
      .img-container {
        cursor: pointer;
        height: 0;
        min-width: calc(50% - 12px);
        padding-top: calc(50% - 12px);
        position: relative;
      }
      img {
        border-radius: 8px;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
      }

      .details-container {
        .title-text {
          color: $black;
          font-size: $font-md;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 15px;
          @include respondto(phone) {
            font-size: 1.2rem;
          }
        }

        .rent-text {
          align-items: center;
          color: $main-color;
          display: flex;
          flex-wrap: wrap;
          font-size: $font-md;
          font-style: normal;
          font-weight: 500;
          gap: 10px;
          line-height: 0px;
          padding-bottom: 5px;
          @include respondto(phone) {
            font-size: 1.2rem;
          }
          @include respondto(phoneSm) {
            align-items: flex-start;
            flex-direction: column;
          }

          span {
            color: $black;
            font-size: $font-md;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            @include respondto(phone) {
              font-size: 1.2rem;
            }
          }
        }
      }
    }

    .description-text {
      color: $black;
      display: -webkit-box;
      font-size: $font-md;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-bottom: 23px;
      overflow: hidden;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      -webkit-box-orient: vertical;
      @include respondto(phone) {
        font-size: 1.2rem;
      }
    }

    .pills-details {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 10px;
    }

    .line-divide {
      background-color: $input-color;
      height: 1px;
      margin-bottom: 14px;
      width: 100%;
    }

    .about-details {
      display: flex;
      flex-direction: column;
      gap: 3px;
      margin-bottom: 20px;
      @include respondto(laptop13) {
        gap: 3px;
      }

      .item-detail {
        align-items: center;
        display: flex;
        justify-content: space-between;

        span {
          color: $black;
          font-size: $font-md;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: 50%;

          @include respondto(phone) {
            font-size: 1.2rem;
            font-weight: 400;
          }
        }

        .label-item-tag {
          width: 50%;
        }
      }

      .links-pils {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 15px;
      }
    }
  }
}

.tab-pane {
  .property-item {
    margin-bottom: 1.5rem;
  }
}
