@import '../../assets/styles/variables';

.add-contact-container {
  display: flex;
  flex-direction: column;
  gap: 35px;
  max-width: 1204px;
  position: relative;
  width: 100%;
  @include respondto(largeDesktopSm) {
    gap: 1.8229vw;
    max-width: calc(100% - 22.969vw);
  }

  @include respondto(laptop13) {
    max-width: 100%;
  }
  .back-btn {
    align-items: center;
    background: $main-color;
    border-radius: 5px;
    box-shadow: $block-shadow;
    cursor: pointer;
    display: none;
    height: 40px;
    justify-content: center;
    transition: 0.3s ease-in-out;
    width: 40px;
    @include respondto(desktopXLg) {
      display: flex;
      left: 10px;
      position: absolute;
      top: 10px;
    }
    svg {
      height: 40px;
      width: 40px;
      path {
        fill: $white;
      }
    }
    &:hover {
      background-color: $main-color-hover;

      svg {
        fill: $white !important;

        path {
          fill: $white !important;
        }
      }
    }
  }
  .form-container {
    background: $white;
    border-radius: 15px;
    box-shadow: $block-shadow;
    display: flex;
    gap: 48px;
    padding: 47px 35px 150px;
    @include respondto(largeDesktopSm) {
      gap: 2.5vw;
      padding: 2.4479vw 1.8229vw 7.813vw;
    }
    @include respondto(laptop13) {
      flex-direction: column;
      padding: 47px 35px 90px;
    }

    @include respondto(phone) {
      gap: 20px;
      padding: 15px 11px;
    }

    .img-upload-block {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 30px;
      @include respondto(largeDesktopSm) {
        gap: 1.563vw;
      }
      @include respondto(phone) {
        gap: 20px;
      }
      .upload-container {
        position: relative;

        .edit-btn,
        .delete-btn {
          background-color: $main-color;
          border-radius: 50%;
          height: 3.2rem;
          width: 3.2rem;
          @include respondto(largeDesktopSm) {
            height: 1.667vw;
            width: 1.667vw;
          }
          &:hover {
            background-color: $main-color-hover;
          }
        }

        .edit-btn {
          position: absolute;
          right: 5px;
          top: 15px;
          @include respondto(largeDesktopSm) {
            right: 0.26vw;
            top: 0.781vw;
          }
        }

        .delete-btn {
          bottom: 10px;
          position: absolute;
          right: 10px;
          @include respondto(largeDesktopSm) {
            bottom: 0.521vw;
            right: 0.521vw;
          }
        }
      }
      input[type='file'] {
        display: none;
      }

      .custom-file-upload {
        align-items: center;
        background-color: $input-color;
        border: 1px solid $main-color;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        height: 161px;
        justify-content: center;
        overflow: hidden;
        width: 161px;
        @include respondto(largeDesktopSm) {
          height: 8.385vw;
          width: 8.385vw;
        }
        @include respondto(phone) {
          height: 130px;
          width: 130px;
        }
        svg {
          height: 6rem;
          fill: rgba($main-color, 0.3);
          stroke: rgba($main-color, 0.3);
          width: 6.9rem;
          @include respondto(largeDesktopSm) {
            height: 3.125vw;
            width: 3.594vw;
          }
          path {
            fill: rgba($main-color, 0.3);
            stroke: rgba($main-color, 0.3);
          }
        }
      }

      .radion-block {
        .form-check-input {
          border: 1px solid $main-color;
          height: 24px;
          width: 47px;
          @include respondto(largeDesktopSm) {
            height: 1.25vw;
            width: 2.4479vw;
          }

          &:checked {
            background-color: $main-color;
          }
        }

        label {
          color: $black;
          font-size: $font-md;
          font-weight: 300;
          line-height: 25px;
          padding-right: 10px;
          @include respondto(largeDesktopSm) {
            line-height: 1.302vw;
            padding-right: 0.521vw;
          }
        }
      }
    }

    .form-block {
      width: 100%;

      .title {
        color: $black;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 20px;
        @include respondto(largeDesktopSm) {
          margin-bottom: 1.042vw;
        }
        @include respondto(phone) {
          margin-bottom: 16px;
        }
      }

      form {
        align-items: flex-start;
        display: flex;
        gap: 30px;
        @include respondto(largeDesktopSm) {
          gap: 1.563vw;
        }
        @include respondto(laptop13) {
          justify-content: space-between;
        }

        @include respondto(phone) {
          flex-direction: column;
          gap: 0px;
        }

        .form-line-section {
          max-width: 237px;
          width: 100%;

          @include respondto(laptop13) {
            max-width: 100%;
          }

          .form-item-group {
            margin-bottom: 20px;
            @include respondto(largeDesktopSm) {
              margin-bottom: 1.042vw;
            }
            @include respondto(phone) {
              margin-bottom: 17px;
            }

            textarea {
              height: 131px;
            }
          }

          .input-group {
            margin-bottom: 17px;
            @include respondto(largeDesktopSm) {
              margin-bottom: 0.885vw;
            }
            .form-item-group {
              margin-bottom: 11px;
              @include respondto(largeDesktopSm) {
                margin-bottom: 0.5729vw;
              }
            }
          }
        }

        .form-line-section:nth-of-type(2) {
          .form-item-group:nth-of-type(4) {
            margin-bottom: 52px;
            @include respondto(largeDesktopSm) {
              margin-bottom: 2.708vw;
            }

            @include respondto(phone) {
              margin-bottom: 17px;
            }
          }
        }

        .form-line-section:nth-of-type(3) {
          .form-item-group:nth-of-type(3) {
            margin-bottom: 52px;
            @include respondto(largeDesktopSm) {
              margin-bottom: 2.708vw;
            }
            @include respondto(phone) {
              margin-bottom: 17px;
            }
          }
        }
      }
    }
  }

  .btns-container {
    align-items: center;
    background: $white;
    border-radius: 15px;
    box-shadow: $block-shadow;
    display: flex;
    gap: 17px;
    justify-content: center;
    padding: 23px;
    width: 100%;
    .button-blue {
      position: relative;
    }
    @include respondto(largeDesktopSm) {
      border-radius: 0.781vw;
      gap: 0.885vw;
      padding: 1.1979vw;
    }

    @include respondto(phone) {
      flex-wrap: wrap;
    }
  }
}
.tab-content {
  [aria-labelledby='ads-tabs-tab-modifica-profil'] {
    .add-contact-container {
      gap: 10px;
      .btns-container {
        box-shadow: none;
      }
    }
    .form-container {
      box-shadow: none;
      padding: 10px;
    }
  }
}
