@import '../../assets/styles/variables';

.share {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: $main-color;
  border-radius: 10px;
  bottom: 30px;
  cursor: pointer;
  gap: 13px;
  height: 50px;
  right: 30px;
  transition: all 0.5s;
  width: 50px;
  z-index: 9999;
  @media screen and (max-width: 768px) {
    bottom: 15px;
    right: 15px;
  }

  p {
    color: $white;
    display: none;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    transition-delay: 0.5s;
    transition: all 0.5s;
  }

  &:hover {
    transition: all 0.5s;
    width: 162px;

    p {
      display: block;
      transition-delay: 0.5s;
      transition: all 0.5s;
    }
  }
}

.share-modal {
  align-items: center;
  display: flex;

  .modal-dialog {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    margin: 0 auto;
    width: 23vw;
    @media screen and (max-width: 768px) {
      width: 90%;
    }
  }

  .modal-content {
    border-radius: 20px;
  }

  .modal-body {
    padding: 40px;
    position: relative;
    @media screen and (max-width: 768px) {
      padding: 25px 15px;
    }
  }

  .close-btn-modal {
    cursor: pointer;
    height: 15px;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 15px;

    svg path {
      fill: #939393;
    }
  }

  &__button {
    background-color: transparent;
    border: none;
    padding: 0;
    font: inherit;
    color: inherit;
    cursor: pointer;
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;

    table {
      tr {
        td {
          padding: 1rem;
        }
      }
    }
  }

  &__top {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    width: 100%;

    p {
      color: $black;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: left;
    }
  }
}
