@import '../../assets/styles/variables';

.section-container {
  display: flex;
  flex-wrap: wrap;
}

.ads {
  &-contracts {
    &-sm {
      margin-right: auto;
      position: relative;
      width: 300px;

      @include respondto(largeDesktopSm) {
        width: 15.152vw;
      }
      @include respondto(laptopMd) {
        width: 22vw;
      }
      @include respondto(laptop13) {
        margin-bottom: 21px;
        width: 100%;
      }
      .ads-general-container {
        padding: 30px;
        @include respondto(phone) {
          padding: 20px 10px;
        }
      }
      .action-block {
        margin-bottom: 15px;
        .label-gray {
          background: $input-color;
          border-radius: 4px;
          color: $black;
          display: flex;
          font-size: $font-sm;
          font-weight: 400;
          justify-content: center;
          line-height: 14px;
          padding: 10px;
          text-align: center;
          transition: all 0.2s ease-in-out;
          width: 100%;
        }
      }
      .user-general-info {
        margin: 15px 0;
      }
    }
    &-lg {
      width: calc(100% - 330px);

      @include respondto(largeDesktopSm) {
        width: calc(100% - 16.667vw);
      }
      @include respondto(laptopMd) {
        width: calc(100% - 23vw);
      }
      @include respondto(laptop13) {
        margin-bottom: 21px;
        width: 100%;
      }
      .ads-container {
        width: 100%;
        form {
          max-width: 101.4rem;

          .row:not(:first-child) {
            margin-top: 20px;
          }
        }
      }
      .row {
        --bs-gutter-x: 2.4rem;
      }
      .link {
        align-items: center;
        background: transparent;
        color: $main-color;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        position: relative;
        width: max-content;
        .link-label {
          position: relative;
          &:after {
            border-bottom: 1px solid;
            bottom: 0;
            content: '';
            left: 0;
            margin: 0 auto;
            opacity: 0;
            position: absolute;
            right: 0;
            transition: 0.3s ease-in-out;
            width: 0;
          }
        }

        &:hover,
        &:focus {
          color: $main-color-hover;
          text-decoration: none;
          .link-label {
            &::after {
              opacity: 1;
              width: 100%;
            }
          }
        }
      }
      .fild-group {
        display: flex;
        flex-direction: row;
        gap: 10px;
        .form-item-group {
          width: calc(100% - 4rem);
        }
        .button-square-md {
          margin-top: 21px;
        }
        .btn-main {
          border-radius: 4px;
          height: 3.4rem;
          transition: 0.3s ease-in-out;
          width: 3.4rem;
          svg {
            transition: all 0.3s ease-in-out;
          }
        }
      }
      .fieldset-heading {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        font-size: 1.4rem;
        font-weight: 500;
        gap: 0.5rem;
        line-height: 21px;
        margin-bottom: 0;
        margin-top: 5px;
        .link {
          color: $main-color;
          padding-left: 5px;
        }
      }
      .fieldset-form {
        row-gap: 15px;
        .row {
          row-gap: 15px;
        }
      }
    }
    &-buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      margin-top: 10px;

      .button {
        min-width: 15rem;
        @include respondto(phone) {
          width: 100%;
        }
      }
    }

    &-info {
      table {
        width: 100%;
      }

      &__content {
        align-items: flex-end;
        color: $black;
        display: flex;
        font-weight: 500;
        justify-content: flex-end;
        text-align: right;
      }

      &-bottom {
        @include respondto(phone) {
          padding: 20px 15px;
        }
      }

      &-more {
        @include respondto(desktop) {
          display: none;
        }
      }
    }
  }
}
