@import '../../assets/styles/variables';
.add-contract-form-contact-wrapper {
  min-height: calc(100% - 4rem);
  padding: 3rem;

  @include respondto(laptop13) {
    box-shadow: none !important;
    padding: 0;
  }

  .fieldset-heading {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 2rem;
    min-width: 100%;
  }
  .form-items-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 1.2rem;
    .form-item-lg {
      width: calc(50% - 2.7rem);
    }
    .form-item-sm {
      margin-top: 2rem;
    }
  }
  .add-block {
    line-height: 1;
  }
  .form-field {
    margin-bottom: 4rem;
  }
}

.contract-info-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0 2.1rem;
  &__item {
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: wrap;
    gap: 0.4rem;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    width: calc(50% - 1.05rem);
    @include respondto(desktopSm) {
      width: 100%;
    }
    @include respondto(phone) {
      width: 100%;
    }
    .label-item-tag {
      font-weight: 500;
      min-height: 2.6rem;
    }
    .item-th {
      font-size: $font-sm;
      font-weight: 300;
      line-height: 2.8rem;
      padding-right: 0.5rem;
      width: max-content;
      .label-item-tag {
        display: inline-block;
        margin-left: 1rem;
        padding: 4px 7px;
        width: auto;
      }
    }
    .item-start {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: calc(100% - 30px);
    }
    .item-td {
      width: max-content;
      .label-item-tag {
        margin-left: auto;
        width: fit-content;
      }
    }
  }
}
