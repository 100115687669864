@import '../../assets/styles/variables';

.agent-container {
  .flex-add-agent {
    gap: 32px;
    justify-content: space-between;
    @include respondto(largeDesktopSm) {
      gap: 1.667vw;
    }
    @include respondto(laptop13) {
      flex-direction: column !important;
    }
  }

  .btns-container-mobile {
    align-items: center;
    background: $white;
    border-radius: 20px;
    box-shadow: 0 27px 42.3px 0 rgba($black, 0.04);
    display: none;
    gap: 17px;
    justify-content: center;
    padding: 15px;
    width: 100%;

    @include respondto(laptop13) {
      display: flex;
    }

    @include respondto(phone) {
      flex-wrap: wrap;
      padding: 15px 10px;
    }
  }

  .spiner-container {
    margin-top: 250px;
  }
}
