@import '../../assets/styles/variables';

.missing-access-container {
  p {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 16px;
  }
}
