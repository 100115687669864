@import '../../assets/styles/variables';
.modal-backdrop.show {
  &:has(.alert-modal.modal.show) {
    --bs-backdrop-zindex: 99999;
  }
}

.alert-modal {
  --bs-modal-zindex: 999999 !important;
  align-items: center;
  display: flex !important;
  justify-content: center;
  margin: 0 auto;

  .modal-dialog {
    --bs-modal-width: 1050px;
    bottom: auto !important;
    top: 0;
    width: 100%;
    @include respondto(phone) {
      max-width: 100%;
    }
  }

  @include respondto(phone) {
    padding: 0 !important;
  }

  .modal-header {
    border-bottom: none;
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 2.4rem;
    margin-bottom: 2.4rem;
    padding: 0;
    text-align: center;
    @include respondto(phone) {
      margin-bottom: 2rem;
    }
    span {
      width: 100%;
      svg {
        height: 2.4rem;
        margin-right: 8px;
        width: 2.4rem;
      }
    }
  }
  .modal-body {
    --bs-modal-padding: 0;
  }
  .modal-dialog {
    max-height: 100%;
  }
  .modal-content {
    background-color: $white;
    border-radius: 20px;
    padding: 42px 30px 15px;
    @include respondto(desktopXLg){
      margin-bottom: 50px;
      margin-top: 180px;
    }
    @include respondto(phone) {
      margin-top: 100px;
      padding: 30px 15px 15px;
    }
  }

  .close-btn-modal {
    align-items: center;
    background: $white;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: 5px;
    top: 5px;
    transition: 0.3s ease-in-out;
    width: 40px;
    &:hover {
      background: $main-color;
    }
  }
}

.accordion-add {
  strong {
    font-weight: 600;
  }

  .accordion-item {
    border: none;
    box-shadow: none;
    margin-bottom: 15px;
  }
  .accordion-button {
    --bs-accordion-inner-border-radius: 5px;
    --bs-accordion-btn-padding-y: 1.6rem;
    --bs-accordion-btn-padding-x: 0;
    --bs-accordion-active-bg: #f5f5f5;
    background: $input-color;
    border-radius: var(--bs-accordion-inner-border-radius);
    color: $black;
    font-size: 1.2rem;
    line-height: 2.4rem;
    @include respondto(phone) {
      --bs-accordion-btn-padding-y: 1rem;
      flex-wrap: wrap;
      line-height: 2rem;
    }
    strong {
      font-weight: 600;
    }
    &::after {
      margin-left: 2rem;
      margin-right: 2.2rem;
      position: absolute;
      right: 0;
    }
    .number {
      font-size: 1.6rem;
      margin-right: 0.3rem;
      min-width: 3rem;
      strong {
        font-weight: 600;
      }
    }
    .header-left {
      display: flex;
      padding-left: 2.2rem;
      width: 75%;
      @include respondto(laptop13) {
        width: 60%;
      }
      @include respondto(phone) {
        padding-left: 1.5rem;
      }
      @include respondto(phoneSm) {
        width: calc(100% - 5rem);
      }
    }
    .title {
      color: $black;
    }
    .result {
      color: $main-color;
      display: inline-block;
      font-size: 1.3rem;
      font-weight: 600;
      width: calc(25% - 6rem);
      @include respondto(laptop13) {
        width: calc(40% - 6rem);
      }
      @include respondto(phone) {
        padding-left: 5rem;
        text-align: right;
        width: auto;
      }
      @include respondto(phoneSm) {
        text-align: left;
        width: calc(100% - 5rem);
      }
    }
    &:not(.collapsed) {
      box-shadow: none;
    }
  }
  .accordion-list-item {
    align-items: center;
    border-bottom: 1px solid $bright-dusk;
    display: flex;
    flex-direction: row;
    padding: 1.2rem 0;
    @include respondto(phone) {
      flex-wrap: wrap;
    }
    .column-list-item {
      align-items: center;
      display: flex;
      flex-direction: row;
      font-size: 1.3rem;
      @include respondto(phone) {
        flex-wrap: wrap;
      }
      @include respondto(phoneSm) {
        gap: 0.5rem;
      }
      &:first-child {
        padding-right: 0.5em;
        width: 75%;
        @include respondto(laptop13) {
          width: 60%;
        }
        @include respondto(phone) {
          width: calc(100% - 10rem);
        }
        @include respondto(phoneSm) {
          flex-direction: column;
          padding-right: 0;
          text-align: center;
          width: 100%;
        }
      }
      &:nth-child(2) {
        width: 10rem;
        @include respondto(phoneSm) {
          text-align: right;
          width: 100%;
        }
      }
      .date {
        @include respondto(phoneSm) {
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }
      }
      &:nth-child(3) {
        padding-left: 0.5rem;
        width: calc(25% - 10rem);
        @include respondto(laptop13) {
          text-align: right;
          width: calc(40% - 10rem);
        }
        @include respondto(phone) {
          width: 100%;
        }
        @include respondto(phoneSm) {
          text-align: center;
        }
      }
    }
    .price {
      margin-left: auto;
      @include respondto(phoneSm) {
        margin-right: auto;
      }
    }
    .title {
      width: calc(100% - 43px);
    }
    .info-img {
      border-radius: 5px;
      height: 30px;
      margin-right: 13px;
      overflow: hidden;
      width: 30px;
      @include respondto(phoneSm) {
        margin-right: 0;
      }
      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }
  .button-block {
    margin: 15px 0 30px;
    width: 100%;
    .button-blue {
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 3rem;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .accordion-body {
    padding: 0;
  }
}
