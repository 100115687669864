@import '../../assets/styles/variables';

.properties-updates-wrapper {
  background: $azure;
  border-radius: 20px;
  max-width: 1136px;
  padding: 36px 32px;
  width: 100%;
  @include respondto(largeDesktopSm) {
    border-radius: 1.042vw;
    padding: 1.875vw 1.667vw;
  }
  @include respondto(desktopLg) {
    margin-bottom: 3rem;
    max-width: 100%;
  }

  @include respondto(laptop13) {
    background: transparent;
    border-radius: 0;
    padding: 0;
  }

  .properties-updates-container {
    background: $white;
    border-radius: 20px;
    box-shadow: $block-shadow;
    padding: 34px 45px;
    @include respondto(largeDesktopSm) {
      border-radius: 1.042vw;
      padding: 1.7708vw 2.344vw;
    }
    @include respondto(laptop13) {
      padding: 15px 11px;
    }

    h4 {
      color: $black;
      font-size: 24px;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 17px;
      @include respondto(largeDesktopSm) {
        font-size: 1.25vw;
        margin-bottom: 0.885vw;
      }
    }
  }

  .input-checkbox-line {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    @include respondto(largeDesktopSm) {
      margin-bottom: 1.667vw;
    }

    @include respondto(phone) {
      align-items: start;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: flex-start;
    }

    .form-check {
      display: flex;

      label {
        color: $black;
        font-size: $font-md;
        font-style: normal;
        font-weight: 400;
        // @include respondto(largeDesktopMd) {
        //   font-size: 0.729vw;
        // }
        // @include respondto(laptopMd) {
        //   font-size: 0.9028vw;
        // }
      }
    }
  }

  .img-block {
    margin-bottom: 19px;
    @include respondto(largeDesktopSm) {
      margin-bottom: 0.99vw;
    }
  }

  .btn-container {
    .button-blue {
      @include respondto(phone) {
        width: 100%;
      }
    }
  }
}
