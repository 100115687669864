@import '../../assets/styles/variables';

.resident-complex-item {
  gap: 22px;
  // padding: 26px 17px 26px 28px;

  @include respondto(largeDesktopSm) {
    gap: 1.1458vw;
    // padding: 1.354vw 0.885vw 1.354vw 1.4583vw;
  }

  @include respondto(laptop13) {
    flex-direction: column;
    gap: 15px;
    padding: 15px 11px;
  }

  .list-item-content {
    align-items: center;
    color: $black;
    display: flex;
    // justify-content: space-between;
    flex-direction: row;
    font-size: $font-md;
    font-style: normal;
    font-weight: 400;
    gap: 0;
    line-height: normal;
    // @include respondto(largeDesktopSm) {
    //     gap: 1.042vw;
    // }

    @include respondto(laptop13) {
      flex-direction: column;
      gap: 10px;
    }

    .column-item {
      padding: 0 3rem;
      @include respondto(largeDesktopSm) {
        padding: 0 1.563vw;
      }
      @include respondto(laptop13) {
        padding: 0;
      }

      &:first-child {
        padding: 0;
        width: 7.2%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }

      &:nth-child(2) {
        padding: 0;
        width: 7.6%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }

      &:nth-child(3) {
        width: 13%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }

      &:nth-child(6) {
        width: 16%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }

      &:nth-child(7) {
        width: 9%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }

      &:nth-child(8) {
        width: 11%;
        @include respondto(laptop13) {
          width: 100%;
        }

        .label-item-rounded-tag {
          margin-left: auto;
          margin-right: auto;
          @include respondto(laptop13) {
            margin-right: 0;
          }
        }
      }

      .label-item-rounded-tag {
        @include respondto(laptop13) {
          font-weight: 400 !important;
        }
      }
    }
  }
  // .label-item-rounded-tag,
  // .label-item-rounded-info-lg {
  //   font-size: $font-md;
  //   height: 30px;
  //   min-height: 30px;
  // }

  .info {
    &-details {
      padding: 0 0 0 3rem;
      width: 14.2%;
      @include respondto(largeDesktopSm) {
        padding: 0 0 0 1.563vw;
      }
      @include respondto(laptop13) {
        padding: 0;
        width: 100%;
      }

      &:nth-child(5) {
        width: 15%;
        @include respondto(laptop13) {
          width: 100%;
        }
      }

      span {
        display: block;
      }

      .flex-mobile {
        display: flex;

        @include respondto(laptop13) {
          align-items: center;
          justify-content: space-between;
        }

        span {
          @include respondto(laptop13) {
            color: $black;
            display: block;
            font-size: $font-md;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          @include respondto(phone) {
            font-size: 1.2rem;
          }

          &.label-item-mobile {
            word-wrap: anywhere;

            @include respondto(laptop13) {
              font-weight: 400;
            }
          }
        }
      }

      @include respondto(laptop13) {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
      }
    }

    &-type {
      max-width: 100px;

      @include respondto(laptop13) {
        max-width: 50%;
      }
    }

    &-photo {
      height: 100px;
      margin: 0 auto;
      width: 100px;
      @include respondto(largeDesktopSm) {
        height: 5.2083vw;
        width: 5.2083vw;
      }
      @include respondto(laptop13) {
        margin: 0 0 0 auto;
      }

      img {
        border-radius: 8px;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    &-photo-mobile {
      cursor: pointer;
      height: 0;
      min-width: calc(50% - 12px);
      padding-top: calc(50% - 12px);
      position: relative;

      img {
        border-radius: 8px;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    &-agent {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 5px;
      max-width: 52px;
      @include respondto(largeDesktopSm) {
        max-width: 2.708vw;
      }

      .info-image {
        border-radius: 50%;
        height: 46px;
        overflow: hidden;
        width: 46px;
        @include respondto(largeDesktopSm) {
          height: 2.3958vw;
          width: 2.3958vw;
        }

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      .info-name {
        font-size: $font-sm;
        @include respondto(phone) {
          font-size: 1.2rem;
        }
      }
    }

    &-asociates {
      align-items: center;
      display: flex;
      gap: 16px;
      @include respondto(largeDesktopSm) {
        gap: 0.833vw;
      }

      .text {
        max-width: 70px;
      }

      .link {
        color: $black;
        display: flex;
        flex-direction: row;
        font-size: $font-md;
        font-style: normal;
        font-weight: 300;
        transition: 0.3s ease-in-out;

        @include respondto(phone) {
          font-size: 1.2rem;
        }

        &:hover {
          color: $main-color-hover;
        }
      }
    }

    &-status {
      background: $input-color;
      border-radius: 22px;
      color: $black;
      font-size: $font-md;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 10px;
    }

    &-icons {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-left: auto;
      margin-right: auto;
      max-width: 105px;

      @include respondto(onlyDesktop) {
        padding-left: 20px;
      }

      @include respondto(laptop13) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
