@import '../../assets/styles/variables';

.request-associated {
  .label-item-rounded-info-sm {
    height: auto;
  }

  &-item {
    display: grid;
    gap: 0;
    grid-template-columns: 4.5% 18.4759% 30.586% 21.952% 15% 9%;
    padding: 1.9rem 1.6rem;
    width: 100%;
    @include respondto(laptop13) {
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem;
      padding: 1.5rem 2rem;
    }
    @include respondto(desktopLg) {
      padding: 1.5rem 2rem;
    }
    &.bg-blue {
      border-radius: 1.5rem;
      padding: 1.5rem;
    }
    .info-adress .display-flex {
      flex-direction: row;
      gap: 0.5rem;
    }
    .checkbox-container .form-check-input :not(:checked) {
      background-color: transparent;
    }
    .mobile-lines {
      .line-border {
        @include respondto(onlyDesktop) {
          display: none;
        }
      }
    }
    &--column {
      align-items: flex-start;
      border-right: 1px solid $white;
      display: flex;
      flex-direction: column;
      font-size: $font-md;
      font-weight: 400;
      justify-content: center;
      line-height: 1.4;
      &:not(:last-child) {
        padding: 0 2rem;
        @include respondto(laptop13) {
          border-right: none;
          padding: 0;
          width: 100%;
        }
      }
      &:last-child,
      &:nth-child(2) {
        border-right: none;

        @include respondto(laptop13) {
          border-right: none;
          padding: 0;
          width: 100%;
        }
      }
      &:first-child {
        align-items: center;
        border-right: none;
        display: flex;
        justify-content: center;
        padding-left: 0;
        @include respondto(laptop13) {
          order: 1;
        }
      }
      &:nth-child(2) {
        @include respondto(laptop13) {
          max-width: 45%;
          order: 2;
          .image {
            cursor: pointer;
            height: 0;
            min-width: calc(100% - 12px);
            padding-top: calc(70% - 12px);
            position: relative;
          }
          img {
            border-radius: 8px;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            width: 100%;
          }
        }
      }
      &:nth-child(3) {
        @include respondto(laptop13) {
          max-width: calc(50% - 20px);
          order: 5;
        }
      }
      &:nth-child(4) {
        @include respondto(laptop13) {
          order: 4;
        }
      }
      &:nth-child(5) {
        @include respondto(laptop13) {
          max-width: 50%;
          order: 3;
        }
      }
      &:nth-child(6) {
        @include respondto(laptop13) {
          order: 6;
        }
      }

      @include respondto(desktopLg) {
        font-size: 1.3rem;
        line-height: 1.8rem;
      }
      @include respondto(laptop13) {
        border-right: none;
        font-size: 1.3rem;
        line-height: 1.8rem;
        padding: 0;
        width: 100%;
      }
      .price {
        @include respondto(laptop13) {
          color: $main-color;
          font-weight: 500;
        }
        span {
          @include respondto(laptop13) {
            color: $black;
          }
        }
      }
      .image {
        margin: 0;
        max-width: 16rem;
        min-height: 8rem;
        min-width: 100%;
        @include respondto(laptop13) {
          margin: 0;
        }
        img {
          border-radius: 8px;
          height: 100%;
          max-height: 11rem;
          max-width: 100%;
          min-height: 100%;
          min-width: 100%;
          object-fit: cover;
        }
      }
      .info-properties {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 0.5rem;
        .display-flex {
          align-items: center;
          gap: 0.5rem;
          &:not(:first-child) {
            @include respondto(laptop13) {
              align-items: center;
              background: $white;
              border-radius: 8px;
              color: $black;
              display: flex;
              font-size: 1.3rem;
              font-weight: 300;
              gap: 10px;
              line-height: normal;
              min-height: 30px;
              padding: 4px 10px;
            }
          }
        }
        @include respondto(laptop13) {
          align-items: center;
          flex-direction: row;
        }
      }
      .action-buttons-list {
        justify-content: center;
        margin: 0;
        width: 100%;
        @include respondto(laptop13) {
          flex-direction: row !important;
          width: 100%;
        }
      }
    }
    .info {
      &-photo {
        margin: 0 auto;
        max-width: 100%;

        @include respondto(laptop13) {
          margin: 0 0 0 auto;
        }
        img {
          width: 100%;
        }
      }

      &-adress {
        display: flex;
        flex-direction: column;
        @include respondto(laptop13) {
          display: flex;
          flex-wrap: wrap;
          gap: 3px;
          padding: 0;
          width: 100%;
        }

        .display-flex {
          @include respondto(laptop13) {
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }
          @include respondto(onlyDesktop) {
            display: flex;
            flex-direction: row;
          }
        }

        span {
          display: block;
          @include respondto(onlyDesktop) {
            display: inline;
          }
          @include respondto(laptop13) {
            color: $black;
            display: block;
            font-size: 1.6rem;
            font-weight: 500;
            line-height: normal;
          }

          @include respondto(phone) {
            font-size: 12px;
          }
          &.label-item-mobile {
            @include respondto(laptop13) {
              font-weight: normal;
            }
          }
        }
      }

      &-data {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 0 9px;
        width: 10.834%;
        @include respondto(laptop13) {
          gap: 3px;
          padding: 0;
          width: 100%;
        }

        .display-flex {
          @include respondto(onlyDesktop) {
            display: flex;
            flex-direction: column;
            width: 100%;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
          @include respondto(laptop13) {
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0;
            width: 100%;
          }
        }

        span {
          display: block;

          @include respondto(laptop13) {
            color: $black;
            display: block;
            font-size: 1.6rem;
            font-weight: 500;
          }

          @include respondto(phone) {
            font-size: 1.2rem;
          }
          &.label-item-mobile {
            @include respondto(laptop13) {
              font-weight: normal;
            }
          }
        }
      }

      &-agent {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 5px;
        min-width: 66px;

        @include respondto(laptop13) {
          font-size: 1.6rem;
        }
        @include respondto(phone) {
          font-size: 1.2rem;
        }

        .info-image {
          border-radius: 50%;
          height: 5.2rem;
          overflow: hidden;
          width: 5.2rem;
          @include respondto(largeDesktopSm) {
            height: 2.708vw;
            width: 2.708vw;
          }
          img {
            height: 100%;
            object-fit: cover;
            width: 100%;
          }
        }
        .info-name {
          text-align: center;
        }
      }
    }
    .action-buttons-list {
      align-items: flex-end;
      flex-direction: column;
      @include respondto(phone) {
        flex-direction: row;
        width: 100%;
      }
    }
  }
}
