@import '../../assets/styles/variables';

.add-activity-container {
  display: flex;
  flex-direction: column;
  gap: 35px;
  max-width: 899px;
  width: 100%;

  @include respondto(largeDesktopSm) {
    gap: 1.8229vw;
    max-width: calc(100% - 38.229vw);
  }

  @include respondto(laptop13) {
    max-width: 100%;
  }

  .quantity {
    align-items: center;
    display: flex;
    gap: 3px;
  }

  // .react-datepicker-wrapper input {
  //   font-size: 1.4rem;
  //   @include respondto(largeDesktopSm) {
  //     font-size: 0.729vw;
  //   }
  // }

  .flex-range {
    display: flex;
    justify-content: flex-start;
  }

  .react-datepicker-wrapper::after {
    background-repeat: no-repeat;
  }

  .input-flex {
    display: flex;
    gap: 10px;

    &:not(:first-child) {
      padding-top: 20px;
    }

    .form-item.col-xxl-2 {
      max-width: 46px;
    }
  }

  .form-container {
    background: $white;
    border-radius: 25px;
    box-shadow: 0px 27px 42.3px 0px rgba($black, 0.04);
    display: flex;
    gap: 48px;
    padding: 42px 54px 39px 47px;
    @include respondto(largeDesktopSm) {
      border-radius: 1.302vw;
      gap: 2.5vw;
      padding: 2.188vw 2.813vw 2.0313vw 2.4479vw;
    }
    @include respondto(phone) {
      padding: 15px 11px;
    }

    .form-block {
      width: 100%;

      .title {
        color: $black;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 26px;
        @include respondto(largeDesktopSm) {
          margin-bottom: 1.354vw;
        }
      }

      form {
        align-items: flex-start;
        display: flex;
        gap: 30px;
        @include respondto(largeDesktopSm) {
          gap: 1.563vw;
        }
        @include respondto(laptop13) {
          flex-direction: column;
        }

        .form-item {
          p.error-message {
            margin-bottom: 10px;
            margin-top: -10px;
          }
        }

        .form-line-section {
          max-width: 368px;
          width: 100%;
          @include respondto(largeDesktopSm) {
            max-width: 19.167vw;
          }
          @media screen and (max-width: 1550px) {
            max-width: 100%;
          }

          .form-item-group {
            margin-bottom: 17px;
            @include respondto(largeDesktopSm) {
              margin-bottom: 0.885vw;
            }

            .form-item-control-select {
              svg {
                height: 18px;
                width: 25px;
                @include respondto(largeDesktopSm) {
                  height: 0.938vw;
                  width: 1.302vw;
                }
              }
            }

            textarea {
              height: 104px;
            }

            &-block {
              position: relative;
            }
          }

          .input-group {
            margin-bottom: 17px;
            @include respondto(largeDesktopSm) {
              margin-bottom: 0.885vw;
            }

            .form-item-group {
              margin-bottom: 10px;
              @include respondto(largeDesktopSm) {
                margin-bottom: 0.521vw;
              }
            }

            .add-block {
              align-items: center;
              display: flex;
              flex-wrap: wrap;
              gap: 3px;
              @include respondto(largeDesktopSm) {
                gap: 0.156vw;
              }
              &:hover {
                text-decoration: none;
              }

              .close {
                cursor: pointer;
                height: 2.4rem;
                width: 2.4rem;
                @include respondto(largeDesktopSm) {
                  height: 1.25vw;
                  width: 1.25vw;
                }

                svg {
                  fill: $text-color-pagination;
                  height: 2.4rem;
                  width: 2.4rem;
                  @include respondto(largeDesktopSm) {
                    height: 1.25vw;
                    width: 1.25vw;
                  }

                  path {
                    fill: $text-color-pagination;
                  }
                }

                &:hover {
                  svg {
                    fill: $main-color-hover;

                    path {
                      fill: $main-color-hover;
                    }
                  }
                }
              }
            }
          }

          .flex-input {
            align-items: flex-start;
            display: flex;
            gap: 15px;
            margin-bottom: 0.885vw;
            @include respondto(laptop13) {
              flex-wrap: wrap;
            }

            @include respondto(phoneSm) {
              flex-direction: column;
              gap: 15px;
            }

            .error-message {
              color: $red;
            }

            .date-picker {
              width: 100%;

              &.date {
                max-width: 50%;
                min-width: calc(50% - 0.75rem);
                @include respondto(laptop13) {
                  max-width: 100%;
                  min-width: 100%;
                }
              }

              label {
                color: $black;
                font-style: normal;
                font-weight: 300;
                margin-bottom: 2px;
                margin-top: -2px;

              }
            }

            .react-datepicker-wrapper {
              position: relative;

              &::after {
                background-image: url('../../assets/svg/calendarpicker.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                right: 8px;

              }
            }

            .react-datepicker-wrapper.hours {
              position: relative;

              &::after {
                background-image: url('../../assets/svg/hourpicker.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;

              }
            }
          }

          .form-check {
            align-items: center;
            display: flex;
            margin-bottom: 17px;
            @include respondto(largeDesktopSm) {
              margin-bottom: 0.885vw;
            }

            label {
              color: $black;
              font-style: normal;
              font-weight: 400;
              margin-bottom: 0px;

            }

            input {
              border: 1px solid $input-check-border;
              border-radius: 4px;
              box-shadow: 0 0 0px 0.5px $input-check-border;
              height: 20px;
              margin-top: 0px;
              width: 20px;

              @include respondto(desktopXLg) {
                box-shadow: none;
              }

              &:checked {
                background-color: $main-color;
                border: 1px solid $main-color;
                box-shadow: 0 0 0px 0.5px $main-color;

                @include respondto(desktopXLg) {
                  box-shadow: none;
                }
              }
            }
          }

          .notification-group {
            margin-bottom: 34px;
            @include respondto(largeDesktopSm) {
              margin-bottom: 1.7708vw;
            }

            p {
              color: $black;
              font-size: $font-md;
              font-style: normal;
              font-weight: 600;
              margin-bottom: 17px;
              @include respondto(largeDesktopSm) {
                margin-bottom: 0.885vw;
              }

            }

            .input-group-flex {
              align-items: center;
              display: flex;
              flex-wrap: wrap;
              gap: 24px;
              margin-bottom: 8px;
              @include respondto(largeDesktopSm) {
                gap: 1.25vw;
                margin-bottom: 0.4167vw;
              }

              &.input-group-flex-sm {
                gap: 8px;
                @include respondto(largeDesktopSm) {
                  gap: 0.4167vw;
                }
              }
            }

            .input-flex {
              align-items: center;
              display: flex;
              gap: 5px;
              padding-top: 0;
              width: 100%;

              .form-item-group {
                margin-bottom: 0px;
              }
            }

            .value {
              align-items: center;
              background: $input-color;
              border-radius: 4px;
              color: $black;
              display: flex;
              font-size: 1.4rem;
              font-style: normal;
              font-weight: 500;
              height: 34px;
              justify-content: center;
              min-width: 34px;
            }

            .close {
              align-items: center;
              background: $light-button-color;
              border: 1px solid $light-button-border;
              border-radius: 4px;
              display: flex;
              height: 34px;
              justify-content: center;
              margin-left: auto;
              transition: 0.3s ease-in-out;
              width: 34px;

              svg {
                height: 24px;
                fill: $black;
                transition: 0.3s ease-in-out;
                width: 24px;
                @include respondto(largeDesktopSm) {
                  height: 1.25vw;
                  width: 1.25vw;
                }

                path {
                  fill: $black;
                }
              }

              &:hover {
                background: $light-button-color-hover;
              }
            }

            .add-notification {
              background: transparent;
              color: $main-color;
              cursor: pointer;
              font-size: $font-md;
              font-style: normal;
              font-weight: 500;
              transition: 0.3s ease-in-out;

              &:hover {
                color: $main-color-hover;
                text-decoration: underline;
              }
            }
          }
        }

        .form-item-md {
          width: calc(48.37% - 51px);
          @include respondto(largeDesktopSm) {
            width: calc(48.37% - 2.656vw);
          }
          @include respondto(phone) {
            width: calc(50% - 51px);
          }
        }

        .form-item-sm {
          width: 46px;
          @include respondto(largeDesktopSm) {
            width: 2.3958vw;
          }
          .form-item-control {
            padding: .1rem;
            text-align: center;
          }
        }

        .react-select__indicator {
          @include respondto(phone) {
            padding: 8px 0;
          }
        }
      }
    }
  }

  .btns-container {
    align-items: center;
    background: $white;
    border-radius: 15px;
    box-shadow: $block-shadow;
    display: flex;
    gap: 17px;
    justify-content: center;
    padding: 13px;
    width: 100%;

    .button-blue {
      position: relative;
    }

    @include respondto(largeDesktopSm) {
      border-radius: 0.781vw;
      gap: 0.885vw;
      padding: 0.677vw;
    }

    @include respondto(laptop13) {
      display: none;
    }
  }
}

.add-activity-container {
  .label-item-rounded-info-sm {
    line-height: 20px !important;
  }
}
