@import '../../assets/styles/variables';

.filter-list-container {
  background: $white;
  border-radius: 0 15px 15px 15px;
  box-shadow: $block-shadow;
  margin-bottom: 35px;
  padding: 15px;

  @include respondto(laptop13) {
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 0;
    padding: 0;
  }

  form {
    display: flex;

    .arrows-label-icon {
      &.active-bottom {
        label {
          margin-bottom: 6px;
          position: relative;

          &::before {
            background-image: url('../../assets//svg/labelicontopactive.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            display: block;
            height: 15px;
            position: absolute;
            right: -10px;
            top: 3px;
            width: 8px;
          }
        }
      }

      &.active-top {
        label {
          margin-bottom: 6px;
          position: relative;
          &::after {
            background-image: url('../../assets//svg/labeliconbottomactive.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            display: block;
            height: 15px;
            position: absolute;
            right: -10px;
            top: 3px;
            width: 8px;
          }
        }
      }

      label {
        margin-bottom: 6px;
        position: relative;
        &::after {
          background-image: url('../../assets//svg/labelicon.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          content: '';
          display: block;
          height: 15px;
          position: absolute;
          right: -10px;
          top: 3px;
          width: 8px;
        }

        &::before {
          background-image: url('../../assets//svg/labelicontop.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          content: '';
          display: block;
          height: 15px;
          position: absolute;
          right: -10px;
          top: 3px;
          width: 8px;
        }
      }
    }

    .filter-item {
      border-right: 1px solid $bright-dusk;
      padding: 0 9px;
      width: 100%;

      @include respondto(largeDesktopSm) {
        padding: 0 0.469vw;
      }

      @include respondto(laptop13) {
        border-right: none;
        padding: 0 !important;
      }

      &:last-of-type {
        border-right: none;
        padding-right: 0 !important;
        text-align: center;
      }

      label {
        color: $black;
        font-size: $font-md;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 6px;
        position: relative;
        @include respondto(laptopMd) {
          font-size: $font-sm;
        }
        @include respondto(laptop13) {
          font-weight: 400;
        }
      }

      .flex-input {
        display: flex;
        gap: 7.95px;
      }
    }

    .form-item-control {
      font-size: $font-sm;
      min-height: 30px;
      padding: 5px 8px;
    }

    .form-item-group {
      .form-item-control-select {
        font-size: $font-sm;
        min-height: 30px;

        .react-select__indicator {
          padding: 3px;

          img {
            height: 2rem;
            width: 2rem;
          }
        }

        .react-select__placeholder {
          font-size: $font-sm;
        }
      }
    }

    .react-datepicker-wrapper {
      input {
        font-size: $font-sm;
        height: 31px;
        padding: 5px 8px;
      }

      &::after {
        height: 18px;
        top: 5px;
        width: 20px;
      }
    }

    .form-check-input {
      height: 20px;
      margin-right: 13px;
      margin-top: 0;
      width: 20px;

      &[type='checkbox'] {
        border-radius: 4px;
      }
    }
  }
}
