@import '../../assets/styles/variables';

.contacts-filter-container {
  background: $azure;
  border-radius: 25px;
  margin-bottom: 35px;
  padding: 20px;
  width: 100%;
  @include respondto(largeDesktopSm) {
    border-radius: 1.302vw;
    margin-bottom: 1.8229vw;
    padding: 1.042vw;
  }
  @include respondto(laptop13) {
    background: $white;
    border-radius: 0;
    margin-bottom: 0;
    padding: 0;
  }

  .header-section {
    .btns-section {
      button {
        background-color: $white !important;
      }
    }
  }
  .select-blue {
    .form-item-control-select {
      border-radius: 1rem;
    }
  }

  #formGridCheckbox {
    align-items: center;
    display: none;
    gap: 10px;
    .form-check-input {
      height: 30px;
      margin-top: 0px;
      width: 30px;
      @include respondto(largeDesktopSm) {
        height: 1.563vw;
        width: 1.563vw;
      }
    }
    .form-item-group-label {
      display: none;
      font-weight: 400;
      margin: 0;

      @include respondto(laptop13) {
        color: $black;
        display: flex;
      }
    }
    @include respondto(laptop13) {
      display: flex;
    }
  }

  .filter-container {
    background: $white;
    border-radius: 25px;
    box-shadow: 0px 27px 42.3px 0px rgba($black, 0.04);
    padding: 20px;
    @include respondto(largeDesktopSm) {
      border-radius: 1.302vw;
      padding: 1.042vw;
    }
    @include respondto(laptop13) {
      border-radius: 0;
      box-shadow: none;
      padding: 0;
    }

    form {
      flex-direction: row !important;
      gap: 20px;
      @include respondto(laptop13) {
        flex-direction: column !important;
        gap: 17px;
      }
      .form-item-group {
        label {
          font-size: 12px;
          margin-bottom: 5px;
        }
      }
      .form-item-control {
        border-radius: 5px;
        font-size: $font-sm;
        line-height: 16px;
        min-height: 30px;
        padding: 5px 15px;
      }
      .form-item-group {
        .form-item-control-select {
          border-radius: 5px;
          font-size: $font-sm;
          min-height: 28px;
          .react-select__indicator {
            padding: 4px;
          }
          .react-select__placeholder {
            font-size: $font-sm;
          }
        }
      }
      .react-datepicker-wrapper {
        height: 30px;
        input {
          border-radius: 5px;
          font-size: $font-sm;
          font-weight: 400;
          height: 30px;
        }
        &::after {
          height: 15px;
          right: 5px;
          top: 5px;
          width: 15px;
        }
      }

      .date-picker {
        label {
          font-size: 12px;
          line-height: 1;
          margin-bottom: 5px;
          margin-top: 0;
        }
      }
    }

    .form-line-section {
      display: flex;
      flex-direction: row;
      gap: 10px;
      justify-content: flex-start;
      width: 100%;
      @include respondto(laptop13) {
        width: 100%;
      }
      &:nth-of-type(2) {
        margin-bottom: 52px;
        @include respondto(largeDesktopSm) {
          margin-bottom: 2.708vw;
        }
        @include respondto(laptop13) {
          margin-bottom: 17px;
        }
      }

      .form-item-group {
        width: 170px;

        @include respondto(laptop13) {
          max-width: 100%;
        }
      }
    }
    .date-column {
      gap: 0 !important;
      position: relative;
    }
    .react-datepicker-popper {
      display: none !important;
    }
    .range-calendar {
      left: 0;
      position: absolute;
      top: 50px;
      z-index: 8;
    }
    .rdrDateRangePickerWrapper {
      box-shadow: $block-shadow;
    }
    .bottom-filter-section {
      margin-left: auto;
      width: calc(35% - 10px);
    }

    .form-btns-section {
      margin-left: auto;
    }
  }
}
