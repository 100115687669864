@import '../../assets/styles/variables';

.activities-modal {
  align-items: center;
  display: flex !important;
  justify-content: center !important;
  margin: 0 auto !important;
  .modal-dialog {
    bottom: auto !important;
    max-width: 744px !important;
    top: 0;
    width: 100%;
    @include respondto(phone) {
      max-width: 100%;
    }
  }
  @include respondto(phone) {
    padding: 0 !important;
  }
  .modal-header {
    border-bottom: none;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0em;
    line-height: 1.3;
    padding: 0;
    text-align: center;
    span {
      width: 100%;
    }

  }
  .modal-content {
    background-color: $azure !important;
    border-radius: 20px !important;
    padding: 56px 72px 20px;
    @include respondto(desktopXLg){
      margin-bottom: 50px;
      margin-top: 150px !important;
    }
    @include respondto(phone) {
      background-color: $white !important;
      margin-bottom: 100px;
      padding: 40px 10px 0;
    }
  }
  // .close-btn-modal {
  //   cursor: pointer;
  //   top: -100px;
  // }
  .add-activity-modal {
    background-color: $white;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 35px 63px;
    @include respondto(phone) {
      padding: 20px 10px 0;
    }
  }
  .flex-range {
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }
  .img-block {
    height: 4rem;
    margin-right: 6rem;
    width: 4rem;
  }
  .column-item {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }
  .column-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    span {
      font-size: 1.4rem;
    }
    .label {
      font-weight: 600;
    }

    a {
      font-size: 1.4rem;
    }
  }
  .close {
    align-items: center;
    background: $light-button-color;
    border: 1px solid $light-button-border;
    border-radius: 4px;
    display: flex;
    height: 34px;
    justify-content: center;
    transition: 0.3s ease-in-out;
    width: 34px;
    path {
      fill: $black;
    }
    &:hover {
      background: $light-button-color-hover;
    }
  }

  .btns-container {
    align-items: center;
    display: flex;
    gap: 17px;
    justify-content: center;
    width: 100%;
    .button-square-md {
      width: 30px;
    }
    .button-blue {
      position: relative;
    }
  }
}
