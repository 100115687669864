@import '../../assets/styles/variables';

.filter-particular-form-container {
  .header-section {
    justify-content: space-between;
    margin-bottom: 20px;

    .title-section {
      color: $black;
      font-size: 2rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @include respondto(laptop13) {
        text-align: center;
        width: 100%;
      }
    }

    .btns-section {
      align-items: center;
      display: flex;
      gap: 20px;

      @include respondto(laptop13) {
        display: none;
      }
    }
  }

  form {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    .form-line-section {
      align-items: center;
      display: flex;
      gap: 28px;
      justify-content: space-between;
      margin-bottom: 18px;
      margin-right: auto;
      max-width: 1032px;
      width: 100%;
      @include respondto(largeDesktopSm) {
        gap: 1.4583vw;
        margin-bottom: 0.938vw;
      }
      @include respondto(laptop13) {
        flex-direction: column;
        max-width: 100%;
      }
      > div {
        width: calc(25% - 28px);
        @include respondto(largeDesktopSm) {
          width: calc(25% - 1.4583vw);
        }
        @include respondto(laptop13) {
          width: 100%;
        }
      }

      .form-item-group {
        @include respondto(laptop13) {
          max-width: 100%;
        }
      }

      .date-picker {
        @include respondto(laptop13) {
          max-width: 100%;
        }
      }

      .form-group {
        max-width: 237px;
        width: 100%;

        @include respondto(laptop13) {
          max-width: 100%;
        }
      }

      label {
        color: $black;
        font-size: $font-sm;
        font-style: normal;
        font-weight: 300;
        line-height: 1;
        margin-bottom: 10px;
      }

      .flex-input {
        align-items: center;
        display: flex;
        gap: 9px;
        width: 100%;
        @include respondto(largeDesktopSm) {
          gap: 0.469vw;
        }

        @include respondto(laptop13) {
          max-width: 100%;
        }

        input {
          width: 100%;
        }

        .react-select__control {
          svg {
            transform: rotate(-90deg);
          }
        }
      }
    }

    .bottom-filter-section {
      margin-left: auto;

      .form-btns-section {
        align-items: center;
        display: flex;
        gap: 20px;

        .filter-btn {
          align-items: center;
          border: 1px solid $main-color;
          border-radius: 4px;
          display: flex;
          font-size: $font-md;
          height: 30px;
          justify-content: center;
          width: 223px;
        }
        .reset-btn {
          border-radius: 4px;
          height: 30px;
        }
      }
    }
  }
}
