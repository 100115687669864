@import '../../assets/styles/variables';

.request-matching {
  .ads-form-tabs.nav-tabs {
    margin-top: 27px;
    width: max-content;
  }
  .filters {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 2rem;
    .form-item-group-label {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 2.4rem;
    }
    .form-item-control {
      height: 34px;
    }
    .form-check {
      background-color: $input-color;
      border-radius: 4px;
      height: 34px;
      max-width: max-content;
      padding: 8px 10px 8px 30px;
    }
    .form-check label {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }
}
