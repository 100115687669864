@import './variables';

// 165px -> 8.594vw;
// 70px -> 3.646vw;
// 66px -> 3.4375vw;
// 60px -> 3.1250vw;
// 57px -> 2.9688vw;
// 48px -> 2.5vw;
// 47px -> 2.4479vw;
// 46px -> 2.3958vw;
// 44px -> 2.292vw;
// 42px -> 2.188vw;
// 41px -> 2.1354vw;
// 39px -> 2.0313vw;
// 38px -> 1.9792vw;
// 37px -> 1.9271vw;
// 36px -> 1.875vw;
// 30px -> 1.563vw;
// 33px -> 1.7188vw;
// 32px -> 1.667vw;
// 29px -> 1.51vw;
// 28px -> 1.4583vw;
// 27px -> 1.406vw;
// 26px -> 1.354vw;
// 25px -> 1.302vw;
// 24px -> 1.25vw;
// 23px -> 1.1979vw;
// 22px -> 1.1458vw;
// 21px -> 1.094vw;
// 20px -> 1.042vw;
// 19px -> 0.99vw;
// 18px -> 0.938vw;
// 17px -> 0.885vw;
// 16px -> 0.833vw;
// 15px -> 0.781vw;
// 14px -> 0.729vw;
// 13px -> 0.677vw;
// 12px -> 0.625vw;
// 10px -> 0.521vw;
// 9px -> 0.469vw;
// 5px-> 0.26vw;
// 3px -> 0.156vw;
html {
  font-size: 62.5%;
}

.main-container {
  min-height: 100vh;
  @media screen and (min-width: 1441px) {
    &:has(.side-nav-container.expanded) {
      .main-wrapper {
        padding-left: 250px;
      }
    }
  }
}

.container {
  height: 100%;
  margin: 0 auto;
  max-width: 1920px;
  padding: 20px;
  position: relative;
  transition: 0.3s ease-in-out;
  width: 100%;
  @include respondto(desktopXLg) {
    padding: 100px 15px 20px 15px;
  }

  &:has(.fixed-actions-container) {
    @include respondto(laptop13) {
      padding-bottom: 9rem;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: $black;
  box-shadow: inset 0 0 20px 20px $white;
  transition: background-color 5000s ease-in-out 0s;
}

a {
  text-decoration: none;
}

p {
  margin-bottom: 0;
}

img {
  display: block;
  height: auto;
}

.display-flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.gap-10 {
  gap: 10px;

  @include respondto(largeDesktopSm) {
    gap: 0.521vw;
  }
}

.main-wrapper {
  padding-left: 50px;
  transition: 0.3s ease-in-out;
  width: 100%;

  .header-container-mobile {
    display: none;
  }

  .swiper-dashboard-container {
    @include respondto(laptop13) {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
    }

    @include respondto(phoneSm) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .swiper {
      @include respondto(phoneSm) {
        order: 1;
      }
    }

    .slider__prev {
      @include respondto(phoneSm) {
        margin-right: 0.5rem;
        order: 2;
      }
    }

    .slider__next {
      @include respondto(phoneSm) {
        margin-left: 0.5rem;
        order: 3;
      }
    }
  }

  .dropdown-brands-container {
    display: none;
  }

  .header-menu {
    &:has(.mobile-nav-container.expanded) {
      @include respondto(desktopXLg) {
        &::before {
          background: rgba(0, 0, 0, 0.3);
          bottom: 0;
          content: '';
          height: 100%;
          left: 0;
          position: fixed;
          right: 0;
          top: 0;
          width: 100%;
          z-index: 99999;
        }
      }
    }
  }

  .mobile-nav-container {
    display: none;
    height: 100%;
    max-height: 100vh;
    max-width: 400px;
    overflow: auto;
    @include respondto(desktopXLg) {
      top: 0;
    }

    &::-webkit-scrollbar {
      height: 5px;
      padding-bottom: 20px;
      padding-top: 30px;
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: $scroll-bar-light;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      box-shadow: inset 0 0 60px rgba($scroll-bar-dark, 1);
    }
  }

  .fixed-actions-container {
    display: none;
  }

  @include respondto(desktopXLg) {
    padding-left: 0;

    .header-container-mobile {
      display: flex;
    }

    .mobile-nav-container {
      display: flex;
    }

    .header-container {
      .header-title {
        display: none;
      }

      .right-section {
        display: none;
      }
    }
  }

  @include respondto(laptop13) {
    padding-left: 0;

    .header-container-mobile {
      display: flex;
    }

    .mobile-nav-container {
      display: flex;
    }

    .dropdown-brands-container {
      display: block;
    }

    .fixed-actions-container {
      display: block;
    }
  }
}

.mobile-text {
  display: none;

  @include respondto(laptop13) {
    color: $black;
    display: block;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  @include respondto(phone) {
    font-size: 1.2rem;
  }
}

.hide-mobile {
  display: flex;

  @include respondto(laptop13) {
    display: none !important;
  }
}

.hide-on-desktop {
  @include respondto(onlyDesktop) {
    display: none;
  }
}

.mobile-lines {
  @include respondto(laptop13) {
    align-items: center;
    display: flex;
    gap: 15px;
    justify-content: space-between;
    width: 100%;
  }

  .form-check-input[type='checkbox'] {
    @include respondto(laptop13) {
      border: 1px solid $input-check-border-device;
    }
    @include respondto(desktopXLg) {
      box-shadow: none;
    }
  }
}

.line-border {
  @include respondto(laptop13) {
    background-color: $input-check-border-device;
    height: 1px;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 100%;
  }
}

.column-item {
  @include respondto(laptop13) {
    align-items: center;
    display: flex;
    gap: 15px;
    justify-content: space-between;
    width: 100%;
  }
}

.title-section {
  color: $black;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @include respondto(largeDesktopMd) {
    font-size: 1.25vw;
  }
  @include respondto(laptopMd) {
    font-size: 1.3889vw;
  }
  @include respondto(phone) {
    font-size: 2rem;
  }
}

.btn-blue {
  background: $main-color;
  border: 1px solid $main-color;
  color: $white;
  transition: 0.3s ease-in-out;

  &:hover:not(:disabled):not([aria-disabled='true']) {
    background-color: $white;
    color: $main-color;
  }
}

.bg-blue {
  background-color: $azure;
  border-radius: 25px;
  @include respondto(largeDesktopSm) {
    border-radius: 1.302vw;
  }
  @include respondto(desktop) {
    padding: 1.51vw;
  }
}

.bg-white {
  background: $white;
  border-radius: 15px;
  box-shadow: $block-shadow;
}

.sr-only {
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.section-container {
  display: flex;
  flex-wrap: wrap;
}

.heading-block {
  color: $black;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;

  @include respondto(phone) {
    text-align: center;
  }
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: -40px !important;
}

.react-datepicker,
.react-datepicker__time-container,
.react-datepicker__time-container .react-datepicker__time,
.react-datepicker__time-list-item {
  font-size: $font-sm;
}

.react-datepicker__header {
  padding-top: 0.8rem;
}

.react-datepicker__month {
  margin: 0.4rem 1rem;
}

.react-datepicker__day-name,
.react-datepicker__day {
  line-height: 1.9rem;
  margin: 0.166rem;
  width: 1.9rem;

  @include respondto(largeDesktopMd) {
    font-size: 0.6vw !important;
  }
  @include respondto(laptop13) {
    font-size: 1.3vw !important;
  }
  @include respondto(phone) {
    font-size: 1rem !important;
  }
}

.react-datepicker__current-month {
  font-size: 1rem;

  @include respondto(largeDesktopMd) {
    font-size: 0.8vw !important;
  }
  @include respondto(laptop13) {
    font-size: 1.6vw !important;
  }
  @include respondto(phone) {
    font-size: 1.2rem !important;
  }
}

.react-datepicker__navigation {
  line-height: 1.7rem;
  top: 0;
}

.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1rem;
}

.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1rem;
}

.react-datepicker-wrapper {
  position: relative;
  width: 100%;

  input {
    background: $input-color;
    border-radius: 4px;
    color: rgba($black, 0.62);
    font-size: 1.4rem;
    height: 34px;
    padding: 5px 30px 5px 14px;
    width: 100%;

    &::placeholder {
      color: $input-placeholder;
      font-size: $font-sm;
    }
  }

  &::after {
    background-image: url('../../assets/svg/calendarpicker.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    height: 18px;
    position: absolute;
    right: 8px;
    top: 6px;
    width: 20px;
  }
}

.date-picker {
  width: 100%;

  label {
    color: $black;
    font-size: $font-sm;
    font-style: normal;
    font-weight: 300;
    margin-bottom: 2px;
    margin-top: -2px;
  }
}

.select-propreties {
  display: none;

  @include respondto(laptop13) {
    display: block;
    margin-bottom: 20px;

    .form-item-group {
      .form-item-control-select {
        background-color: $input-bg;
        border: 1px solid $input-border-color;
        border-radius: 4px;
        color: $input-font-color !important;
        font-size: $font-md;
        line-height: 17px;
        min-height: 34px;
        width: 100%;

        .react-select__single-value,
        .react-select__input-container,
        .react-select__placeholder {
          color: $input-font-color !important;
        }

        svg {
          fill: $input-font-color;
        }
      }
    }
  }
}

.react-select__single-value {
  color: $black !important;
  font-family: Poppins;
  font-weight: 500;
  text-align: left;
}

.react-select__indicators {
  height: auto !important;
  max-height: 100%;
}

.mobile-modal {
  .modal-dialog {
    border-radius: 20px 20px 0 0;
    bottom: 45px;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 100vw;
    position: absolute;
    width: 100%;
    @include respondto(largeDesktopSm) {
      border-radius: 1.042vw 1.042vw 0 0;
    }

    .modal-content {
      border: none;
      border-radius: 20px 20px 0 0;
      min-height: 100px;
      @include respondto(largeDesktopSm) {
        border-radius: 1.042vw 1.042vw 0 0;
      }

      .modal-body {
        padding: 40px 15px 55px;
        @include respondto(desktopSmall) {
          padding: 50px 15px 55px;
        }
      }
    }
  }

  .close-btn-modal {
    align-items: center;
    background: transparent;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: 5px;
    text-align: center;
    top: 5px;
    width: 40px;
    z-index: 1;
    &:hover {
      background: $main-color;
      opacity: 0.4;
    }
  }

  &.filter-overflow {
    .modal-dialog {
      height: max-content;
      max-height: 60vh;
    }

    .modal-body {
      padding-bottom: 100px !important;
    }
  }
}

.form-check {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 13px;
  padding-left: 16px;

  @include respondto(largeDesktopSm) {
    gap: 0.677vw;
  }

  input[type='checkbox'] {
    background-color: $white;
    border: 1px solid $input-check-border;
    border-radius: 3px;
    box-shadow: 0 0 0 0.5px $input-check-border;
    height: 16px;
    margin-left: -16px;
    margin-top: 0;
    padding: 0;
    width: 16px;

    @include respondto(desktopXLg) {
      box-shadow: none;
    }

    &:checked {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01Ljg2MzMgMTEuOTk5OUM1LjU4NzMgMTEuOTk5OSA1LjMyMzMgMTEuODg1OSA1LjEzNDMgMTEuNjg0OUwwLjI3MTI5OCA2LjUwNTg3Qy0wLjEwNzcwMiA2LjEwMzg3IC0wLjA4NjcwMTkgNS40NzA4NyAwLjMxNTI5OCA1LjA5Mjg3QzAuNzE4Mjk4IDQuNzE0ODcgMS4zNTEzIDQuNzM0ODcgMS43MjgzIDUuMTM2ODdMNS44NTMzIDkuNTI3ODdMMTQuMjYxMyAwLjMyNTg2OUMxNC42MzUzIC0wLjA4MzEzMTQgMTUuMjY3MyAtMC4xMTAxMzEgMTUuNjc1MyAwLjI2MTg2OUMxNi4wODIzIDAuNjMzODY5IDE2LjExMDMgMS4yNjY4NyAxNS43MzgzIDEuNjczODdMNi42MDEzIDExLjY3MzlDNi40MTQzIDExLjg3OTkgNi4xNDgzIDExLjk5NzkgNS44NzAzIDExLjk5OTlINS44NjMzWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 11px;
      border: 1.5px solid $main-color !important;
      border-color: $main-color !important;
      box-shadow: 0 0 0 0.5px $main-color !important;
    }
  }

  .form-check-input[type='radio'] {
    border: 1px solid $input-check-border;
    box-shadow: 0 0 0 0.5px $input-check-border;
    height: 16px;
    margin-left: -17px;
    position: relative;
    width: 16px;

    @include respondto(desktopXLg) {
      box-shadow: none;
    }

    &::before {
      background-color: $main-color;
      border-radius: 50%;
      content: '';
      display: block;
      height: 10px;
      opacity: 0;
      position: absolute;
      right: 2px;
      top: 2px;
      visibility: hidden;
      width: 10px;
    }

    &:checked {
      background: $white;
      border: 1px solid $main-color !important;
      box-shadow: 0 0 0 0.5px $main-color;

      @include respondto(desktopXLg) {
        box-shadow: none;
      }

      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  label {
    color: $black;
    font-size: $font-md;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6rem;
  }
}

.form-check-input[type='checkbox'] {
  background-color: $white;
  border: 1px solid $input-check-border;
  box-shadow: 0 0 0 0.5px $input-check-border;

  @include respondto(desktopXLg) {
    box-shadow: none;
  }

  &[type='checkbox'] {
    border-radius: 3px;

    // @include respondto(largeDesktopSm) {
    //   border-radius: 0.3646vw;
    // }
  }

  &:checked {
    background-color: $main-color;
    border: 1.5px solid $main-color;
    border-color: $main-color;
    box-shadow: 0 0 0 0.5px $main-color;

    @include respondto(desktopXLg) {
      box-shadow: none;
    }

    &[type='checkbox'] {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01Ljg2MzMgMTEuOTk5OUM1LjU4NzMgMTEuOTk5OSA1LjMyMzMgMTEuODg1OSA1LjEzNDMgMTEuNjg0OUwwLjI3MTI5OCA2LjUwNTg3Qy0wLjEwNzcwMiA2LjEwMzg3IC0wLjA4NjcwMTkgNS40NzA4NyAwLjMxNTI5OCA1LjA5Mjg3QzAuNzE4Mjk4IDQuNzE0ODcgMS4zNTEzIDQuNzM0ODcgMS43MjgzIDUuMTM2ODdMNS44NTMzIDkuNTI3ODdMMTQuMjYxMyAwLjMyNTg2OUMxNC42MzUzIC0wLjA4MzEzMTQgMTUuMjY3MyAtMC4xMTAxMzEgMTUuNjc1MyAwLjI2MTg2OUMxNi4wODIzIDAuNjMzODY5IDE2LjExMDMgMS4yNjY4NyAxNS43MzgzIDEuNjczODdMNi42MDEzIDExLjY3MzlDNi40MTQzIDExLjg3OTkgNi4xNDgzIDExLjk5NzkgNS44NzAzIDExLjk5OTlINS44NjMzWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 11px;

      // @include respondto(largeDesktopSm) {
      //   background-size: 0.833vw;
      // }
    }
  }
}

.form-switch {
  &.form-check-reverse {
    flex-direction: row-reverse;
  }

  &.form-check-reverse,
  & {
    .form-check-input {
      --bs-form-switch-bg: none !important;
      border: 1px solid $main-color;
      box-shadow: 0 0 0 0.5px $main-color;
      margin-right: 0;
      position: relative;

      @include respondto(desktopXLg) {
        box-shadow: none;
      }

      &[type='checkbox'] {
        border-radius: 20px;
        height: 24px;
        width: 47px;
        @include respondto(largeDesktopSm) {
          border-radius: 1.042vw;
          height: 1.25vw;
          width: 2.4479vw;
        }
      }

      &::before {
        background-color: $main-color;
        border-radius: 50%;
        content: '';
        height: 16px;
        left: 3px;
        position: absolute;
        top: 3px;
        transition: left 0.3s linear;
        width: 16px;
        @include respondto(largeDesktopSm) {
          height: 0.833vw;
          left: 0.156vw;
          top: 0.156vw;
          width: 0.833vw;
        }
      }

      &:checked {
        background-color: $main-color;
        border-color: $main-color;

        &[type='checkbox'] {
          background-size: 24px;
          @include respondto(largeDesktopSm) {
            background-size: 1.25vw;
          }
        }

        &::before {
          background-color: $white;
          left: calc(100% - 21px);
          @include respondto(largeDesktopSm) {
            left: calc(100% - 1.094vw);
          }
        }
      }
    }
  }
}

//lables

.label-item {
  &-info {
    &,
    &-lg {
      align-items: center;
      background-color: $input-color;
      border-radius: 5px;
      color: $black;
      display: flex;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: normal;
      padding: 5px 10px;
      width: 100%;
    }

    &-lg {
      height: 28px;
    }
  }
  &-green,
  &-link {
    align-items: center;
    border-radius: 5px;
    color: $black;
    display: flex;
    font-size: 1.2rem;
    font-weight: 400;
    gap: 10px;
    height: 28px;
    justify-content: center;
    line-height: normal;
    padding: 7px;
    transition: 0.3s ease-in-out;

    // @include respondto(largeDesktopMd) {
    //   // font-size: 0.833vw;
    //   height: 1.9792vw;
    // }

    // @include respondto(largeDesktopSm) {
    //   border-radius: 0.4167vw;
    //   padding: 0.3646vw;
    // }

    // @include respondto(laptopMd) {
    //   font-size: 1.0417vw;
    //   height: 2.5694vw;
    // }

    @include respondto(phone) {
      font-size: 1.2rem;
      height: 31px;
    }
  }
  &-mark {
    align-items: center;
    background: $info-labels;
    border-radius: 5px;
    color: $black;
    display: flex;
    font-size: 1.2rem;
    font-weight: 400;
    gap: 10px;
    height: 28px;
    justify-content: center;
    line-height: normal;
    padding: 7px;
  }
  &-link {
    background: $light-button-color;
    border: 1px solid $light-button-border;
    &:hover {
      background: $light-button-color-hover;
    }

    svg {
      height: 1.7rem;
      transition: 0.3s ease-in-out;
      width: 1.7rem;
    }
  }

  &-tag {
    align-items: center;
    background: $input-color;
    border-radius: 4px;
    color: $black;
    display: flex;
    font-size: 1.2rem;
    font-weight: 400;
    gap: 10px;
    line-height: normal;
    min-height: 28px;
    padding: 4px 15px;

    @include respondto(largeDesktopSm) {
      // font-size: 0.833vw;
      gap: 0.521vw;
      // min-height: 1.9792vw;
      padding: 0.2083vw 0.781vw;
    }

    // @include respondto(laptopMd) {
    //   font-size: 1.0417vw;
    // }

    @include respondto(phone) {
      font-size: 12px;
      font-weight: 400;
      min-height: 32px;
      padding: 6px 15px;
    }

    img,
    svg {
      height: 16px;
      width: 16px;
    }
  }

  &-mobile {
    @include respondto(laptop13) {
      align-items: center;
      background: $input-color !important;
      border-radius: 8px !important;
      color: $black;
      display: flex;
      flex-wrap: wrap;
      font-size: 1.6rem !important;
      font-weight: 400 !important;
      gap: 10px;
      justify-content: flex-start !important;
      line-height: normal;
      min-height: 38px !important;
      padding: 7px 15px !important;
      width: 50% !important;
    }

    @include respondto(phone) {
      box-shadow: 0 27px 42.3px 0 rgba($black, 0.04);
      font-size: 12px !important;
      font-weight: 400 !important;
      min-height: 32px !important;
      padding: 6px 15px;
    }
  }
}

.label-item-rounded {
  &-info {
    &,
    &-sm,
    &-lg {
      align-items: center;
      background: $input-color;
      border-radius: 5px;
      color: $black;
      display: flex;
      font-weight: 500;
      gap: 10px;
      justify-content: center;
      width: fit-content;
      @include respondto(largeDesktopSm) {
        border-radius: 0.26vw;
        gap: 0.521vw;
      }
    }

    & {
      font-size: 1.2rem;
      height: 28px;
      padding: 4px 20px;
      text-align: center;
      @include respondto(largeDesktopSm) {
        // height: 2.292vw;
        padding: 0.2083vw 1.042vw;
      }
      // @include respondto(largeDesktopMd) {
      //   font-size: 0.833vw;
      // }

      // @include respondto(laptopMd) {
      //   font-size: 0.9722vw;
      // }
      @include respondto(phone) {
        font-size: 1.2rem;
        height: auto;
        min-height: 28px;
        padding: 5px 10px;
      }

      img {
        height: auto !important;
        width: 1.8rem !important;
        @include respondto(largeDesktopSm) {
          width: 0.938vw !important;
        }
        @include respondto(phone) {
          width: 1.4rem !important;
        }
      }

      svg {
        height: 1.8rem !important;
        width: 1.8rem !important;
        @include respondto(largeDesktopSm) {
          height: 0.938vw !important;
          width: 0.938vw !important;
        }
        @include respondto(phone) {
          height: 1.4rem !important;
          width: 1.4rem !important;
        }
      }
    }

    &-lg {
      font-size: 1.2rem;
      height: 28px;
      padding: 4px 20px;
      text-align: center;
      @include respondto(largeDesktopSm) {
        // height: 2.5vw;
        padding: 0.2083vw 1.042vw;
      }
      // @include respondto(largeDesktopMd) {
      //   font-size: 0.833vw;
      // }

      @include respondto(laptopMd) {
        // font-size: 1.0417vw;
        height: auto;
      }
      @include respondto(phone) {
        font-size: 1.2rem;
        height: auto;
        min-height: 30px;
        padding: 5px 10px;
      }

      img {
        height: auto !important;
        width: 1.8rem !important;

        @include respondto(largeDesktopMd) {
          width: 0.938vw !important;
        }

        @include respondto(laptopMd) {
          width: 1.1111vw !important;
        }
        @include respondto(phone) {
          width: 1.4rem !important;
        }
      }

      svg {
        height: 1.8rem;
        width: 1.8rem;

        @include respondto(largeDesktopMd) {
          height: 0.938vw;
          width: 0.938vw;
        }

        @include respondto(laptopMd) {
          height: 1.1111vw;
          width: 1.1111vw;
        }
        @include respondto(phone) {
          height: 1.4rem;
          width: 1.4rem;
        }
      }
    }

    &-sm {
      font-size: 1.2rem;
      min-height: 28px;
      padding: 5px 14px;
      @include respondto(largeDesktopSm) {
        height: fit-content;
        // min-height: 1.615vw;
        padding: 0.26vw 0.729vw;
      }
      // @include respondto(largeDesktopMd) {
      //   font-size: 0.729vw;
      // }

      // @include respondto(laptopMd) {
      //   font-size: 0.9028vw;
      // }
      @include respondto(phone) {
        font-size: 1.2rem;
        height: auto;
        min-height: 25px;
        padding: 5px 10px;
      }

      img {
        height: auto !important;
        width: 1.4rem !important;
        @include respondto(largeDesktopSm) {
          width: 0.729vw !important;
        }
        @include respondto(phone) {
          width: 1.2rem !important;
        }
      }

      svg {
        height: 1.4rem !important;
        width: 1.4rem !important;
        @include respondto(largeDesktopSm) {
          height: 0.729vw !important;
          width: 0.729vw !important;
        }
        @include respondto(phone) {
          height: 1.2rem;
          width: 1.2rem !important;
        }
      }
    }
  }

  &-tag {
    &,
    &-sm {
      align-items: center;
      background: $input-color;
      border-radius: 5px;
      display: flex;
      font-weight: 500;
      gap: 10px;
      justify-content: center;
      text-align: center;
      width: fit-content;
    }

    & {
      font-size: 1.2rem;
      min-height: 28px;
      padding: 4px 20px;

      @include respondto(largeDesktopMd) {
        // font-size: 0.833vw;
        // min-height: 1.4583vw;
        padding: 0.2083vw 1.042vw;
      }

      // @include respondto(laptopMd) {
      //   // font-size: 0.9722vw;
      //   // min-height: 2.8472vw;
      //   padding: 0.521vw 1.042vw;
      // }
      @include respondto(phone) {
        font-size: 1.2rem;
        height: auto;
        min-height: 30px;
        padding: 5px 10px;
      }
    }
  }
}

.label-info {
  &-inactive,
  &-active {
    align-items: center;
    border-radius: 5px;
    color: $white;
    display: flex;
    font-size: 1.2rem;
    font-weight: 500;
    height: 28px;
    justify-content: center;
    line-height: normal;
    min-width: 89px;
    padding: 12px;
    text-align: center;
    @include respondto(largeDesktopSm) {
      // border-radius: 1.354vw;
      // height: 2.0313vw;
      // min-width: 4.635vw;
      padding: 0.625vw;
    }
    // @include respondto(largeDesktopMd) {
    //   font-size: 0.729vw;
    // }
    // @include respondto(laptopMd) {
    //   font-size: 0.9028vw;
    // }
    @include respondto(phone) {
      font-size: 1.2rem;
    }

    &-sm {
      align-items: center;
      border-radius: 4px;
      color: $white;
      display: flex;
      font-size: 1.2rem;
      font-weight: 500;
      height: 19px;
      justify-content: center;
      line-height: normal;
      min-width: 49px;
      padding: 3px 9px;
      text-align: center;
      @include respondto(largeDesktopSm) {
        height: 0.99vw;
        min-width: 2.552vw;
        padding: 0.156vw 0.469vw;
      }
      @include respondto(largeDesktopMd) {
        font-size: 0.625vw;
      }
      @include respondto(laptopMd) {
        font-size: 0.764vw;
      }
    }
  }

  &-active,
  &-active-sm {
    background: #4aba7026;
    color: $black;
    font-weight: 400;
  }

  &-inactive {
    background: $gray;
  }
}

//More button
.item-details-container {
  &.close {
    @include respondto(laptop13) {
      height: 125px;
      overflow: hidden;
    }
  }
}

.more-button {
  background-color: $white;
  width: 100%;

  &:hover {
    span {
      color: $main-color;
    }
  }

  span {
    color: $black;
    display: block;
    font-size: 10px;
    font-weight: 600;
    transition: 0.3s ease-in-out;
  }

  .more-button-border {
    position: relative;

    &::before {
      background-color: $input-color;
      bottom: 0;
      content: '';
      display: block;
      height: 1px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }
  }

  .arrow-icon {
    background-color: $white;
    margin: 0 auto;
    padding: 10px 28px;
    position: relative;
    transition: 0.3s ease-in-out;
    width: fit-content;
    z-index: 2;

    svg,
    img {
      transition: 0.3s ease-in-out;
    }
  }

  &:not(.open) {
    .arrow-icon {
      svg,
      img {
        transform: rotate(180deg);
      }
    }
  }
}

.select-tabs {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: $light-button-color;
  background-image: url('../../assets/svg/arrowdrop.svg');
  background-position: calc(100% - 1rem);
  background-repeat: no-repeat;
  background-size: 2.5rem;
  border: 1px solid $light-button-border;
  border-radius: 0.4rem;
  color: $light-button-font;
  font-size: $font-md;
  font-weight: 600;
  height: 3.4rem;
  line-height: 2rem;
  margin-bottom: 2rem;
  padding: 0.4rem 1.5rem;
  width: 100%;

  option {
    background-color: $white;
    color: $black;
    font-size: $font-md;
    padding: 2rem;
  }
}

.form-item-control.error,
input.error {
  border: 1px solid $red;
}
.form-item-group .error-message {
  font-size: 1.2rem;
  line-height: 120%;
  @include respondto(largeDesktopSm) {
    margin-top: 0.469vw;
  }
  @include respondto(largeDesktopMd) {
    font-size: 0.625vw;
  }
  @include respondto(laptopMd) {
    font-size: 0.764vw;
  }
}

.react-datepicker-popper {
  box-shadow: $block-shadow;
  font-family: 'Poppins';
  margin-top: 10px;
  padding: 0 !important;

  .react-datepicker {
    border: none;
    min-width: 200px;
  }

  .react-datepicker--time-only {
    min-width: auto;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    background-color: $white;
    border: none;
    width: 100%;
  }

  .react-datepicker__header--time--only {
    display: none;
  }

  .react-datepicker__day-names {
    display: flex;
    font-weight: 400;
    justify-content: space-around;
    text-transform: uppercase;
  }

  .react-datepicker__current-month {
    font-size: 1vw;
    font-weight: 300;
    height: 30px;
  }

  .react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__week {
    display: flex;
    justify-content: space-around;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__day {
    font-size: 0.8vw;
  }

  .react-datepicker__day--weekend {
    color: $red;
  }

  .react-datepicker__header__dropdown--select {
    font-family: 'Poppins';
    font-size: 1.3rem;
    font-weight: 300;
    margin: 5px 0;
  }

  .react-datepicker__year-select,
  .react-datepicker__month-select {
    font-weight: 300;
  }
}

.react-datepicker-wrapper {
  input {
    font-size: 0.7vw;
    @include respondto(largeDesktopMd) {
      font-size: 0.6vw;
    }
    @include respondto(laptop13) {
      font-size: 1.4rem;
    }
  }
}

.react-datepicker__day--outside-month {
  color: gray !important;
  opacity: 0.7;
}

.rdrDateDisplayItemActive {
  border-color: $main-color !important;
}

.rdrStaticRangeLabel {
  color: $main-color !important;
}

.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  color: $main-color !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background: $main-color !important;
}

.rdrDayInPreview {
  border-color: $main-color !important;
}

@media screen and (max-width: 1370px) {
  .fc-v-event .fc-event-title {
    max-width: 62%;
    word-break: break-word;
  }
}
@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
.btn-fixed-right {
  align-items: center;
  background: $white;
  border-radius: 10px;
  bottom: 2.4rem;
  box-shadow: $block-shadow;
  display: flex;
  font-size: 1.3rem;
  font-weight: 400;
  gap: 3.2rem;
  line-height: 2.1rem;
  padding: 9px;
  position: fixed;
  right: 2.4rem;
  z-index: 99;
  @include respondto(phone) {
    font-size: 1.3rem;
    gap: 1rem;
    right: 1rem;
  }
  .btn-label {
    display: flex;
    flex-direction: row;
  }
  .ring-bell {
    margin-right: 1rem;
  }
  svg {
    height: 2.4rem;
    width: 2.4rem;
    @include respondto(phone) {
      height: 1.8rem;
      width: 1.8rem;
    }
  }
  .btn-squere-blue {
    background: $main-color;
    border: 1px solid $main-color;
    @include respondto(phone) {
      height: 3rem;
      width: 3rem;
    }
    svg,
    img {
      height: 1.5rem;
      width: 1.5rem;
    }
    svg {
      fill: $white;
      path {
        fill: $white;
      }
    }
  }

  strong {
    font-weight: 600;
  }
  &:hover {
    background: $main-color;
    color: $white;
    svg {
      fill: $white;
      path {
        fill: $white;
      }
    }
    .ring-bell {
      -webkit-animation: ring 4s 0.1s ease-in-out infinite;
      -moz-animation: ring 4s 0.1s ease-in-out infinite;
      animation: ring 4s 0.1s ease-in-out infinite;
      -webkit-transform-origin: 50% 4px;
      -moz-transform-origin: 50% 4px;
    }
    .btn-squere-blue {
      background: $white;
      border: 1px solid $white;
      svg {
        fill: $main-color;
        path {
          fill: $main-color;
        }
      }
    }
  }
}
