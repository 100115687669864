/**
 * Variables
 */

// ============================================================================= Breakpoints
@mixin respondto($device) {
  @if $device ==largeDesktopMd {
    @media #{$largeDesktopMd} {
      @content;
    }
  }

  @if $device ==largeDesktopSm {
    @media #{$largeDesktopSm} {
      @content;
    }
  }

  @if $device ==largeDesktopXlg {
    @media #{$largeDesktopXlg} {
      @content;
    }
  }

  @if $device ==largeDesktopXXl {
    @media #{$largeDesktopXXl} {
      @content;
    }
  }

  @if $device ==laptopMd {
    @media #{$laptopMd} {
      @content;
    }
  }

  @if $device ==largeDesktopLg {
    @media #{$largeDesktopLg} {
      @content;
    }
  }

  @if $device ==desktopXLg {
    @media #{$desktopXLg} {
      @content;
    }
  }

  @if $device ==desktopLg {
    @media #{$desktopLg} {
      @content;
    }
  }

  @if $device ==largeDesktop {
    @media #{$largeDesktop} {
      @content;
    }
  }

  @if $device ==desktopMd {
    @media #{$desktopMd} {
      @content;
    }
  }

  @if $device ==desktop {
    @media #{$desktop} {
      @content;
    }
  }

  @if $device ==desktopSmall {
    @media #{$desktopSmall} {
      @content;
    }
  }

  @if $device ==desktopSm {
    @media #{$desktopSm} {
      @content;
    }
  }

  @if $device ==onlyDesktop {
    @media #{$onlyDesktop} {
      @content;
    }
  }

  // @if $device ==maxTablet {
  //     @media #{$maxTablet} {
  //         @content;
  //     }
  // }

  @if $device ==tablet {
    @media #{$tablet} {
      @content;
    }
  }

  @if $device ==not-phone {
    @media #{$not-phone} {
      @content;
    }
  }

  @if $device ==phone {
    @media #{$phone} {
      @content;
    }
  }

  @if $device ==phoneSm {
    @media #{$phoneSm} {
      @content;
    }
  }

  @if $device ==laptop13 {
    @media #{$laptop13} {
      @content;
    }
  }
}

//Media Queries
$largeDesktopMd: '(min-width: 1441px) and (max-width: 1920px)';
$largeDesktopSm: '(min-width: 1081px) and (max-width: 1920px)';
$laptopMd: '(min-width: 1081px) and (max-width: 1440px)';
$largeDesktopXXl: '(min-width: 2300px)';
$largeDesktopXlg: '(min-width: 1700px)';
$largeDesktopLg: '(min-width: 1500px)';
$desktopXLg: '(max-width: 1440px)';
$desktopLg: '(max-width: 1399px)';
$largeDesktop: '(min-width: 1301px)';
$desktopMd: '(max-width: 1300px)';
$desktop: '(min-width: 1200px)';
$desktopSmall: '(max-width: 1199px)';
$desktopSm: '(min-width: 1081px) and (max-width: 1199px)';
$onlyDesktop: '(min-width: 1081px)';
$laptop13: '(max-width: 1080px)';

// $maxTablet: '(max-width: 991px)';
$tablet: '(min-width: 768px) and (max-width: 1150px)';

$not-phone: '(min-width: 768px)';
$phone: '(max-width: 767px)';
$phoneSm: '(max-width: 450px)';

// ============================================================================= Colors
// background-color: palette(main, base);
//Changing variables
$main-color: #ff7a45;
$main-color-hover: #ff5e1d;
$main-color-second: #ff5e1d;
$scroll-bar-dark: #33475b;
$scroll-bar-light: #f2f2fc;
$calendar-prev-bg: #f1f6fe;
$block-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);

$marks-color: #e9e9ff;
//input
$input-color: #f5f5f5;
$input-check-border: #696868;
$input-check-border-device: rgba(51, 71, 91, 0.1);
$input-placeholder: rgba(52, 71, 91, 0.5);
//Table colors
$table-head-bg: #51699a17;
$table-body-even-bg: #f5f5f5;
$table-head-font-color: #33475b;
//icons header
$icon-svg-fill: #231f20;
$nav-icons: #1d1956;
$nav-buttons: rgba(212, 211, 238, 0.41);
//links
$blue: #3176fe;

//Pagination
$light-button-color: #e9f0f6;
$light-button-font: #4f6e91;
$light-button-border: #cbd6e1;
$light-button-color-hover: #f5f9fa;
$text-color-pagination: #939393;
//labels
$info-labels: #4aba7026;
$info-lables-device-color: #f2f2fa;

$info-budget: #c5c5ff;

$header-title: #1d1859;
$dark-blue: #1b1a54;
//end Changing variables
$gainsboro: #d9d9d9;
///
$white: #fff;
$black: #33475b;
$black-shade: #0f0f0f;
$blue-genie: #6f6bf9;
$blue-genie-hover: #4c46f6;
// $blue-pink: #827eff;
// $blue-royal: #020202;
// $brink-pink: #6283ff;
// $french-sky-blue: #718FFF;
$rose-bud: #9d9aff;
$azure: #f0f2f6;
$bright-dusk: #ececfb;
// $zinc-dust: #5b5b5b;
// $glitter: #e9e9ff;
$gray: #484848;
$dark-gray: #3e3e3e;
$maingrey: #f5f8fa;
$silver: #a9a4a4;
$ghost-white: #f5f5fd;
$delta-ligthness: #e9ecef;
$platinum: #e5e0e0;
// $white-smoke: #eeeefc;
$light-slate-blue: #e3e3fe;
// $lavender: #eaeaff;
// $gainsboro: #dbdada;
$whisper: #e5e5e5;
$whisper-second: #ededed;
$light-shade-gray: #e6e6e6;
$dim-gray: #6f6e6e;
$dim-gray-2: #676767;
$dark-charcoal: #333;
$red: #f80018;
$gainsboro-second: #dedede;
// $lavender-blue: #d3d3ff;
$gray-second: #7e7e7e;

$nav-color: #33475b;
$nav-color-active: #415a75;
$tab-color: #e8e9eb;
$input-bg: #e9f0f6;
$input-border-color: #cbd6e1;
$input-font-color: #4f6e91;
$bg-page: #f5f8fa;

// ============================================================================= Fonts
$font-lg: 1.6rem;
$font-md: 1.3rem;
$font-sm: 1.2rem;

$mainFont: 'Poppins', sans-serif;
