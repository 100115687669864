@import "../../assets/styles/variables";

.heading-block-sm {
    align-items: center;
    color: $black;
    display: flex;
    flex-wrap: wrap;
    gap: 0 12px ;
    margin-bottom: 12px;
    @include respondto(largeDesktopSm) {
        gap: 0 0.625vw;
        margin-bottom: 0.625vw;
    }
    .title {
        color: $black;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.8rem;
        margin-bottom: 0;
    }

    span {
        font-size: 1.2rem;
        line-height: 18px;
        letter-spacing: -0.36px;
        @include respondto(largeDesktopSm){
            line-height: 1.1;
        }
    }

}

.form-item-block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0  1.3rem;
    @include respondto(largeDesktopSm){
        gap: 0 0.677vw;
    }
    .col-label-lg {
        width: 100%;
    }
    .col-half {
        width: calc(50% - .65rem);
        @include respondto(largeDesktopSm){
            width: calc(50% - 0.3646vw);
        }
    }

}

.add-images-area-form {
    .form-item {
        margin-bottom: 1.7rem;
        @include respondto(largeDesktopSm){
            margin-bottom: 0.885vw;
        }
    }
    .fieldset-block__hr {
        margin-top: 2.5rem;
        @include respondto(largeDesktopSm){
            margin-top: 1.302vw;
        }
    }
}