@import '../../assets/styles/variables';

.request-list-filter {
  .form-check-input {
    margin-right: 21px !important;
    @include respondto(largeDesktopSm) {
      margin-right: 1.094vw !important;
    }
  }

  form {
    display: grid;
    grid-template-columns: 1.6% 4% 14.695% 24.329% 10.122% 10.122% 10% 10% 10% !important;
  }

  .filter-item {
    max-width: unset !important;
    padding: 0 26px !important;

    @include respondto(largeDesktopSm) {
      padding: 0 1.25vw !important;
    }

    .form-item-control {
      padding: 12px 10px;
      @include respondto(largeDesktopSm) {
        padding: 0.1vw 0.521vw;
      }
    }

    &:nth-of-type(2) {
      padding-left: 0 !important;
    }
  }
}
